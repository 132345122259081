import React from 'react';
import { connect } from 'react-redux';
import { setPathname } from '../../services/common/commonActions';
import { getRequests } from '../../services/request/requestActions';
import { showModal } from '../../modals/modalConductorActions';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import 'datatables.net'
import 'datatables.net-bs'
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
class Request extends React.Component {
    constructor(props) {
        super();
        this.receivedTable = null;
        this.madeTable = null;
        this.state = {
            owner_ontoclass_id: [],
            receivedRequests: [],
            madeRequests: [],
            profile: ''
        }
        $.fn.dataTableExt.oSort["customdate-desc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();
            if (x > y) {
                return -1;
            }
            return 1;
        };

        $.fn.dataTableExt.oSort["customdate-asc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();

            if (x > y) {
                return 1;
            }
            return -1;
        }
    }
    componentWillMount() {
        this.props.commonActions.setPathname(
            [
                { "pathName": "Home", "Url": "", "id": 1, "color": "" },
                { "pathName": "Requests", "Url": "requests", "id": 2, "color": "#bfa422", "subHeader": "Requests" }
            ]
        )
        this.props.requestActions.getRequests();
        if (this.props.auth && this.props.auth.currentUser) {
            var temp = [];
            var ontoclasses = this.props.auth.currentUser.ontoclasses;
            if (ontoclasses && ontoclasses.length > 0) {
                for (let ontoclass of ontoclasses) {
                    if (ontoclass) {
                        temp.push(ontoclass.ontoclass_id)
                    }
                }
                this.setState({ owner_ontoclass_id: temp }, () => { console.log('--=-=-==-==', this.state.owner_ontoclass_id) });
            }

            var profile = this.props.auth.currentUser.profile;
            if (profile) {
                this.setState({ profile });
            }
        }
    }
    drawTable = () => {
        this.receivedTable.clear();
        if (this.state.receivedRequests && this.state.receivedRequests.length > 0) {
            this.receivedTable.rows.add(this.state.receivedRequests);
        }
        this.receivedTable.draw();
        this.madeTable.clear();
        if (this.state.madeRequests && this.state.madeRequests.length > 0) {
            this.madeTable.rows.add(this.state.madeRequests);
        }
        this.madeTable.draw();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.requests && this.props.requests.requests && this.props.requests.requests !== prevProps.requests.requests && this.receivedTable !== null) {
            var requests = [...this.props.requests.requests];
            if (requests && requests.length > 0) {
                if (this.state.profile === 'Administrator') {
                    this.setState({
                        receivedRequests: [...this.props.requests.requests],
                        madeRequests: [...this.props.requests.requests]
                    }, () => {
                        this.drawTable();
                    });
                } else if (this.state.profile === 'Publisher') {
                    var _receivedRequests = [...this.state.receivedRequests];
                    var _madeRequests = [...this.state.madeRequests];
                    for (var i = 0; i < requests.length; i++) {
                        if (this.state.owner_ontoclass_id.indexOf(requests[i].to_ontoclass_id) > -1) {
                            var item = requests[i]
                            _receivedRequests.push(item);
                        }
                        if (this.state.owner_ontoclass_id.indexOf(requests[i].from_ontoclass_id) > -1) {
                            var item = requests[i]
                            _madeRequests.push(item);
                        }
                    }
                    this.setState({
                        receivedRequests: _receivedRequests,
                        madeRequests: _madeRequests
                    }, () => {
                        this.drawTable()
                    })
                }
            }

        }
        if (this.props.requests.error !== prevProps.requests.error && this.props.requests.error !== null) {
            toastr.error('Error Occured', this.props.requests.message);
        }

        if (this.props.requests.error === null && !this.props.requests.loading && prevProps.requests && prevProps.requests.loading && this.props.requests.message) {
            toastr.success('Success', this.props.requests.message);
            if (this.props.requests.sel_request && this.props.requests.sel_request !== prevProps.requests.sel_request) {
                this.props.history.push(`/request/${this.props.requests.sel_request.request_id}`)
            }
        }
    }
    componentDidMount() {
        var _that = this;
        this.receivedTable = $('#list_table_received').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'info': true,
            'autoWidth': false,
            'scrollX': false,
            'createdRow': function (row, data, dataIndex) {
                $(row).on('click', function () {
                    if (data && data.request_id) {
                        _that.goRequest(data.request_id);
                    }
                })
            },
            'columns': [
                {
                    'data': 'createdAt',
                },
                {
                    'data': 'object',
                },
                {
                    'data': 'type_state_request',
                },
                {
                    'data': 'to_ontoclass',
                },
                {
                    'data': 'from_ontoclass',
                },
                {
                    'data': 'auth',
                },
            ],
            columnDefs: [{
                'targets': 0, //index of column
                'type': 'customdate'
            }],
            order: [[0, 'desc']]
        });
        this.receivedTable.clear();
        if (this.props.requests.requests) {
            this.receivedTable.rows.add(this.props.requests.requests);
        }
        this.receivedTable.draw();
        this.madeTable = $('#list_table_made').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'scrollX': false,
            'createdRow': function (row, data, dataIndex) {
                $(row).on('click', function () {
                    if (data && data.request_id) {
                        _that.goRequest(data.request_id);
                    }
                })
            },
            'columns': [
                {
                    'data': 'createdAt',
                },
                {
                    'data': 'object',
                },
                {
                    'data': 'type_state_request',
                },
                {
                    'data': 'to_ontoclass',
                },
                {
                    'data': 'from_ontoclass',
                },
                {
                    'data': 'auth',
                },
            ],
            columnDefs: [{
                'targets': 0, //index of column
                'type': 'customdate'
            }],
            order: [[0, 'desc']]
        });
        this.madeTable.clear();
        if (this.props.requests.requests) {
            this.madeTable.rows.add(this.props.requests.requests);
        }
        this.madeTable.draw();
    }
    goRequest = (requestId) => {
        this.props.history.push(`request/${requestId}`);
    }
    render() {
        if (this.props.requests.loading) {
            Swal.fire({
                title: 'Loading...',
                text: "Plese wait",
                onOpen: () => {
                    Swal.showLoading();
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
        } else {
            Swal.close();
        }
        return (
            <React.Fragment>
                <section className="content skin-richieste">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="top_comandi">
                                <button type="button" id="add_request" className="btn btn-default" onClick={() => { this.props.history.push(`/newrequest`) }}>
                                    <span className="glyphicon glyphicon-plus-sign"></span>
                                    <Trans id="Button.InsertNewRequest"></Trans>
                                </button>
                            </div>
                            <div className="nav-tabs-custom">
                                <ul className="nav nav-tabs">
                                    <li className="active"><a href="#tab_received" data-toggle="tab" aria-expanded="false"><Trans id="Table.RequestsReceived"></Trans></a></li>
                                    <li><a href="#tab_made" data-toggle="tab" aria-expanded="true"><Trans id="Table.RequestsMade"></Trans></a></li>
                                </ul>
                                <div className="tab-content no-padding">
                                    <div className="tab-pane active" id="tab_received">

                                        <table id="list_table_received" className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span data-tip data-for="header1"><Trans id="Table.Date" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header1" className="dynTooltip">
                                                            <div><Trans id="Table.Date" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                    <th>
                                                        <span data-tip data-for="header2"><Trans id="Table.Object" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
                                                            <div><Trans id="Table.Object" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                    <th>
                                                        <span data-tip data-for="header3"><Trans id="Content.State" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header3" className="dynTooltip">
                                                            <div><Trans id="Content.State" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                    <th>
                                                        <span data-tip data-for="header4"><Trans id="Table.RequestAssigned" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header4" className="dynTooltip">
                                                            <div><Trans id="Table.RequestAssigned" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                    <th>
                                                        <span data-tip data-for="header5"><Trans id="Table.RequestApplied" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header5" className="dynTooltip">
                                                            <div><Trans id="Table.RequestApplied" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                    <th>
                                                        <span data-tip data-for="header6"><Trans id="Table.User" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header6" className="dynTooltip">
                                                            <div><Trans id="Table.User" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tab-pane" id="tab_made">
                                        <table id="list_table_made" className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span data-tip data-for="header1"><Trans id="Table.Date" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header1" className="dynTooltip">
                                                            <div><Trans id="Table.Date" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                    <th>
                                                        <span data-tip data-for="header2"><Trans id="Table.Object" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
                                                            <div><Trans id="Table.Object" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                    <th>
                                                        <span data-tip data-for="header3"><Trans id="Content.State" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header3" className="dynTooltip">
                                                            <div><Trans id="Content.State" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                    <th>
                                                        <span data-tip data-for="header4"><Trans id="Table.RequestAssigned" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header4" className="dynTooltip">
                                                            <div><Trans id="Table.RequestAssigned" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                    <th>
                                                        <span data-tip data-for="header5"><Trans id="Table.RequestApplied" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header5" className="dynTooltip">
                                                            <div><Trans id="Table.RequestApplied" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                    <th>
                                                        <span data-tip data-for="header6"><Trans id="Table.User" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header6" className="dynTooltip">
                                                            <div><Trans id="Table.User" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({
        requests: state.requests,
        auth: state.auth
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname }, dispatch),
        requestActions: bindActionCreators({ getRequests }, dispatch),
        modalActions: bindActionCreators({ showModal }, dispatch),
    })
)(Request);
