import { createActions } from 'redux-actions';

const {
    getDefinitions,
    getDefinitionsFailed,
    getDefinitionsSucceed,
    postDefinition,
    postDefinitionFailed,
    postDefinitionSucceed,
    getDefinitionDetail,
    getDefinitionDetailFailed,
    getDefinitionDetailSucceed,
    updateDefinition,
    updateDefinitionFailed,
    updateDefinitionSucceed,
    deleteDefinition,
    deleteDefinitionFailed,
    deleteDefinitionSucceed,
    getDefinitionAuto,
    getDefinitionAutoFailed,
    getDefinitionAutoSucceed

} = createActions({
    GET_DEFINITIONS: () => ({}),
    GET_DEFINITIONS_FAILED: error => ({ error }),
    GET_DEFINITIONS_SUCCEED: definitions => ({ definitions }),
    POST_DEFINITION: (definition, owner_ontoclass_id) => ({ definition, owner_ontoclass_id }),
    POST_DEFINITION_FAILED: error => ({ error }),
    POST_DEFINITION_SUCCEED: definitions => ({ definitions }),
    GET_DEFINITION_DETAIL: definition_id => ({ definition_id }),
    GET_DEFINITION_DETAIL_FAILED: error => ({ error }),
    GET_DEFINITION_DETAIL_SUCCEED: definition => ({ definition }),
    UPDATE_DEFINITION: (definition_id, definition, owner_ontoclass_id) => ({ definition_id, definition, owner_ontoclass_id }),
    UPDATE_DEFINITION_FAILED: error => ({ error }),
    UPDATE_DEFINITION_SUCCEED: definitions => ({ definitions }),
    DELETE_DEFINITION: definition_id => ({ definition_id }),
    DELETE_DEFINITION_FAILED: error => ({ error }),
    DELETE_DEFINITION_SUCCEED: () => ({}),
    GET_DEFINITION_AUTO: query_string => ({ query_string }),
    GET_DEFINITION_AUTO_FAILED: error => ({ error }),
    GET_DEFINITION_AUTO_SUCCEED: definitions => ({ definitions })
});

export {
    getDefinitions,
    getDefinitionsFailed,
    getDefinitionsSucceed,
    postDefinition,
    postDefinitionFailed,
    postDefinitionSucceed,
    getDefinitionDetail,
    getDefinitionDetailFailed,
    getDefinitionDetailSucceed,
    updateDefinition,
    updateDefinitionFailed,
    updateDefinitionSucceed,
    deleteDefinition,
    deleteDefinitionFailed,
    deleteDefinitionSucceed,
    getDefinitionAuto,
    getDefinitionAutoFailed,
    getDefinitionAutoSucceed
};
