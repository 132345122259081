import React from 'react';
import { connect } from 'react-redux';
import { setPathname, addPathname, deletePathname } from '../../services/common/commonActions';
import { getOntoclassDetail } from '../../services/ontoclass/ontoclassActions'
import { showModal } from '../../modals/modalConductorActions';
import Trans from '../../services/utils/Trans';
import { getStoredUser } from '../../services/auth/services';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import 'datatables.net'
import 'datatables.net-bs'
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';

class OntoclassDetail extends React.Component {
	constructor() {
		super();
		$.fn.dataTableExt.oSort["customdate-desc"] = function (_x, _y) {

			var xx = _x.split('/');
			var x = new Date(xx[2], xx[1], xx[0]);
			x = x.getTime();

			var yy = _y.split('/');
			var y = new Date(yy[2], yy[1], yy[0]);
			y = y.getTime();
			if (x > y) {
				return -1;
			}

			return 1;

		};

		$.fn.dataTableExt.oSort["customdate-asc"] = function (_x, _y) {

			var xx = _x.split('/');
			var x = new Date(xx[2], xx[1], xx[0]);
			x = x.getTime();

			var yy = _y.split('/');
			var y = new Date(yy[2], yy[1], yy[0]);
			y = y.getTime();

			if (x > y) {
				return 1;
			}
			return -1;
		}
	}
	componentWillMount() {
		this.props.ontoclasses.sel_ontoclass = {};
		// this.props.commonActions.setPathname(
		// 	[
		// 		{ "pathName": "Home", "Url": "", "id": 1, "color": "" },
		// 		{ "pathName": "Ontoclasses", "Url": "ontoclasses", "id": 2, "color": "#da645b" },
		// 		{ "pathName": "Ontoclass", "Url": "/", "id": 3, "color": "#da645b" }
		// 	]
		// )
		this.props.ontoclassActions.getOntoclassDetail(this.props.match.params.id);
	}
	componentDidUpdate(prevProps) {
		var temp = this.props.ontoclasses;
		if (temp && temp.sel_ontoclass && temp.sel_ontoclass !== prevProps.ontoclasses.sel_ontoclass) {
			this.props.commonActions.addPathname(
				{ "pathName": temp.sel_ontoclass.ontoclass, "Url": "ontoclass/" + this.props.match.params.id, "color": "#6d81ab", "subHeader": "Ontoclass Detail" }
			)
		}
		if (temp && temp.sel_ontoclass && temp.sel_ontoclass !== prevProps.ontoclasses.sel_ontoclass && this.ontoclassTable !== null) {
			this.ontoclassTable.clear();
			if (temp.sel_ontoclass.concepts) {
				this.ontoclassTable.rows.add(temp.sel_ontoclass.concepts);
			}
			this.ontoclassTable.draw();

			this.indicatorTable.clear();
			if (temp.sel_ontoclass.indicators) {
				this.indicatorTable.rows.add(temp.sel_ontoclass.indicators);
			}
			this.indicatorTable.draw();
		}
		if (this.props.ontoclasses.error !== prevProps.ontoclasses.error && this.props.ontoclasses.error !== null) {
			toastr.error('Error Occured', this.props.ontoclasses.message);
		}
		if (this.props.ontoclasses.error === null && !this.props.ontoclasses.loading && prevProps.ontoclasses && prevProps.ontoclasses.loading && this.props.ontoclasses.message) {
			toastr.success('Success', this.props.ontoclasses.message);
			if (temp.sel_ontoclass === null) {
				this.props.history.push('/ontoclasses')
			}
		}
		if (this.props.ontoclasses && this.props.ontoclasses.sel_ontoclass === null && this.props.ontoclasses.sel_ontoclass !== prevProps.ontoclasses.sel_ontoclass && this.props.ontoclasses.success) {
			this.props.history.push("/ontoclasses");
		}
	}
	componentDidMount() {
		var _that = this;
		var currentUser = getStoredUser();
		if (currentUser.profile === 'Guest' || currentUser.profile === 'Publisher') {
			$("[edit=1]").hide();
		}
		this.ontoclassTable = $('#list_table').DataTable({
			'paging': true,
			'lengthChange': false,
			'searching': false,
			'ordering': true,
			'info': true,
			// 'scrollX': true,
			'autoWidth': false,
			'createdRow': function (row, data, dataIndex) {
				$(row).on('click', function () {
					if (data && data.concept_id) {
						_that.goConcept(data.concept_id);
					}
				})
			},
			'columns': [
				{
					'data': 'lemma',
				},
				{
					'data': 'definition',
				},
				{
					'data': 'sources',
					'render': function (data, type, full, meta) {
						var result = "";
						for (var i = 0; i < data.length; i++) {
							result += data[i].source;
							if (i < data.length - 1) {
								result += '; ';
							}
						}
						return result;
					}
				},
				{
					'data': 'indicators_t',
					'render': function (data, type, full, meta) {
						if (data) {
							return data.length
						} else {
							return null
						}
					}
				},
				{
					'data': 'indicators_a',
					'render': function (data, type, full, meta) {
						if (data) {
							return data.length
						} else {
							return null
						}
					}
				},
				{
					'data': 'ontoclasses',
					'render': function (data, type, full, meta) {
						var result = "";
						for (var i = 0; i < data.length; i++) {
							result += data[i].ontoclass;
							if (i < data.length - 1) {
								result += '; ';
							}
						}
						return result;
					}
				},
				{
					'data': 'updatedAt',
				},
				{
					'data': 'auth',
				},
			],
			columnDefs: [{
				'targets': 6, //index of column
				'type': 'customdate'
			}],
		});
		this.ontoclassTable.clear();
		if (this.props.ontoclasses.sel_ontoclass) {
			var list_concepts = this.props.ontoclasses.sel_ontoclass.concepts || [];
			this.ontoclassTable.rows.add(list_concepts);
		}

		this.ontoclassTable.draw();

		// Table indicathor
		this.indicatorTable = $('#list_table_i').DataTable({
			'paging': true,
			'lengthChange': false,
			'searching': false,
			'ordering': true,
			'info': true,
			// 'scrollX': true,
			'autoWidth': false,
			'createdRow': function (row, data, dataIndex) {
				$(row).on('click', function () {
					if (data && data.indicator_t_id) {
						_that.goIndicator(data.indicator_t_id);
					}
				})
			},
			'columns': [
				{
					'data': 'name'
				},
				{
					'data': 'note'
				},
				{
					'data': 'concepts',
					'render': function (data, type, full, meta) {
						return data.length;
					}
				},
				{
					'data': 'indicators_applied',
					'render': function (data, type, full, meta) {
						return data.length;
					}
				},
				{
					'data': 'updatedAt',

				},
				{
					'data': 'auth',
				},
			],
			columnDefs: [{
				'targets': 3, //index of column
				'type': 'customdate'
			}],
		});
		this.indicatorTable.clear();
		if (this.props.ontoclasses.sel_ontoclass) {
			var list_indicators = this.props.ontoclasses.sel_ontoclass.indicators || [];
			list_indicators = [];
			this.indicatorTable.rows.add(list_indicators);
		}

		this.indicatorTable.draw();
	}
	goConcept = (conceptId) => {
		this.props.history.push(`/concept/${conceptId}`);
	}
	goIndicator = (indicatorId) => {
		this.props.history.push(`/theordetail/${indicatorId}`);
	}
	render() {
		var projects = [];
		var sel_ontoclass = {};
		if (this.props.ontoclasses) {
			if (this.props.ontoclasses.loading) {
				Swal.fire({
					title: 'Loading...',
					text: "Plese wait",
					onOpen: () => {
						Swal.showLoading();
					},
					allowOutsideClick: false,
					allowEscapeKey: false,
					allowEnterKey: false
				});
			} else {
				Swal.close();
			}
			if (this.props.ontoclasses.sel_ontoclass) {
				sel_ontoclass = this.props.ontoclasses.sel_ontoclass;
				projects = sel_ontoclass.projects;
			}
		}
		var currentUser = getStoredUser();
		var user_edit = 0;
		currentUser.ontoclasses.map(onto => {
			if (onto.ontoclass_id === this.props.match.params.id) {
				user_edit = 1;
			}
		})
		if (currentUser.profile === 'Administrator') user_edit = 1;
		return (
			<React.Fragment>
				<section className="content skin-ontoclassi">
					<div className="row">
						<div className="col-md-12">
							<div className="sommario">
								<label><Trans id="Content.CreationDate"></Trans>:</label><span id="createdAt">{sel_ontoclass ? sel_ontoclass.createdAt : ''}</span>
								<label><Trans id="Content.LastUpdate"></Trans>:</label><span id="updatedAt">{sel_ontoclass ? sel_ontoclass.updatedAt : ''}</span>
								<label><Trans id="Content.User"></Trans>:</label><span id="auth">{sel_ontoclass ? sel_ontoclass.auth : ''}</span>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="nav-tabs-custom">
								<ul className="nav nav-tabs">
									<li className="active"><a href="#tab_ontoclass" data-toggle="tab"><Trans id="Table.Ontoclass"></Trans></a></li>
									<li><a href="#tab_concepts" data-toggle="tab"><Trans id="Table.ConceptsInserted"></Trans></a></li>
									<li><a href="#tab_indicators" data-toggle="tab"><Trans id="Table.IndicatorsInserted"></Trans></a></li>
								</ul>
								<div className="tab-content no-padding">
									<div className="tab-pane active" id="tab_ontoclass">
										<div className="form-group row">
											<div className="col-md-12">
												<label className="text-muted text-center"><Trans id="Table.Ontoclass"></Trans></label>
												<div>{sel_ontoclass ? sel_ontoclass.ontoclass : ''}</div>
											</div>
										</div>
										<div className="comandi">
											<div>
												<button id="update" edit="1" type="button" className="btn btn-default" onClick={() => {
													this.props.modalActions.showModal('EDIT_ONTOCLASS_MODAL');
												}}><Trans id="Button.Modification"></Trans>
												</button>
												{
													sel_ontoclass && sel_ontoclass.concepts && sel_ontoclass.concepts.length == 0 ?
														<button id="delete" edit="1" className="btn" onClick={() => {
															this.props.modalActions.showModal('DELETE_ONTOCLASS_MODAL');
														}}><b><Trans id="Button.Delete"></Trans></b></button> : <></>
												}
												<button id="back" className="btn" onClick={() => { this.props.history.goBack() }}><b><Trans id="Button.Back"></Trans></b></button>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12" id="list_projects">
												<h3><Trans id="Table.ProjectsAssociated"></Trans>:</h3>
												{
													projects && projects.length > 0 ? projects.map(project => {
														return <div className="lista_item pointer" key={"project_" + project.project_id}
															onClick={() => { this.props.history.push(`/project/${project.project_id}`) }}>
															<i id="project_name">{project.name}</i>
														</div>
													}) : <div><Trans id="Content.NoProject"></Trans></div>
												}
											</div>
										</div>
									</div>
									<div className="tab-pane" id="tab_concepts" style={{ clear: "both" }}>
										<div className="row" >
											<div className="col-md-12">
												{user_edit == 1 ?
													<div className="top_comandi">
														<button className="btn btn-default" onClick={() => { this.props.history.push(`/concepts`) }}>
															<span className="glyphicon glyphicon-plus-sign"></span><b><Trans id="Button.InsertNewConcept"></Trans></b></button>
													</div>
													: <></>
												}
												<table id="list_table" className="table table-hover">
													<thead>
														<tr>
															<th>
																<span data-tip data-for="header1"><Trans id="Table.Lemma" /></span>
																<ReactTooltip place="top" effect="solid" id="header1" className="dynTooltip">
																	<div><Trans id="Table.Lemma" /></div>
																</ReactTooltip>
															</th>
															<th>
																<span data-tip data-for="header2"><Trans id="Table.Definition" /></span>
																<ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
																	<div><Trans id="Table.Definition" /></div>
																</ReactTooltip>
															</th>
															<th>
																<span data-tip data-for="header3"><Trans id="Table.Sources" /></span>
																<ReactTooltip place="top" effect="solid" id="header3" className="dynTooltip">
																	<div><Trans id="Table.Sources" /></div>
																</ReactTooltip>
															</th>
															<th>
																<span data-tip data-for="header4"><Trans id="Table.IndicatorsTheor" /><br></br><Trans id="Table.Contained" /></span>
																<ReactTooltip place="top" effect="solid" id="header4" className="dynTooltip">
																	<div><Trans id="Table.IndicatorsTheor" /><br></br><Trans id="Table.Contained" /></div>
																</ReactTooltip>
															</th>
															<th><span data-tip data-for="header5"><Trans id="Table.IndicatorsApplied" /><br></br><Trans id="Table.Contained" /></span>
																<ReactTooltip place="top" effect="solid" id="header5" className="dynTooltip">
																	<div><Trans id="Table.IndicatorsApplied" /><br></br><Trans id="Table.Contained" /></div>
																</ReactTooltip>
															</th>
															<th>
																<span data-tip data-for="header6"><Trans id="Table.Ontoclasses" /></span>
																<ReactTooltip place="top" effect="solid" id="header6" className="dynTooltip">
																	<div><Trans id="Table.Ontoclasses" /></div>
																</ReactTooltip>
															</th>
															<th>
																<span data-tip data-for="header7"><Trans id="Table.LastUpdate" /></span>
																<ReactTooltip place="top" effect="solid" id="header7" className="dynTooltip">
																	<div><Trans id="Table.LastUpdate" /></div>
																</ReactTooltip>
															</th>
															<th>
																<span data-tip data-for="header8"><Trans id="Table.User" /></span>
																<ReactTooltip place="top" effect="solid" id="header8" className="dynTooltip">
																	<div><Trans id="Table.User" /></div>
																</ReactTooltip>
															</th>
														</tr>
													</thead>
													<tbody></tbody>
												</table>
											</div>
										</div>
									</div>
									<div className="tab-pane" id="tab_indicators" style={{ clear: "both" }}>
										<div className="row" >
											<div className="col-md-12">
												{user_edit == 1 ?
													<div className="top_comandi">
														<button className="btn btn-default" id="new_indicator" onClick={() => { this.props.history.push(`/indicators`) }}>
															<span className="glyphicon glyphicon-plus-sign"></span><b><Trans id="Button.InsertNewTheor"></Trans></b></button>
													</div>
													: <></>
												}
												<table id="list_table_i" className="table table-hover">
													<thead>
														<tr>
															<th>
																<span data-tip data-for="header9"><Trans id="Content.Name" /></span>
																<ReactTooltip place="top" effect="solid" id="header9" className="dynTooltip">
																	<div><Trans id="Content.Name" /></div>
																</ReactTooltip>
															</th>
															<th>
																<span data-tip data-for="header10"><Trans id="Content.Note" /></span>
																<ReactTooltip place="top" effect="solid" id="header10" className="dynTooltip">
																	<div><Trans id="Content.Note" /></div>
																</ReactTooltip>
															</th>
															<th>
																<span data-tip data-for="header11"><Trans id="Content.ConceptsUsed" /></span>
																<ReactTooltip place="top" effect="solid" id="header11" className="dynTooltip">
																	<div><Trans id="Content.ConceptsUsed" /></div>
																</ReactTooltip>
															</th>
															<th>
																<span data-tip data-for="header12"><Trans id="Table.IndicatorsAppliedAssociated" /></span>
																<ReactTooltip place="top" effect="solid" id="header12" className="dynTooltip">
																	<div><Trans id="Table.IndicatorsAppliedAssociated" /></div>
																</ReactTooltip>
															</th>
															<th>
																<span data-tip data-for="header13"><Trans id="Table.LastUpdate" /></span>
																<ReactTooltip place="top" effect="solid" id="header13" className="dynTooltip">
																	<div><Trans id="Table.LastUpdate" /></div>
																</ReactTooltip>
															</th>
															<th>
																<span data-tip data-for="header14"><Trans id="Content.User" /></span>
																<ReactTooltip place="top" effect="solid" id="header14" className="dynTooltip">
																	<div><Trans id="Content.User" /></div>
																</ReactTooltip>
															</th>
														</tr>
													</thead>
													<tbody></tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section >
			</React.Fragment>
		)
	}
}

export default connect(
	state => ({
		ontoclasses: state.ontoclasses
	}),
	dispatch => ({
		commonActions: bindActionCreators({ setPathname, addPathname, deletePathname }, dispatch),
		ontoclassActions: bindActionCreators({ getOntoclassDetail }, dispatch),
		modalActions: bindActionCreators({ showModal }, dispatch),

	})
)(OntoclassDetail);
