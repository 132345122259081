import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { approveRequest, discardRequest } from '../../../../services/request/requestActions';
import { hideModal, showModal } from '../../../modalConductorActions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import Trans from '../../../../services/utils/Trans';

class RequestAddModal extends React.Component {
    constructor() {
        super();
        this.onCancel = this.onCancel.bind(this);
        this.state = {
            button: '',
            request_id: ''
        }
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }
    componentDidMount() {
        console.log('Props in Request add modal', this.props);
        if (this.props.requests && this.props.requests.sel_request) {
            var sel_request = this.props.requests.sel_request;
            if (sel_request && sel_request.request) {
                this.setState({ request_id: sel_request.request.request_id })
            }
        }
    }
    componentDidUpdate(prevProps) {
        console.log('success', this.props.requests)
    }
    render() {
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader><Trans id="Modal.AnswerRequest"></Trans></ModalHeader>
                <Formik
                    initialValues={{
                        answer: ''
                    }}
                    validationSchema={Yup.object().shape({
                        answer: Yup.string()
                            .required('Answer is required')
                    })}
                    onSubmit={fields => {
                        if (this.state.button === 'approve') {
                            this.props.modalActions.showModal('CONFIRM_APPROVE_MODAL', { request_id: this.state.request_id, answer: fields.answer })
                            // this.props.requestActions.approveRequest(this.state.request_id, fields.answer);
                        } else if (this.state.button === 'discard') {
                            this.props.modalActions.showModal('CONFIRM_DISCARD_MODAL', { request_id: this.state.request_id, answer: fields.answer })
                            // this.props.requestActions.discardRequest(this.state.request_id, fields.answer);
                        }
                        // this.props.modalActions.hideModal();
                    }}>
                    {({ errors, status, touched, setFieldValue }) => (
                        <Form className="skin-richieste">
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Content.Reply"></Trans>:</label>
                                    <Field as="textarea" name="answer" type="text" className={'form-control' + (errors.answer && touched.answer ? ' is-invalid' : '')} />
                                    <ErrorMessage name="answer" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button type="submit" id="approve" className="btn btn-default" onClick={() => { this.setState({ button: 'approve' }) }}><Trans id="Button.Approve"></Trans></button>
                                <button type="submit" id="discard" className="btn btn-default" onClick={() => { this.setState({ button: 'discard' }) }}><Trans id="Button.Refuse"></Trans></button>
                                <button className="btn" onClick={this.onCancel}>
                                    <Trans id="Button.Cancel"></Trans>
                                </button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </Modal>
        );
    }
}

export default connect(
    state => ({
        requests: state.requests
    }), dispatch => ({
        requestActions: bindActionCreators({ approveRequest, discardRequest }, dispatch),
        modalActions: bindActionCreators({ hideModal, showModal }, dispatch),
    })
)(RequestAddModal);
