import { wrapRequest, xapi } from '../utils_services';

const getLemmasApi = wrapRequest(async () =>
    xapi().get('/lemmas')
);
const getLemmaAutoApi = wrapRequest(async (query_string) =>
    xapi().get('/lemmas/auto?query_string=' + query_string)
);
const postLemmaApi = wrapRequest(async (lemma, owner_ontoclass_id) => {
    console.log('post data', lemma);
    return xapi().post('/lemmas', { lemma, owner_ontoclass_id })
});
const getLemmaDetailApi = wrapRequest(async (lemma_id) =>
    xapi().get('/lemmas/' + lemma_id)
);
const updateLemmaApi = wrapRequest(async (lemma_id, lemma, owner_ontoclass_id) => {
    console.log('update data', lemma);
    return xapi().put('/lemmas/' + lemma_id, { lemma, owner_ontoclass_id })
});
const deleteLemmaApi = wrapRequest(async (lemma_id) => xapi().delete('/lemmas/' + lemma_id)
)
export { getLemmasApi, postLemmaApi, getLemmaDetailApi, updateLemmaApi, deleteLemmaApi, getLemmaAutoApi };
