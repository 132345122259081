import { handleActions } from 'redux-actions';

import {
    getLemmas,
    getLemmasFailed,
    getLemmasSucceed,
    postLemma,
    postLemmaFailed,
    postLemmaSucceed,
    getLemmaDetail,
    getLemmaDetailFailed,
    getLemmaDetailSucceed,
    updateLemma,
    updateLemmaFailed,
    updateLemmaSucceed,
    deleteLemma,
    deleteLemmaFailed,
    deleteLemmaSucceed,
    getLemmaAuto,
    getLemmaAutoFailed,
    getLemmaAutoSucceed
} from './lemmaActions';

const defaultState = {
    lemmas: null,
    error: null,
    loading: false,
    success: false,
    message: null,
    sel_lemma: null
};

const reducer = handleActions(
    {
        [getLemmas](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getLemmasFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error? error : 'Error',
                loading: false,
                success: false,
                message: error? error.message : 'API connection error'
            };
        },
        [getLemmasSucceed](
            state,
            {
                payload: { lemmas }
            }
        ) {
            return {
                ...state,
                lemmas: lemmas,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
        [postLemma](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null,
                success: false
            };
        },
        [postLemmaFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in postLemma', error)
            return {
                ...state,
                error,
                loading: false,
                success: false,
                message: error && error.message ? error.message: error
            };
        },
        [postLemmaSucceed](
            state,
            {
                payload: {lemmas}
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                message: "Add Lemma Succeed!",
                sel_lemma: lemmas,
                success: true
            };
        },
        [getLemmaDetail](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getLemmaDetailFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error? error: 'Error',
                loading: false,
                success: false,
                sel_lemma: null,
                message: error? error.message : 'API connection error'
            };
        },
        [getLemmaDetailSucceed](
            state,
            {
                payload: { lemma }
            }
        ) {
            return {
                ...state,
                sel_lemma: lemma,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
        [updateLemma](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null,
                success: false
            };
        },
        [updateLemmaFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in updateLemma', error)
            return {
                ...state,
                error,
                loading: false,
                success: false,
                message: error && error.message ? error.message: error
            };
        },
        [updateLemmaSucceed](
            state,
            {
                payload: { lemmas }
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                message: "Update Lemma Succeed!",
                sel_lemma: lemmas,
                success: true
            };
        },
        [deleteLemma](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null,
                success: false
            };
        },
        [deleteLemmaFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in deleteLemma', error)
            return {
                ...state,
                error,
                loading: false,
                success: false,
                message: error && error.message ? error.message : error
            };
        },
        [deleteLemmaSucceed](state) {
            return {
                ...state,
                error: null,
                loading: false,
                message: "Delete Lemma Succeed!",
                sel_lemma: null,
                success: true
            };
        },
        [getLemmaAuto](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getLemmaAutoFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error ? error : 'Error',
                loading: false,
                success: false,
                message: error ? error.message : 'API connection error'
            };
        },
        [getLemmaAutoSucceed](
            state,
            {
                payload: { lemmas }
            }
        ) {
            return {
                ...state,
                lemmas: lemmas,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
    },
    defaultState
);

export default reducer;
