import { createActions } from 'redux-actions';

const {
    getProjects,
    getProjectsFailed,
    getProjectsSucceed,
    postProject,
    postProjectFailed,
    postProjectSucceed,
    getProjectDetail,
    getProjectDetailFailed,
    getProjectDetailSucceed,
    setSubProjectConcepts,
    updateProject,
    updateProjectFailed,
    updateProjectSucceed,
    updateProjectOntoclass,
    updateProjectOntoclassFailed,
    updateProjectOntoclassSucceed,
    deleteProject,
    deleteProjectFailed,
    deleteProjectSucceed,
} = createActions({
    GET_PROJECTS: () => ({}),
    GET_PROJECTS_FAILED: error => ({ error }),
    GET_PROJECTS_SUCCEED: projects => ({ projects }),
    POST_PROJECT: (father_id, formData) => ({ father_id, formData }),
    POST_PROJECT_FAILED: error => ({ error }),
    POST_PROJECT_SUCCEED: projects => ({ projects }),
    GET_PROJECT_DETAIL: (project_id) => ({ project_id }),
    GET_PROJECT_DETAIL_FAILED: error => ({ error }),
    GET_PROJECT_DETAIL_SUCCEED: project => ({ project }),
    SET_SUB_PROJECT_CONCEPTS: concepts => ({ concepts }),
    // UPDATE_PROJECT: (project_id, name, description) => ({ project_id, name, description }),
    UPDATE_PROJECT: (project_id, formData) => ({ project_id, formData }),
    UPDATE_PROJECT_FAILED: error => ({ error }),
    UPDATE_PROJECT_SUCCEED: project => ({ project }),
    UPDATE_PROJECT_ONTOCLASS: (project_id, ontoclasses, concepts, pro_onto_del_flag) => ({ project_id, ontoclasses, concepts, pro_onto_del_flag }),
    UPDATE_PROJECT_ONTOCLASS_FAILED: error => ({ error }),
    UPDATE_PROJECT_ONTOCLASS_SUCCEED: project => ({ project }),
    DELETE_PROJECT: project_id => ({ project_id }),
    DELETE_PROJECT_FAILED: error => ({ error }),
    DELETE_PROJECT_SUCCEED: () => ({}),
});

export {
    getProjects,
    getProjectsFailed,
    getProjectsSucceed,
    postProject,
    postProjectFailed,
    postProjectSucceed,
    getProjectDetail,
    getProjectDetailFailed,
    getProjectDetailSucceed,
    setSubProjectConcepts,
    updateProject,
    updateProjectFailed,
    updateProjectSucceed,
    updateProjectOntoclass,
    updateProjectOntoclassFailed,
    updateProjectOntoclassSucceed,
    deleteProject,
    deleteProjectFailed,
    deleteProjectSucceed,
};
