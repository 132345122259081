import React from 'react';
import { connect } from 'react-redux';
import { setPathname, addPathname, deletePathname } from '../../services/common/commonActions';
import { getSources, postSource } from '../../services/source/sourceActions';
import { getOntoclasses } from '../../services/ontoclass/ontoclassActions';
import { showModal } from '../../modals/modalConductorActions';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import 'datatables.net'
import 'datatables.net-bs'
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';

class Source extends React.Component {
    constructor() {
        super();
        this.state = {
            filter_source: '',
            filter_count_concepts_from: '',
            filter_count_concepts_to: '',
            filter_owner_ontoclasse: '',
            filter_auth: ''
        }
        this.sourceTable = null;
        $.fn.dataTableExt.oSort["customdate-desc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();
            if (x > y) {
                return -1;
            }

            return 1;

        };

        $.fn.dataTableExt.oSort["customdate-asc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();

            if (x > y) {
                return 1;
            }
            return -1;
        }
    }
    componentWillMount() {
        this.props.commonActions.setPathname(
            [
                { "pathName": "Home", "Url": "", "id": 1, "color": "" },
                { "pathName": "Sources", "Url": "sources", "id": 2, "color": "#0c8d52", "subHeader": "Sources" }
            ]
        )
        this.props.sourceActions.getSources();
        this.props.ontoclassActions.getOntoclasses();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.sources && this.props.sources.sources !== prevProps.sources.sources && this.sourceTable !== null) {
            this.sourceTable.clear();
            if (this.props.sources.sources) {
                this.sourceTable.rows.add(this.props.sources.sources);
            }
            this.sourceTable.draw();
        }
        if (this.props.sources.error !== prevProps.sources.error && this.props.sources.error !== null) {
            toastr.error('Error Occured', this.props.sources.message);
        }

        if (this.props.sources.error === null && !this.props.sources.loading && prevProps.sources && prevProps.sources.loading && this.props.sources.message) {
            toastr.success('Success', this.props.sources.message);
            if (this.props.sources && this.props.sources.sel_source && this.props.sources.sel_source.source) {
                this.props.history.push(`/source/${this.props.sources.sel_source.source.source_id}`);
            }
        }
    }
    componentDidMount() {
        var _that = this;
        this.sourceTable = $('#list_table').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'scrollX': true,
            'createdRow': function (row, data, dataIndex) {
                $(row).on('click', function () {
                    if (data && data.source_id) {
                        _that.goSource(data.source_id);
                    }
                })
            },
            'columns': [
                {
                    'data': 'source'
                },
                {
                    'data': 'concepts',
                    'render': function (data, type, full, meta) {
                        return data ? data.length : ''
                    }
                },
                {
                    'data': 'indicators_applied',
                    'render': function (data, type, full, meta) {
                        return data ? data.length : ''
                    }
                },
                {
                    'data': 'owner_ontoclass',
                },
                {
                    'data': 'updatedAt',
                },
                {
                    'data': 'auth',
                }
            ],
            columnDefs: [{
                'targets': 3, //index of column
                'type': 'customdate'
            }],
        });
        this.sourceTable.clear();
        if (this.props.sources && this.props.sources.sources) {
            this.sourceTable.rows.add(this.props.sources.sources);
        }
        this.sourceTable.draw();
    }

    goSource = (sourceId) => {
        this.props.history.push(`source/${sourceId}`);
    }
    onFilterChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSearch = (e) => {
        var filterData = [];
        if (this.props.sources && this.props.sources.sources && this.props.sources.sources.length > 0) {
            var sources = this.props.sources.sources;
            for (var i = 0; i < sources.length; i++) {
                var fileterRes = true;
                if (this.state.filter_source !== '') {
                    if (sources[i].source.toLowerCase().indexOf(this.state.filter_source.toLowerCase()) === -1) fileterRes = false;
                }
                if (this.state.filter_owner_ontoclasse) {
                    if (sources[i].owner_ontoclass.toLowerCase().indexOf(this.state.filter_owner_ontoclasse.toLowerCase()) === -1) fileterRes = false;
                }
                if (this.state.filter_count_concepts_from) {
                    if (sources[i].concepts.length < this.state.filter_count_concepts_from) fileterRes = false;
                }
                if (this.state.filter_count_concepts_to) {
                    if (sources[i].concepts.length > this.state.filter_count_concepts_to) fileterRes = false;
                }
                if (this.state.filter_auth) {
                    if (sources[i].auth.toLowerCase().indexOf(this.state.filter_auth.toLowerCase()) === -1) fileterRes = false;
                }
                if (fileterRes) {
                    filterData.push(sources[i]);
                }
            }
            this.sourceTable.clear();
            this.sourceTable.rows.add(filterData);
            this.sourceTable.draw();
        }
    }
    handleReset = (e) => {
        this.setState({
            filter_source: '',
            filter_count_concepts_from: '',
            filter_count_concepts_to: '',
            filter_owner_ontoclasse: '',
            filter_auth: ''
        });
        this.sourceTable.clear();
        if (this.props.sources.sources) {
            this.sourceTable.rows.add(this.props.sources.sources);
        }
        this.sourceTable.draw();
    }

    render() {
        if (this.props.sources && this.props.sources.loading) {
            Swal.fire({
                title: 'Loading...',
                text: "Plese wait",
                onOpen: () => {
                    Swal.showLoading();
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
        } else {
            Swal.close();
        }
        return (
            <React.Fragment>
                <section className="content skin-fonti">
                    <div className="top_comandi">
                        <button type="button" onClick={() => {
                            this.props.modalActions.showModal('ADD_SOURCE_MODAL')
                        }} id="add" className="btn btn-default">
                            <span className="glyphicon glyphicon-plus-sign"></span><Trans id="Button.InsertNewSource" />
                        </button>
                    </div>
                    <div className="ricerca">
                        <div className="_form-inline">
                            <div className="form-group row">
                                <div className="col-md-4">
                                    <label><Trans id="Table.Filterby"></Trans>:</label>
                                    <div className="input-group">
                                        <span className="input-group-addon"><Trans id="Table.Source"></Trans></span>
                                        <input type="text" name="filter_source" className="form-control" value={this.state.filter_source} onChange={this.onFilterChange} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label><Trans id="Table.ConceptsContained"></Trans>:</label>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="input-group">
                                                <span className="input-group-addon"><Trans id="Table.From"></Trans></span>
                                                <input type="number" size="2" name="filter_count_concepts_from" value={this.state.filter_count_concepts_from} min="0" className="form-control" onChange={this.onFilterChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-group">
                                                <span className="input-group-addon"><Trans id="Table.To"></Trans></span>
                                                <input type="number" size="2" name="filter_count_concepts_to" value={this.state.filter_count_concepts_to} min="0" className="form-control" onChange={this.onFilterChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label><Trans id="Table.ReferenceOntoclass"></Trans>:</label>
                                    <input type="text" name="filter_owner_ontoclasse" className="form-control" value={this.state.filter_owner_ontoclasse} onChange={this.onFilterChange} />
                                </div>
                                <div className="col-md-4">
                                    <label><Trans id="Content.User"></Trans>:</label>
                                    <input type="text" name="filter_auth" className="form-control" value={this.state.filter_auth} onChange={this.onFilterChange} />
                                </div>
                                <div className="col-md-2">
                                    <label>&nbsp;</label>
                                    <button type="button" className="btn btn-default form-control" onClick={() => this.handleSearch()}><Trans id="Button.SearchFor"></Trans></button>
                                </div>
                                <div className="col-md-2">
                                    <label>&nbsp;</label>
                                    <button type="button" className="btn form-control" onClick={() => this.handleReset()}><Trans id="Button.Reset"></Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <table id="list_table" className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <span data-tip data-for="header1"><Trans id="Table.Sources" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header1" className="dynTooltip">
                                                <div><Trans id="Table.Sources" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header2"><Trans id="Table.ConceptsContained" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
                                                <div><Trans id="Table.ConceptsContained" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header3"><Trans id="Table.IndicatorsAppliedContained" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header3" className="dynTooltip">
                                                <div><Trans id="Table.IndicatorsAppliedContained" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header4"><Trans id="Table.ReferenceOntoclass" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header4" className="dynTooltip">
                                                <div><Trans id="Table.ReferenceOntoclass" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header5"><Trans id="Table.LastUpdate" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header5" className="dynTooltip">
                                                <div><Trans id="Table.LastUpdate" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header6"><Trans id="Table.User" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header6" className="dynTooltip">
                                                <div><Trans id="Table.User" /></div>
                                            </ReactTooltip>
                                        </th>
                                    </tr>
                                </thead>
                            <tbody id="list_table_body">

                            </tbody>

                            </table>
                    </div>
                    </div>
                </section>
            </React.Fragment >
        )
    }
}

export default connect(
    state => ({
        sources: state.sources
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname, addPathname, deletePathname }, dispatch),
        sourceActions: bindActionCreators({ getSources, postSource }, dispatch),
        ontoclassActions: bindActionCreators({ getOntoclasses }, dispatch),
        modalActions: bindActionCreators({ showModal }, dispatch),
    })
)(Source);
