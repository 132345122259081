import { createActions } from 'redux-actions';

const {
    getLemmas,
    getLemmasFailed,
    getLemmasSucceed,
    postLemma,
    postLemmaFailed,
    postLemmaSucceed,
    getLemmaDetail,
    getLemmaDetailFailed,
    getLemmaDetailSucceed,
    updateLemma,
    updateLemmaFailed,
    updateLemmaSucceed,
    deleteLemma,
    deleteLemmaFailed,
    deleteLemmaSucceed,
    getLemmaAuto,
    getLemmaAutoFailed,
    getLemmaAutoSucceed
} = createActions({
    GET_LEMMAS: () => ({}),
    GET_LEMMAS_FAILED: error => ({ error }),
    GET_LEMMAS_SUCCEED: lemmas => ({ lemmas }),
    POST_LEMMA: (lemma, owner_ontoclass_id) => ({ lemma, owner_ontoclass_id }),
    POST_LEMMA_FAILED: error => ({ error }),
    POST_LEMMA_SUCCEED: lemmas => ({ lemmas }),
    GET_LEMMA_DETAIL: lemma_id => ({ lemma_id }),
    GET_LEMMA_DETAIL_FAILED: error => ({ error }),
    GET_LEMMA_DETAIL_SUCCEED: lemma => ({ lemma }),
    UPDATE_LEMMA: (lemma_id, lemma, owner_ontoclass_id) => ({ lemma_id, lemma, owner_ontoclass_id }),
    UPDATE_LEMMA_FAILED: error => ({ error }),
    UPDATE_LEMMA_SUCCEED: lemmas => ({ lemmas }),
    DELETE_LEMMA: lemma_id => ({ lemma_id }),
    DELETE_LEMMA_FAILED: error => ({ error }),
    DELETE_LEMMA_SUCCEED: () => ({}),
    GET_LEMMA_AUTO: query_string => ({ query_string }),
    GET_LEMMA_AUTO_FAILED: error => ({ error }),
    GET_LEMMA_AUTO_SUCCEED: lemmas => ({ lemmas })
});

export {
    getLemmas,
    getLemmasFailed,
    getLemmasSucceed,
    postLemma,
    postLemmaFailed,
    postLemmaSucceed,
    getLemmaDetail,
    getLemmaDetailFailed,
    getLemmaDetailSucceed,
    updateLemma,
    updateLemmaFailed,
    updateLemmaSucceed,
    deleteLemma,
    deleteLemmaFailed,
    deleteLemmaSucceed,
    getLemmaAuto,
    getLemmaAutoFailed,
    getLemmaAutoSucceed
};
