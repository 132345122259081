import React from 'react';
import { connect } from 'react-redux';
import { setPathname, addPathname, deletePathname } from '../../services/common/commonActions';
import { getLemmaDetail } from '../../services/lemma/lemmaActions'
import { getEvents } from '../../services/event_entity/eventActions';
import { showModal } from '../../modals/modalConductorActions';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import {capitalize} from '../../services/utils_services';
import 'datatables.net'
import 'datatables.net-bs'
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
class LemmaDetail extends React.Component {
    constructor(){
        super();
        $.fn.dataTableExt.oSort["customdate-desc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();
            if (x > y) {
                return -1;
            }

            return 1;

        };

        $.fn.dataTableExt.oSort["customdate-asc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();

            if (x > y) {
                return 1;
            }
            return -1;
        }
    }
    componentWillMount() {
        // this.props.commonActions.setPathname(
        //     [
        //         { "pathName": "Home", "Url": "", "id": 1, "color": "" },
        //         { "pathName": "Lemmas", "Url": "lemmas", "id": 2, "color": "#1c7ea5" },
        //         { "pathName": "Lemma", "Url": "/", "id": 3, "color": "#1c7ea5" }
        //     ]
        // )
        this.props.lemmaActions.getLemmaDetail(this.props.match.params.id);
        this.props.eventActions.getEvents(this.props.match.params.id, 'lemma')
    }
    componentDidUpdate(prevProps) {
        var temp = this.props.lemmas;
        if (temp && temp.sel_lemma && temp.sel_lemma !== prevProps.lemmas.sel_lemma){
            this.props.commonActions.addPathname(
                { "pathName": temp.sel_lemma.lemma.lemma, "Url": "lemma/" + this.props.match.params.id, "color": "#6d81ab", "subHeader": "Lemma Detail" }
            )
        }
        if (temp && temp.sel_lemma && temp.sel_lemma !== prevProps.lemmas.sel_lemma && this.lemmaTable !== null) {
            this.lemmaTable.clear();
            if (temp.sel_lemma.lemma && temp.sel_lemma.lemma.concepts) {
                this.lemmaTable.rows.add(temp.sel_lemma.lemma.concepts);
            }
            this.lemmaTable.draw();
        }
        if (this.props.lemmas.error !== prevProps.lemmas.error && this.props.lemmas.error !== null) {
            toastr.error('Error Occured', this.props.lemmas.message);
        }

        if (this.props.lemmas.error === null && !this.props.lemmas.loading && prevProps.lemmas && prevProps.lemmas.loading && this.props.lemmas.message) {
            toastr.success('Success', this.props.lemmas.message);
            if (this.props.lemmas.sel_lemma === null) {
                this.props.history.push('/lemmas')
            }
        }
    }
    componentDidMount() {
        var _that = this;
        this.lemmaTable = $('#list_table').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'scrollX': true,
            'autoWidth': false,
            'createdRow': function (row, data, dataIndex) {
                $(row).on('click', function () {
                    if (data && data.concept_id) {
                        _that.goConcept(data.concept_id);
                    }
                })
            },
            'columns': [
                {
                    'data': 'lemma'
                },
                {
                    'data': 'definition'
                },
                {
                    'data': 'sources',
                    'render': function (data, type, full, meta) {
                        var result = "";
                        for (var i = 0; i < data.length; i++) {
                            result += data[i].source;
                            if (i < data.length - 1) {
                                result += '; ';
                            }
                        }
                        return result;
                    }
                },
                {
                    'data': 'updatedAt',

                },
                {
                    'data': 'auth',
                },
            ],
            columnDefs: [{
                'targets': 3, //index of column
                'type': 'customdate'
            }],
        });
        this.lemmaTable.clear();
        if (this.props.lemmas && this.props.lemmas.sel_lemma && this.props.lemmas.sel_lemma.lemma) {
            var list_concepts = this.props.lemmas.sel_lemma.lemma.concepts || [];
            // this.lemmaTable.rows.add(this.props.lemmas.sel_lemma.lemma.concepts);
            this.lemmaTable.rows.add(list_concepts);
        }
        this.lemmaTable.draw();
    }
    goConcept = (conceptId) => {
        this.props.history.push(`/concept/${conceptId}`);
    }
    render() {
        var list_transition = [];
        var lemma = {};
        if(this.props.lemmas) {
            if (this.props.lemmas.loading) {
                Swal.fire({
                    title: 'Loading...',
                    text: "Please wait",
                    onOpen: () => {
                        Swal.showLoading();
                    },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            } else {
                Swal.close();
            }
            if(this.props.lemmas.sel_lemma) {
                lemma = this.props.lemmas.sel_lemma.lemma;
                list_transition = this.props.lemmas.sel_lemma.list_transition;
            }
        }

        return (
            <section className="content skin-lemmi">
                <div className="row">
                    <div className="col-md-12">
                        <div className="sommario">
                            <label><Trans id="Content.CreationDate"></Trans>:</label><span id="createdAt">{lemma ? lemma.createdAt : ''}</span>
                            <label><Trans id="Content.LastUpdate"></Trans>:</label><span id="updatedAt">{lemma ? lemma.updatedAt : ''}</span>
                            <label><Trans id="Content.User"></Trans>:</label><span id="auth">{lemma ? lemma.auth : ''}</span>
                            <label><Trans id="Content.State"></Trans>:</label> <span className="label label-success" id="state" >{lemma ? capitalize(lemma.state) : ''}</span>
                            <label><Trans id="Modal.OntoclassRefer"></Trans>:</label><span id="owner_ontoclass">{lemma ? lemma.owner_ontoclass : ''}</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <div className="box box-noborder">
                            <div className="box-body">
                                <p className="text-label text-center">Lemma</p>
                                <h3 className="nome_main text-center" id="lemma">{lemma ? lemma.lemma : ''}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="comandi">
                    <div>
                        {
                            list_transition && list_transition.indexOf('update') > -1 ? <button id="update" type="button" className="btn btn-default" onClick={() => {
                                this.props.modalActions.showModal('EDIT_LEMMA_MODAL');
                            }}><Trans id="Button.Modification"></Trans>
                            </button> : <></>
                        }
                        {
                            list_transition && list_transition.indexOf('deletion') > -1 ?
                                <button id="delete" className="btn" onClick={() => {
                                    this.props.modalActions.showModal('DELETE_LEMMA_MODAL');
                                }}><b><Trans id="Button.Delete"></Trans></b></button> : <></>
                        }
                        <button id="back" className="btn" onClick={() => { this.props.history.goBack() }}><b><Trans id="Button.Back"></Trans></b></button>
                    </div>
                </div>
                < div className="row" >
                    <div className="col-md-12" id="_list_concepts">
                        <h3> <Trans id="Table.ConceptsAssociated"></Trans>:</h3 >
                        <table id="list_table" className="table table-hover">
                            <thead>
                                <tr>
                                    <th>
                                        <span data-tip data-for="header1"><Trans id="Table.Lemma" /></span>
                                        <ReactTooltip place="top" effect="solid" id="header1" className="dynTooltip">
                                            <div><Trans id="Table.Lemma" /></div>
                                        </ReactTooltip>
                                    </th>
                                    <th>
                                        <span data-tip data-for="header2"><Trans id="Table.Definition" /></span>
                                        <ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
                                            <div><Trans id="Table.Definition" /></div>
                                        </ReactTooltip>
                                    </th>
                                    <th>
                                        <span data-tip data-for="header3"><Trans id="Table.Sources" /></span>
                                        <ReactTooltip place="top" effect="solid" id="header3" className="dynTooltip">
                                            <div><Trans id="Table.Sources" /></div>
                                        </ReactTooltip>
                                    </th>
                                    <th>
                                        <span data-tip data-for="header7"><Trans id="Table.LastUpdate" /></span>
                                        <ReactTooltip place="top" effect="solid" id="header7" className="dynTooltip">
                                            <div><Trans id="Table.LastUpdate" /></div>
                                        </ReactTooltip>
                                    </th>
                                    <th>
                                        <span data-tip data-for="header8"><Trans id="Table.User" /></span>
                                        <ReactTooltip place="top" effect="solid" id="header8" className="dynTooltip">
                                            <div><Trans id="Table.User" /></div>
                                        </ReactTooltip>
                                    </th>
                                </tr>
                            </thead>
                            <tbody id="list_table_body">
                            </tbody>
                        </table>
                    </div >
                </div >
            </section >
        )
    }
}

export default connect(
    state => ({
        lemmas: state.lemmas,
        events: state.events
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname, addPathname, deletePathname }, dispatch),
        lemmaActions: bindActionCreators({ getLemmaDetail }, dispatch),
        eventActions: bindActionCreators({ getEvents }, dispatch),
        modalActions: bindActionCreators({ showModal }, dispatch),

    })
)(LemmaDetail);
