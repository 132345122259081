import { handleActions } from 'redux-actions';

import {
    getEvents,
    getEventsFailed,
    getEventsSucceed,
} from './eventActions';

const defaultState = {
    events: null,
    error: null,
    loading: false,
    success: false,
    message: null,
};

const reducer = handleActions(
    {
        [getEvents](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getEventsFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error.data,
                events: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [getEventsSucceed](
            state,
            {
                payload: { events }
            }
        ) {
            return {
                ...state,
                events: events,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
    },
    defaultState
);

export default reducer;
