import { handleActions } from 'redux-actions';

import {
    getUtilities,
    getUtilitiesFailed,
    getUtilitiesSucceed,
    postUtility,
    postUtilityFailed,
    postUtilitySucceed,
    updateUtility,
    updateUtilityFailed,
    updateUtilitySucceed,
    deleteUtility,
    deleteUtilityFailed,
    deleteUtilitySucceed
} from './utilityActions';

const defaultState = {
    error: null,
    loading: false,
    success: false,
    message: null,
    times: null,
    legisls: null,
    measures: null,
    type_relations: null,
    sel_table: null,
    sel_utility: null
};
const reducer = handleActions(
    {
        [getUtilities](state,) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getUtilitiesFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error.data,
                loading: false,
                success: false,
                message: error ? error.message : 'Something went wrong'
            };
        },
        [getUtilitiesSucceed](
            state,
            {
                payload: { utilities, ref_table }
            }
        ) {
            switch (ref_table) {
                case 'time':
                    return {
                        ...state,
                        times: utilities,
                        error: null,
                        loading: false,
                        success: true,
                        message: null
                    };
                case 'legisl':
                    return {
                        ...state,
                        legisls: utilities,
                        error: null,
                        loading: false,
                        success: true,
                        message: null
                    };
                case 'measure':
                    return {
                        ...state,
                        measures: utilities,
                        error: null,
                        loading: false,
                        success: true,
                        message: null
                    };
                case 'type_relation':
                    return {
                        ...state,
                        type_relations: utilities,
                        error: null,
                        loading: false,
                        success: true,
                        message: null
                    };
                default:
            }
        },
        [postUtility](state) {
            return {
                ...state,
                error: null,
                // loading: true,
                message: null,
                success: false,
            };
        },
        [postUtilityFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in postUtility', error)
            return {
                ...state,
                error,
                loading: false,
                success: false,
                message: error ? error.message : "Something went wrong"
            };
        },
        [postUtilitySucceed](
            state,
            {
                payload: { utility }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                message: "Add Utility Succeed!",
                success: true
            };
        },
        [updateUtility](state) {
            return {
                ...state,
                error: null,
                // loading: true,
                message: null,
                success: false,
            };
        },
        [updateUtilityFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in updateUtiltiy', error)
            return {
                ...state,
                error,
                loading: false,
                success: false,
                message: error ? error.message : "Something went wrong"
            };
        },
        [updateUtilitySucceed](
            state,
            {
                payload: { utility }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                message: "Update Utility Succeed!",
                success: true
            };
        },
        [deleteUtility](state) {
            return {
                ...state,
                error: null,
                // loading: true,
                message: null,
                success: false,
            };
        },
        [deleteUtilityFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in deleteUtility', error)
            return {
                ...state,
                error,
                loading: false,
                success: false,
                message: error && error.data? error.data.message : "Something went wrong"
            };
        },
        [deleteUtilitySucceed](state ) {
            return {
                ...state,
                error: null,
                loading: false,
                message: "Delete Utility Succeed!",
                success: true
            };
        },
    },
    defaultState
);

export default reducer;
