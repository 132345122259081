import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { setPathname } from '../../services/common/commonActions';
import { getUtilities, updateUtility } from '../../services/utilities/utilityActions';
import { showModal } from '../../modals/modalConductorActions';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import 'datatables.net'
import 'datatables.net-bs'
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
class Utility extends React.Component {
    constructor() {
        super();
        this.timeReferTable = null;
        $.fn.dataTableExt.oSort["customdate-desc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();
            if (x > y) {
                return -1;
            }

            return 1;

        };

        $.fn.dataTableExt.oSort["customdate-asc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();

            if (x > y) {
                return 1;
            }
            return -1;
        }
    }

    componentWillMount() {
        this.props.commonActions.setPathname(
            [
                { "pathName": "Home", "Url": "", "id": 1, "color": "" },
                { "pathName": "Utilities", "Url": "utilities", "id": 2, "color": "#f99953" }
            ]
        )
        this.props.utilityActions.getUtilities('time');
        this.props.utilityActions.getUtilities('legisl');
        this.props.utilityActions.getUtilities('measure');
        this.props.utilityActions.getUtilities('type_relation');

    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.utilities && this.props.utilities.times !== prevProps.utilities.times && this.timeReferTable !== null) {
            this.timeReferTable.clear();
            if (this.props.utilities.times) {
                this.timeReferTable.rows.add(this.props.utilities.times);
            }
            this.timeReferTable.draw();
        }
        if (this.props.utilities && this.props.utilities.legisls !== prevProps.utilities.legisls && this.legislReferTable !== null) {
            this.legislReferTable.clear();
            if (this.props.utilities.legisls) {
                this.legislReferTable.rows.add(this.props.utilities.legisls);
            }
            this.legislReferTable.draw();
        }
        if (this.props.utilities && this.props.utilities.measures !== prevProps.utilities.measures && this.measureReferTable !== null) {
            this.measureReferTable.clear();
            if (this.props.utilities.measures) {
                this.measureReferTable.rows.add(this.props.utilities.measures);
            }
            this.measureReferTable.draw();
        }
        if (this.props.utilities && this.props.utilities.type_relations !== prevProps.utilities.type_relations && this.type_relationReferTable !== null) {
            this.type_relationReferTable.clear();
            if (this.props.utilities.type_relations) {
                this.type_relationReferTable.rows.add(this.props.utilities.type_relations);
            }
            this.type_relationReferTable.draw();
        }
        if (this.props.utilities.error !== prevProps.utilities.error && this.props.utilities.error !== null) {
            toastr.error('Error Occured', this.props.utilities.message);
        }

        if (this.props.utilities.error === null && !this.props.utilities.loading && prevProps.utilities && prevProps.utilities.loading && this.props.utilities.message) {
            toastr.success('Success', this.props.utilities.message);
        }
    }
    componentDidMount() {
        this.timeReferTable = $('#list_table_time').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'scrollX': false,
            'columns': [
                {
                    'data': 'label'
                },
                {
                    'data': 'updatedAt',
                },
                {
                    'data': 'id',
                    'createdCell': (td, cellData, rowData, row, col) => ReactDOM.render(
                        <div className="btn-group pull-right">
                            <button type="button" className="btn btn-default btn-sm"
                                onClick={() => this.props.modalActions.showModal('EDIT_UTILITY_MODAL', { id: cellData, ref_table: 'time', label: rowData.label })}>
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                            <button type="button" className="btn btn-default btn-sm"
                                onClick={() => this.props.modalActions.showModal('DELETE_UTILITY_MODAL', { id: cellData, ref_table: 'time' })}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                        </div>, td
                    )
                }
            ],
            columnDefs: [{
                'targets': 1, //index of column
                'type': 'customdate'
            }],
        });
        this.legislReferTable = $('#list_table_legisl').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'scrollX': false,
            'columns': [
                {
                    'data': 'label'
                },
                {
                    'data': 'updatedAt',
                },
                {
                    'data': 'id',
                    'createdCell': (td, cellData, rowData, row, col) => ReactDOM.render(
                        <div className="btn-group pull-right">
                            <button type="button" className="btn btn-default btn-sm"
                                onClick={() => this.props.modalActions.showModal('EDIT_UTILITY_MODAL', { id: cellData, ref_table: 'legisl', label: rowData.label })}>
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                            <button type="button" className="btn btn-default btn-sm"
                                onClick={() => this.props.modalActions.showModal('DELETE_UTILITY_MODAL', { id: cellData, ref_table: 'legisl' })}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                        </div>, td
                    )
                }
            ],
            columnDefs: [{
                'targets': 1, //index of column
                'type': 'customdate'
            }],
        });
        this.measureReferTable = $('#list_table_measure').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'scrollX': false,
            'columns': [
                {
                    'data': 'label'
                },
                {
                    'data': 'updatedAt',
                },
                {
                    'data': 'id',
                    'createdCell': (td, cellData, rowData, row, col) => ReactDOM.render(
                        <div className="btn-group pull-right">
                            <button type="button" className="btn btn-default btn-sm"
                                onClick={() => this.props.modalActions.showModal('EDIT_UTILITY_MODAL', { id: cellData, ref_table: 'measure', label: rowData.label })}>
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                            <button type="button" className="btn btn-default btn-sm"
                                onClick={() => this.props.modalActions.showModal('DELETE_UTILITY_MODAL', { id: cellData, ref_table: 'measure' })}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                        </div>, td
                    )
                }
            ],
            columnDefs: [{
                'targets': 1, //index of column
                'type': 'customdate'
            }],
        });
        this.type_relationReferTable = $('#list_table_type_relation').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'scrollX': false,
            'columns': [
                {
                    'data': 'label'
                },
                {
                    'data': 'updatedAt',
                },
                {
                    'data': 'id',
                    'createdCell': (td, cellData, rowData, row, col) => ReactDOM.render(
                        <div className="btn-group pull-right">
                            <button type="button" className="btn btn-default btn-sm"
                                onClick={() => this.props.modalActions.showModal('EDIT_UTILITY_MODAL', { id: cellData, ref_table: 'type_relation', label: rowData.label })}>
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                            <button type="button" className="btn btn-default btn-sm"
                                onClick={() => this.props.modalActions.showModal('DELETE_UTILITY_MODAL', { id: cellData, ref_table: 'type_relation' })}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                        </div>, td
                    )
                }
            ],
            columnDefs: [{
                'targets': 1, //index of column
                'type': 'customdate'
            }],
        });
        this.legislReferTable.clear();
        if (this.props.utilities && this.props.utilities.legisls) {
            this.legislReferTable.rows.add(this.props.utilities.legisls);
        }
        this.legislReferTable.draw();
        this.timeReferTable.clear();
        if (this.props.utilities && this.props.utilities.times) {
            this.timeReferTable.rows.add(this.props.utilities.times);
        }
        this.timeReferTable.draw();
        this.measureReferTable.clear();
        if (this.props.utilities && this.props.utilities.measures) {
            this.measureReferTable.rows.add(this.props.utilities.measures);
        }
        this.measureReferTable.draw();
        this.type_relationReferTable.clear();
        if (this.props.utilities && this.props.utilities.type_relations) {
            this.type_relationReferTable.rows.add(this.props.utilities.type_relations);
        }
        this.type_relationReferTable.draw();
    }

    render() {
        if (this.props.utilities && this.props.utilities.loading) {
            Swal.fire({
                title: 'Loading...',
                text: "Plese wait",
                onOpen: () => {
                    Swal.showLoading();
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
        } else {
            Swal.close();
        }
        return (
            <React.Fragment>
                <section className="content skin-utility">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="nav-tabs-custom">
                                <ul className="nav nav-tabs">
                                    <li className="active"><a href="#tab_time" data-toggle="tab"><Trans id="Table.TimeRefers"></Trans></a></li>
                                    <li><a href="#tab_legisl" data-toggle="tab"><Trans id="Table.LegislRefers"></Trans></a></li>
                                    <li><a href="#tab_measure" data-toggle="tab"><Trans id="Table.MeasureRefers"></Trans></a></li>
                                    <li><a href="#tab_type_relation" data-toggle="tab"><Trans id="Table.TypeRelationsRefer"></Trans></a></li>
                                </ul>
                                <div className="tab-content no-padding">
                                    <div className="tab-pane active" id="tab_time">
                                        <div className="top_comandi">
                                            <button type="button" id="add" className="btn btn-default" onClick={() => this.props.modalActions.showModal('ADD_UTILITY_MODAL', 'time')}>
                                                <span className="glyphicon glyphicon-plus-sign"></span><Trans id="Button.New"></Trans>
                                            </button>
                                        </div>
                                        <table id="list_table_time" className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span data-tip data-for="header1"><Trans id="Table.TimeRefer" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header1" className="dynTooltip">
                                                            <div><Trans id="Table.TimeRefer" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                    <th>
                                                        <span data-tip data-for="header2"><Trans id="Table.LastUpdate" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
                                                            <div><Trans id="Table.LastUpdate" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tab-pane" id="tab_legisl">
                                        <div className="top_comandi">
                                            <button type="button" className="btn btn-default" onClick={() => this.props.modalActions.showModal('ADD_UTILITY_MODAL', 'legisl')}>
                                                <span className="glyphicon glyphicon-plus-sign"></span> <Trans id="Button.New"></Trans>
                                            </button>
                                        </div>
                                        <table id="list_table_legisl" className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span data-tip data-for="3"><Trans id="Table.LegislRefer" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header3" className="dynTooltip">
                                                            <div><Trans id="Table.LegislRefer" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                    <th><Trans id="Table.LastUpdate"></Trans>
                                                        <span data-tip data-for="header4"><Trans id="Table.LastUpdate" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header4" className="dynTooltip">
                                                            <div><Trans id="Table.LastUpdate" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tab-pane" id="tab_measure">
                                        <div className="top_comandi">
                                            <button type="button" className="btn btn-default" onClick={() => this.props.modalActions.showModal('ADD_UTILITY_MODAL', 'measure')}>
                                                <span className="glyphicon glyphicon-plus-sign"></span><Trans id="Button.New"></Trans>
                                            </button>
                                        </div>
                                        <table id="list_table_measure" className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span data-tip data-for="header5"><Trans id="Table.MeasureRefers" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header5" className="dynTooltip">
                                                            <div><Trans id="Table.MeasureRefers" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                    <th>
                                                        <span data-tip data-for="header6"><Trans id="Table.LastUpdate" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header6" className="dynTooltip">
                                                            <div><Trans id="Table.LastUpdate" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tab-pane" id="tab_type_relation">
                                        <div className="top_comandi">
                                            <button type="button" className="btn btn-default" onClick={() => this.props.modalActions.showModal('ADD_UTILITY_MODAL', 'type_relation')}>
                                                <span className="glyphicon glyphicon-plus-sign"></span><Trans id="Button.New"></Trans>
                                            </button>
                                        </div>
                                        <table id="list_table_type_relation" className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span data-tip data-for="header7"><Trans id="Table.TypeRelationRefer" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header7" className="dynTooltip">
                                                            <div><Trans id="Table.TypeRelationRefer" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                    <th>
                                                        <span data-tip data-for="header8"><Trans id="Table.LastUpdate" /></span>
                                                        <ReactTooltip place="top" effect="solid" id="header8" className="dynTooltip">
                                                            <div><Trans id="Table.LastUpdate" /></div>
                                                        </ReactTooltip>
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({
        utilities: state.utilities
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname }, dispatch),
        utilityActions: bindActionCreators({ getUtilities, updateUtility }, dispatch),
        modalActions: bindActionCreators({ showModal }, dispatch),
    })
)(Utility);
