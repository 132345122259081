import { authSubscriber } from './auth/authSaga';
import { projectSubscriber } from './project/projectSaga';
import { usersSubscriber } from './users/usersSaga';
import { ontoclassSubscriber } from './ontoclass/ontoclassSaga';
import { lemmaSubscriber } from './lemma/lemmaSaga';
import { definitionSubscriber } from './definition/definitionSaga';
import { utilitySubscriber } from './utilities/utilitySaga';
import { conceptSubscriber } from './concept/conceptSaga';
import { sourceSubscriber } from './source/sourceSaga';
import { bibliosSubscriber } from './biblio/biblioSaga';
import { indicator_theorSubscriber } from './indicatorTheor/indicator_theorSaga';
import { indicator_appliedSubscriber } from './indicatorApplied/indicator_appliedSaga';
import { requestSubscriber } from './request/requestSaga';
import { eventSubscriber } from './event_entity/eventSaga';
import { dashboardSubscriber } from './dashboard/dashboardSaga'
export {
    authSubscriber,
    projectSubscriber,
    usersSubscriber,
    ontoclassSubscriber,
    lemmaSubscriber,
    definitionSubscriber,
    utilitySubscriber,
    conceptSubscriber,
    sourceSubscriber,
    bibliosSubscriber,
    indicator_theorSubscriber,
    indicator_appliedSubscriber,
    requestSubscriber,
    eventSubscriber,
    dashboardSubscriber
}