import { wrapRequest, xapi } from '../utils_services';

const get = wrapRequest(async (project_id) =>
    xapi().get('/indicators/applied?project_id=' + project_id)
);

const postIndicatorApplied = wrapRequest(async (name, note, indicator_theor_id, concepts, owner_ontoclass_id, sources, leg_refs) => {
    return xapi().post('/indicators/applied/', { name, note, indicator_theor_id, concepts, owner_ontoclass_id, sources, leg_refs })
});
const getIndicatorAppliedDetailApi = wrapRequest(async (indicator_applied_id) =>
    xapi().get('/indicators/applied/' + indicator_applied_id)
);
const updateIndicatorAppliedApi = wrapRequest(async (indicator_applied_id, name, note, indicator_theor_id, concepts, owner_ontoclass_id, sources, leg_refs) =>
    xapi().put('/indicators/applied/' + indicator_applied_id, { name, note, indicator_theor_id, concepts, owner_ontoclass_id, sources, leg_refs })
);
const updateIndicatorAppliedOntoclassApi = wrapRequest(async (indicator_applied_id, ontoclasses) =>
    xapi().post('/indicators/applied/' + indicator_applied_id + '/addontoclass', { ontoclasses })
);
const deleteIndicatorAppliedApi = wrapRequest(async (indicator_applied_id) =>
    xapi().delete('/indicators/applied/' + indicator_applied_id)
);
const postIndicatorAppliedTime = wrapRequest(async (indicator_applied_id, time_id, value, measure_id) => {
    return xapi().post('/indicators/applied/' + indicator_applied_id + '/time', { time_id, value, measure_id })
});
const updateIndicatorAppliedTimeApi = wrapRequest(async (indicator_applied_id, indicator_a_time_id, time_id, value, measure_id) =>
    xapi().put('/indicators/applied/' + indicator_applied_id + '/time/' + indicator_a_time_id, { time_id, value, measure_id })
);
const deleteIndicatorAppliedTimeApi = wrapRequest(async (indicator_applied_id, indicator_a_time_id) =>
    xapi().delete('/indicators/applied/' + indicator_applied_id + '/time/' + indicator_a_time_id)
);
export { get, postIndicatorApplied, getIndicatorAppliedDetailApi, updateIndicatorAppliedApi, updateIndicatorAppliedOntoclassApi, deleteIndicatorAppliedApi, postIndicatorAppliedTime, updateIndicatorAppliedTimeApi, deleteIndicatorAppliedTimeApi };