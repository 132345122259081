import React from 'react';
import { connect } from 'react-redux';
import { setPathname } from '../../services/common/commonActions';
import { getBiblios, postBiblio } from '../../services/biblio/biblioActions';
import { getOntoclasses } from '../../services/ontoclass/ontoclassActions';
import { showModal } from '../../modals/modalConductorActions';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import 'datatables.net'
import 'datatables.net-bs'
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';

class Biblio extends React.Component {
    constructor() {
        super();
        this.state = {
            filter_biblio: '',
            filter_count_concepts_from: '',
            filter_count_concepts_to: '',
            filter_owner_ontoclasse: '',
            filter_auth: ''
        }
        this.biblioTable = null;
        $.fn.dataTableExt.oSort["customdate-desc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();
            if (x > y) {
                return -1;
            }

            return 1;

        };

        $.fn.dataTableExt.oSort["customdate-asc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();

            if (x > y) {
                return 1;
            }
            return -1;
        }
    }
    componentWillMount() {
        this.props.commonActions.setPathname(
            [
                { "pathName": "Home", "Url": "", "id": 1, "color": "" },
                { "pathName": "Biblios", "Url": "biblios", "id": 2, "color": "#d16de0", "subHeader" : "Biblios" }
            ]
        )
        this.props.biblioActions.getBiblios();
        this.props.ontoclassActions.getOntoclasses();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.biblios && this.props.biblios.biblios !== prevProps.biblios.biblios && this.biblioTable !== null) {
            this.biblioTable.clear();
            if (this.props.biblios.biblios) {
                this.biblioTable.rows.add(this.props.biblios.biblios);
            }
            this.biblioTable.draw();
        }
        if (this.props.biblios && this.props.biblios.error !== prevProps.biblios.error && this.props.biblios.error !== null) {
            toastr.error('Error Occured', this.props.biblios.message);
        }

        if (this.props.biblios && this.props.biblios.error === null && !this.props.biblios.loading && prevProps.biblios && prevProps.biblios.loading && this.props.biblios.message) {
            toastr.success('Success', this.props.biblios.message);
            if (this.props.biblios && this.props.biblios.sel_biblio && this.props.biblios.sel_biblio.biblio) {
                this.props.history.push(`/biblio/${this.props.biblios.sel_biblio.biblio.biblio_id}`);
            }
        }
    }
    componentDidMount() {
        var _that = this;
        this.biblioTable = $('#list_table').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'scrollX': true,
            'createdRow': function (row, data, dataIndex) {
                $(row).on('click', function () {
                    if (data && data.biblio_id) {
                        _that.goBiblio(data.biblio_id);
                    }
                })
            },
            'columns': [
                {
                    'data': 'biblio'
                },
                {
                    'data': 'concepts',
                    'render': function (data, type, full, meta) {
                        return data ? data.length : ''
                    }
                },
                {
                    'data': 'indicators_theor',
                    'render': function (data, type, full, meta) {
                        return data ? data.length : ''
                    }
                },
                {
                    'data': 'owner_ontoclass',
                },
                {
                    'data': 'updatedAt',
                },
                {
                    'data': 'auth',
                }
            ],
            columnDefs: [{
                'targets': 3, //index of column
                'type': 'customdate'
            }],
        });
        this.biblioTable.clear();
        if (this.props.biblios && this.props.biblios.biblios) {
            this.biblioTable.rows.add(this.props.biblios.biblios);
        }
        this.biblioTable.draw();
    }

    goBiblio = (biblioId) => {
        this.props.history.push(`biblio/${biblioId}`);
    }
    onFilterChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSearch = (e) => {
        var filterData = [];
        if (this.props.biblios && this.props.biblios.biblios && this.props.biblios.biblios.length > 0) {
            var biblios = this.props.biblios.biblios;
            for (var i = 0; i < biblios.length; i++) {
                var fileterRes = true;
                if (this.state.filter_biblio !== '') {
                    if (biblios[i].biblio.toLowerCase().indexOf(this.state.filter_biblio.toLowerCase()) === -1) fileterRes = false;
                }
                if (this.state.filter_owner_ontoclasse) {
                    if (biblios[i].owner_ontoclass.toLowerCase().indexOf(this.state.filter_owner_ontoclasse.toLowerCase()) === -1) fileterRes = false;
                }
                if (this.state.filter_count_concepts_from) {
                    if (biblios[i].concepts.length < this.state.filter_count_concepts_from) fileterRes = false;
                }
                if (this.state.filter_count_concepts_to) {
                    if (biblios[i].concepts.length > this.state.filter_count_concepts_to) fileterRes = false;
                }
                if (this.state.filter_auth) {
                    if (biblios[i].auth.toLowerCase().indexOf(this.state.filter_auth.toLowerCase()) === -1) fileterRes = false;
                }
                if (fileterRes) {
                    filterData.push(biblios[i]);
                }
            }
            this.biblioTable.clear();
            this.biblioTable.rows.add(filterData);
            this.biblioTable.draw();
        }
    }
    handleReset = (e) => {
        this.setState({
            filter_biblio: '',
            filter_count_concepts_from: '',
            filter_count_concepts_to: '',
            filter_owner_ontoclasse: '',
            filter_auth: ''
        });
        this.biblioTable.clear();
        if (this.props.biblios.biblios) {
            this.biblioTable.rows.add(this.props.biblios.biblios);
        }
        this.biblioTable.draw();
    }

    render() {
        if (this.props.biblios && this.props.biblios.loading) {
            Swal.fire({
                title: 'Loading...',
                text: "Plese wait",
                onOpen: () => {
                    Swal.showLoading();
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
        } else {
            Swal.close();
        }
        return (
            <React.Fragment>
                <section className="content skin-biblio">
                    <div className="top_comandi">
                        <button type="button" onClick={() => {
                            this.props.modalActions.showModal('ADD_BIBLIO_MODAL')
                        }} id="add" className="btn btn-default">
                            <span className="glyphicon glyphicon-plus-sign"></span><Trans id="Button.InsertNewBiblio" />
                        </button>
                    </div>
                    <div className="ricerca">
                        <div className="_form-inline">
                            <div className="form-group row">
                                <div className="col-md-4">
                                    <label><Trans id="Table.Filterby"></Trans>:</label>
                                    <div className="input-group">
                                        <span className="input-group-addon"><Trans id="Table.Biblio"></Trans></span>
                                        <input type="text" name="filter_biblio" className="form-control" value={this.state.filter_biblio} onChange={this.onFilterChange} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label><Trans id="Table.ConceptsContained"></Trans>:</label>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="input-group">
                                                <span className="input-group-addon"><Trans id="Table.From"></Trans></span>
                                                <input type="number" size="2" name="filter_count_concepts_from" value={this.state.filter_count_concepts_from} min="0" className="form-control" onChange={this.onFilterChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-group">
                                                <span className="input-group-addon"><Trans id="Table.To"></Trans></span>
                                                <input type="number" size="2" name="filter_count_concepts_to" value={this.state.filter_count_concepts_to} min="0" className="form-control" onChange={this.onFilterChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label><Trans id="Table.ReferenceOntoclass"></Trans>:</label>
                                    <input type="text" name="filter_owner_ontoclasse" className="form-control" value={this.state.filter_owner_ontoclasse} onChange={this.onFilterChange} />
                                </div>
                                <div className="col-md-4">
                                    <label><Trans id="Content.User"></Trans>:</label>
                                    <input type="text" name="filter_auth" className="form-control" value={this.state.filter_auth} onChange={this.onFilterChange} />
                                </div>
                                <div className="col-md-2">
                                    <label>&nbsp;</label>
                                    <button type="button" className="btn btn-default form-control" onClick={() => this.handleSearch()}><Trans id="Button.SearchFor"></Trans></button>
                                </div>
                                <div className="col-md-2">
                                    <label>&nbsp;</label>
                                    <button type="button" className="btn form-control" onClick={() => this.handleReset()}><Trans id="Button.Reset"></Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <table id="list_table" className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <span data-tip data-for="header1"><Trans id="Table.Biblio" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header1" className="dynTooltip">
                                                <div><Trans id="Table.Biblio" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header2"><Trans id="Table.ConceptsContained" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
                                                <div><Trans id="Table.ConceptsContained" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header3"><Trans id="Table.IndicatorsTheorContained" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header3" className="dynTooltip">
                                                <div><Trans id="Table.IndicatorsTheorContained" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header4"><Trans id="Table.ReferenceOntoclass" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header4" className="dynTooltip">
                                                <div><Trans id="Table.ReferenceOntoclass" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header5"><Trans id="Table.LastUpdate" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header5" className="dynTooltip">
                                                <div><Trans id="Table.LastUpdate" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header6"><Trans id="Table.User" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header6" className="dynTooltip">
                                                <div><Trans id="Table.User" /></div>
                                            </ReactTooltip>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="list_table_body">

                                </tbody>

                            </table>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({
        biblios: state.biblios
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname }, dispatch),
        biblioActions: bindActionCreators({ getBiblios, postBiblio }, dispatch),
        ontoclassActions: bindActionCreators({ getOntoclasses }, dispatch),
        modalActions: bindActionCreators({ showModal }, dispatch),
    })
)(Biblio);
