import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postOntoclass } from '../../../../services/ontoclass/ontoclassActions';
import { hideModal } from '../../../modalConductorActions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import Trans from '../../../../services/utils/Trans';

class OntoclassAddModal extends React.Component {
    constructor() {
        super();
        this.change = this.change.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    change(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }

    render() {
        console.log('ADD ONTOCLASS:' , this.props.modal.params)
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader><Trans id="Modal.NewOntoclass"></Trans></ModalHeader>
                <Formik
                    initialValues={{
                        ontoclass: '',
                    }}
                    validationSchema={Yup.object().shape({
                        ontoclass: Yup.string()
                            .required('Ontoclass is required')
                    })}
                    onSubmit={fields => {
                        this.props.ontoclassActions.postOntoclass(fields.ontoclass);
                        this.props.modalActions.hideModal();
                    }}
                    render={({ errors, status, touched, setFieldValue }) => (
                        <Form className="skin-ontoclassi">
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Modal.Ontoclass"></Trans></label>
                                    <Field name="ontoclass" type="text" className={'form-control' + (errors.ontoclass && touched.ontoclass ? ' is-invalid' : '')} placeholder="Nuova Ontoclasse" />
                                    <ErrorMessage name="ontoclass" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button type="submit" className="btn btn-default" >
                                    <Trans id="Button.Confirm"></Trans>
                                </button>
                                <button className="btn" onClick={this.onCancel}>
                                    <Trans id="Button.Cancel"></Trans>
                                </button>
                            </ModalFooter>
                        </Form>
                    )}
                />
            </Modal>
        );
    }
}

export default connect(
    null,
    dispatch => ({
        ontoclassActions: bindActionCreators({ postOntoclass }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(OntoclassAddModal);
