import { createActions } from 'redux-actions';

const {
    getIndicatorApplied,
    getIndicatorAppliedFailed,
    getIndicatorAppliedSucceed,
    postIndicatorApplied,
    postIndicatorAppliedFailed,
    postIndicatorAppliedSucceed,
    getIndicatorAppliedDetail,
    getIndicatorAppliedDetailFailed,
    getIndicatorAppliedDetailSucceed,
    updateIndicatorApplied,
    updateIndicatorAppliedFailed,
    updateIndicatorAppliedSucceed,
    deleteIndicatorApplied,
    deleteIndicatorAppliedFailed,
    deleteIndicatorAppliedSucceed,
    postIndicatorAppliedTime,
    postIndicatorAppliedTimeFailed,
    postIndicatorAppliedTimeSucceed,
    updateIndicatorAppliedTime,
    updateIndicatorAppliedTimeFailed,
    updateIndicatorAppliedTimeSucceed,
    deleteIndicatorAppliedTime,
    deleteIndicatorAppliedTimeFailed,
    deleteIndicatorAppliedTimeSucceed

} = createActions({
    GET_INDICATOR_APPLIED: (project_id) => ({ project_id }),
    GET_INDICATOR_APPLIED_FAILED: error => ({ error }),
    GET_INDICATOR_APPLIED_SUCCEED: indicators_applied => ({ indicators_applied }),
    POST_INDICATOR_APPLIED: (name, note, indicator_theor_id, concepts, owner_ontoclass_id, sources, leg_refs) => ({ name, note, indicator_theor_id, concepts, owner_ontoclass_id, sources, leg_refs}),
    POST_INDICATOR_APPLIED_FAILED: error => ({ error }),
    POST_INDICATOR_APPLIED_SUCCEED: indicators_applied => ({ indicators_applied }),
    GET_INDICATOR_APPLIED_DETAIL: indicator_applied_id => ({ indicator_applied_id }),
    GET_INDICATOR_APPLIED_DETAIL_FAILED: error => ({ error }),
    GET_INDICATOR_APPLIED_DETAIL_SUCCEED: indicator_applied => ({ indicator_applied }),
    UPDATE_INDICATOR_APPLIED: (indicator_applied_id, name, note, indicator_theor_id, concepts, owner_ontoclass_id, sources, leg_refs) => ({ indicator_applied_id, name, note, indicator_theor_id, concepts, owner_ontoclass_id, sources, leg_refs}),
    UPDATE_INDICATOR_APPLIED_FAILED: error => ({ error }),
    UPDATE_INDICATOR_APPLIED_SUCCEED: indicators_applied => ({ indicators_applied }),
    DELETE_INDICATOR_APPLIED: indicator_applied_id => ({ indicator_applied_id }),
    DELETE_INDICATOR_APPLIED_FAILED: error => ({ error }),
    DELETE_INDICATOR_APPLIED_SUCCEED: () => ({}),
    POST_INDICATOR_APPLIED_TIME: (indicator_applied_id, time_id, value, measure_id) => ({ indicator_applied_id, time_id, value, measure_id }),
    POST_INDICATOR_APPLIED_TIME_FAILED: error => ({ error }),
    POST_INDICATOR_APPLIED_TIME_SUCCEED: indicators_applied => ({ indicators_applied }),
    UPDATE_INDICATOR_APPLIED_TIME: (indicator_applied_id, indicator_a_time_id, time_id, value, measure_id) => ({ indicator_applied_id, indicator_a_time_id, time_id, value, measure_id }),
    UPDATE_INDICATOR_APPLIED_TIME_FAILED: error => ({ error }),
    UPDATE_INDICATOR_APPLIED_TIME_SUCCEED: indicators_applied => ({ indicators_applied }),
    DELETE_INDICATOR_APPLIED_TIME: (indicator_applied_id, indicator_a_time_id) => ({ indicator_applied_id, indicator_a_time_id }),
    DELETE_INDICATOR_APPLIED_TIME_FAILED: error => ({ error }),
    DELETE_INDICATOR_APPLIED_TIME_SUCCEED: () => ({}),
});

export {
    getIndicatorApplied,
    getIndicatorAppliedFailed,
    getIndicatorAppliedSucceed,
    postIndicatorApplied,
    postIndicatorAppliedFailed,
    postIndicatorAppliedSucceed,
    getIndicatorAppliedDetail,
    getIndicatorAppliedDetailFailed,
    getIndicatorAppliedDetailSucceed,
    updateIndicatorApplied,
    updateIndicatorAppliedFailed,
    updateIndicatorAppliedSucceed,
    deleteIndicatorApplied,
    deleteIndicatorAppliedFailed,
    deleteIndicatorAppliedSucceed,
    postIndicatorAppliedTime,
    postIndicatorAppliedTimeFailed,
    postIndicatorAppliedTimeSucceed,
    updateIndicatorAppliedTime,
    updateIndicatorAppliedTimeFailed,
    updateIndicatorAppliedTimeSucceed,
    deleteIndicatorAppliedTime,
    deleteIndicatorAppliedTimeFailed,
    deleteIndicatorAppliedTimeSucceed
};
