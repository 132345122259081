import { wrapRequest, xapi } from '../utils_services';

const get = wrapRequest(async () =>
    xapi().get('/requests')
);

const requestpost = wrapRequest(async (object, request, ref_id, tbl, from_ontoclass_id, to_ontoclass_id) => {
    return xapi().post('/requests', { object, request, ref_id, tbl, from_ontoclass_id, to_ontoclass_id })
});
const getRequestDetailApi = wrapRequest(async (request_id) =>
    xapi().get('/requests/' + request_id)
);
const updateRequestApi = wrapRequest(async (request_id, object, request, ref_id, tbl, from_ontoclass_id, to_ontoclass_id) => {
    return xapi().put('/requests/' + request_id, { object, request, ref_id, tbl, from_ontoclass_id, to_ontoclass_id })
});
const deleteRequestApi = wrapRequest(async (request_id) => {
    console.log('form data in requestApi', request_id);
    return xapi().delete('/requests/' + request_id)
});
const approveRequestApi = wrapRequest(async (request_id, answer) => {
    return xapi().put('/requests/' + request_id + '/approve', { answer })
});
const discardRequestApi = wrapRequest(async (request_id, answer) => {
    return xapi().put('/requests/' + request_id + '/discard', { answer })
});
export { get, requestpost, getRequestDetailApi, updateRequestApi, deleteRequestApi, approveRequestApi, discardRequestApi };
