import React from 'react';
import { connect } from 'react-redux';
import { setPathname, addPathname, deletePathname } from '../../services/common/commonActions';
import { getBiblioDetail } from '../../services/biblio/biblioActions';
import { getEvents } from '../../services/event_entity/eventActions';
import { showModal } from '../../modals/modalConductorActions';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import { capitalize } from '../../services/utils_services';
import 'datatables.net'
import 'datatables.net-bs'
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';

class BiblioDetail extends React.Component {
    constructor() {
        super();
        this.biblioTable = null;
        $.fn.dataTableExt.oSort["customdate-desc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();
            if (x > y) {
                return -1;
            }

            return 1;

        };

        $.fn.dataTableExt.oSort["customdate-asc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();

            if (x > y) {
                return 1;
            }
            return -1;
        }
    }
    componentWillMount() {
        // this.props.commonActions.setPathname(
        //     [
        //         { "pathName": "Home", "Url": "", "id": 1, "color": "" },
        //         { "pathName": "Biblios", "Url": "biblios", "id": 2, "color": "#d16de0" },
        //         { "pathName": "Biblio", "Url": "/", "id": 3, "color": "#d16de0" }
        //     ]
        // )
        this.props.biblioActions.getBiblioDetail(this.props.match.params.id);
        this.props.eventActions.getEvents(this.props.match.params.id, 'biblio')
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.biblios && this.props.biblios.sel_biblio && this.props.biblios.sel_biblio !== prevProps.biblios.sel_biblio && this.props.biblios.sel_biblio.biblio) {
            var biblio_name = this.props.biblios.sel_biblio.biblio.biblio
            this.props.commonActions.addPathname(
                { "pathName": biblio_name, "Url": "biblio/" + this.props.match.params.id, "color": "#6d81ab", "subHeader": "Biblio Detail" }
            )
        }
        if (this.props.biblios && this.props.biblios.sel_biblio !== prevProps.biblios.sel_biblio && this.biblioTable !== null) {
            this.biblioTable.clear();
            if (this.props.biblios.sel_biblio && this.props.biblios.sel_biblio.biblio && this.props.biblios.sel_biblio.biblio.concepts) {
                this.biblioTable.rows.add(this.props.biblios.sel_biblio.biblio.concepts);
            }
            this.biblioTable.draw();

            this.indicatorsTable.clear();
            if (this.props.biblios.sel_biblio && this.props.biblios.sel_biblio.biblio && this.props.biblios.sel_biblio.biblio.indicators_theor) {
                this.indicatorsTable.rows.add(this.props.biblios.sel_biblio.biblio.indicators_theor);
            }
            this.indicatorsTable.draw();
        }
        if (this.props.biblios.error !== prevProps.biblios.error && this.props.biblios.error !== null) {
            toastr.error('Error Occured', this.props.biblios.message);
        }

        if (this.props.biblios.error === null && !this.props.biblios.loading && prevProps.biblios && prevProps.biblios.loading && this.props.biblios.message) {
            toastr.success('Success', this.props.biblios.message);
            if (this.props.biblios.sel_biblio === null) {
                this.props.history.push('/biblios');
            }
        }
    }
    componentDidMount() {
        var _that = this;
        this.biblioTable = $('#list_table').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'createdRow': function (row, data, dataIndex) {
                $(row).on('click', function () {
                    if (data && data.concept_id) {
                        _that.goConcept(data.concept_id);
                    }
                })
            },
            'columns': [
                {
                    'data': 'lemma'
                },
                {
                    'data': 'definition',
                },
                {
                    'data': 'biblios',
                    'render': function (data, type, full, meta) {
                        var result = "";
                        if (data) {
                            for (var i = 0; i < data.length; i++) {
                                result += data[i];
                                if (i < data.length - 1) {
                                    result += '; ';
                                }
                            }
                        }
                        return result;
                    }
                },
                {
                    'data': 'updatedAt',
                },
                {
                    'data': 'auth',
                }
            ],
            columnDefs: [{
                'targets': 3, //index of column
                'type': 'customdate'
            }],
        });
        this.biblioTable.clear();
        if (this.props.biblios && this.props.biblios.sel_biblio) {
            var list_concepts = this.props.biblios.sel_biblio.concepts || [];
            // this.biblioTable.rows.add(this.props.biblios.sel_biblio.biblio.concepts);
            this.biblioTable.rows.add(list_concepts);
        }
        this.biblioTable.draw();

        /// Indicatori Teorici
        this.indicatorsTable = $('#list_table_indicator').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'createdRow': function (row, data, dataIndex) {
                $(row).on('click', function () {
                    if (data && data.indicator_theor_id) {
                        _that.goIndicator(data.indicator_theor_id);
                    }
                })
            },
            'columns': [
                {
                    'data': 'name'
                },
                {
                    'data': 'note',
                },
                {
                    'data': 'owner_ontoclass',
                },
                {
                    'data': 'updatedAt',
                },
                {
                    'data': 'auth',
                }
            ],
            columnDefs: [{
                'targets': 3, //index of column
                'type': 'customdate'
            }],
        });
        this.indicatorsTable.clear();
        if (this.props.biblios && this.props.biblios.sel_biblio) {
            var list_indicators = this.props.biblios.sel_biblio.biblio.indicators_theor || [];
            this.indicatorsTable.rows.add(list_indicators);
        }
        this.indicatorsTable.draw();
    }
    goConcept = (concept_id) => {
        this.props.history.push(`/concept/${concept_id}`);
    }
    goIndicator = (indicator_theor_id) => {
        this.props.history.push(`/theordetail/${indicator_theor_id}`);
    }
    render() {
        var list_transition = [];
        var biblio = {};
        if (this.props.biblios) {
            if (this.props.biblios.loading) {
                Swal.fire({
                    title: 'Loading...',
                    text: "Plese wait",
                    onOpen: () => {
                        Swal.showLoading();
                    },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            } else {
                Swal.close();
            }
            if (this.props.biblios.sel_biblio) {
                biblio = this.props.biblios.sel_biblio.biblio;
                list_transition = this.props.biblios.sel_biblio.list_transition;
            }
        }
        return (
            <React.Fragment>
                <section className="content skin-biblio">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sommario">
                                <label><Trans id="Content.CreationDate"></Trans>:</label><span id="createdAt">{biblio ? biblio.createdAt : ''}</span>
                                <label><Trans id="Content.LastUpdate"></Trans>:</label><span id="updatedAt">{biblio ? biblio.updatedAt : ''}</span>
                                <label><Trans id="Content.User"></Trans>:</label><span id="auth">{biblio ? biblio.auth : ''}</span>
                                <label><Trans id="Content.State"></Trans>:</label> <span className="label label-success" id="state" >{biblio ? capitalize(biblio.state) : ''}</span>
                                <label><Trans id="Modal.OntoclassRefer"></Trans>:</label><span id="owner_ontoclass">{biblio ? biblio.owner_ontoclass : ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="nav-tabs-custom">
                                <ul className="nav nav-tabs">
                                    <li className="active"><a href="#tab_biblio" data-toggle="tab"><Trans id="Content.Biblio"></Trans></a></li>
                                    <li><a href="#tab_concepts" data-toggle="tab"><Trans id="Table.ConceptsContained" /></a></li>
                                    <li><a href="#tab_indicators" data-toggle="tab"><Trans id="Table.IndicatorsTheorContained" /></a></li>
                                </ul>
                                <div className="tab-content no-padding">
                                    <div className="tab-pane active" id="tab_biblio" style={{ clear: "both" }}>
                                        <div className="box-body">
                                            <div className="col-md-6">
                                                <div><a href={biblio.biblio} target="_blank"><h3 className="nome_main" id="lemma">{biblio ? biblio.biblio : ''}</h3></a></div>
                                            </div>
                                        </div>
                                        <div className="comandi">
                                            <div>
                                                {
                                                    list_transition && list_transition.indexOf('update') > -1 ? <button id="update" type="button" className="btn btn-default" onClick={() => {
                                                        this.props.modalActions.showModal('EDIT_BIBLIO_MODAL');
                                                    }}><Trans id="Button.Modification"></Trans>
                                                    </button> : <></>
                                                }
                                                {
                                                    list_transition && list_transition.indexOf('deletion') > -1 ?
                                                        <button id="delete" className="btn" onClick={() => {
                                                            this.props.modalActions.showModal('DELETE_BIBLIO_MODAL');
                                                        }}><b><Trans id="Button.Delete"></Trans></b></button> : <></>
                                                }
                                                <button id="back" className="btn" onClick={() => { this.props.history.goBack() }}><b><Trans id="Button.Back"></Trans></b></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="tab_concepts" style={{ clear: "both" }}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table id="list_table" className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <span data-tip data-for="header1"><Trans id="Table.Lemma" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header1" className="dynTooltip">
                                                                    <div><Trans id="Table.Lemma" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header2"><Trans id="Table.Definition" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
                                                                    <div><Trans id="Table.Definition" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header3"><Trans id="Table.Biblios" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header3" className="dynTooltip">
                                                                    <div><Trans id="Table.Biblios" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header4"><Trans id="Table.LastUpdate" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header4" className="dynTooltip">
                                                                    <div><Trans id="Table.LastUpdate" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header5"><Trans id="Table.User" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header5" className="dynTooltip">
                                                                    <div><Trans id="Table.User" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="list_table_body"></tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="tab_indicators" style={{ clear: "both" }}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table id="list_table_indicator" className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <span data-tip data-for="header6"><Trans id="Content.Name" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header6" className="dynTooltip">
                                                                    <div><Trans id="Content.Name" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header7"><Trans id="Content.Note" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header7" className="dynTooltip">
                                                                    <div><Trans id="Content.Note" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header8"><Trans id="Modal.OntoclassRefer" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header8" className="dynTooltip">
                                                                    <div><Trans id="Modal.OntoclassRefer" /></div>
                                                                </ReactTooltip>:
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header9"><Trans id="Table.LastUpdate" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header9" className="dynTooltip">
                                                                    <div><Trans id="Table.LastUpdate" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header10"><Trans id="Content.User" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header10" className="dynTooltip">
                                                                    <div><Trans id="Content.User" /></div>
                                                                </ReactTooltip>:
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody></tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({
        biblios: state.biblios
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname, addPathname, deletePathname }, dispatch),
        biblioActions: bindActionCreators({ getBiblioDetail }, dispatch),
        eventActions: bindActionCreators({ getEvents }, dispatch),
        modalActions: bindActionCreators({ showModal }, dispatch),
    })
)(BiblioDetail);
