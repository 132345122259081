import { put, takeEvery, call, all } from 'redux-saga/effects';

/** Import actions */
import {
    getUsersFromApi,
    getUsersFromApiFailed,
    getUsersFromApiSucceed,
    postUserToApiFailed,
    postUserToApiSucceed,
    getUserDetailFailed,
    getUserDetailSucceed,
    updateUserFailed,
    updateUserSucceed,
    changeUserPwdFailed,
    changeUserPwdSucceed,
    getUserOntoclassesFailed,
    getUserOntoclassesSucceed,
    addUserOntoclassesFailed,
    addUserOntoclassesSucceed,
    getUserProjectsFailed,
    getUserProjectsSucceed,
    addUserProjectsFailed,
    addUserProjectsSucceed,
    deleteUserFailed,
    deleteUserSucceed
} from './usersAction';

/** Import api */
import * as usersApi from './usersApi';

export function* usersSubscriber() {
    yield all([takeEvery('GET_USERS_FROM_API', getUsers)]);
    yield all([takeEvery('POST_USER_TO_API', postUser)]);
    yield all([takeEvery('GET_USER_DETAIL', getUserDetail)]);
    yield all([takeEvery('UPDATE_USER', updateUserDetail)]);
    yield all([takeEvery('CHANGE_USER_PWD', changeUserPassword)]);
    yield all([takeEvery('GET_USER_ONTOCLASSES', getUserOntoclasses)]);
    yield all([takeEvery('ADD_USER_ONTOCLASSES', addUserOntoclasses)]);
    yield all([takeEvery('GET_USER_PROJECTS', getProjects)]);
    yield all([takeEvery('ADD_USER_PROJECTS', addUserProjects)]);
    yield all([takeEvery('DELETE_USER', deleteUser)]);

}

export function* getUsers() {
    try {
        const users = yield call(usersApi.getUsersApi);
        yield put(getUsersFromApiSucceed(users));
    } catch (error) {
        yield put(getUsersFromApiFailed(error));
    }
}

export function* postUser({ payload: { name, lastname, username, password, email, profile_id } }) {
    try {
        const user = yield call(usersApi.postUserApi, name, lastname, username, password, email, profile_id);
        yield put(postUserToApiSucceed(user));
        yield put(getUsersFromApi());
    } catch (error) {
        yield put(postUserToApiFailed(error));
    }
}

export function* getUserDetail({ payload: { user_id } }) {
    try {
        const users = yield call(usersApi.getUserDetailApi, user_id);
        yield put(getUserDetailSucceed(users));
    } catch (error) {
        yield put(getUserDetailFailed(error));
    }
}
export function* updateUserDetail({ payload: { user_id, name, lastname, email, username } }) {
    try {
        const users = yield call(usersApi.updateUserApi, user_id, name, lastname, email, username);
        yield put(updateUserSucceed(users));
    } catch (error) {
        yield put(updateUserFailed(error));
    }
}
export function* changeUserPassword({ payload: { user_id, password } }) {
    try {
        const users = yield call(usersApi.changePwdApi, user_id, password);
        yield put(changeUserPwdSucceed(users));
    } catch (error) {
        yield put(changeUserPwdFailed(error));
    }
}
export function* getUserOntoclasses() {
    try {
        const ontoclass = yield call(usersApi.getOntoclassesApi);
        yield put(getUserOntoclassesSucceed(ontoclass));
    } catch (error) {
        yield put(getUserOntoclassesFailed(error));
    }
}
export function* addUserOntoclasses({ payload: { user_id, ontoclasses } }) {
    try {
        const ontoclass = yield call(usersApi.addUserOntoclassApi, user_id, ontoclasses);
        yield put(addUserOntoclassesSucceed(ontoclass));
    } catch (error) {
        yield put(addUserOntoclassesFailed(error));
    }
}
export function* addUserProjects({ payload: { user_id, projects } }) {
    try {
        const project = yield call(usersApi.addUserProjectApi, user_id, projects);
        yield put(addUserProjectsSucceed(project));
    } catch (error) {
        yield put(addUserProjectsFailed(error));
    }
}
export function* getProjects() {
    try {
        const project = yield call(usersApi.getProjectsApi);
        yield put(getUserProjectsSucceed(project));
    } catch (error) {
        yield put(getUserProjectsFailed(error));
    }
}
export function* deleteUser({ payload: { user_id } }) {
    try {
        yield call(usersApi.deleteUserApi, user_id);
        yield put(deleteUserSucceed());
    } catch (error) {
        yield put(deleteUserFailed(error));
    }
}