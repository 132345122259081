import axios from 'axios';
// Import Settings
// Import Logout action
// import { logout } from './auth/authActions';


export const wrapRequest = func => {
    return async (...args) => {
        const res = await func(...args);
        if (
            res &&
            res.status &&
            res.status !== 200 &&
            res.status !== 201 &&
            res.status !== 204
        ) {
            throw res;
        } else {
            return res.data;
        }
    };
};
export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
export const xapi = () => {
    let token = null;
    if (localStorage.getItem('token')) {
        token = localStorage.getItem('token');
    }
    let headers = {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
        charset: 'UTF-8'
    };

    if (token) {
        headers = {
            ...headers,
            "x-access-token": token
        };
    }

    let xapi = axios.create({
        // baseURL: "http://95.110.255.212:4100",
        baseURL: "https://sidoc.mosis-lab.org/api",
        headers: headers
    });

    // Check expired token
    xapi.interceptors.response.use(undefined, function (err) {
        if (err.response && err.response.status === 401) {
            // store.dispatch(logout());
        }

        if (typeof err.response === 'undefined') {
            throw err;
        }

        if (err.response && err.response.status !== 200) {
            throw err.response;
        }
    });

    return xapi;
};

export const uploadApi = () => {
    let token = null;
    console.log("xapi_token", localStorage.getItem('token'))
    if (localStorage.getItem('token')) {
        token = localStorage.getItem('token');
    }
    console.log("TOKEN", token)
    let headers = {
        'content-type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
        charset: 'UTF-8'
    };

    if (token) {
        headers = {
            ...headers,
            "x-access-token": token
        };
    }

    let uploadApi = axios.create({
        // baseURL: "http://95.110.255.212:4100",
        baseURL: "https://sidoc.mosis-lab.org/api",
        headers: headers
    });

    return uploadApi;
};
