import { wrapRequest, xapi } from '../utils_services';

const get = wrapRequest(async () =>
    xapi().get('/biblios')
);

const postBiblio = wrapRequest(async (biblio, owner_ontoclass_id) => {
    return xapi().post('/biblios', { biblio, owner_ontoclass_id })
});
const getBiblioDetailApi = wrapRequest(async (biblio_id) =>
    xapi().get('/biblios/' + biblio_id)
);
const updateBiblioApi = wrapRequest(async (biblio_id, biblio, owner_ontoclass_id) =>
    xapi().put('/biblios/' + biblio_id, { biblio, owner_ontoclass_id })
);
const updateBiblioOntoclassApi = wrapRequest(async (biblio_id, ontoclasses) =>
    xapi().post('/biblios/' + biblio_id + '/addontoclass', { ontoclasses })
);
const deleteBiblioApi = wrapRequest(async (biblio_id) =>
    xapi().delete('/biblios/' + biblio_id)
);
const getBiblioAutoApi = wrapRequest(async (query_string) =>
    xapi().get('/biblios/auto?query_string=' + query_string)
);
export { get, postBiblio, getBiblioDetailApi, updateBiblioApi, updateBiblioOntoclassApi, deleteBiblioApi, getBiblioAutoApi };