import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteBiblio } from '../../../../services/biblio/biblioActions';
import { hideModal } from '../../../modalConductorActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Trans from '../../../../services/utils/Trans';

class BiblioDeleteModal extends React.Component {
    constructor() {
        super();
        this.onCancel = this.onCancel.bind(this);
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }

    render() {
        console.log('Props in Biblio Delete Modal', this.props)
        var sel_biblio = null;
        if (this.props.biblios && this.props.biblios.sel_biblio) {
            sel_biblio = this.props.biblios.sel_biblio
        }
        console.log('sel_biblio in Delete modal', sel_biblio)
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader><Trans id="Modal.DeleteBiblio"></Trans></ModalHeader>
                <div className="skin-fonti">
                    <ModalBody>
                        <h2 className="fonti-color">
                            <Trans id="Message.DeleteConfirm"></Trans>
                        </h2>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-default" onClick={() => {
                            this.props.biblioActions.deleteBiblio(sel_biblio.biblio.biblio_id);
                            this.props.modalActions.hideModal()
                        }} >
                            <Trans id="Button.Confirm"></Trans>
                        </button>
                        <button className="btn" onClick={this.onCancel}>
                            <Trans id="Button.Cancel"></Trans>
                        </button>
                    </ModalFooter>
                </div>

            </Modal>
        );
    }
}

export default connect(
    state => ({
        biblios: state.biblios
    }),
    dispatch => ({
        biblioActions: bindActionCreators({ deleteBiblio }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(BiblioDeleteModal);
