import { put, takeEvery, call, all } from 'redux-saga/effects';
/** Import actions */
import {
    getProjects,
    getProjectsFailed,
    getProjectsSucceed,
    postProjectFailed,
    postProjectSucceed,
    getProjectDetailFailed,
    getProjectDetailSucceed,
    updateProjectSucceed,
    updateProjectFailed,
    updateProjectOntoclassSucceed,
    updateProjectOntoclassFailed,
    deleteProjectFailed,
    deleteProjectSucceed,
} from './projectActions';

/** Import api */
import * as projectApi from './projectApi';

export function* projectSubscriber() {
    yield all([takeEvery('GET_PROJECTS', getProjectSaga)]);
    yield all([takeEvery('POST_PROJECT', postProjectSaga)]);
    yield all([takeEvery('GET_PROJECT_DETAIL', getProjectDetailSaga)]);
    yield all([takeEvery('UPDATE_PROJECT', updateProjectSaga)]);
    yield all([takeEvery('UPDATE_PROJECT_ONTOCLASS', updateProjectOntoclassSaga)]);
    yield all([takeEvery('DELETE_PROJECT', deleteProjectSaga)]);
}
export function* getProjectSaga() {
    try {
        const projects = yield call(projectApi.get);
        yield put(getProjectsSucceed(projects));
    } catch (error) {
        console.log('project saga error')
        yield put(getProjectsFailed(error));
    }
}
export function* postProjectSaga({ payload: {father_id, formData } }) {
    try {
        const projects = yield call(projectApi.projectpost, formData);
        yield put(postProjectSucceed(projects));
        if (!father_id) {
            yield put(getProjects());
        }
    } catch (error) {
        console.log("error--", error)
        yield put(postProjectFailed(error));
    }
}
export function* updateProjectSaga({ payload: { project_id, formData } }) {
    try {
        const project = yield call(projectApi.updateProjectApi, project_id, formData);
        yield put(updateProjectSucceed(project));
    } catch (error) {
        console.log("error--", error)
        yield put(updateProjectFailed(error));
    }
}
export function* getProjectDetailSaga({ payload: { project_id } }) {
    try {
        const main_project = yield call(projectApi.getProjectDetailApi, project_id, true);
        const sub_project = yield call(projectApi.getProjectDetailApi, project_id, false);
        main_project.user.subprojects = sub_project.user.subprojects;
        yield put(getProjectDetailSucceed(main_project));
    } catch (error) {
        console.log('projectDetail saga error')
        yield put(getProjectDetailFailed(error));
    }
}
// export function* updateProjectSaga({ payload: { project_id, name, description } }) {
//     try {
//         const project = yield call(projectApi.updateProjectApi, project_id, name, description);
//         yield put(updateProjectSucceed(project));
//     } catch (error) {
//         console.log("error--", error)
//         yield put(updateProjectFailed(error));
//     }
// }
export function* updateProjectOntoclassSaga({ payload: { project_id, ontoclasses, concepts, pro_onto_del_flag } }) {
    try {
        const project = yield call(projectApi.updateProjectOntoclassApi, project_id, ontoclasses, concepts, pro_onto_del_flag);
        yield put(updateProjectOntoclassSucceed(project));
    } catch (error) {
        console.log("error--", error)
        yield put(updateProjectOntoclassFailed(error));
    }
}
export function* deleteProjectSaga({ payload: { project_id } }) {
    try {
        yield call(projectApi.deleteProjectApi, project_id);
        yield put(deleteProjectSucceed());
    } catch (error) {
        console.log("error-- in delete", error)
        yield put(deleteProjectFailed(error));
    }
}