import { wrapRequest, xapi } from '../utils_services';

const getConceptsApi = wrapRequest(async (project_id, by_user, lemma_id) =>
    xapi().get('/concepts?project_id=' + project_id + '&by_user=' + by_user + '&lemma_id=' + lemma_id)
);

const postConceptApi = wrapRequest(async (lemma_id, definition_id, sources, biblios, is_applied, rels, owner_ontoclass_id, ontoclasses) => {
    return xapi().post('/concepts', { lemma_id, definition_id, sources, biblios, is_applied, rels, owner_ontoclass_id, ontoclasses })
});
const getConceptDetailApi = wrapRequest(async (concept_id) =>
    xapi().get('/concepts/' + concept_id)
);
const updateConceptApi = wrapRequest(async (concept_id, lemma_id, definition_id, sources, biblios, is_applied, rels, owner_ontoclass_id, ontoclasses) => {
    return xapi().put('/concepts/' + concept_id, { lemma_id, definition_id, sources, biblios, is_applied, rels, owner_ontoclass_id, ontoclasses })
});
const deleteConceptApi = wrapRequest(async (concept_id) => {
    return xapi().delete('/concepts/' + concept_id)
});
const getConceptAutoApi = wrapRequest(async (query_string) =>
    xapi().get('/concepts/auto?query_string=' + query_string)
);
export { getConceptsApi, postConceptApi, getConceptDetailApi, updateConceptApi, deleteConceptApi, getConceptAutoApi };
