import { wrapRequest, xapi } from '../utils_services';

const get = wrapRequest(async () =>
    xapi().get('/sources')
);

const postSource = wrapRequest(async (source, owner_ontoclass_id) => {
    return xapi().post('/sources', { source, owner_ontoclass_id })
});
const getSourceDetailApi = wrapRequest(async (source_id) =>
    xapi().get('/sources/' + source_id)
);
const updateSourceApi = wrapRequest(async (source_id, source, owner_ontoclass_id) =>
    xapi().put('/sources/' + source_id, { source, owner_ontoclass_id })
);
const updateSourceOntoclassApi = wrapRequest(async (source_id, ontoclasses) =>
    xapi().post('/sources/' + source_id + '/addontoclass', { ontoclasses })
);
const deleteSourceApi = wrapRequest(async (source_id) =>
    xapi().delete('/sources/' + source_id)
);
const getSourceAutoApi = wrapRequest(async (query_string) =>
    xapi().get('/sources/auto?query_string=' + query_string)
);
export { get, postSource, getSourceDetailApi, updateSourceApi, updateSourceOntoclassApi, deleteSourceApi, getSourceAutoApi };