import React from 'react';
import { connect } from 'react-redux';
import { setPathname } from '../../services/common/commonActions';
import { getProjects, postProject } from '../../services/project/projectActions';
import { showModal } from '../../modals/modalConductorActions';
import { getStoredUser } from '../../services/auth/services';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import 'datatables.net'
import 'datatables.net-bs'
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
class Project extends React.Component {
    constructor() {
        super();
        this.projectTable = null;
    }
    componentWillMount() {
        this.props.commonActions.setPathname(
            [
                { "pathName": "Home", "Url": "", "id": 1, "color": "" },
                { "pathName": "Projects", "Url": "projects", "id": 2, "color": "#6d81ab", "subHeader": "Projects" }
            ]
        )
        this.props.projectActions.getProjects();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.projects && this.props.projects.projects !== prevProps.projects.projects && this.projectTable !== null) {
            this.projectTable.clear();
            if (this.props.projects.projects) {
                let father_projects = [];
                for (let project of this.props.projects.projects) {
                    if (project && !project.father_id) {
                        father_projects.push(project);
                    }
                }
                this.projectTable.rows.add(father_projects);
            }
            this.projectTable.draw();
        }
        if (this.props.projects.error !== prevProps.projects.error && this.props.projects.error !== null) {
            toastr.error('Error Occured', this.props.projects.message);
        }

        if (this.props.projects.error === null && !this.props.projects.loading && prevProps.projects && prevProps.projects.loading && this.props.projects.message) {
            toastr.success('Success', this.props.projects.message);
            if (this.props.projects && this.props.projects.sel_project && this.props.projects.sel_project.project_id) {
                this.props.history.push(`/project/${this.props.projects.sel_project.project_id}`)
            }
        }
    }
    componentDidMount() {
        var currentUser = getStoredUser();
        if (currentUser.profile == 'Guest') {
            $("[edit=1]").hide();
        } else if (currentUser.profile == "Publisher") {
            $("#add").hide();
        }
        var _that = this;
        this.projectTable = $('#list_table').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'scrollX': true,
            'createdRow': function (row, data, dataIndex) {
                $(row).on('click', function () {
                    if (data && data.project_id) {
                        _that.goProject(data.project_id);
                    }
                })
            },
            'columns': [
                {
                    'data': 'name'
                },
                {
                    'data': 'subprojects',
                    'render': function (data, type, full, meta) {
                        var result = "";
                        var ontoclass_ids = [];
                        var ontoclass_array = [];
                        if (data && data.length > 0) {
                            for (let subproject of data) {
                                if (subproject.ontoclasses && subproject.ontoclasses.length > 0) {
                                    for (let ontoclass of subproject.ontoclasses) {
                                        if (ontoclass_ids.indexOf(ontoclass.ontoclass_id) < 0) {
                                            ontoclass_array.push(ontoclass.ontoclass);
                                            ontoclass_ids.push(ontoclass.ontoclass_id);
                                        }
                                    }
                                }
                            }
                        }
                        if (ontoclass_array.length > 0) {
                            for (let i = 0; i < ontoclass_array.length; i++) {
                                result += ontoclass_array[i];
                                if (i < ontoclass_array.length - 1) {
                                    result += '; ';
                                }
                            }
                        }
                        return result;
                    }
                },
                {
                    'data': 'subprojects',
                    'render': function (data, type, full, meta) {
                        var result = "";
                        for (var i = 0; i < data.length; i++) {
                            result += data[i].name;
                            if (i < data.length - 1) {
                                result += '; ';
                            }
                        }
                        return result;
                    }
                }
            ]
        });
        this.projectTable.clear();
        if (this.props.projects && this.props.projects.projects) {
            let father_projects = [];
            for (let project of this.props.projects.projects) {
                if (project && !project.father_id) {
                    father_projects.push(project);
                }
            }
            this.projectTable.rows.add(father_projects);
        }
        this.projectTable.draw();
    }

    goProject = (projectId) => {
        this.props.history.push(`project/${projectId}`);
    }

    render() {
        if (this.props.projects && this.props.projects.loading) {
            Swal.fire({
                title: 'Loading...',
                text: "Plese wait",
                onOpen: () => {
                    Swal.showLoading();
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
        } else {
            Swal.close();
        }
        return (
            <React.Fragment>
                <section className="content skin-progetti">
                    <div className="top_comandi">
                        <button type="button" edit="1" onClick={() => {
                            this.props.modalActions.showModal('ADD_PROJECT_MODAL')
                        }} id="add" className="btn btn-default">
                            <span className="glyphicon glyphicon-plus-sign"></span><Trans id="Button.InsertNewProject" />
                        </button>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <table id="list_table" className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <span data-tip data-for="header1"><Trans id="Table.Projects" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header1" className="dynTooltip">
                                                <div><Trans id="Table.Projects" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header3"><Trans id="Table.Ontoclasses" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header3" className="dynTooltip">
                                                <div><Trans id="Table.Ontoclasses" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header2"><Trans id="Table.Subprojects" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
                                                <div><Trans id="Table.Subprojects" /></div>
                                            </ReactTooltip>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="list_table_body">

                                </tbody>

                            </table>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({
        projects: state.projects,
        ontoclasses: state.ontoclasses
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname }, dispatch),
        projectActions: bindActionCreators({ getProjects, postProject }, dispatch),
        modalActions: bindActionCreators({ showModal }, dispatch),
    })
)(Project);
