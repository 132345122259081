import React, { Component } from 'react'
import {
  Router,
  Route,
  Switch,
} from 'react-router-dom'
import { connect } from 'react-redux'
import history from './history'
// COMPONENTS
import Home from './scenes/layout/home';
import Login from './scenes/login'
import ModalConductor from './modals/ModalConductor';
import ReduxToastr from 'react-redux-toastr'

class AppRoutes extends Component {
  constructor() {
    super()
    if (this.props) {
      // console.log('props in approutes', this.props);
    }
  }

  render() {
    // console.log('props in approutes', this.props);

    return (
      <React.Fragment>
        <ModalConductor history={history}/>
        <Router history={history}>
          <Switch>
            <Route path="/login" render={props => <Login {...props} />} />
            <Route path="/" render={props => <Home {...props} />} />
          </Switch>
        </Router >
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-left"
          getState={(state) => state.toastr} // This is the default
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick />
      </React.Fragment>
    )
  }
}

export default connect(
  state => ({
    auth: state.auth
  }),
  null
)(AppRoutes);
