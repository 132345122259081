import { put, takeEvery, call, all } from 'redux-saga/effects';

/** Import actions */
import {
  loginFailed,
  loginSucceed,
  logoutSucceed,
} from './authActions';

/** Import api */
import * as authApi from './authApi';

export function* authSubscriber() {
  yield all([takeEvery('LOGIN', login)]);
  yield all([takeEvery('LOGOUT', logout)]);
}
export function* login({ payload: { usr, pwd } }) {
  
  try {
    const userInfo = yield call(authApi.login, usr, pwd);
    yield put(loginSucceed(userInfo));
    // yield put(getUserFromApiAction());
  } catch (error) {
    yield put(loginFailed(error));
  }
}
export function* logout() {

  try {
    yield call(authApi.logout);
    yield put(logoutSucceed());
  } catch (error) {
  }
}


