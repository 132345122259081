import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateUser } from '../../../../services/users/usersAction';
import { hideModal } from '../../../modalConductorActions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import Trans from '../../../../services/utils/Trans';

class UserEditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            lastname: "",
            email: "",
            username: "",
        }
        this.onCancel = this.onCancel.bind(this);
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }

    render() {
        var userinfo = this.props.users.users
        console.log('props in edit modal', userinfo)
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader><Trans id="Modal.EditUser"></Trans></ModalHeader>
                <Formik
                    initialValues={{
                        name: userinfo.name,
                        lastname: userinfo.lastname,
                        email: userinfo.email,
                        username: userinfo.username,
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required('Name is required'),
                        lastname: Yup.string()
                            .required('Lastname is required'),
                        email: Yup.string().email('Invalid email')
                            .required('Email is required'),
                        username: Yup.string().min(8, 'Should be more than 8 characters')
                            .required('Username is required'),
                    })}
                    onSubmit={fields => {
                        const name = fields.name;
                        const lastname = fields.lastname;
                        const username = fields.username;
                        const email = fields.email;
                        this.props.usersActions.updateUser(userinfo.user_id, name, lastname, email, username);
                        this.props.modalActions.hideModal();
                    }}
                    render={({ errors, status, touched, setFieldValue }) => (
                        <Form className="skin-utenti">
                            <ModalBody>
                                <label className="text-muted text-center"><Trans id="Modal.Name"></Trans></label>
                                <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </ModalBody>
                            <ModalBody>
                                <label className="text-muted text-center"><Trans id="Modal.LastName"></Trans></label>
                                <Field name="lastname" type="text" className={'form-control' + (errors.lastname && touched.lastname ? ' is-invalid' : '')} placeholder="Cognome" />
                                <ErrorMessage name="lastname" component="div" className="invalid-feedback" />
                            </ModalBody>
                            <ModalBody>
                                <label className="text-muted text-center"><Trans id="Modal.Email"></Trans></label>
                                <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                <ErrorMessage name="email" component="div" className="invalid-feedback" />
                            </ModalBody>
                            <ModalBody>
                                <label className="text-muted text-center"><Trans id="Modal.Username"></Trans></label>
                                <Field name="username" type="text" className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')} />
                                <ErrorMessage name="username" component="div" className="invalid-feedback" />
                            </ModalBody>
                            <ModalFooter>
                                <button type="submit" className="btn btn-default" >
                                    <Trans id="Button.Confirm"></Trans>
                                </button>
                                <button className="btn" onClick={this.onCancel}>
                                    <Trans id="Button.Cancel"></Trans>
                                </button>
                            </ModalFooter>
                        </Form>
                    )}
                />
            </Modal>
        );
    }
}

export default connect(
    state => ({
        users: state.users
    }),
    dispatch => ({
        usersActions: bindActionCreators({ updateUser }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(UserEditModal);
