import { createActions } from 'redux-actions';

const {
    getDashboard,
    getDashboardFailed,
    getDashboardSucceed,
} = createActions({
    GET_DASHBOARD: () => ({}),
    GET_DASHBOARD_FAILED: error => ({ error }),
    GET_DASHBOARD_SUCCEED: dashboard => ({ dashboard }),
});

export {
    getDashboard,
    getDashboardFailed,
    getDashboardSucceed,
};
