import React from "react";
import { useSelector } from "react-redux";
import { addLocaleData, IntlProvider } from "react-intl";

import * as en from "react-intl/locale-data/en";
import * as it from "react-intl/locale-data/it";

import enMessages from "./messages/en";
import itMessages from "./messages/it";
const allMessages = {
    en: enMessages,
    it: itMessages
};

addLocaleData([...en,  ...it]);

export default function I18nProvider({ children }) {
    const locale = useSelector(({ i18n }) => i18n.lang);
    const messages = allMessages[locale];
    return (
        <IntlProvider locale={locale} messages={messages}>
            {children}
        </IntlProvider>
    );
}
