import React from 'react';
import ReactTooltip from 'react-tooltip';
import "./index.css";
class TruncTip extends React.Component {
    render(){
        const str = this.props.params.str;
        let substr = '';
        if( str && str.length > this.props.params.limit ){
            substr = str.substr(0, this.props.params.limit);
        } 
        return (
            <div>
                {
                    substr ? <div>
                        <span data-tip data-for={`uuid-${this.props.params.uuid}`}>{substr}...</span>
                        <ReactTooltip place="bottom" effect="solid" id={`uuid-${this.props.params.uuid}`} className="dynTooltip">
                            <div>{str}</div>
                        </ReactTooltip>
                    </div> : str
                }
            </div>
        )
    }
}

export default TruncTip;