import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postProject } from '../../../../services/project/projectActions';
import { hideModal } from '../../../modalConductorActions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import Trans from '../../../../services/utils/Trans';

class ProjectAddModal extends React.Component {
    constructor() {
        super();
        this.onCancel = this.onCancel.bind(this);
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }

    render() {
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader><Trans id="Modal.NewProject"></Trans></ModalHeader>
                <Formik
                    initialValues={{
                        name: '',
                        description: '',
                        logo: '',
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required('Name is required'),
                        description: Yup.string(),
                        logo: Yup.mixed()
                    })}
                    onSubmit={fields => {
                        const formData = new FormData();
                        formData.append('myImage', fields.logo);
                        formData.append('name', fields.name);
                        formData.append('description', fields.description)
                        console.log("OnSubmit:", formData)
                        this.props.projectActions.postProject(null, formData);
                        this.props.modalActions.hideModal();
                    }}
                >
                    {({ errors, status, touched, setFieldValue }) => (
                        <Form className="skin-progetti">
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Modal.Name"></Trans></label>
                                    <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} placeholder="Nome del progetto" />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Modal.Description"></Trans></label>
                                    <Field as="textarea" name="description" type="text" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} placeholder="Descrizione del progetto" />
                                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Modal.Logo"></Trans></label>
                                    <input name="logo" type="file" className={'form-control' + (errors.logo && touched.logo ? ' is-invalid' : '')} onChange={(event) => {
                                        setFieldValue("logo", event.currentTarget.files[0]);
                                    }} />
                                    <ErrorMessage name="logo" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button type="submit" className="btn btn-default" >
                                    <Trans id="Button.Confirm"></Trans>
                                </button>
                                <button className="btn" onClick={this.onCancel}>
                                    <Trans id="Button.Cancel"></Trans>
                                </button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </Modal>
        );
    }
}

export default connect(
    null,
    dispatch => ({
        projectActions: bindActionCreators({ postProject }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(ProjectAddModal);
