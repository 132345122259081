import React from 'react';
import { connect } from 'react-redux';
import { setPathname, addPathname, deletePathname } from '../../services/common/commonActions';
import { getIndicatorTheorDetail, deleteIndicatorTheor } from '../../services/indicatorTheor/indicator_theorActions';
import { getEvents } from '../../services/event_entity/eventActions';
import { showModal } from '../../modals/modalConductorActions';
import { getStoredUser } from '../../services/auth/services';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import { capitalize } from '../../services/utils_services';
import MathJax from 'react-mathjax2'
import 'datatables.net'
import 'datatables.net-bs'
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
class IndicatorTheorDetail extends React.Component {
    constructor() {
        super();
        this.indicatorAppliedTable = null;
        this.state = {
            algorithmArray: [],
            algorithmString: ''
        }
        $.fn.dataTableExt.oSort["customdate-desc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();
            if (x > y) {
                return -1;
            }

            return 1;

        };

        $.fn.dataTableExt.oSort["customdate-asc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();

            if (x > y) {
                return 1;
            }
            return -1;
        }
    }

    componentWillMount() {
        // this.props.commonActions.setPathname(
        //     [
        //         { "pathName": "Home", "Url": "", "id": 1, "color": "" },
        //         { "pathName": "Indicators", "Url": "indicators", "id": 2, "color": "#927e9b" },
        //         { "pathName": "TheorDetail", "Url": "/", "id": 3, "color": "#927e9b" },
        //     ]
        // );
        var indicator_t_id = this.props.match.params.theorId;
        if (indicator_t_id) {
            this.props.indicator_theorActions.getIndicatorTheorDetail(indicator_t_id);
            this.props.eventActions.getEvents(indicator_t_id, 'indicator_theor')
        }
    }
    componentDidMount() {
        var _that = this;
        var currentUser = getStoredUser();
        var userVisibility = true;
        if (currentUser.profile == 'Guest') {
            $("[edit=1]").hide();
            userVisibility = false;
        }
        this.indicatorAppliedTable = $('#list_table_applied').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'scrollX': false,
            'createdRow': function (row, data, dataIndex) {
                $(row).on('click', function () {
                    if (data && data.indicator_applied_id) {
                        _that.goAppliedDetail(data.indicator_applied_id);
                    }
                })
            },
            'columns': [
                {
                    'data': 'name'
                },
                {
                    'data': 'note',
                },
                {
                    'data': 'owner_ontoclass'
                },
                {
                    'data': 'updatedAt',
                },
                {
                    'data': 'auth',
                },
            ],
            columnDefs: [
                {
                    'targets': 3, //index of column
                    'type': 'customdate',
                    "visible": userVisibility,
                },
                {
                    'targets': 4, //index of column
                    "visible": userVisibility,
                }
            ],
        });
        this.indicatorAppliedTable.clear();
        if (this.props.indicator_theors && this.props.indicator_theors.sel_indicator_theor && this.props.indicator_theors.sel_indicator_theor.indicator.indicators_applied) {
            this.indicatorAppliedTable.rows.add(this.props.indicator_theors.sel_indicator_theor.indicator.indicators_applied);
            var algorithmArray = [];
            if(this.props.indicator_theors.sel_indicator_theor.indicator.algorithm) algorithmArray = JSON.parse(this.props.indicator_theors.sel_indicator_theor.indicator.algorithm);
            var temp_string = '';
            if (algorithmArray.length > 0) {
                for (var i = 0; i < algorithmArray.length; i++) {
                    if (algorithmArray[i].type === 'string') {
                        temp_string += `"${algorithmArray[i].content}"`
                    } else {
                        temp_string += algorithmArray[i].content;
                    }
                }
            }
            this.setState({
                algorithmString: temp_string,
                algorithmArray: algorithmArray
            })
        }
        this.indicatorAppliedTable.draw();

    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.indicator_theors && this.props.indicator_theors.sel_indicator_theor && this.props.indicator_theors.sel_indicator_theor !== prevProps.indicator_theors.sel_indicator_theor) {
            var sel_indicator_theor = this.props.indicator_theors.sel_indicator_theor.indicator;
            var indicator_name = sel_indicator_theor.name;
            this.props.commonActions.addPathname(
                { "pathName": indicator_name, "Url": "theordetail/" + this.props.match.params.theorId, "color": "#6d81ab", "subHeader": "Indicator Theoretical Detail" }
            )
            var _algorithm = [];
            if (sel_indicator_theor.algorithm) _algorithm = JSON.parse(sel_indicator_theor.algorithm);
            this.setState({
                algorithmArray: _algorithm
            })
        }
        if (this.state.algorithmArray && prevState.algorithmArray && this.state.algorithmArray !== prevState.algorithmArray) {
            var temp_string = '';
            if (this.state.algorithmArray.length > 0) {
                for (var i = 0; i < this.state.algorithmArray.length; i++) {
                    if (this.state.algorithmArray[i].type === 'string') {
                        temp_string += `"${this.state.algorithmArray[i].content}"`
                    } else {
                        temp_string += this.state.algorithmArray[i].content;
                    }
                }
            }

            this.setState({
                algorithmString: temp_string
            })
        }
        if (this.props.indicator_theors.error !== prevProps.indicator_theors.error && this.props.indicator_theors.error !== null) {
            toastr.error('Error Occured', this.props.indicator_theors.message);
        }

        if (this.props.indicator_theors.error === null && !this.props.indicator_theors.loading && prevProps.indicator_theors && prevProps.indicator_theors.loading && this.props.indicator_theors.message) {
            toastr.success('Success', this.props.indicator_theors.message);
            this.props.history.push(`/indicators`);
        }
    }
    goAppliedDetail = (indicator_applied_id) => {
        this.props.history.push(`/applieddetail/${indicator_applied_id}`)
    }
    render() {
        var list_transition = [];
        var indicator_theor = {};
        var concepts = [];
        if (this.props.indicator_theors) {
            if(this.props.indicator_theors.loading) {
                Swal.fire({
                    title: 'Loading...',
                    text: "Plese wait",
                    onOpen: () => {
                        Swal.showLoading();
                    },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            } else {
                Swal.close();
                if (this.props.indicator_theors.sel_indicator_theor) {
                    indicator_theor = this.props.indicator_theors.sel_indicator_theor.indicator;
                    list_transition = this.props.indicator_theors.sel_indicator_theor.list_transition;
                    concepts = indicator_theor.concepts;
                    if(this.indicatorAppliedTable) {
                        this.indicatorAppliedTable.clear();
                        if (this.props.indicator_theors && this.props.indicator_theors.sel_indicator_theor && this.props.indicator_theors.sel_indicator_theor.indicator.indicators_applied) {
                            this.indicatorAppliedTable.rows.add(this.props.indicator_theors.sel_indicator_theor.indicator.indicators_applied);
                        }
                        this.indicatorAppliedTable.draw();
                    }
                }
            }
        }

        var currentUser = getStoredUser();
        return (
            <React.Fragment>
                <section className="content skin-indicatori">
				{ currentUser.profile != 'Guest' && 
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sommario">
                                <label><Trans id="Content.CreationDate"></Trans>:</label><span id="createdAt">{indicator_theor ? indicator_theor.createdAt : ''}</span><label><Trans id="Content.LastUpdate"></Trans>:</label>
                                <span id="updatedAt">{indicator_theor ? indicator_theor.updatedAt : ''}</span> <label><Trans id="Content.User"></Trans>:</label><span id="auth">{indicator_theor ? indicator_theor.auth : ''}</span> <label><Trans id="Content.State"></Trans>:</label> <span className="label label-success" id="state" >{capitalize(indicator_theor ? indicator_theor.state : '')}</span><br />
                                <label><Trans id="Modal.OntoclassRefer"></Trans>:</label><span id="owner_ontoclass">{indicator_theor? indicator_theor.owner_ontoclass : ''}</span>
                            </div>
                        </div>
                    </div>
                }
                    <div className="row">
                        <div className="col-md-12">
                            <div className="nav-tabs-custom">
                                <ul className="nav nav-tabs">
                                    <li className="active"><a href="#tab_main" data-toggle="tab"><Trans id="Table.IndicatorsTheor"></Trans></a></li>
                                    <li><a href="#tab_indicators_a" data-toggle="tab"><Trans id="Table.IndicatorsApplied"></Trans></a></li>
                                </ul>
                                <div className="tab-content no-padding">
                                    <div className="tab-pane active" id="tab_main">
                                        <div className="form-group row underlined">
                                            <div className="col-md-12">
                                                <label><Trans id="Content.Name"></Trans>:</label>
                                                <div id="name">{indicator_theor ? indicator_theor.name : ''}</div>
                                            </div>
                                        </div>
                                        <div className="form-group row underlined">
                                            <div className="col-md-12">
                                                <label><Trans id="Content.Note"></Trans></label>
                                                <div id="note">{indicator_theor ? indicator_theor.note : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row underlined">
                                            <div className="col-md-12">
                                                <label><Trans id="Content.ConceptAssociatedWithIndicator"></Trans>:</label>
                                                <div>
                                                    <div><span><Trans id="Table.Lemma"></Trans>: </span><span id="rel_lemma">{indicator_theor && indicator_theor.rel_concept ? indicator_theor.rel_concept.lemma : ''}</span></div>
                                                    <div><span><Trans id="Table.Definition"></Trans>: </span><span id="rel_definition">{indicator_theor && indicator_theor.rel_concept ? indicator_theor.rel_concept.definition : ''}</span></div>
                                                    <div className="rx_comandi">
                                                        <button type="button" className="btn btn-default btn-xs" id="rel_concept_id" onClick={() => { this.props.history.push(`/concept/${indicator_theor.rel_concept.concept_id}`) }}>
                                                            <span className="glyphicon glyphicon-link" aria-hidden="true"></span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row underlined">
                                            <div className="col-md-12">
                                                <label><Trans id="Table.Biblio"></Trans>:</label>
                                                {
                                                    indicator_theor && indicator_theor.biblios ? indicator_theor.biblios.map(biblio => {
                                                        return <div className="lista_item" key={"biblio_" + biblio.biblio_id}><a href={biblio.biblio} target="_blank">{biblio.biblio}</a> </div>
                                                    }) : <></>
                                                }
                                            </div>
                                        </div>
                                        <div className="row nomargin">
                                            <div className="col-md-12" style={{ textAlign: "center" }}>
                                                <div className="col-md-12" style={{ marginTop: "10px", marginBottom: "10px" }}><strong ><Trans id="Content.Algorithm"></Trans></strong></div>
                                                <div className="col-md-12" style={{ minHeight: "30px" }}>
                                                    <MathJax.Context input='ascii'>
                                                        <div>
                                                            <MathJax.Node inline>{this.state.algorithmString}</MathJax.Node>
                                                        </div>
                                                    </MathJax.Context></div>
                                            </div>
                                        </div>
                                        <h3><Trans id="Content.Concepts"></Trans></h3>
                                        <div id="box_concetto_rel" className="box_fonte">
                                            {
                                                concepts ? concepts.map((concept, index) => {
                                                    return <div id="concept_line" className="linea_sx" key={"concept_rel_" + index}>
                                                        <div><span><Trans id="Table.Lemma"></Trans>: </span><strong id="rel_lemma">{concept.lemma}</strong></div>
                                                        <div><span><Trans id="Table.Definition"></Trans>: </span><strong id="rel_definition">{concept.definition}</strong></div>
                                                        <div className="rx_comandi">
                                                            <button type="button" className="btn btn-default btn-xs" id="rel_concept_id" onClick={() => { this.props.history.push(`/concept/${concept.concept_id}`) }}>
                                                                <span className="glyphicon glyphicon-link" aria-hidden="true"></span></button>
                                                        </div>
                                                    </div>
                                                }) : <></>
                                            }
                                        </div>
                                        <hr />
                                        <hr />
                                        <div className="comandi">
                                            <div>
                                                {
                                                    list_transition && list_transition.indexOf('update') > -1 ? <button className="btn btn-default view" id="update" onClick={() => { this.props.history.push(`/newtheor/${indicator_theor.indicator_t_id}`) }}><b><Trans id="Button.Modification"></Trans></b></button> : <></>
                                                }
                                                <button className="btn btn-default" edit="1" id="add_indicator_applied" onClick={() => { this.props.history.push(`/newapplied/null/${this.props.match.params.theorId}`) }}><b><Trans id="Button.InsertIndicatorApplied"></Trans></b></button>
                                                {
                                                    list_transition && list_transition.indexOf('deletion') > -1 ? <button className="btn" id="delete" onClick={() => { this.props.modalActions.showModal('DELETE_THEOR_MODAL') }}><b><Trans id="Button.Delete"></Trans></b></button> : <></>
                                                }
                                                <button className="btn" id="back" onClick={() => { this.props.history.goBack() }}><b><Trans id="Button.Back"></Trans></b></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="tab_indicators_a">
                                        <div className="row">

                                            <div className="col-md-12">
                                                <table className="table table-hover" id="list_table_applied">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <span data-tip data-for="header2"><Trans id="Content.Name" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
                                                                    <div><Trans id="Content.Name" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header3"><Trans id="Content.Note" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header3" className="dynTooltip">
                                                                    <div><Trans id="Content.Note" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header8"><Trans id="Modal.OntoclassRefer" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header8" className="dynTooltip">
                                                                    <div><Trans id="Modal.OntoclassRefer" /></div>
                                                                </ReactTooltip>
													        </th>
                                                            <th>
                                                                <span data-tip data-for="header13"><Trans id="Table.LastUpdate" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header13" className="dynTooltip">
                                                                    <div><Trans id="Table.LastUpdate" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header14"><Trans id="Content.User" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header14" className="dynTooltip">
                                                                    <div><Trans id="Content.User" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({
        indicator_theors: state.indicator_theors,
        common: state.common,
        projects: state.projects
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname, addPathname, deletePathname }, dispatch),
        indicator_theorActions: bindActionCreators({ getIndicatorTheorDetail, deleteIndicatorTheor }, dispatch),
        eventActions: bindActionCreators({ getEvents }, dispatch),
        modalActions: bindActionCreators({ showModal }, dispatch),
    })
)(IndicatorTheorDetail);
