import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteDefinition } from '../../../../services/definition/definitionActions';
import { hideModal } from '../../../modalConductorActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Trans from '../../../../services/utils/Trans';

class DefinitionDeleteModal extends React.Component {
    constructor() {
        super();
        this.onCancel = this.onCancel.bind(this);
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }

    render() {
        console.log('Props in Definition Delete Modal', this.props)
        var sel_definition = null;
        if (this.props.definitions && this.props.definitions.sel_definition) {
            sel_definition = this.props.definitions.sel_definition
        }
        console.log('sel_definition in Delete modal', sel_definition)
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader><Trans id="Modal.DeleteDefinition"></Trans></ModalHeader>
                <div className="skin-lemmi">
                    <ModalBody>
                        <h2 className="ontoclass-color">
                            <Trans id="Message.DeleteConfirm"></Trans>
                        </h2>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-default" onClick={() => {
                            this.props.definitionActions.deleteDefinition(sel_definition.definition.definition_id);
                            this.props.modalActions.hideModal()
                        }} >
                            <Trans id="Button.Confirm"></Trans>
                        </button>
                        <button className="btn" onClick={this.onCancel}>
                            <Trans id="Button.Cancel"></Trans>
                        </button>
                    </ModalFooter>
                </div>

            </Modal>
        );
    }
}

export default connect(
    state => ({
        definitions: state.definitions
    }),
    dispatch => ({
        definitionActions: bindActionCreators({ deleteDefinition }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(DefinitionDeleteModal);
