import { wrapRequest, xapi } from '../utils_services';

const login = wrapRequest(async (usr, pwd) =>
  xapi().post('/users/login', {
    usr,
    pwd
  })
);
const logout = wrapRequest(async () =>
  xapi().post('/users/logout')
);

const getUsersApi = wrapRequest(async () =>
  xapi().get("/users")
);
export { login, logout, getUsersApi };
