import React from "react";
import { connect } from "react-redux";
import * as  i18n  from "../../../services/i18n/i18n";
import { NavLink } from 'react-router-dom';

const languages = [
    {
        lang: "en",
        name: "English",
        flag:"/images/en.png"
    },
    {
        lang: "it",
        name: "italian",
        flag: "/images/it.png"
    }
];

class LanguageSelector extends React.Component {
    render() {
        const { lang, setLanguage } = this.props;
        const currentLanguage = languages.find(x => x.lang === lang);
        return (
                <li className="dropdown">
                    <NavLink to="/" className="dropdown-toggle" data-toggle="dropdown">
                    <img src={"/images/" + currentLanguage.lang + ".png"} className="lingua" alt="italiano" />
                    </NavLink>
                    <ul className="dropdown-menu">
                        <li className="dropdown-toggle" data-toggle="dropdown"
                            onClick = {()=>{
                                setLanguage("en");
                            }}
                        >
                            <NavLink to = "/">
                            <img src="/images/en.png" className="lingua" alt="english" /> English
                            </NavLink>
                        </li>
                        <li className="dropdown-toggle" data-toggle="dropdown"
                            onClick={() => {
                                setLanguage("it");
                            }}
                        >
                            <NavLink to = "/">
                                <img src="/images/it.png" className="lingua" alt="italiano" /> Italiano
                            </NavLink>
                        </li>
                    </ul>
                </li>
             );
    }
}

const mapStateToProps = ({ i18n }) => ({ lang: i18n.lang });

export default connect(
    mapStateToProps,
    i18n.actions
)(LanguageSelector);
