import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateProjectOntoclass } from '../../../../services/project/projectActions';
import { getConcepts } from '../../../../services/concept/conceptActions';
import { getOntoclasses } from '../../../../services/ontoclass/ontoclassActions';
import { hideModal } from '../../../modalConductorActions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Trans from '../../../../services/utils/Trans';
import $ from 'jquery';
import Button from 'reactstrap/lib/Button';

class ImportConceptsModal extends React.Component {
	constructor() {
		super();
		this.state = {
			selectedItems: [],
			selectedConcept: [],
			// associa: 1
		}
		this.onCancel = this.onCancel.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		console.log("CONSTRUCTOR PROPS:", this.props)
	}
	componentWillMount() {
		this.props.ontoclassActions.getOntoclasses();
		this.props.conceptActions.getConcepts();
	}
	onCancel() {
		this.props.modalActions.hideModal();
	}
	componentDidUpdate(prevProps) {
	}
	handleInputChange(event) {
	}
	ChangeModal() {
	}

	handleSubmit() {
	}
	render() {
		return (
			<Modal
				isOpen={true}
				size="lg" 
				toggle={this.props.modalActions.hideModal}
			>
				<ModalHeader><Trans id="Modal.ImportConcepts"></Trans></ModalHeader>
				<div className="skin-progetti" id="associa">
						<ModalBody>
							<div className="form-group row">
								<div className="col-md-6">Project</div>
								<div className="col-md-6">
									<select>
										<option>Select a project</option>
										<option>Erasmus Plus TLCF</option>
										<option>Paragri</option>
									</select>
								</div>
							</div>
							<div className="form-group row">
								<div className="col-md-6">Subproject</div>
								<div className="col-md-6">
									<select>
										<option>Select a subproject</option>
										<option>Paragri Metodologia</option>
										<option>Paragri Questionario</option>
									</select>
								</div>
							</div>
							<div className="form-group row">
								<div className="col-md-6">Ontoclass</div>
								<div className="col-md-6">
									<select>
										<option>Select a ontoclass</option>
										<option>Agricolura</option>
										<option>Istruzione</option>
										<option>Migranti</option>
									</select>
								</div>
							</div>
							<div>
								<button className="btn" onClick={this.onCancel}>
									<Trans id="Button.SearchFor"></Trans>
								</button>
							</div>
							<div><label>Concepts</label>
							<div className="form-group row">
								<div className="col-md-3">Lemma</div>
								<div className="col-md-3">Definition</div>
								<div className="col-md-3">Ontoclass</div>
								<div className="col-md-3">Select</div>
							</div>
							<div className="form-group row">
								<div className="col-md-3">3D Animator</div>
								<div className="col-md-3">The arrangement or digital effect which simulates lighting in a 3D environment.</div>
								<div className="col-md-3">Agricoltura</div>
								<div className="col-md-3"><input type="checkbox"/></div>
							</div>


							</div>
						</ModalBody>
					<ModalFooter>
						<button type="button" className="btn btn-default" onClick={() => this.handleSubmit()} >
							<Trans id="Button.ImportConcepts"></Trans>
						</button>
						<button className="btn" onClick={this.onCancel}>
							<Trans id="Button.Cancel"></Trans>
						</button>
					</ModalFooter>
				</div>
			</Modal>
		);
	}
}

export default connect(
	state => ({
		projects: state.projects,
		ontoclasses: state.ontoclasses,
		concepts: state.concepts
	}), dispatch => ({
		projectActions: bindActionCreators({ updateProjectOntoclass }, dispatch),
		ontoclassActions: bindActionCreators({ getOntoclasses }, dispatch),
		conceptActions: bindActionCreators({ getConcepts }, dispatch),
		modalActions: bindActionCreators({ hideModal }, dispatch),
	})
)(ImportConceptsModal);
