import { wrapRequest, xapi } from '../utils_services';

const get = wrapRequest(async () =>
    xapi().get('/definitions')
);

const definitionpost = wrapRequest(async (definition, owner_ontoclass_id) => {
    return xapi().post('/definitions', { definition, owner_ontoclass_id })
});
const getDefinitionDetailApi = wrapRequest(async (definition_id) =>
    xapi().get('/definitions/' + definition_id)
);
const updateDefinitionApi = wrapRequest(async (definition_id, definition, owner_ontoclass_id) =>
    xapi().put('/definitions/' + definition_id, { definition, owner_ontoclass_id })
);
const updateDefinitionOntoclassApi = wrapRequest(async (definition_id, ontoclasses) =>
    xapi().post('/definitions/' + definition_id + '/addontoclass', { ontoclasses })
);
const deleteDefinitionApi = wrapRequest(async (definition_id) =>
    xapi().delete('/definitions/' + definition_id)
);

const getDefinitionAutoApi = wrapRequest(async (query_string) =>
    xapi().get('/definitions/auto?query_string=' + query_string)
);
export { get, definitionpost, getDefinitionDetailApi, updateDefinitionApi, updateDefinitionOntoclassApi, deleteDefinitionApi, getDefinitionAutoApi };