import { handleActions } from 'redux-actions';

import {
    getDashboard,
    getDashboardFailed,
    getDashboardSucceed,
} from './dashboardActions';

const defaultState = {
    dashboard: null,
    error: null,
    loading: false,
    success: false,
};

const reducer = handleActions(
    {
        [getDashboard](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                dashboard: null,
            };
        },
        [getDashboardFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error? error : 'Error',
                loading: false,
                success: false,
                dashboard: null,
            };
        },
        [getDashboardSucceed](
            state,
            {
                payload: { dashboard }
            }
        ) {
            return {
                ...state,
                dashboard: dashboard,
                error: null,
                loading: false,
                success: true,
            };
        },
    },
    defaultState
);

export default reducer;
