import { createActions } from 'redux-actions';

const {
    getUtilities,
    getUtilitiesFailed,
    getUtilitiesSucceed,
    postUtility,
    postUtilityFailed,
    postUtilitySucceed,
    updateUtility,
    updateUtilityFailed,
    updateUtilitySucceed,
    deleteUtility,
    deleteUtilityFailed,
    deleteUtilitySucceed
} = createActions({
    GET_UTILITIES: ref_table => ({ ref_table }),
    GET_UTILITIES_FAILED: error => ({ error }),
    GET_UTILITIES_SUCCEED: (utilities, ref_table) => ({ utilities, ref_table }),
    POST_UTILITY: (ref_table, label, owner_ontoclass_id) => ({ ref_table, label, owner_ontoclass_id }),
    POST_UTILITY_FAILED: error => ({ error }),
    POST_UTILITY_SUCCEED: utility => ({ utility }),
    UPDATE_UTILITY: (utility_id, tbl, label) => ({ utility_id, tbl, label }),
    UPDATE_UTILITY_FAILED: error => ({ error }),
    UPDATE_UTILITY_SUCCEED: utility => ({ utility }),
    DELETE_UTILITY: (utility_id, ref_table) => ({ utility_id, ref_table }),
    DELETE_UTILITY_FAILED: error => ({ error }),
    DELETE_UTILITY_SUCCEED: () => ({})
});

export {
    getUtilities,
    getUtilitiesFailed,
    getUtilitiesSucceed,
    postUtility,
    postUtilityFailed,
    postUtilitySucceed,
    updateUtility,
    updateUtilityFailed,
    updateUtilitySucceed,
    deleteUtility,
    deleteUtilityFailed,
    deleteUtilitySucceed
};
