import React from 'react';
// import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { setPathname } from '../../services/common/commonActions';
import { getDefinitions, postDefinition } from '../../services/definition/definitionActions';
import { getOntoclasses } from '../../services/ontoclass/ontoclassActions';
import { showModal } from '../../modals/modalConductorActions';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
// import 'datatables.net'
// import 'datatables.net-bs'
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';

class Definition extends React.Component {
	constructor() {
		super();
		this.state = {
			filter_definition: '',
			filter_count_concepts_from: '',
			filter_count_concepts_to: '',
			filter_owner_ontoclasse: '',
			filter_auth: ''
		}
		this.definitionTable = null;
		$.fn.dataTableExt.oSort["customdate-desc"] = function (_x, _y) {

			var xx = _x.split('/');
			var x = new Date(xx[2], xx[1], xx[0]);
			x = x.getTime();

			var yy = _y.split('/');
			var y = new Date(yy[2], yy[1], yy[0]);
			y = y.getTime();
			if (x > y) {
				return -1;
			}

			return 1;

		};

		$.fn.dataTableExt.oSort["customdate-asc"] = function (_x, _y) {

			var xx = _x.split('/');
			var x = new Date(xx[2], xx[1], xx[0]);
			x = x.getTime();

			var yy = _y.split('/');
			var y = new Date(yy[2], yy[1], yy[0]);
			y = y.getTime();

			if (x > y) {
				return 1;
			}
			return -1;
		}
	}
	componentWillMount() {
		this.props.commonActions.setPathname(
			[
				{ "pathName": "Home", "Url": "", "id": 1, "color": "" },
				{ "pathName": "Definitions", "Url": "definitions", "id": 2, "color": "#51abae", "subHeader": "Definitions" }
			]
		)
		this.props.definitionActions.getDefinitions();
		this.props.ontoclassActions.getOntoclasses();
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.props.definitions && this.props.definitions.definitions !== prevProps.definitions.definitions && this.definitionTable !== null) {
			this.definitionTable.clear();
			if (this.props.definitions.definitions) {
				this.definitionTable.rows.add(this.props.definitions.definitions);
			}
			this.definitionTable.draw();
		}
		if (this.props.definitions.error !== prevProps.definitions.error && this.props.definitions.error !== null) {
			toastr.error('Error Occured', this.props.definitions.message);
		}

		if (this.props.definitions.error === null && !this.props.definitions.loading && prevProps.definitions && prevProps.definitions.loading && this.props.definitions.message) {
			toastr.success('Success', this.props.definitions.message);
			if (this.props.definitions && this.props.definitions.sel_definition && this.props.definitions.sel_definition.definition) {
				this.props.history.push(`/definition/${this.props.definitions.sel_definition.definition.definition_id}`);
			}
		}
	}
	componentDidMount() {
		var _that = this;
		this.definitionTable = $('#list_table').DataTable({
			'paging': true,
			'lengthChange': false,
			'searching': false,
			'ordering': true,
			'info': true,
			'autoWidth': false,
			'scrollX': true,
			'createdRow': function (row, data, dataIndex) {
				$(row).on('click', function () {
					if (data && data.definition_id) {
						_that.goDefinition(data.definition_id);
					}
				});
				// $(row).on('mouseover', function () {
				// 	$(row).tooltip(
				// 		{placemente:'',html: true}
				// 	)
				// })
			},
			'columns': [
				{
					'data': 'definition',
					'render': function (data, type, full, meta) {
						if (data.length <= 150) return data;
						return data.substring(0, 150) + '...';
					}
				},
				{
					'data': 'concepts',
					'render': function (data, type, full, meta) {
						return data ? data.length : ''
					}
				},
				{
					'data': 'owner_ontoclass',
				},
				{
					'data': 'updatedAt',
				},
				{
					'data': 'auth',
				}
			],
			columnDefs: [{
				'targets': 3, //index of column
				'type': 'customdate'
			}],
		});
		this.definitionTable.clear();
		if (this.props.definitions && this.props.definitions.definitions) {
			this.definitionTable.rows.add(this.props.definitions.definitions);
		}
		this.definitionTable.draw();
	}

	goDefinition = (definitionId) => {
		this.props.history.push(`definition/${definitionId}`);
	}
	onFilterChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	handleSearch = (e) => {
		var filterData = [];
		if (this.props.definitions && this.props.definitions.definitions && this.props.definitions.definitions.length > 0) {
			var definitions = this.props.definitions.definitions;
			for (var i = 0; i < definitions.length; i++) {
				var fileterRes = true;
				if (this.state.filter_definition !== '') {
					if (definitions[i].definition.toLowerCase().indexOf(this.state.filter_definition.toLowerCase()) === -1) fileterRes = false;
				}
				if (this.state.filter_owner_ontoclasse) {
					if (definitions[i].owner_ontoclass.toLowerCase().indexOf(this.state.filter_owner_ontoclasse.toLowerCase()) === -1) fileterRes = false;
				}
				if (this.state.filter_count_concepts_from) {
					if (definitions[i].concepts.length < this.state.filter_count_concepts_from) fileterRes = false;
				}
				if (this.state.filter_count_concepts_to) {
					if (definitions[i].concepts.length > this.state.filter_count_concepts_to) fileterRes = false;
				}
				if (this.state.filter_auth) {
					if (definitions[i].auth.toLowerCase().indexOf(this.state.filter_auth.toLowerCase()) === -1) fileterRes = false;
				}
				if (fileterRes) {
					filterData.push(definitions[i]);
				}
			}
			this.definitionTable.clear();
			this.definitionTable.rows.add(filterData);
			this.definitionTable.draw();
		}
	}
	handleReset = (e) => {
		this.setState({
			filter_definition: '',
			filter_count_concepts_from: '',
			filter_count_concepts_to: '',
			filter_owner_ontoclasse: '',
			filter_auth: ''
		});
		this.definitionTable.clear();
		if (this.props.definitions.definitions) {
			this.definitionTable.rows.add(this.props.definitions.definitions);
		}
		this.definitionTable.draw();
	}

	render() {
		if (this.props.definitions && this.props.definitions.loading) {
			Swal.fire({
				title: 'Loading...',
				text: "Plese wait",
				onOpen: () => {
					Swal.showLoading();
				},
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false
			});
		} else {
			Swal.close();
		}
		return (
			<React.Fragment>
				<section className="content skin-definizioni">
					<div className="top_comandi">
						<button type="button" onClick={() => {
							this.props.modalActions.showModal('ADD_DEFINITION_MODAL')
						}} id="add" className="btn btn-default">
							<span className="glyphicon glyphicon-plus-sign"></span><Trans id="Button.InsertNewDefinition" />
						</button>
					</div>
					<div className="ricerca">
						<div className="_form-inline">
							<div className="form-group row">
								<div className="col-md-4">
									<label><Trans id="Table.Filterby"></Trans>:</label>
									<div className="input-group">
										<span className="input-group-addon"><Trans id="Content.Definition"></Trans></span>
										<input type="text" name="filter_definition" className="form-control" value={this.state.filter_definition} onChange={this.onFilterChange} />
									</div>
								</div>
								<div className="col-md-4">
									<label><Trans id="Table.ConceptsContained"></Trans>:</label>
									<div className="row">
										<div className="col-md-6">
											<div className="input-group">
												<span className="input-group-addon"><Trans id="Table.From"></Trans></span>
												<input type="number" size="2" name="filter_count_concepts_from" value={this.state.filter_count_concepts_from} min="0" className="form-control" onChange={this.onFilterChange} />
											</div>
										</div>
										<div className="col-md-6">
											<div className="input-group">
												<span className="input-group-addon"><Trans id="Table.To"></Trans></span>
												<input type="number" size="2" name="filter_count_concepts_to" value={this.state.filter_count_concepts_to} min="0" className="form-control" onChange={this.onFilterChange} />
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<label><Trans id="Table.ReferenceOntoclass"></Trans>:</label>
									<input type="text" name="filter_owner_ontoclasse" className="form-control" value={this.state.filter_owner_ontoclasse} onChange={this.onFilterChange} />
								</div>
								<div className="col-md-4">
									<label><Trans id="Content.User"></Trans>:</label>
									<input type="text" name="filter_auth" className="form-control" value={this.state.filter_auth} onChange={this.onFilterChange} />
								</div>
								<div className="col-md-2">
									<label>&nbsp;</label>
									<button type="button" className="btn btn-default form-control" onClick={() => this.handleSearch()}><Trans id="Button.SearchFor"></Trans></button>
								</div>
								<div className="col-md-2">
									<label>&nbsp;</label>
									<button type="button" className="btn form-control" onClick={() => this.handleReset()}><Trans id="Button.Reset"></Trans></button>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-12">
							<table id="list_table" className="table table-hover">
								<thead>
									<tr>
										<th><Trans id="Table.Definitions" />
											<span data-tip data-for="header1"><Trans id="Table.Definitions" /></span>
											<ReactTooltip place="top" effect="solid" id="header1" className="dynTooltip">
												<div><Trans id="Table.Definitions" /></div>
											</ReactTooltip>
										</th>
										<th><Trans id="Table.ConceptsContained" />
											<span data-tip data-for="header2"><Trans id="Table.ConceptsContained" /></span>
											<ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
												<div><Trans id="Table.ConceptsContained" /></div>
											</ReactTooltip>
										</th>
										<th><Trans id="Table.ReferenceOntoclass" />
											<span data-tip data-for="header3"><Trans id="Table.ReferenceOntoclass" /></span>
											<ReactTooltip place="top" effect="solid" id="header3" className="dynTooltip">
												<div><Trans id="Table.ReferenceOntoclass" /></div>
											</ReactTooltip>
										</th>
										<th>
											<span data-tip data-for="header7"><Trans id="Table.LastUpdate" /></span>
											<ReactTooltip place="top" effect="solid" id="header7" className="dynTooltip">
												<div><Trans id="Table.LastUpdate" /></div>
											</ReactTooltip>
										</th>
										<th>
											<span data-tip data-for="header8"><Trans id="Table.User" /></span>
											<ReactTooltip place="top" effect="solid" id="header8" className="dynTooltip">
												<div><Trans id="Table.User" /></div>
											</ReactTooltip>
										</th>
									</tr>
								</thead>
								<tbody id="list_table_body">

								</tbody>

							</table>
						</div>
					</div>
				</section>
			</React.Fragment>
		)
	}
}

export default connect(
	state => ({
		definitions: state.definitions
	}),
	dispatch => ({
		commonActions: bindActionCreators({ setPathname }, dispatch),
		definitionActions: bindActionCreators({ getDefinitions, postDefinition }, dispatch),
		ontoclassActions: bindActionCreators({ getOntoclasses }, dispatch),
		modalActions: bindActionCreators({ showModal }, dispatch),
	})
)(Definition);
