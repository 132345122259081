import React from 'react';
import { connect } from 'react-redux';
import { setPathname } from '../../services/common/commonActions';
import { getConcepts, postConcept } from '../../services/concept/conceptActions';
import { getStoredUser } from '../../services/auth/services';
import { getProjects } from '../../services/project/projectActions';
import { getUtilities } from '../../services/utilities/utilityActions';
import { getOntoclasses } from '../../services/ontoclass/ontoclassActions';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
// import 'datatables.net'
// import 'datatables.net-bs';
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from 'react-tooltip';
import { file } from 'jszip';
class Concept extends React.Component {
    constructor(props) {
        super();
        this.conceptTable = null;
        this.state = {
            filter_lemma: '',
            filter_definition: '',
            filter_source: '',
            filter_biblio: '',
            filter_count_indicators_t_from: '',
            filter_count_indicators_t_to: '',
            filter_count_indicators_a_from: '',
            filter_count_indicators_a_to: '',
            filter_updatedAt_from: '',
            filter_updatedAt_to: '',
            filter_project_id: '0',
            filter_ontoclass: '0',
            filter_auth: '',
            filter_type_relation: '0',
        }
        this.search_button_ref = null;
        $.fn.dataTableExt.oSort["customdate-desc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();
            if (x > y) {
                return -1;
            }

            return 1;

        };

        $.fn.dataTableExt.oSort["customdate-asc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();

            if (x > y) {
                return 1;
            }
            return -1;
        }
    }
    componentWillMount() {
        this.props.commonActions.setPathname(
            [
                { "pathName": "Home", "Url": "", "id": 1, "color": "" },
                { "pathName": "Concepts", "Url": "concepts", "id": 2, "color": "#81b05f", "subHeader": "Concepts" }
            ]
        )
        this.props.conceptActions.getConcepts();
        this.props.projectActions.getProjects();
        this.props.utilityActions.getUtilities('type_relation');
        this.props.ontoclassActions.getOntoclasses();
        if (this.props.location.state) {
            this.setState({
                filter_source: this.props.location.state
            }, () => {
                this.handleSearch()
            })

        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.concepts.error !== prevProps.concepts.error && this.props.concepts.error !== null) {
            toastr.error('Error Occured', this.props.concepts.message);
        }

        if (this.props.concepts.error === null && !this.props.concepts.loading && prevProps.concepts && prevProps.concepts.loading && this.props.concepts.message) {
            toastr.success('Success', this.props.concepts.message);
        }
        if (this.props.concepts && this.props.concepts.concepts && this.props.concepts.concepts !== prevProps.concepts.concepts) {
            this.conceptTable.clear();
            this.conceptTable.rows.add(this.props.concepts.concepts);
            this.conceptTable.draw();
        }
    }
    componentDidMount() {
        var _that = this;
        var currentUser = getStoredUser();
        var userVisibility = true;
        if (currentUser.profile == 'Guest') {
            $("[edit=1]").hide();
            userVisibility = false;
        }

        this.conceptTable = $('#list_table').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'scrollX': true,
            'createdRow': function (row, data, dataIndex) {
                $(row).on('click', function () {
                    if (data && data.concept_id) {
                        _that.goConcept(data.concept_id);
                    }
                })
            },
            'columns': [
                {
                    'data': 'lemma',
                },
                {
                    'data': 'definition',
                    'render': function (data, type, full, meta) {
                        var data_short = data;
                        if (data.length > 150) {
                            data_short = data.substring(0, 150) + '...';
                        }
                        return '<span data-toggle="tooltip" title="' + data + '">' + data_short + '</span>';
                    }
                },
                {
                    'data': 'sources',
                    'render': function (data, type, full, meta) {
                        var data_short = data;
                        if (data && data.length > 150) {
                            data_short = data.substring(0, 150) + '...';
                        }
                        return '<span data-toggle="tooltip" title="' + data + '">' + data_short + '</span>';
                    }
                },
                { 'data': 'biblios' },
                { 'data': 'indicators_t' },
                { 'data': 'indicators_a' },
                { 'data': 'ontoclasses' },
                { 'data': 'updatedAt'},
                { 'data': 'auth'}
            ],
            columnDefs: [
                {
                    'targets': 7, //index of column
                    'type': 'customdate',
                    "visible": userVisibility,
                },
                {
                    'targets': 8, //index of column
                    "visible": userVisibility,
                },
            ],
        });
        this.conceptTable.clear();
        if (this.props.concepts.concepts) {
            this.conceptTable.rows.add(this.props.concepts.concepts);
        }
        this.conceptTable.draw();
    }

    goConcept = (conceptId) => {
        this.props.history.push(`/concept/${conceptId}`);
    }
    onFilterChange = (e) => {
        var value = e.target.value;
        console.log('++++++++++before', value, typeof(value))
        // var index = e.nativeEvent.target.selectedIndex;
        // if (index) value = e.nativeEvent.target[index].text;
        this.setState({
            [e.target.name]: value
        })
    }
    handleSearch = (e) => {
        var filterData = [];
        var projects_list = this.props.projects.projects;
        var subproject_ids = [];
        if (this.state.filter_project_id !== '0') {
            var sel_project = projects_list.filter(project => project.project_id.toString() === this.state.filter_project_id);
            console.log('+++++++++++', sel_project, subproject_ids)
            if (sel_project[0] && sel_project[0].subprojects && sel_project[0].subprojects.length > 0) {
                for (let subproject of sel_project[0].subprojects) {
                    console.log('subproject detail', subproject)
                    subproject_ids.push(subproject.project_id);
                }
            }
        }
        if (this.props.concepts && this.props.concepts.concepts && this.props.concepts.concepts.length > 0) {
            var concepts = this.props.concepts.concepts;
            for (var i = 0; i < concepts.length; i++) {
                var fileterRes = true;
                var temp = concepts[i].updatedAt.split('/');
                var year = temp[2];
                var month = temp[1];
                var day = temp[0];
                var concept_date = new Date(`${year}/${month}/${day}`);
                if (this.state.filter_updatedAt_from !== '') {
                    var filter_date_from = this.state.filter_updatedAt_from
                    if (filter_date_from.getTime() > concept_date.getTime()) {
                        fileterRes = false;
                    }
                }
                if (this.state.filter_updatedAt_to !== '') {
                    var filter_date_to = new Date(this.state.filter_updatedAt_to)
                    if (filter_date_to.getTime() < concept_date.getTime()) fileterRes = false;
                }
                if (this.state.filter_lemma !== '') {
                    if (concepts[i].lemma.toLowerCase().indexOf(this.state.filter_lemma.toLowerCase()) === -1) fileterRes = false;
                }
                if (this.state.filter_definition !== '') {
                    if (concepts[i].definition.toLowerCase().indexOf(this.state.filter_definition.toLowerCase()) === -1) fileterRes = false;
                }
                if (this.state.filter_source !== '') {
                    if (concepts[i].sources && concepts[i].sources.toLowerCase().indexOf(this.state.filter_source.toLowerCase()) === -1) fileterRes = false;
                }
                if (this.state.filter_biblio !== '') {
                    if (concepts[i].biblios && concepts[i].biblios.toLowerCase().indexOf(this.state.filter_biblio.toLowerCase()) === -1) fileterRes = false;
                }
                if (this.state.filter_count_indicators_t_from) {
                    if (concepts[i].indicators_t < this.state.filter_count_indicators_t_from) fileterRes = false;
                }
                if (this.state.filter_count_indicators_t_to) {
                    if (concepts[i].indicators_t > this.state.filter_count_indicators_t_to) fileterRes = false;
                }
                if (this.state.filter_count_indicators_a_from) {
                    if (concepts[i].indicators_a < this.state.filter_count_indicators_a_from) fileterRes = false;
                }
                if (this.state.filter_count_indicators_a_to) {
                    if (concepts[i].indicators_a > this.state.filter_count_indicators_a_to) fileterRes = false;
                }
                if (this.state.filter_ontoclass !== '0') {
                    let flag = false;
                    for(var j in concepts[i].ontoclasses_id) {
                        if(concepts[i].ontoclasses_id[j] == this.state.filter_ontoclass) {
                            flag = true;
                            break;
                        }
                    }
                    if (!flag) fileterRes = false;
                }
                if (this.state.filter_auth) {
                    if (concepts[i].auth.toLowerCase().indexOf(this.state.filter_auth.toLowerCase()) === -1) fileterRes = false;
                }
                if (this.state.filter_project_id !=='0') {
                    let flag = false;
                    for(var j in concepts[i].projects_id) {
                        if(concepts[i].projects_id[j] == this.state.filter_project_id) {
                            flag = true;
                            break;
                        }
                    }
                    if (!flag) fileterRes = false;
                }
                if (this.state.filter_type_relation !== '0') {
                    var flag = false;
                
                    for(var j in concepts[i].type_rel_id) {
                        if(concepts[i].type_rel_id[j] == this.state.filter_type_relation) {
                            flag = true;
                            break;
                        }
                    }
                    if (!flag) fileterRes = false;
                }
                if (fileterRes) {
                    filterData.push(concepts[i]);
                }
            }
            this.conceptTable.clear();
            this.conceptTable.rows.add(filterData);
            this.conceptTable.draw();
        }
    }
    handleDateChange = (date, type) => {
        switch (type) {
            case 'filter_updatedAt_from':
                this.setState({ filter_updatedAt_from: date });
                break;
            case 'filter_updatedAt_to':
                this.setState({ filter_updatedAt_to: date });
                break;
            default:
        }
    }
    handleReset = (e) => {
        this.props.conceptActions.getConcepts();
        this.setState({
            filter_lemma: '',
            filter_definition: '',
            filter_source: '',
            filter_biblio: '',
            filter_count_indicators_t_from: '',
            filter_count_indicators_t_to: '',
            filter_count_indicators_a_from: '',
            filter_count_indicators_a_to: '',
            filter_updatedAt_from: '',
            filter_updatedAt_to: '',
            filter_project_id: '0',
            filter_ontoclass: '0',
            filter_auth: '',
            filter_type_relation: '0',
        });
        this.props.history.push('/concepts')
        this.conceptTable.clear();
        if (this.props.concepts.concepts) {
            this.conceptTable.rows.add(this.props.concepts.concepts);
        }
        this.conceptTable.draw();
    }
    render() {
        if (this.props.concepts) {
            if (this.props.concepts.loading) {
                Swal.fire({
                    title: 'Loading...',
                    text: "Plese wait",
                    onOpen: () => {
                        Swal.showLoading();
                    },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            } else {
                Swal.close();
            }
        }
        var projects = this.props.projects && this.props.projects.projects ? this.props.projects.projects : [];
        var type_relations = this.props.utilities && this.props.utilities.type_relations ? this.props.utilities.type_relations : [];
        var ontoclasses = this.props.ontoclasses && this.props.ontoclasses.ontoclasses ? this.props.ontoclasses.ontoclasses : [];
        return (
            <React.Fragment>
                <section className="content skin-concetti">
                    <div className="top_comandi">
                        <button type="button" edit="1" onClick={() => {
                            this.props.history.push('/newconcept')
                        }} id="add" className="btn btn-default">
                            <span className="glyphicon glyphicon-plus-sign"></span><Trans id="Button.InsertNewConcept" />
                        </button>
                    </div>
                    <div className="ricerca">
                        <div className="_form-inline">
                            <div className="form-group row">
                                <div className="col-md-3">
                                    <label><Trans id="Table.Filterby"></Trans>:</label>
                                    <div className="input-group">
                                        <span className="input-group-addon"><Trans id="Table.Lemma"></Trans></span>
                                        <input type="text" name="filter_lemma" className="form-control" value={this.state.filter_lemma} onChange={this.onFilterChange} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label><Trans id="Table.Filterby"></Trans>:</label>
                                    <div className="input-group">
                                        <span className="input-group-addon"><Trans id="Table.Definition"></Trans></span>
                                        <input type="text" name="filter_definition" className="form-control" value={this.state.filter_definition} onChange={this.onFilterChange} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label><Trans id="Table.Filterby"></Trans>:</label>
                                    <div className="input-group">
                                        <span className="input-group-addon"><Trans id="Table.Source"></Trans></span>
                                        <input type="text" name="filter_source" className="form-control" value={this.state.filter_source} onChange={this.onFilterChange} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label><Trans id="Table.Filterby"></Trans>:</label>
                                    <div className="input-group">
                                        <span className="input-group-addon"><Trans id="Table.Biblio"></Trans></span>
                                        <input type="text" name="filter_biblio" className="form-control" value={this.state.filter_biblio} onChange={this.onFilterChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-4">
                                    <label><Trans id="Table.Filterby"></Trans>:</label>
                                    <div className="input-group">
                                        <span className="input-group-addon"><Trans id="Content.Project"></Trans></span>
                                        <select name="filter_project_id" className="form-control" value={this.state.filter_project_id} onChange={this.onFilterChange}>
                                            <option key="project_0" value={'0'} >Select</option>
                                            {
                                                projects ? projects.map(project => {
                                                    return <option key={"project_" + project.project_id} value={project.project_id}>{project.name}</option>
                                                }) : <></>
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label><Trans id="Table.Filterby"></Trans>:</label>
                                    <div className="input-group">
                                        <span className="input-group-addon"><Trans id="Modal.Ontoclass"></Trans></span>
                                        <select name="filter_ontoclass" className="form-control" value={this.state.filter_ontoclass} onChange={this.onFilterChange}>
                                            <option key="onto_0" value={'0'} >Select</option>
                                            {
                                                ontoclasses ? ontoclasses.map((ontoclass, index) => {
                                                    return <option key={"onto_" + index} value={ontoclass.ontoclass_id}>{ontoclass.ontoclass}</option>
                                                }) : <></>
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label><Trans id="Table.Filterby"></Trans>:</label>
                                    <div className="input-group">
                                        <span className="input-group-addon"><Trans id="Table.TypeRelationRefer"></Trans></span>
                                        <select name="filter_type_relation" className="form-control" value={this.state.filter_type_relation} onChange={this.onFilterChange}>
                                            <option key="tr_0" value={'0'} >Select</option>
                                            {
                                                type_relations ? type_relations.map(tr => {
                                                    return <option key={"tr_" + tr.id} value={tr.id}>{tr.label}</option>
                                                }) : <></>
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label><Trans id="Table.IndicatorsTheor"></Trans><Trans id="Table.Contained"></Trans>:</label>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="input-group">
                                                <span className="input-group-addon"><Trans id="Table.From"></Trans></span>
                                                <input type="number" size="2" name="filter_count_indicators_t_from" className="form-control" value={this.state.filter_count_indicators_t_from} onChange={this.onFilterChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-group">
                                                <span className="input-group-addon"><Trans id="Table.To"></Trans></span>
                                                <input type="number" size="2" name="filter_count_indicators_t_to" className="form-control" value={this.state.filter_count_indicators_t_to} onChange={this.onFilterChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label><Trans id="Table.IndicatorsApplied"></Trans><Trans id="Table.Contained"></Trans>:</label>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="input-group">
                                                <span className="input-group-addon"><Trans id="Table.From"></Trans></span>
                                                <input type="number" size="2" name="filter_count_indicators_a_from" className="form-control" value={this.state.filter_count_indicators_a_from} onChange={this.onFilterChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-group">
                                                <span className="input-group-addon"><Trans id="Table.To"></Trans></span>
                                                <input type="number" size="2" name="filter_count_indicators_a_to" className="form-control" value={this.state.filter_count_indicators_a_to} onChange={this.onFilterChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label><Trans id="Table.LastUpdate"></Trans>:</label>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="input-group">
                                                <span className="input-group-addon"><Trans id="Table.From"></Trans></span>
                                                <DatePicker onChange={(date) => {
                                                    this.handleDateChange(date, "filter_updatedAt_from");
                                                }}
                                                    showMonthDropdown={true}
                                                    relativeSize={true}
                                                    selected={this.state.filter_updatedAt_from}
                                                    showYearDropdown={true}
                                                    scrollableYearDropdown={false}
                                                    dateFormat={'dd/MM/yyyy'}
                                                    className="form-control"
                                                    placeholderText="dd/mm/yyyy"
                                                />
                                                <FontAwesomeIcon className="calendar-icon" icon={["far", "calendar"]} />
                                                {/* <input type="date" size="2" name="filter_updatedAt_from" className="form-control date" value={this.state.filter_updatedAt_from} onChange={this.onFilterChange} /> */}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-group">
                                                <span className="input-group-addon"><Trans id="Table.To"></Trans></span>
                                                <DatePicker onChange={(date) => {
                                                    this.handleDateChange(date, "filter_updatedAt_to");
                                                }}
                                                    showMonthDropdown={true}
                                                    relativeSize={true}
                                                    selected={this.state.filter_updatedAt_to}
                                                    showYearDropdown={true}
                                                    scrollableYearDropdown={false}
                                                    dateFormat={'dd/MM/yyyy'}
                                                    className="form-control"
                                                    placeholderText="dd/mm/yyyy"
                                                />
                                                <FontAwesomeIcon className="calendar-icon" icon={["far", "calendar"]} />
                                                {/* <input type="date" size="2" name="filter_updatedAt_to" className="form-control" value={this.state.filter_updatedAt_to} onChange={this.onFilterChange} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label><Trans id="Table.User"></Trans>:</label>
                                    <input type="text" name="filter_auth" className="form-control" value={this.state.filter_auth} onChange={this.onFilterChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-2">
                                    <label>&nbsp;</label>
                                    <button className="btn btn-default form-control" onClick={() => this.handleSearch()} ref={ref => this.search_button_ref = ref}><Trans id="Button.SearchFor"></Trans></button>
                                </div>
                                <div className="col-md-2">
                                    <label>&nbsp;</label>
                                    <button className="btn form-control" onClick={() => this.handleReset()}><Trans id="Button.Reset"></Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <table id="list_table" className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <span data-tip data-for="header1"><Trans id="Table.Lemma" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header1" className="dynTooltip">
                                                <div><Trans id="Table.Lemma" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header2"><Trans id="Table.Definition" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
                                                <div><Trans id="Table.Definition" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header3"><Trans id="Table.Sources" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header3" className="dynTooltip">
                                                <div><Trans id="Table.Sources" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header9"><Trans id="Table.Biblios" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header9" className="dynTooltip">
                                                <div><Trans id="Table.Biblios" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header4"><Trans id="Table.IndicatorsTheor" /><br></br><Trans id="Table.Contained" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header4" className="dynTooltip">
                                                <div><Trans id="Table.IndicatorsTheor" /><br></br><Trans id="Table.Contained" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header5"><Trans id="Table.IndicatorsApplied" /><br></br><Trans id="Table.Contained" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header5" className="dynTooltip">
                                                <div><Trans id="Table.IndicatorsApplied" /><br></br><Trans id="Table.Contained" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header6"><Trans id="Table.Ontoclasses" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header6" className="dynTooltip">
                                                <div><Trans id="Table.Ontoclasses" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header7"><Trans id="Table.LastUpdate" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header7" className="dynTooltip">
                                                <div><Trans id="Table.LastUpdate" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header8"><Trans id="Table.User" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header8" className="dynTooltip">
                                                <div><Trans id="Table.User" /></div>
                                            </ReactTooltip>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="list_table_body">

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="comandi">
                        <div>
                            <button className="btn" id="back" onClick={() => { this.props.history.goBack() }}><b><Trans id="Button.Back"></Trans></b></button>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({
        concepts: state.concepts,
        projects: state.projects,
        utilities: state.utilities,
        ontoclasses: state.ontoclasses
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname }, dispatch),
        conceptActions: bindActionCreators({ getConcepts, postConcept }, dispatch),
        projectActions: bindActionCreators({ getProjects }, dispatch),
        utilityActions: bindActionCreators({ getUtilities }, dispatch),
        ontoclassActions: bindActionCreators({ getOntoclasses }, dispatch),
    })
)(Concept);
