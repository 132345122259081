import React from "react";
import Header from './header';
import Aside from './aside';
import Footer from './footer';
import Content from './content';
import { Redirect } from 'react-router-dom';
import { getDashboard } from "../../services/dashboard/dashboardActions";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';



class Home extends React.Component {
    
    componentWillMount(){
        // this.props.dashboardAction.getDashboard();
    }
    componentDidMount(){
        var token = localStorage.getItem('token');
        if (!token) {
            return <Redirect to="/login" />
        } else {
            if (this.props.dashboard && this.props.dashboard.error && this.props.dashboard.error.data && this.props.dashboard.error.data.error === "Unauthorized!") {
                localStorage.removeItem('token');
                return <Redirect to="/login" />
            }
        }
    }

    render() {
        return (
            <div className="wrapper">
                <Header/>
                <Aside />
                <div className="an-ok">
                    <Content />
                    <Footer />
                </div>
            </div>
        )
    } 
};

export default connect(
    state => ({
        dashboard: state.dashboard,
        common: state.common
    }),
    dispatch => ({
        dashboardAction: bindActionCreators({ getDashboard }, dispatch)
    })
)(Home);
