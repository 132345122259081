import React from 'react';
import { connect } from 'react-redux';
import { setPathname } from '../../services/common/commonActions';
import { getStoredUser } from '../../services/auth/services';
import { getUsersFromApi, postUserToApi } from '../../services/users/usersAction';
import { showModal } from '../../modals/modalConductorActions';
import Trans from '../../services/utils/Trans';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import 'datatables.net'
import 'datatables.net-bs'
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
class User extends React.Component {
    constructor() {
        super();
        this.usersTable = null;
    }
    componentWillMount() {
        this.props.commonActions.setPathname(
            [
                { "pathName": "Home", "Url": "", "id": 1, "color": "" },
                { "pathName": "Users", "Url": "users", "id": 2, "color": "#de95c0" }
            ]
        )
        this.props.usersActions.getUsersFromApi();

    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.users && prevProps.users && this.props.users.users !== prevProps.users.users && this.usersTable !== null) {
            this.usersTable.clear();
            if (this.props.users.users) {
                this.usersTable.rows.add(this.props.users.users);
            }
            this.usersTable.draw();
        }
        if (this.props.users.error !== prevProps.users.error && this.props.users.error !== null) {
            toastr.error('Error Occured', this.props.users.message);
        }

        if (this.props.users.error === null && this.props.users.success && !this.props.users.loading && prevProps.users && prevProps.users.loading && this.props.users.message) {
            toastr.success('Success', this.props.users.message);
        }
    }
    componentDidMount() {
        var _that = this;
        var currentUser = getStoredUser();
        var userVisibility = true;
        if (currentUser.profile == 'Guest') {
            $("[edit=1]").hide();
            userVisibility = false;
        }
        this.usersTable = $('#list_table').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'scrollX': true,
            'autoWidth': false,
            'createdRow': function (row, data, dataIndex) {
                $(row).on('click', function () {
                    if (data && data.user_id) {
                        _that.goUser(data.user_id);
                    }
                })
            },
            'columns': [
                {
                    'data': 'name'
                },
                {
                    'data': 'lastname'
                },
                {
                    'data': 'profile'
                },
                {
                    'data': 'ontoclasses',
                    'render': function (data, type, full, meta) {
                        var result = "";
                        for (var i = 0; i < data.length; i++) {
                            result += data[i].ontoclass;
                            if (i < data.length - 1) {
                                result += '; ';
                            }
                        }
                        return result;
                    }
                },
                {
                    'data': 'projects',
                    'render': function (data, type, full, meta) {
                        var result = "";
                        for (var i = 0; i < data.length; i++) {
                            result += data[i].name;
                            if (i < data.length - 1) {
                                result += '; ';
                            }
                        }
                        return result;
                    }
                },
            ],
        });
        this.usersTable.clear();
        if (this.props.users.users) {
            this.usersTable.rows.add(this.props.users.users);
        }

        this.usersTable.draw();
    }

    goUser = (userId) => {
        this.props.history.push(`user/${userId}`);
    }

    renderTable = (user) => {
        return <tr onClick={() => this.goUser(user.user_id)} key={"user_" + user.user_id}>
            <td>{user.name}</td>
            <td>
                {user.ontoclasses.map((oc) => {
                    if (oc !== user.ontoclasses[user.ontoclasses.length - 1]) {
                        return <span key={"oc_" + oc.ontoclass_id}>{oc.ontoclass}; </span>
                    } else {
                        return <span key={"oc_" + oc.ontoclass_id}>{oc.ontoclass}</span>
                    }
                }
                )}
            </td>
        </tr>
    }

    render() {
        if (this.props.users.loading) {
            Swal.fire({
                title: 'Loading...',
                text: "Plese wait",
                onOpen: () => {
                    Swal.showLoading();
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
        } else {
            Swal.close();
        }

        return (
            <React.Fragment>
                <section className="content skin-utenti">
                    <div className="top_comandi">
                        <button edit="1" type="button" onClick={() => {
                            this.props.modalActions.showModal('ADD_USER_MODAL')
                        }} id="add" className="btn btn-default">
                            <span className="glyphicon glyphicon-plus-sign"></span><Trans id="Button.InsertNewUser" />
                        </button>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <table id="list_table" className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <span data-tip data-for="header9"><Trans id="Table.Name" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header9" className="dynTooltip">
                                                <div><Trans id="Table.Name" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header10"><Trans id="Table.LastName" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header10" className="dynTooltip">
                                                <div><Trans id="Table.LastName" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header11"><Trans id="Table.Profile" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header11" className="dynTooltip">
                                                <div><Trans id="Table.Profile" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header12"><Trans id="Table.Ontoclasses" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header12" className="dynTooltip">
                                                <div><Trans id="Table.Ontoclasses" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header13"><Trans id="Table.Projects" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header13" className="dynTooltip">
                                                <div><Trans id="Table.Projects" /></div>
                                            </ReactTooltip>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="list_table_body">
                                </tbody>

                            </table>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({
        users: state.users
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname }, dispatch),
        usersActions: bindActionCreators({ getUsersFromApi, postUserToApi }, dispatch),
        modalActions: bindActionCreators({ showModal }, dispatch),
    })
)(User);
