import React from 'react';
import { connect } from 'react-redux';
import { setPathname, addPathname, deletePathname } from '../../services/common/commonActions';
import { getIndicatorAppliedDetail, deleteIndicatorApplied } from '../../services/indicatorApplied/indicator_appliedActions';
import { getEvents } from '../../services/event_entity/eventActions';
import { showModal } from '../../modals/modalConductorActions';
import { getStoredUser } from '../../services/auth/services';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import { capitalize } from '../../services/utils_services';
import MathJax from 'react-mathjax2'
import 'datatables.net'
import 'datatables.net-bs'
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
class AppliedDetail extends React.Component {
    constructor() {
        super();
        this.indicatorAppliedTable = null;
        this.state = {
            algorithmArray: [],
            algorithmString: '',
            indicator_a_id: ''
        }
    }

    componentWillMount() {
        // this.props.commonActions.setPathname(
        //     [
        //         { "pathName": "Home", "Url": "", "id": 1, "color": "" },
        //         { "pathName": "Indicators", "Url": "indicators", "id": 2, "color": "#927e9b" },
        //         { "pathName": "AppliedDetail", "Url": "/", "id": 3, "color": "#927e9b" },
        //     ]
        // );
        var indicator_a_id = this.props.match.params.appliedId;
        if (indicator_a_id) {
            this.setState({ indicator_a_id: indicator_a_id });
            this.props.indicator_appliedActions.getIndicatorAppliedDetail(indicator_a_id);
            this.props.eventActions.getEvents(indicator_a_id, 'indicator_applied')

        }
    }
    componentDidMount() {
        var _that = this;
        var currentUser = getStoredUser();
        if (currentUser.profile == 'Guest') {
            $("[edit=1]").hide();
        }
        this.indicatorAppliedTable = $('#list_table_applied').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'scrollX': false,
            'createdRow': function (row, data, dataIndex) {
                if (currentUser.profile != 'Guest') {
                    $(row).on('click', function () {
                        if (data && data.indicator_applied_time_id) {
                            _that.props.modalActions.showModal('SHOW_APPLIED_MODAL',
                                {
                                    time_id: data.time_id,
                                    ind_value: data.ind_value,
                                    measure_id: data.measure_id,
                                    indicator_applied_time_id: data.indicator_applied_time_id,
                                    indicator_applied_id: _that.props.indicators_applied.sel_indicator_applied.indicator.indicator_applied_id
                                });
                        }
                    })
                }
            },
            'columns': [
                {
                    'data': 'time'
                },
                {
                    'data': 'ind_value',
                },
                {
                    'data': 'measure'
                },
            ]
        });
        this.indicatorAppliedTable.clear();
        if (this.props.indicators_applied && this.props.indicators_applied.sel_indicator_applied && this.props.indicators_applied.sel_indicator_applied.indicator.times) {
            this.indicatorAppliedTable.rows.add(this.props.indicators_applied.sel_indicator_applied.indicator.times);
        }
        this.indicatorAppliedTable.draw();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.indicators_applied && this.props.indicators_applied.sel_indicator_applied && this.props.indicators_applied.sel_indicator_applied !== prevProps.indicators_applied.sel_indicator_applied) {
            var sel_indicator_applied = this.props.indicators_applied.sel_indicator_applied.indicator;
            var applied_name = sel_indicator_applied.name;
            this.props.commonActions.addPathname(
                { "pathName": applied_name, "Url": "applieddetail/" + this.props.match.params.id, "color": "#6d81ab", "subHeader": "Applied Detail" }
            )
            if (sel_indicator_applied.indicator_theor && sel_indicator_applied.indicator_theor.algorithm) {
                try {
                    var _algorithm = JSON.parse(sel_indicator_applied.indicator_theor.algorithm.replace(/'/g, '"'));
                } catch (err) {
                    var _algorithm = '';
                }
            }
            this.setState({
                algorithmArray: _algorithm
            })
        }
        if (this.state.algorithmArray && prevState.algorithmArray && this.state.algorithmArray !== prevState.algorithmArray) {
            var temp_string = '';
            if (this.state.algorithmArray.length > 0) {
                for (var i = 0; i < this.state.algorithmArray.length; i++) {
                    if (this.state.algorithmArray[i].type === 'string') {
                        temp_string += `"${this.state.algorithmArray[i].content}"`
                    } else {
                        temp_string += this.state.algorithmArray[i].content;
                    }
                }
            }
            this.setState({
                algorithmString: temp_string
            })
        }
        if (this.props.indicators_applied && this.props.indicators_applied.sel_indicator_applied && prevProps.indicators_applied && this.props.indicators_applied.sel_indicator_applied !== prevProps.indicators_applied.sel_indicator_applied) {
            this.indicatorAppliedTable.clear();
            if (this.props.indicators_applied.sel_indicator_applied.indicator.times) {
                this.indicatorAppliedTable.rows.add(this.props.indicators_applied.sel_indicator_applied.indicator.times);
            }
            this.indicatorAppliedTable.draw();
        }
        if (this.props.indicators_applied.error !== null) {
            toastr.error('Error Occured', this.props.indicators_applied.message);
        }

        if (this.props.indicators_applied.error === null && !this.props.indicators_applied.loading && prevProps.indicators_applied && prevProps.indicators_applied.loading && !this.props.indicators_applied.applied_time) {
            toastr.success('Success', this.props.indicators_applied.message);
            this.props.history.push(`/indicators`);
        }
        if (this.props.indicators_applied.error !== prevProps.indicators_applied.error && this.props.indicators_applied.error !== null && this.props.indicators_applied.applied_time) {
            console.log('come to error---------------------')

            toastr.error('Error Occured', this.props.indicators_applied.message);
        }
        if (this.props.indicators_applied.error === null && !this.props.indicators_applied.loading && prevProps.indicators_applied && prevProps.indicators_applied.loading && this.props.indicators_applied.message && this.props.indicators_applied.applied_time) {
            toastr.success('Success', this.props.indicators_applied.message);
            this.props.history.push(`/applieddetail/${this.state.indicator_a_id}`);
        }
    }

    render() {
        var list_transition = [];
        var indicator_applied = {};
        if (this.props.indicators_applied) {
            if (this.props.indicators_applied.loading) {
                Swal.fire({
                    title: 'Loading...',
                    text: "Plese wait",
                    onOpen: () => {
                        Swal.showLoading();
                    },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            } else {
                Swal.close();
            }
            if (this.props.indicators_applied.sel_indicator_applied) {
                indicator_applied = this.props.indicators_applied.sel_indicator_applied.indicator;
                list_transition = this.props.indicators_applied.sel_indicator_applied.list_transition;
            }
        }

        var currentUser = getStoredUser();
        return (
            <React.Fragment>
                <section className="content skin-indicatori ">
				{ currentUser.profile != 'Guest' && 
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sommario">
                                <label><Trans id="Content.CreationDate"></Trans>:</label><span id="createdAt">{indicator_applied ? indicator_applied.createdAt : ''}</span><label><Trans id="Content.LastUpdate"></Trans>:</label>
                                <span id="updatedAt">{indicator_applied ? indicator_applied.updatedAt : ''}</span> <label><Trans id="Content.User"></Trans>:</label><span id="auth">{indicator_applied ? indicator_applied.auth : ''}</span> <label><Trans id="Content.State"></Trans>:</label> <span className="label label-success" id="state" >{capitalize(indicator_applied ? indicator_applied.state : '')}</span><br />
                                <label><Trans id="Modal.OntoclassRefer"></Trans>:</label><span id="owner_ontoclass">{indicator_applied? indicator_applied.owner_ontoclass : ''}</span>
                            </div>
                        </div>
                    </div>
                }
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="nav nav-tabs" style={{ height: "42px" }}>
                                <li className="active"><a href="#tab_main" data-toggle="tab"><Trans id="ContentHeader.AppliedDetail"></Trans></a></li>
                                <li><a href="#tab_times" data-toggle="tab"><Trans id="ContentHeader.Value"></Trans></a></li>
                            </ul>
                            <div className="tab-content no-padding">
                                <div className="tab-pane active" id="tab_main">
                                    <div className="form-group row underlined">
                                        <div className="col-md-12">
                                            <label><Trans id="Content.Name"></Trans>:</label>
                                            <div id="name">{indicator_applied ? indicator_applied.name : ''}</div>
                                        </div>
                                    </div>
                                    <div className="form-group row underlined">
                                        <div className="col-md-12">
                                            <label><Trans id="Content.Note"></Trans></label>
                                            <div id="algoritmo">{indicator_applied && indicator_applied.note ? indicator_applied.note : ''}</div>
                                        </div>
                                    </div>
                                    <div className="form-group row underlined">
                                        <div className="col-md-6">
                                            <label><Trans id="Table.IndicatorTheor"></Trans>:</label>
                                            <div id="t_name">{indicator_applied && indicator_applied.indicator_theor ? indicator_applied.indicator_theor.name : ''}</div>
                                        </div>
                                        <div className="col-md-6">
                                            <label><Trans id="Content.Algorithm"></Trans>:</label>
                                            <div id="algoritmo">
                                                <MathJax.Context input='ascii'>
                                                    <div>
                                                        <MathJax.Node inline>{this.state.algorithmString}</MathJax.Node>
                                                    </div>
                                                </MathJax.Context>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row underlined">
                                        <div className="col-md-12">
                                            <label><Trans id="Table.Source" />:</label>
                                            {
                                                indicator_applied && indicator_applied.sources ? indicator_applied.sources.map((source, index) => {
                                                    return <div className="lista_item" key={"source_" + source.source_id}><a href={source.source} target="_blank">{source.source}</a> </div>
                                                }) : <></>
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row underlined">
                                        <div className="col-md-12">
                                            <label><Trans id="Table.LegislRefers" />:</label>
                                            {
                                                indicator_applied && indicator_applied.legisls ? indicator_applied.legisls.map((legisl, index) => {
                                                    return <div className="lista_item" id="source_line" key={"legisl_" + index}><i id="legisl">{legisl.legisl}</i> </div>
                                                }) : <></>
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row underlined">
                                        <div className="col-md-12">
                                            <label><Trans id="Table.Cohort" />:</label>
                                            {
                                                indicator_applied && indicator_applied.cohort ? indicator_applied.cohort.map((cohort, index) => {
                                                    return <div className="lista_item pointer" id="source_line" key={"cohort_" + index} onClick={() => { this.props.history.push(`/concept/${cohort.concept_id}`) }}><i id="legisl">{cohort.lemma} {cohort.definition}</i> </div>
                                                }) : <></>
                                            }
                                        </div>
                                    </div>
                                    <div className="comandi">
                                        <div>
                                            {
                                                list_transition && list_transition.indexOf('update') > -1 ? <button className="btn btn-default view" id="update" onClick={() => { this.props.history.push(`/newapplied/${indicator_applied.indicator_applied_id}`) }}><b><Trans id="Button.Modification" /></b></button> : <></>
                                            }
                                            {
                                                list_transition && list_transition.indexOf('deletion') > -1 ? <button className="btn" id="delete" onClick={() => { this.props.modalActions.showModal('DELETE_APPLIED_MODAL') }}><b><Trans id="Button.Delete" /></b></button> : <></>
                                            }
                                            <button className="btn" id="back" onClick={() => this.props.history.goBack()}><b><Trans id="Button.Back" /></b></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="tab_times">
                                    <div className="top_comandi" style={{ marginTop: "20px" }}>
                                        <button type="button" edit="1" id="add" className="btn btn-default" onClick={() => { this.props.modalActions.showModal('SHOW_APPLIED_MODAL', { indicator_applied_id: this.state.indicator_a_id }) }}>
                                            <span className="glyphicon glyphicon-plus-sign"></span><Trans id="Button.InsertNewAppliedTime"></Trans>
                                        </button>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <table className="table table-hover" id="list_table_applied">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <span data-tip data-for="header1"><Trans id="Table.TimeRefer" /></span>
                                                            <ReactTooltip place="top" effect="solid" id="header1" className="dynTooltip">
                                                                <div><Trans id="Table.TimeRefer" /></div>
                                                            </ReactTooltip>
                                                        </th>
                                                        <th>
                                                            <span data-tip data-for="header2"><Trans id="Modal.Value" /></span>
                                                            <ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
                                                                <div><Trans id="Modal.Value" /></div>
                                                            </ReactTooltip>
                                                        </th>
                                                        <th>
                                                            <span data-tip data-for="header2"><Trans id="Table.MeasureRefers" /></span>
                                                            <ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
                                                                <div><Trans id="Table.MeasureRefers" /></div>
                                                            </ReactTooltip>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment >
        )
    }
}

export default connect(
    state => ({
        indicator_theors: state.indicator_theors,
        common: state.common,
        indicators_applied: state.indicators_applied,
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname, addPathname, deletePathname}, dispatch),
        indicator_appliedActions: bindActionCreators({ getIndicatorAppliedDetail, deleteIndicatorApplied }, dispatch),
        eventActions: bindActionCreators({ getEvents }, dispatch),
        modalActions: bindActionCreators({ showModal }, dispatch),
    })
)(AppliedDetail);
