import { handleActions } from 'redux-actions';

import {
    getOntoclasses,
    getOntoclassesFailed,
    getOntoclassesSucceed,
    postOntoclass,
    postOntoclassFailed,
    postOntoclassSucceed,
    getOntoclassDetail,
    getOntoclassDetailFailed,
    getOntoclassDetailSucceed,
    updateOntoclass,
    updateOntoclassFailed,
    updateOntoclassSucceed,
    deleteOntoclass,
    deleteOntoclassFailed,
    deleteOntoclassSucceed,
    getOntoclassAuto,
    getOntoclassAutoFailed,
    getOntoclassAutoSucceed
} from './ontoclassActions';

const defaultState = {
    ontoclasses: null,
    error: null,
    loading: false,
    success: false,
    message: null,
    sel_ontoclass: null,
    auto_ontoclasses: null,
};

const reducer = handleActions(
    {
        [getOntoclasses](state) {
            return {
                ...state,
                error: null,
                // loading: true,
                success: false,
                message: null,
                ontoclasses: null
            };
        },
        [getOntoclassesFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error,
                ontoclasses: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [getOntoclassesSucceed](
            state,
            {
                payload: { ontoclasses }
            }
        ) {
            return {
                ...state,
                ontoclasses: ontoclasses,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
        [postOntoclass](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null
            };
        },
        [postOntoclassFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in postFailed', error)
            return {
                ...state,
                error,
                loading: false,
                success: false,
                message: error.data.message,
            };
        },
        [postOntoclassSucceed](
            state,
            {
                payload: {ontoclass}
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                message: "Add Ontoclass Succeed!",
                sel_ontoclass: ontoclass.user
            };
        },
        [getOntoclassDetail](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getOntoclassDetailFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error,
                sel_ontoclass: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [getOntoclassDetailSucceed](
            state,
            {
                payload: { ontoclasses }
            }
        ) {
            return {
                ...state,
                sel_ontoclass: ontoclasses.user,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
        [updateOntoclass](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null
            };
        },
        [updateOntoclassFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error,
                sel_ontoclass: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [updateOntoclassSucceed](
            state,
            {
                payload: { ontoclass }
            }
        ) {
            return {
                ...state,
                sel_ontoclass: ontoclass.user,
                error: null,
                loading: false,
                success: true,
                message: "Update Ontoclass Succeed"
            };
        },
        [deleteOntoclass](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null
            };
        },
        [deleteOntoclassFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [deleteOntoclassSucceed](state) {
            return {
                ...state,
                sel_ontoclass: null,
                error: null,
                loading: false,
                success: true,
                message: "Delete Ontoclass Succeed"
            };
        },
        [getOntoclassAuto](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getOntoclassAutoFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('get ontoclass auto failed', error)
            return {
                ...state,
                error,
                auto_ontoclasses: null,
                loading: false,
                success: false,
                message: error ? error.message: ''
            };
        },
        [getOntoclassAutoSucceed](
            state,
            {
                payload: { ontoclasses }
            }
        ) {
            return {
                ...state,
                auto_ontoclasses: ontoclasses,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
    },
    defaultState
);

export default reducer;
