import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postIndicatorAppliedTime, updateIndicatorAppliedTime, deleteIndicatorAppliedTime } from '../../../services/indicatorApplied/indicator_appliedActions';
import { getUtilities } from '../../../services/utilities/utilityActions';
import { hideModal } from '../../modalConductorActions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import Trans from '../../../services/utils/Trans';
class IndicatorAppliedModal extends React.Component {
    constructor() {
        super();
        this.onCancel = this.onCancel.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.state = {
            time: 1,
            value: '',
            measure: 1,
            indicator_a_id: 0,
            indicator_a_time_id: 0
        }
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }
    handleDelete() {
        if (this.state.indicator_a_id !== 0 && this.state.indicator_a_time_id !== 0) {
            this.props.indicator_appliedActions.deleteIndicatorAppliedTime(this.state.indicator_a_id, this.state.indicator_a_time_id);
            this.props.modalActions.hideModal();
        }
    }
    componentWillMount() {
        this.props.utilityActions.getUtilities('time');
        this.props.utilityActions.getUtilities('measure');
        var params = this.props.modal && this.props.modal.params ? this.props.modal.params : null;
        if (params) {
            this.setState({
                time: params.time_id ? params.time_id : this.state.time,
                value: params.ind_value ? params.ind_value : this.state.value,
                measure: params.measure_id ? params.measure_id : this.state.measure,
                indicator_a_id: params.indicator_applied_id ? params.indicator_applied_id : 0,
                indicator_a_time_id: params.indicator_applied_time_id ? params.indicator_applied_time_id : 0
            })
        }
    }
    componentDidUpdate(prevProps, prevState) {
       
    }
    render() {
        var _that = this;
        var times = null;
        var measures = null;
        if (this.props && this.props.utilities && this.props.utilities.times) {
            times = this.props.utilities.times
        }
        if (this.props && this.props.utilities && this.props.utilities.measures) {
            measures = this.props.utilities.measures
        }
        var indicator_a_time_id = this.state.indicator_a_time_id;
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader><Trans id="Modal.Value"></Trans></ModalHeader>
                <Formik
                    initialValues={{
                        time: _that.state.time === 1 && times && times[0] ? times[0].id : _that.state.time,
                        value: _that.state.value,
                        measure: _that.state.measure === 1 && measures && measures[0] ? measures[0].id : _that.state.measure
                    }}
                    validationSchema={Yup.object().shape({
                        time: Yup.string()
                            .required('Time is required'),
                        value: Yup.string()
                            .required('Value is required'),
                        measure: Yup.string()
                            .required('Measure is required'),
                    })}
                    onSubmit={fields => {
                        this.setState({
                            time: fields.time,
                            value: fields.value,
                            measure: fields.measure
                        })
                        if (this.state.indicator_a_time_id !== 0) {
                            this.props.indicator_appliedActions.updateIndicatorAppliedTime(this.state.indicator_a_id, this.state.indicator_a_time_id, this.state.time, this.state.value, this.state.measure)
                        } else {
                            this.props.indicator_appliedActions.postIndicatorAppliedTime(this.state.indicator_a_id, this.state.time, this.state.value, this.state.measure)
                        }
                        this.props.modalActions.hideModal();
                    }}>
                    {({ errors, status, touched, setFieldValue }) => (
                        <Form className="skin-indicatori">
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Table.TimeRefer"></Trans>:</label>
                                    <Field as="select" name="time" type="text" className={'form-control' + (errors.time && touched.time ? ' is-invalid' : '')}>
                                        {
                                            times ? times.map(time => {
                                                return <option key={"time_" + time.id} value={time.id}>{time.label}</option>
                                            }) : <></>
                                        }
                                    </Field>
                                    <ErrorMessage name="time" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Modal.Value"></Trans></label>
                                    <Field name="value" type="text" className={'form-control' + (errors.value && touched.value ? ' is-invalid' : '')} />
                                    <ErrorMessage name="value" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Table.MeasureRefers"></Trans>:</label>
                                    <Field as="select" name="measure" type="text" className={'form-control' + (errors.measure && touched.measure ? ' is-invalid' : '')}>
                                        {
                                            measures ? measures.map(measure => {
                                                return <option key={"measure_" + measure.id} value={measure.id}>{measure.label}</option>
                                            }) : <></>
                                        }
                                    </Field>
                                    <ErrorMessage name="measure" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button type="submit" className="btn btn-default" >
                                    <Trans id="Button.Confirm"></Trans>
                                </button>
                                {
                                    indicator_a_time_id !== 0 ? <button type="button" className="btn btn-default" onClick={this.handleDelete}>
                                        <Trans id="Button.Delete"></Trans>
                                    </button> : <></>
                                }
                                <button className="btn" onClick={this.onCancel}>
                                    <Trans id="Button.Cancel"></Trans>
                                </button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>

            </Modal>
        );
    }
}

export default connect(
    state => ({
        auth: state.auth,
        utilities: state.utilities,
        ontoclasses: state.ontoclasses,
        indicators_applied: state.indicators_applied
    }), dispatch => ({
        indicator_appliedActions: bindActionCreators({ postIndicatorAppliedTime, updateIndicatorAppliedTime, deleteIndicatorAppliedTime }, dispatch),
        utilityActions: bindActionCreators({ getUtilities }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(IndicatorAppliedModal);
