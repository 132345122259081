import React from 'react';
import { connect } from 'react-redux';
import { setPathname, addPathname, deletePathname} from '../../services/common/commonActions';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import { getDashboard } from '../../services/dashboard/dashboardActions';
import { Redirect } from 'react-router-dom';
import { Icon, InlineIcon } from '@iconify/react';
import iosGearOutline from '@iconify-icons/ion/ios-gear-outline';
import iosCartOutline from '@iconify-icons/ion/ios-cart-outline';
import { NavLink } from 'react-router-dom';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

import TruncTip from '../components/t.js';
const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
        return (
            <div className="custom-tooltip">
                <div>
                    {label}
                </div>
                {payload ? (
                    payload.map((item, index) => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                padding: "3px"
                            }}
                            key={"key_" + index}
                        >
                            <div
                                style={{
                                    width: "15px",
                                    height: "15px",
                                    backgroundColor: item.fill,
                                    marginRight: "5px"
                                }}
                            ></div>
                            {
                                item.name && item.name === 'count_ontoclasses' ?
                                    <span className="label"> {item.value + ' ' + 'Ontoclasses'}</span> : <></>
                            }
                            {
                                item.name && item.name === 'count_concepts' ?
                                    <span className="label"> {item.value + ' ' + 'Concepts'}</span> : <></>
                            }
                            {
                                item.name && item.name === 'count_indicators' ?
                                    <span className="label"> {item.value + ' ' + 'Indicators'}</span> : <></>
                            }
                        </div>
                    ))
                ) : (
                        <></>
                    )}
            </div>
        );
    }

    return null;
};
class Dashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            projects: {},
            data: '',
            options: '',
            profile: ''
        }
    }
    componentWillMount() {
        this.props.commonActions.setPathname(
            [
                { "pathName": "Home", "Url": "/", "id": 1 },
                { "pathName": "Dashboard", "Url": "/", "id": 2, "color": "#b3aba7" }
            ]
        );
        this.props.dashboardActions.getDashboard();
        if (this.props.auth && this.props.auth.currentUser) {
            this.setState({
                profile: this.props.auth.currentUser.profile
            });
        }
    }
    componentDidMount() {
        if (this.props.dashboard && this.props.dashboard.dashboard) {
            this.setState({ data: [...this.props.dashboard.dashboard.projects] })
        }
        
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.dashboard && this.props.dashboard.dashboard && this.props.dashboard.dashboard !== prevProps.dashboard.dashboard) {
            this.setState({ data: [...this.props.dashboard.dashboard.projects] })
        }
       
    }
    render() {
        if (this.props && this.props.dashboard && this.props.dashboard.dashboard) {
            var dashboard = this.props.dashboard.dashboard;
            // var requests = dashboard.requests;
            var requests = 1;
            var top_concepts = dashboard.top_concepts;
            var top_sources = dashboard.top_sources;
        }
        var token = localStorage.getItem('token');

        if (!token) {
            console.log('come to here dahsboard');
            return <Redirect to="/login" />
        } else {
            if (this.props.dashboard && this.props.dashboard.error && this.props.dashboard.error.data && this.props.dashboard.error.data.error === "Unauthorized!") {
                localStorage.removeItem('token');
                return <Redirect to="/login" />
            }
        }
        var project_id;
        if (this.props.auth.currentUser) {
            project_id = this.props.auth.currentUser.projects.length ? 'app/map/' + this.props.auth.currentUser.projects[0].project_id : '';
        }
        return (
            <section className="content">
                {
                    requests ? <div className="row richieste">
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="info-box">
                                <NavLink to={{pathname:"https://smartview.mosis-lab.org/" + project_id}} target="_blank">
                                    <span className="info-box-icon bg-richieste2"><i class="ion ion-net"></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">SIDOC Ontological Glossary<br/> Graphical Interface</span>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        { 1 == 0 &&
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="info-box">
                                <span className="info-box-icon bg-richieste"><i className="fa fa-google-plus"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text"><Trans id="Content.RequestsInprogress" /></span>
                                    {
                                        this.state.profile === 'Administrator' || this.state.profile === 'Publisher' ?
                                            <span className="info-box-number" id="count_in_progress" onClick={() => { this.props.history.push(`/requests`) }}>{requests && requests.in_progress ? requests.in_progress : 0}</span> : <></>
                                    }
                                </div>
                            </div>
                        </div>
                        }

                        <div className="clearfix visible-sm-block"></div>

                        { 1 == 0 &&
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="info-box">
                                <span className="info-box-icon bg-richieste">
                                    <InlineIcon icon={iosCartOutline} />
                                </span>
                                <div className="info-box-content">
                                    <span className="info-box-text"><Trans id="Content.RequestsApproved" /></span>
                                    {
                                        this.state.profile === 'Administrator' || this.state.profile === 'Publisher' ?
                                            <span className="info-box-number" id="count_approved" onClick={() => { this.props.history.push(`/requests`) }}>{requests && requests.approved ? requests.approved : 0}</span> : <></>
                                    }
                                </div>
                            </div>
                        </div>
                        }
                    </div> : <></>
                }
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        <div className="chart" style={{ display: "flex" }}>
                            {this.state.data && this.state.data.length > 0 ? <ResponsiveContainer width="100%" height={300}>
                                <BarChart
                                    width={300}
                                    height={300}
                                    data={this.state.data ? this.state.data : null}
                                    barCategoryGap={2}
                                    barGap={0.5}
                                    margin={{ left: -40, top: 20 }}
                                >
                                    <CartesianGrid strokeDasharray="0.5" vertical={true} />
                                    <XAxis dataKey="name" tickSize={0} />
                                    <YAxis interval={0} domain={[0, dataMax => (Math.ceil(dataMax / 10) * 10)]} tickCount={11} />
                                    <Tooltip
                                        content={<CustomTooltip />}
                                        cursor={{ fill: "transparent" }}
                                    />
                                    <Bar dataKey="count_ontoclasses" fill="rgba(210, 214, 222, 1)" />
                                    <Bar dataKey="count_concepts" fill="#00a65a" />
                                    <Bar dataKey="count_indicators" fill="rgba(60,141,188,0.9)" />

                                </BarChart>
                            </ResponsiveContainer> : <></>
                            }
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-6">
                            <div className="box box-concetti">
                                <div className="box-header with-border">
                                    <h3 className="box-title"><Trans id="Content.ConceptsMostUsedIndicators" /></h3>
                                    <div className="box-tools pull-right">
                                        <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="box-body">
                                    <div className="table-responsive">
                                        <table className="table no-margin">
                                            <tbody>
                                                {
                                                    top_concepts && top_concepts.length > 0 ? top_concepts.map((top_concept, index) => {
                                                        return <tr key={"concept_label_" + index}>
                                                            <td id="concept_label" onClick={() => { this.props.history.push(`/concept/${top_concept.concept_id}`) }}><TruncTip params={{ str: top_concept.concept_label + "(" + top_concept.ontoclass + ")", limit: 120, uuid: "concept_label_" + index}}></TruncTip></td>
                                                            <td><span className="label label-success" id="concept_count">{top_concept.concept_count ? top_concept.concept_count : 0}</span></td>
                                                        </tr>
                                                    }) : <></>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="box box-concetti">
                                <div className="box-header with-border">
                                    <h3 className="box-title"><Trans id="Content.SourcesMostUsedConcepts" /></h3>
                                    <div className="box-tools pull-right">
                                        <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="box-body">
                                    <div className="table-responsive">
                                        <table className="table no-margin">
                                            <tbody>
                                                {
                                                    top_sources && top_sources.length > 0 ? top_sources.map((top_source, index) => {
                                                        return <tr key={"source_" + index}>
                                                            <td id="source_label" onClick={() => { this.props.history.push({pathname: `/concepts/`, state: top_source.source }) }}>{top_source.source ? top_source.source : ''}</td>
                                                            <td><span className="label label-success" id="source_count">{top_source.source_count ? top_source.source_count : 0}</span></td>
                                                        </tr>
                                                    }) : <></>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default connect(
    state => ({
        dashboard: state.dashboard,
        auth: state.auth
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname, addPathname, deletePathname }, dispatch),
        dashboardActions: bindActionCreators({ getDashboard }, dispatch)
    })
)(Dashboard);
