import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateLemma } from '../../../../services/lemma/lemmaActions';
import { getOntoclasses } from '../../../../services/ontoclass/ontoclassActions';
import { hideModal } from '../../../modalConductorActions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import Trans from '../../../../services/utils/Trans';

class LemmaEditModal extends React.Component {
    constructor() {
        super();
        this.onCancel = this.onCancel.bind(this);
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }
    componentWillUnmount() {
        this.props.ontoclassActions.getOntoclasses();
    }
    componentDidMount() {
        console.log('Props in Lemma edit modal', this.props, this.props.ontoclasses)
    }

    render() {
        var ontoclasses = null;
        if (this.props && this.props.auth && this.props.auth.currentUser) {
            var profile = this.props.auth.currentUser.profile;
            if (profile === "Administrator") {
                ontoclasses = this.props.ontoclasses ? this.props.ontoclasses.ontoclasses : []
            } else if (profile === "Publisher") {
                ontoclasses = this.props.auth.currentUser.ontoclasses
            }
        }
        if (this.props && this.props.lemmas && this.props.lemmas.sel_lemma) {
            var sel_lemma = this.props.lemmas.sel_lemma
        }
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader><Trans id="Modal.EditLemma"></Trans></ModalHeader>
                <Formik
                    initialValues={{
                        ontoclass: sel_lemma && sel_lemma.lemma.owner_ontoclass_id ? sel_lemma.lemma.owner_ontoclass_id : 0,
                        lemma: sel_lemma ? sel_lemma.lemma.lemma : ''
                    }}
                    validationSchema={Yup.object().shape({
                        ontoclass: Yup.string()
                            .required('Ontoclass is required'),
                        lemma: Yup.string()
                            .required('Lemma is required')
                    })}
                    onSubmit={fields => {
                        this.props.lemmaActions.updateLemma(sel_lemma.lemma.lemma_id, fields.lemma, fields.ontoclass);
                        this.props.modalActions.hideModal();
                    }}>
                    {({ errors, status, touched, setFieldValue }) => (
                        <Form className="skin-lemmi">
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Modal.OntoclassRefer"></Trans></label>
                                    <Field as="select" name="ontoclass" type="text" className={'form-control' + (errors.ontoclass && touched.ontoclass ? ' is-invalid' : '')}>
                                        {
                                            ontoclasses ? ontoclasses.map(ontoclass => {
                                                return <option key={"onto_" + ontoclass.ontoclass_id} value={ontoclass.ontoclass_id}>{ontoclass.ontoclass}</option>
                                            }) : <></>
                                        }
                                    </Field>
                                    <ErrorMessage name="ontoclass" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center">Lemma</label>
                                    <Field name="lemma" type="text" className={'form-control' + (errors.lemma && touched.lemma ? ' is-invalid' : '')} />
                                    <ErrorMessage name="lemma" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn" onClick={this.onCancel}>
                                    <Trans id="Button.Cancel"></Trans>
                                </button>
                                <button type="submit" className="btn btn-default" >
                                    <Trans id="Button.Confirm"></Trans>
                                </button>

                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </Modal>
        );
    }
}

export default connect(
    state => ({
        auth: state.auth,
        ontoclasses: state.ontoclasses,
        lemmas: state.lemmas
    }), dispatch => ({
        lemmaActions: bindActionCreators({ updateLemma }, dispatch),
        ontoclassActions: bindActionCreators({ getOntoclasses }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(LemmaEditModal);
