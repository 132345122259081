import { put, takeEvery, call, all } from 'redux-saga/effects';
/** Import actions */
import {
    getDefinitions,
    getDefinitionsFailed,
    getDefinitionsSucceed,
    postDefinitionFailed,
    postDefinitionSucceed,
    getDefinitionDetailFailed,
    getDefinitionDetailSucceed,
    updateDefinitionFailed,
    updateDefinitionSucceed,
    deleteDefinitionFailed,
    deleteDefinitionSucceed,
    getDefinitionAutoFailed,
    getDefinitionAutoSucceed
} from './definitionActions';

/** Import api */
import * as definitionApi from './definitionApi';

export function* definitionSubscriber() {
    yield all([takeEvery('GET_DEFINITIONS', getDefinitionSaga)]);
    yield all([takeEvery('POST_DEFINITION', postDefinitionSaga)]);
    yield all([takeEvery('GET_DEFINITION_DETAIL', getDefinitionDetailSaga)]);
    yield all([takeEvery('UPDATE_DEFINITION', updateDefinitionSaga)]);
    yield all([takeEvery('DELETE_DEFINITION', deleteDefinitionSaga)]);
    yield all([takeEvery('GET_DEFINITION_AUTO', getDefinitionAutoSaga)]);

}
export function* getDefinitionSaga() {
    try {
        const definitions = yield call(definitionApi.get);
        yield put(getDefinitionsSucceed(definitions));
    } catch (error) {
        console.log('definition saga error')
        yield put(getDefinitionsFailed(error));
    }
}
export function* getDefinitionAutoSaga({ payload: { query_string } }) {
    try {
        const definitions = yield call(definitionApi.getDefinitionAutoApi, query_string);
        yield put(getDefinitionAutoSucceed(definitions));
    } catch (error) {
        yield put(getDefinitionAutoFailed(error));
    }
}
export function* postDefinitionSaga({ payload: { definition, owner_ontoclass_id } }) {
    try {
        const definitions = yield call(definitionApi.definitionpost, definition, owner_ontoclass_id);
        yield put(postDefinitionSucceed(definitions));
        yield put(getDefinitions());
    } catch (error) {
        console.log("error--", error)
        yield put(postDefinitionFailed(error));
    }
}
export function* getDefinitionDetailSaga({ payload: { definition_id } }) {
    try {
        const definition = yield call(definitionApi.getDefinitionDetailApi, definition_id);
        yield put(getDefinitionDetailSucceed(definition));
    } catch (error) {
        console.log('definitionDetail saga error')
        yield put(getDefinitionDetailFailed(error));
    }
}
export function* updateDefinitionSaga({ payload: { definition_id, definition, owner_ontoclass_id } }) {
    try {
        const definitions = yield call(definitionApi.updateDefinitionApi, definition_id, definition, owner_ontoclass_id);
        yield put(updateDefinitionSucceed(definitions));
    } catch (error) {
        console.log("error--", error)
        yield put(updateDefinitionFailed(error));
    }
}
export function* deleteDefinitionSaga({ payload: { definition_id } }) {
    try {
        yield call(definitionApi.deleteDefinitionApi, definition_id);
        yield put(deleteDefinitionSucceed());
    } catch (error) {
        console.log("error--", error)
        yield put(deleteDefinitionFailed(error));
    }
}
