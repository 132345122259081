import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../services/auth/authActions';
import { getDashboard } from '../../services/dashboard/dashboardActions';

import { bindActionCreators } from 'redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

class Login extends React.Component {
    constructor(props) {
        super();
        this.state = {
            username: '',
            password: '',
            submitted: false,
        }
    }
    componentDidMount() {
        this.props.dashboardActions.getDashboard();
    }
    render() {
        var authResult = this.props.auth.error;
        var token = localStorage.getItem('token');
        if (token && this.props.auth.error === null) {
            return <Redirect to="/dashboard" />
        }
        return (
            <Formik
                initialValues={{
                    username: '',
                    password: '',
                }}
                validationSchema={Yup.object().shape({
                    username: Yup.string()
                        .required('UserName is required'),
                    password: Yup.string()
                        .required('Password is required'),
                })}
                onSubmit={fields => {
                    this.setState({ submitted: true })
                    this.setState({ username: fields.username });
                    this.setState({ password: fields.password });
                    this.props.authActions.login(this.state.username, this.state.password)
                }}
                render={({ errors, status, touched, setFieldValue }) => (
                    <div className="login-page">
                        <div className="login-box">
                            <div className="login-logo">
                                <NavLink to="/"><img src="images/logo_sidoc.png" alt="logo_sidoc" /></NavLink>
                            </div>
                            <div className="login-box-body">
                                <p className="login-box-msg">Reserved Area</p>
                                <Form>
                                    <div className="form-group has-feedback">
                                        <Field name="username" type="text" className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')} placeholder="Username" />
                                        <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                                        <ErrorMessage name="username" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group has-feedback">
                                        <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} placeholder="Password" />
                                        <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                    </div>
                                    {
                                        authResult && (authResult.error === "Error") && this.state.submitted ?
                                            <div className="invalid-feedback marginbottom">
                                                {authResult.message}
                                            </div> : <></>
                                    }
                                    <div className="row">
                                        <div className="col-md-12 col-xs-12">
                                            <button type="submit" id="login" className="btn btn-default btn-block btn-flat"
                                            >LOGIN</button>
                                        </div>
                                        <div class="col-xs-12" style={{"text-align": "center","padding-top": "20px"}}>
											{/* <img src="images/tclf.png" style={{"width":"140px"}}/> */}
                                        </div>
                                    </div>
                                </Form>
                            </div>
							 <div class="col-xs-12 col-md-12" style={{"text-align": "center", "margin-top":"30px"}}>
								<a href="http://95.110.255.212:3101" target="_blank"><img src="/images/seriousgame.png" style={{"height":"100px", "padding":"10px"}}/></a>
								<a href="http://95.110.255.212:7001" target="_blank"><img src="/images/esco.png" style={{"height":"70px", "padding":"10px"}}/></a>
							</div>
                        </div>
                    </div>
                )}
            />
        )
    }
}

export default connect(
    state => ({
        auth: state.auth,
        dashboard: state.dashboard
    }),
    dispatch => ({
        authActions: bindActionCreators({ login }, dispatch),
        dashboardActions: bindActionCreators({ getDashboard }, dispatch)
    })
)(Login);
