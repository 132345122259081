import React from 'react';
import { connect } from 'react-redux';
import { setPathname } from '../../services/common/commonActions';
import { getLemmas, postLemma } from '../../services/lemma/lemmaActions';
import { getOntoclasses } from '../../services/ontoclass/ontoclassActions';
import { showModal } from '../../modals/modalConductorActions';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
// import 'datatables.net'
// import 'datatables.net-bs'
// import 'datatables.net-buttons-dt'
// import 'datatables.net-dt'
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
class Lemma extends React.Component {
    constructor(props) {
        super();
        this.state = {
            filter_lemma: '',
            filter_count_concepts_from: '',
            filter_count_concepts_to: '',
            filter_owner_ontoclasse: '',
            filter_auth: ''
        }
        this.lemmaTable = null;
        $.fn.dataTableExt.oSort["customdate-desc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();
            if (x > y) {
                return -1;
            }

            return 1;

        };

        $.fn.dataTableExt.oSort["customdate-asc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();

            if (x > y) {
                return 1;
            }
            return -1;
        }
    }
    componentWillMount() {
        this.props.commonActions.setPathname(
            [
                { "pathName": "Home", "Url": "", "id": 1, "color": "" },
                { "pathName": "Lemmas", "Url": "lemmas", "id": 2, "color": "#1c7ea5", "subHeader":"Lemmas" }
            ]
        )
        this.props.lemmaActions.getLemmas();
        this.props.ontoclassActions.getOntoclasses();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.lemmas && this.props.lemmas.lemmas !== prevProps.lemmas.lemmas && this.lemmaTable !== null) {
            this.lemmaTable.clear();
            if (this.props.lemmas.lemmas) {
                this.lemmaTable.rows.add(this.props.lemmas.lemmas);
            }
            this.lemmaTable.draw();
        }
        if (this.props.lemmas.error !== prevProps.lemmas.error && this.props.lemmas.error !== null) {
            toastr.error('Error Occured', this.props.lemmas.message);
        }

        if (this.props.lemmas.error === null && !this.props.lemmas.loading && prevProps.lemmas && prevProps.lemmas.loading && this.props.lemmas.message) {
            toastr.success('Success', this.props.lemmas.message);
            if (this.props.lemmas && this.props.lemmas.sel_lemma && this.props.lemmas.sel_lemma.lemma) {
                this.props.history.push(`/lemma/${this.props.lemmas.sel_lemma.lemma.lemma_id}`)
            }
        }
    }
    componentDidMount() {
        var _that = this;
        this.lemmaTable = $('#list_table').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'scrollX': true,
            'createdRow': function (row, data, dataIndex) {
                $(row).on('click', function () {
                    if (data && data.lemma_id) {
                        _that.goLemma(data.lemma_id);
                    }
                })
            },
            'columns': [
                {
                    'data': 'lemma',
                },
                {
                    'data': 'concepts',
                    'render': function (data, type, full, meta) {
                        if (data) {
                            return data.length
                        } else {
                            return null
                        }
                    }
                },
                {
                    'data': 'owner_ontoclass'
                },
                {
                    'data': 'updatedAt'
                },
                {
                    'data': 'auth'
                }
            ],
            columnDefs: [{
                'targets': 3, //index of column
                'type': 'customdate'
            }],
        });
        this.lemmaTable.clear();
        if (this.props.lemmas.lemmas) {
            this.lemmaTable.rows.add(this.props.lemmas.lemmas);
        }
        this.lemmaTable.draw();
    }

    goLemma = (lemmaId) => {
        this.props.history.push(`lemma/${lemmaId}`);
    }
    onFilterChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSearch = (e) => {
        var filterData = [];
        if (this.props.lemmas && this.props.lemmas.lemmas && this.props.lemmas.lemmas.length > 0) {
            var lemmas = this.props.lemmas.lemmas;
            for (var i = 0; i < lemmas.length; i++) {
                var fileterRes = true;
                if (this.state.filter_lemma !== '') {
                    if (lemmas[i].lemma.toLowerCase().indexOf(this.state.filter_lemma.toLowerCase()) === -1) fileterRes = false;
                }
                if (this.state.filter_owner_ontoclasse) {
                    if (lemmas[i].owner_ontoclass.toLowerCase().indexOf(this.state.filter_owner_ontoclasse.toLowerCase()) === -1) fileterRes = false;
                }
                if (this.state.filter_count_concepts_from) {
                    if (lemmas[i].concepts.length < this.state.filter_count_concepts_from) fileterRes = false;
                }
                if (this.state.filter_count_concepts_to) {
                    if (lemmas[i].concepts.length > this.state.filter_count_concepts_to) fileterRes = false;
                }
                if (this.state.filter_auth) {
                    if (lemmas[i].auth.toLowerCase().indexOf(this.state.filter_auth.toLowerCase()) === -1) fileterRes = false;
                }
                if (fileterRes) {
                    filterData.push(lemmas[i]);
                }
            }
            this.lemmaTable.clear();
            this.lemmaTable.rows.add(filterData);
            this.lemmaTable.draw();
        }
    }
    handleReset = (e) => {
        this.setState({
            filter_lemma: '',
            filter_count_concepts_from: '',
            filter_count_concepts_to: '',
            filter_owner_ontoclasse: '',
            filter_auth: ''
        });
        this.lemmaTable.clear();
        if (this.props.lemmas.lemmas) {
            this.lemmaTable.rows.add(this.props.lemmas.lemmas);
        }
        this.lemmaTable.draw();
    }
    render() {
        if (this.props.lemmas.loading) {
            Swal.fire({
                title: 'Loading...',
                text: "Plese wait",
                onOpen: () => {
                    Swal.showLoading();
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
        } else {
            Swal.close();
        }
        return (
            <React.Fragment>
                <section className="content skin-lemmi">
                    <div className="top_comandi">
                        <button type="button" onClick={() => {
                            this.props.modalActions.showModal('ADD_LEMMA_MODAL')
                        }} id="add" className="btn btn-default">
                            <span className="glyphicon glyphicon-plus-sign"></span><Trans id="Button.InsertNewLemma" />
                        </button>
                    </div>
                    <div className="ricerca">
                        <div className="_form-inline">
                            <div className="form-group row">
                                <div className="col-md-4">
                                    <label><Trans id="Table.Filterby"></Trans>:</label>
                                    <div className="input-group">
                                        <span className="input-group-addon"><Trans id="Table.Lemma"></Trans></span>
                                        <input type="text" name="filter_lemma" className="form-control" value={this.state.filter_lemma} onChange={this.onFilterChange} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label><Trans id="Table.ConceptsContained"></Trans>:</label>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="input-group">
                                                <span className="input-group-addon"><Trans id="Table.From"></Trans></span>
                                                <input type="number" size="2" name="filter_count_concepts_from" value={this.state.filter_count_concepts_from} min="0" className="form-control" onChange={this.onFilterChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-group">
                                                <span className="input-group-addon"><Trans id="Table.To"></Trans></span>
                                                <input type="number" size="2" name="filter_count_concepts_to" value={this.state.filter_count_concepts_to} min="0" className="form-control" onChange={this.onFilterChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label><Trans id="Table.ReferenceOntoclass"></Trans>:</label>
                                    <input type="text" name="filter_owner_ontoclasse" className="form-control" value={this.state.filter_owner_ontoclasse} onChange={this.onFilterChange} />
                                </div>
                                <div className="col-md-4">
                                    <label><Trans id="Table.User"></Trans>:</label>
                                    <input type="text" name="filter_auth" className="form-control" value={this.state.filter_auth} onChange={this.onFilterChange} />
                                </div>
                                <div className="col-md-2">
                                    <label>&nbsp;</label>
                                    <button type="button" className="btn btn-default form-control" onClick={() => this.handleSearch()}><Trans id="Button.SearchFor"></Trans></button>
                                </div>
                                <div className="col-md-2">
                                    <label>&nbsp;</label>
                                    <button type="button" className="btn form-control" onClick={() => this.handleReset()}><Trans id="Button.Reset"></Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <table id="list_table" className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <span data-tip data-for="header1"><Trans id="Table.Lemmas" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header1" className="dynTooltip">
                                                <div><Trans id="Table.Lemmas" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header2"><Trans id="Table.ConceptsContained" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
                                                <div><Trans id="Table.ConceptsContained" /></div>
                                            </ReactTooltip></th>
                                        <th>
                                            <span data-tip data-for="header3"><Trans id="Table.ReferenceOntoclass" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header3" className="dynTooltip">
                                                <div><Trans id="Table.ReferenceOntoclass" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header4"><Trans id="Table.LastUpdate" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header4" className="dynTooltip">
                                                <div><Trans id="Table.LastUpdate" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header5"><Trans id="Table.User" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header5" className="dynTooltip">
                                                <div><Trans id="Table.User" /></div>
                                            </ReactTooltip>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="list_table_body">

                                </tbody>

                            </table>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({
        lemmas: state.lemmas
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname }, dispatch),
        lemmaActions: bindActionCreators({ getLemmas, postLemma }, dispatch),
        ontoclassActions: bindActionCreators({ getOntoclasses }, dispatch),
        modalActions: bindActionCreators({ showModal }, dispatch),
    })
)(Lemma);
