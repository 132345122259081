import { createActions } from 'redux-actions';

const {
    getEvents,
    getEventsFailed,
    getEventsSucceed,

} = createActions({
    GET_EVENTS: (id, table) => ({id, table}),
    GET_EVENTS_FAILED: error => ({ error }),
    GET_EVENTS_SUCCEED: events => ({ events }),
});

export {
    getEvents,
    getEventsFailed,
    getEventsSucceed,
};
