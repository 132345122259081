import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateOntoclass } from '../../../../services/ontoclass/ontoclassActions';
import { hideModal } from '../../../modalConductorActions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import Trans from '../../../../services/utils/Trans';

class OntoclassEditModal extends React.Component {
    constructor() {
        super();
        this.state = {
            name: "",
            description: "",
            logo: null
        }
        this.onCancel = this.onCancel.bind(this);
    }

    onCancel() {
        this.props.modalActions.hideModal();
    }

    render() {
        console.log('Props in Ontoclass Edit Modal', this.props)
        var sel_ontoclass = null;
        if (this.props.ontoclasses && this.props.ontoclasses.sel_ontoclass) {
            sel_ontoclass = this.props.ontoclasses.sel_ontoclass
        }
        console.log('sel_ontoclass in edit modal', sel_ontoclass)
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader><Trans id="Modal.EditOntoclass"></Trans></ModalHeader>
                <Formik
                    initialValues={{
                        ontoclass: sel_ontoclass ? sel_ontoclass.ontoclass : '',
                    }}
                    validationSchema={Yup.object().shape({
                        ontoclass: Yup.string()
                            .required('Ontoclass is required')
                    })}
                    onSubmit={fields => {
                        this.props.ontoclassActions.updateOntoclass(fields.ontoclass, sel_ontoclass.ontoclass_id);
                        this.props.modalActions.hideModal();
                    }}
                    render={({ errors, status, touched, setFieldValue }) => (
                        <Form className="skin-ontoclassi">
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Modal.Ontoclass"></Trans></label>
                                    <Field name="ontoclass" type="text" className={'form-control' + (errors.ontoclass && touched.ontoclass ? ' is-invalid' : '')} />
                                    <ErrorMessage name="ontoclass" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button type="submit" className="btn btn-default" >
                                    <Trans id="Button.Confirm"></Trans>
                                </button>
                                <button className="btn" onClick={this.onCancel}>
                                    <Trans id="Button.Cancel"></Trans>
                                </button>
                            </ModalFooter>
                        </Form>
                    )}
                />
            </Modal>
        );
    }
}

export default connect(
    state => ({
        ontoclasses: state.ontoclasses
    }),
    dispatch => ({
        ontoclassActions: bindActionCreators({ updateOntoclass }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(OntoclassEditModal);
