import { wrapRequest, xapi } from '../utils_services';

const get = wrapRequest(async () =>
    xapi().get('/ontoclasses')
);

const ontoclasspost = wrapRequest(async (ontoclass) => {
    return xapi().post('/ontoclasses', { ontoclass })
});
const getOntoclassDetailApi = wrapRequest(async (ontoclass_id) =>
    xapi().get('/ontoclasses/' + ontoclass_id)
);
const updateOntoclassApi = wrapRequest(async (ontoclass, ontoclass_id) => {
    return xapi().put('/ontoclasses/' + ontoclass_id, { ontoclass })
});
const deleteOntoclassApi = wrapRequest(async (ontoclass_id) => {
    console.log('form data in ontoclassApi', ontoclass_id);
    return xapi().delete('/ontoclasses/' + ontoclass_id)
});
const getOntoclassAutoApi = wrapRequest(async (query_string) => xapi().get('/ontoclasses/auto?query_string=' + query_string))
export { get, ontoclasspost, getOntoclassDetailApi, updateOntoclassApi, deleteOntoclassApi, getOntoclassAutoApi };
