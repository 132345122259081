import { createActions } from 'redux-actions';

const {
    getUsersFromApi,
    getUsersFromApiFailed,
    getUsersFromApiSucceed,
    getUserDetail,
    getUserDetailFailed,
    getUserDetailSucceed,
    postUserToApi,
    postUserToApiFailed,
    postUserToApiSucceed,
    updateUser,
    updateUserFailed,
    updateUserSucceed,
    changeUserPwd,
    changeUserPwdFailed,
    changeUserPwdSucceed,
    getUserOntoclasses,
    getUserOntoclassesFailed,
    getUserOntoclassesSucceed,
    addUserOntoclasses,
    addUserOntoclassesFailed,
    addUserOntoclassesSucceed,
    getUserProjects,
    getUserProjectsFailed,
    getUserProjectsSucceed,
    addUserProjects,
    addUserProjectsFailed,
    addUserProjectsSucceed,
    deleteUser,
    deleteUserFailed,
    deleteUserSucceed
} = createActions({
    GET_USERS_FROM_API: () => ({}),
    GET_USERS_FROM_API_FAILED: error => ({ error }),
    GET_USERS_FROM_API_SUCCEED: users => ({ users }),
    GET_USER_DETAIL: user_id => ({ user_id }),
    GET_USER_DETAIL_FAILED: error => ({ error }),
    GET_USER_DETAIL_SUCCEED: user => ({ user }),
    POST_USER_TO_API: (name, lastname, username, password, email, profile_id) => ({ name, lastname, username, password, email, profile_id }),
    POST_USER_TO_API_FAILED: error => ({ error }),
    POST_USER_TO_API_SUCCEED: user => ({ user }),
    UPDATE_USER: (user_id, name, lastname, email, username) => ({ user_id, name, lastname, email, username }),
    UPDATE_USER_FAILED: error => ({ error }),
    UPDATE_USER_SUCCEED: user => ({ user }),
    CHANGE_USER_PWD: (user_id, password) => ({ user_id, password }),
    CHANGE_USER_PWD_FAILED: error => ({ error }),
    CHANGE_USER_PWD_SUCCEED: user => ({ user }),
    GET_USER_ONTOCLASSES: () => ({}),
    GET_USER_ONTOCLASSES_FAILED: error => ({ error }),
    GET_USER_ONTOCLASSES_SUCCEED: ontoclass => ({ ontoclass }),
    ADD_USER_ONTOCLASSES: (user_id, ontoclasses) => ({ user_id, ontoclasses }),
    ADD_USER_ONTOCLASSES_FAILED: error => ({ error }),
    ADD_USER_ONTOCLASSES_SUCCEED: user => ({ user }),
    GET_USER_PROJECTS: () => ({}),
    GET_USER_PROJECTS_FAILED: error => ({ error }),
    GET_USER_PROJECTS_SUCCEED: projects => ({ projects }),
    ADD_USER_PROJECTS: (user_id, projects) => ({ user_id, projects }),
    ADD_USER_PROJECTS_FAILED: error => ({ error }),
    ADD_USER_PROJECTS_SUCCEED: user => ({ user }),
    DELETE_USER: user_id => ({ user_id }),
    DELETE_USER_FAILED: error => ({ error}),
    DELETE_USER_SUCCEED: ()=>({})
});

export {
    getUsersFromApi,
    getUsersFromApiFailed,
    getUsersFromApiSucceed,
    getUserDetail,
    getUserDetailFailed,
    getUserDetailSucceed,
    postUserToApi,
    postUserToApiFailed,
    postUserToApiSucceed,
    updateUser,
    updateUserFailed,
    updateUserSucceed,
    changeUserPwd,
    changeUserPwdFailed,
    changeUserPwdSucceed,
    getUserOntoclasses,
    getUserOntoclassesFailed,
    getUserOntoclassesSucceed,
    addUserOntoclasses,
    addUserOntoclassesFailed,
    addUserOntoclassesSucceed,
    getUserProjects,
    getUserProjectsFailed,
    getUserProjectsSucceed,
    addUserProjects,
    addUserProjectsFailed,
    addUserProjectsSucceed,
    deleteUser,
    deleteUserFailed,
    deleteUserSucceed
};
