import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteOntoclass } from '../../../../services/ontoclass/ontoclassActions';
import { hideModal } from '../../../modalConductorActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Trans from '../../../../services/utils/Trans';

class OntoclassDeleteModal extends React.Component {
    constructor() {
        super();
        this.onCancel = this.onCancel.bind(this);
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }

    render() {
        console.log('Props in Ontoclass Delete Modal', this.props)
        var sel_ontoclass = null;
        if (this.props.ontoclasses && this.props.ontoclasses.sel_ontoclass) {
            sel_ontoclass = this.props.ontoclasses.sel_ontoclass
        }
        console.log('sel_ontoclass in Delete modal', sel_ontoclass)
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader><Trans id="Modal.DeleteOntoclass"></Trans></ModalHeader>
                <div className="skin-ontoclassi">
                    <ModalBody>
                        <h2 className="ontoclass-color">
                            <Trans id="Message.DeleteConfirm"></Trans>
                        </h2>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-default" onClick={() => {this.props.ontoclassActions.deleteOntoclass(sel_ontoclass.ontoclass_id);
                        this.props.modalActions.hideModal()}} >
                            <Trans id="Button.Confirm"></Trans>
                        </button>
                        <button className="btn" onClick={this.onCancel}>
                            <Trans id="Button.Cancel"></Trans>
                        </button>
                    </ModalFooter>
                </div>

            </Modal>
        );
    }
}

export default connect(
    state => ({
        ontoclasses: state.ontoclasses
    }), dispatch => ({
        ontoclassActions: bindActionCreators({ deleteOntoclass }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(OntoclassDeleteModal);
