import { handleActions } from 'redux-actions';

import {
    getIndicatorApplied,
    getIndicatorAppliedFailed,
    getIndicatorAppliedSucceed,
    postIndicatorApplied,
    postIndicatorAppliedFailed,
    postIndicatorAppliedSucceed,
    getIndicatorAppliedDetail,
    getIndicatorAppliedDetailFailed,
    getIndicatorAppliedDetailSucceed,
    updateIndicatorApplied,
    updateIndicatorAppliedFailed,
    updateIndicatorAppliedSucceed,
    deleteIndicatorApplied,
    deleteIndicatorAppliedFailed,
    deleteIndicatorAppliedSucceed,
    postIndicatorAppliedTime,
    postIndicatorAppliedTimeFailed,
    postIndicatorAppliedTimeSucceed,
    updateIndicatorAppliedTime,
    updateIndicatorAppliedTimeFailed,
    updateIndicatorAppliedTimeSucceed,
    deleteIndicatorAppliedTime,
    deleteIndicatorAppliedTimeFailed,
    deleteIndicatorAppliedTimeSucceed

} from './indicator_appliedActions';

const defaultState = {
    indicators_applied: null,
    error: null,
    loading: false,
    success: false,
    message: null,
    sel_indicator_applied: null,
    applied_time: false
};

const reducer = handleActions(
    {
        [getIndicatorApplied](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null,
                applied_time: false
            };
        },
        [getIndicatorAppliedFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error.data,
                indicators_applied: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [getIndicatorAppliedSucceed](
            state,
            {
                payload: { indicators_applied }
            }
        ) {
            return {
                ...state,
                indicators_applied: indicators_applied,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
        [postIndicatorApplied](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null,
                success: false,
                applied_time: false
            };
        },
        [postIndicatorAppliedFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in postFailed', error)
            return {
                ...state,
                error,
                indicators_applied: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [postIndicatorAppliedSucceed](
            state,
            {
                payload: { indicators_applied }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                message: "Add IndicatorApplied Succeed!",
                sel_indicator_applied: indicators_applied,
                success: true
            };
        },
        [getIndicatorAppliedDetail](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null,
                applied_time: false
            };
        },
        [getIndicatorAppliedDetailFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in getIndicatorAppliedDetail Failed', error)
            return {
                ...state,
                error: error,
                indicators_applied: null,
                loading: false,
                success: false,
                sel_indicator_applied: null,
                message: error.data.message
            };
        },
        [getIndicatorAppliedDetailSucceed](
            state,
            {
                payload: { indicator_applied }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                success: true,
                message: null,
                sel_indicator_applied: indicator_applied
            };
        },
        [updateIndicatorApplied](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null,
                applied_time: false
            };
        },
        [updateIndicatorAppliedFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in updateIndicatorApplied', error)
            return {
                ...state,
                error,
                indicators_applied: null,
                loading: false,
                success: false,
                message: error && error.message ? error.message : error
            };
        },
        [updateIndicatorAppliedSucceed](
            state,
            {
                payload: { indicators_applied }
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                sel_indicator_applied: indicators_applied,
                message: "Update IndicatorApplied Succeed!",
            };
        },
        [deleteIndicatorApplied](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null
            };
        },
        [deleteIndicatorAppliedFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in deleteIndicatorApplied', error)
            return {
                ...state,
                error,
                indicators_applied: null,
                loading: false,
                success: false,
                message: error && error.message ? error.message : error
            };
        },
        [deleteIndicatorAppliedSucceed](
            state,
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                sel_indicator_applied: null,
                success: true,
                message: "Delete IndicatorApplied Succeed!"
            };
        },
        [postIndicatorAppliedTime](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null,
                success: false,
                applied_time: true
            };
        },
        [postIndicatorAppliedTimeFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in postFailedTime', error)
            return {
                ...state,
                error,
                indicators_applied: null,
                loading: false,
                success: false,
                message: error.data.message,
                applied_time: true,
            };
        },
        [postIndicatorAppliedTimeSucceed](
            state,
            {
                payload: { indicators_applied }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                message: "Add IndicatorAppliedTime Succeed!",
                sel_indicator_applied: indicators_applied,
                success: true,
                applied_time: true
            };
        },
        [updateIndicatorAppliedTime](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null,
                applied_time: true
            };
        },
        [updateIndicatorAppliedTimeFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in updateIndicatorAppliedTime', error)
            return {
                ...state,
                error,
                indicators_applied: null,
                loading: false,
                success: false,
                message: error && error.message ? error.message : error,
                applied_time: true
            };
        },
        [updateIndicatorAppliedTimeSucceed](
            state,
            {
                payload: { indicators_applied }
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                sel_indicator_applied: indicators_applied,
                message: "Update IndicatorApplied Succeed!",
                applied_time: true
            };
        },
        [deleteIndicatorAppliedTime](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null,
                applied_time: true
            };
        },
        [deleteIndicatorAppliedTimeFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in deleteIndicatorAppliedTime', error)
            return {
                ...state,
                error,
                indicators_applied: null,
                loading: false,
                success: false,
                message: error && error.data ? error.data.message : error
            };
        },
        [deleteIndicatorAppliedTimeSucceed](state) {
            return {
                ...state,
                error: null,
                loading: false,
                sel_indicator_applied: null,
                success: true,
                message: "Delete IndicatorAppliedTime Succeed!"
            };
        },
    },
    defaultState
);

export default reducer;
