import { handleActions } from 'redux-actions';

import {
    getConcepts,
    getConceptsFailed,
    getConceptsSucceed,
    postConcept,
    postConceptFailed,
    postConceptSucceed,
    getConceptDetail,
    getConceptDetailFailed,
    getConceptDetailSucceed,
    updateConcept,
    updateConceptFailed,
    updateConceptSucceed,
    deleteConcept,
    deleteConceptFailed,
    deleteConceptSucceed,
    getConceptAuto,
    getConceptAutoFailed,
    getConceptAutoSucceed
} from './conceptActions';

const defaultState = {
    concepts: null,
    error: null,
    loading: false,
    success: false,
    message: null,
    sel_concept: null,
    list_transition: null,
};

const reducer = handleActions(
    {
        [getConcepts](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null,
                concepts: null,
            };
        },
        [getConceptsFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error,
                concepts: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [getConceptsSucceed](
            state,
            {
                payload: { concepts }
            }
        ) {
            return {
                ...state,
                concepts: concepts,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
        [postConcept](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null
            };
        },
        [postConceptFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in postConcept', error)
            return {
                ...state,
                error,
                loading: false,
                success: false,
                message: error.data.message,
            };
        },
        [postConceptSucceed](
            state,
            {
                payload: {concept}
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                message: "Add Concept Succeed!",
                sel_concept: concept.concept
            };
        },
        [getConceptDetail](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null,
                sel_concept: null,
            };
        },
        [getConceptDetailFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error,
                sel_concept: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [getConceptDetailSucceed](
            state,
            {
                payload: { concepts }
            }
        ) {
            return {
                ...state,
                sel_concept: concepts.concept,
                error: null,
                loading: false,
                success: true,
                message: null,
                list_transition: concepts.list_transition
            };
        },
        [updateConcept](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null
            };
        },
        [updateConceptFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error,
                sel_concept: null,
                loading: false,
                success: false,
                message: error.data.error
            };
        },
        [updateConceptSucceed](
            state,
            {
                payload: { concept }
            }
        ) {
            return {
                ...state,
                sel_concept: concept.concept,
                error: null,
                loading: false,
                success: true,
                message: "Update Concept Succeed"
            };
        },
        [deleteConcept](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null
            };
        },
        [deleteConceptFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [deleteConceptSucceed](state) {
            return {
                ...state,
                sel_concept: null,
                error: null,
                loading: false,
                success: true,
                message: "Delete Concept Succeed"
            };
        },
        [getConceptAuto](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getConceptAutoFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error ? error : 'Error',
                loading: false,
                success: false,
                message: error ? error.message : 'API connection error'
            };
        },
        [getConceptAutoSucceed](
            state,
            {
                payload: { concepts }
            }
        ) {
            return {
                ...state,
                concepts: concepts,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
    },
    defaultState
);

export default reducer;
