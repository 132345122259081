import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postUtility } from '../../../../services/utilities/utilityActions';
import { hideModal } from '../../../modalConductorActions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import Trans from '../../../../services/utils/Trans';

class UtilityAddModal extends React.Component {
    constructor() {
        super();
        this.onCancel = this.onCancel.bind(this);
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }
    componentDidMount() {
        console.log('Props in Utility add modal', this.props)
    }

    render() {
        if (this.props && this.props.modal && this.props.modal.params) {
            var header = this.props.modal.params
        }
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader>
                    {
                        header && header === 'time' ? <Trans id="Modal.TimeRefer"></Trans> : <></>
                    }
                    {
                        header && header === 'legisl' ? <Trans id="Modal.LegislRefer"></Trans> : <></>
                    }
                    {
                        header && header === 'measure' ? <Trans id="Modal.MeasureRefer"></Trans> : <></>
                    }
                    {
                        header && header === 'type_relation' ? <Trans id="Modal.TypeRelationRefer"></Trans> : <></>
                    }
                </ModalHeader>
                <Formik
                    initialValues={{
                        label: '',
                    }}
                    validationSchema={Yup.object().shape({
                        label: Yup.string()
                            .required('Label is required'),
                    })}
                    onSubmit={fields => {
                        this.props.utilityActions.postUtility(this.props.modal.params, fields.label, null);
                        this.props.modalActions.hideModal();
                    }}>
                    {({ errors, status, touched, setFieldValue }) => (
                        <Form className="skin-utility">
                            <ModalBody>
                                <div className="form-group">
                                    <Field name="label" type="text" className={'form-control' + (errors.label && touched.label ? ' is-invalid' : '')} />
                                    <ErrorMessage name="label" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button type="submit" className="btn btn-default" >
                                    <Trans id="Button.Confirm"></Trans>
                                </button>
                                <button className="btn" onClick={this.onCancel}>
                                    <Trans id="Button.Cancel"></Trans>
                                </button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>

            </Modal>
        );
    }
}

export default connect(
    state => ({
        modal: state.modal,
        auth: state.auth
    }), dispatch => ({
        utilityActions: bindActionCreators({ postUtility }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(UtilityAddModal);
