import React from 'react';
import { connect } from 'react-redux';
import { setPathname, addPathname, deletePathname } from '../../services/common/commonActions';
import { getDefinitionDetail } from '../../services/definition/definitionActions';
import { getEvents } from '../../services/event_entity/eventActions';
import { showModal } from '../../modals/modalConductorActions';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import { capitalize } from '../../services/utils_services';
import 'datatables.net'
import 'datatables.net-bs'
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
class DefinitionDetail extends React.Component {
    constructor() {
        super();
        this.definitionTable = null;
        $.fn.dataTableExt.oSort["customdate-desc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();
            if (x > y) {
                return -1;
            }

            return 1;

        };

        $.fn.dataTableExt.oSort["customdate-asc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();

            if (x > y) {
                return 1;
            }
            return -1;
        }
    }
    componentWillMount() {
        // this.props.commonActions.setPathname(
        //     [
        //         { "pathName": "Home", "Url": "", "id": 1, "color": "" },
        //         { "pathName": "Definitions", "Url": "definitions", "id": 2, "color": "#51abae" },
        //         { "pathName": "Definition", "Url": "/", "id": 3, "color": "#51abae" }
        //     ]
        // )
        this.props.definitionActions.getDefinitionDetail(this.props.match.params.id);
        this.props.eventActions.getEvents(this.props.match.params.id, 'definition')

    }
    componentDidUpdate(prevProps, prevState) {

        if (this.props.definitions && this.props.definitions.sel_definition !== prevProps.definitions.sel_definition && this.props.definitions.sel_definition && this.props.definitions.sel_definition.definition) {
            var definition = this.props.definitions.sel_definition.definition.definition;
            this.props.commonActions.addPathname(
                { "pathName": definition, "Url": "definition/" + this.props.match.params.id, "color": "#6d81ab", "subHeader": "Definition Detail" }
            )
        }
        if (this.props.definitions && this.props.definitions.sel_definition !== prevProps.definitions.sel_definition && this.definitionTable !== null) {
            var temp = this.props.definitions.sel_definition;

            this.definitionTable.clear();
            if (temp && temp.definition && temp.definition.concepts) {
                this.definitionTable.rows.add(this.props.definitions.sel_definition.definition.concepts);
            }
            this.definitionTable.draw();
        }
        if (this.props.definitions.error !== prevProps.definitions.error && this.props.definitions.error !== null) {
            toastr.error('Error Occured', this.props.definitions.message);
        }

        if (this.props.definitions.error === null && !this.props.definitions.loading && prevProps.definitions && prevProps.definitions.loading && this.props.definitions.message) {
            toastr.success('Success', this.props.definitions.message);
            if (this.props.definitions.sel_definition === null) {
                this.props.history.push('/definitions');
            }
        }
    }
    componentDidMount() {
        var _that = this;
        this.definitionTable = $('#list_table').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'createdRow': function (row, data, dataIndex) {
                $(row).on('click', function () {
                    if (data && data.concept_id) {
                        _that.goConcept(data.concept_id);
                    }
                })
            },
            'columns': [
                {
                    'data': 'lemma'
                },
                {
                    'data': 'definition',
                },
                {
                    'data': 'sources',
                    'render': function (data, type, full, meta) {
                        var result = "";
                        if (data) {
                            for (var i = 0; i < data.length; i++) {
                                result += data[i].source;
                                if (i < data.length - 1) {
                                    result += '; ';
                                }
                            }
                        }
                        return result;
                    }
                },
                {
                    'data': 'updatedAt',
                },
                {
                    'data': 'auth',
                }
            ],
            columnDefs: [{
                'targets': 3, //index of column
                'type': 'customdate'
            }],
        });
        this.definitionTable.clear();
        if (this.props.definitions && this.props.definitions.sel_definition) {
            var list_concepts = this.props.definitions.sel_definition.definition.concepts || [];
            // this.definitionTable.rows.add(this.props.definitions.sel_definition.definition.concepts);
            this.definitionTable.rows.add(list_concepts);
        }
        this.definitionTable.draw();
    }
    goConcept = (conceptId) => {
        this.props.history.push(`/concept/${conceptId}`);
    }
    render() {
        var list_transition = [];
        var definition = {};
        var sel_definition = {};
        if (this.props.definitions) {
            if (this.props.definitions.loading) {
                Swal.fire({
                    title: 'Loading...',
                    text: "Plese wait",
                    onOpen: () => {
                        Swal.showLoading();
                    },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            } else {
                Swal.close();
            }
            if (this.props.definitions.sel_definition) {
                var sel_definition = this.props.definitions.sel_definition;
                definition = sel_definition.definition;
                list_transition = sel_definition.list_transition;
            }
        }
        return (
            <React.Fragment>
                <section className="content skin-definizioni">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sommario">
                                <label><Trans id="Content.CreationDate"></Trans>:</label><span id="createdAt">{definition ? definition.createdAt : ''}</span>
                                <label><Trans id="Content.LastUpdate"></Trans>:</label><span id="updatedAt">{definition ? definition.updateAt : ''}</span>
                                <label><Trans id="Content.User"></Trans>:</label><span id="auth">{definition ? definition.auth : ''}</span>
                                <label><Trans id="Content.State"></Trans>:</label> <span className="label label-success" id="state" >{definition ? capitalize(definition.state) : ''}</span>
                                <label><Trans id="Modal.OntoclassRefer"></Trans>:</label><span id="owner_ontoclass">{definition ? definition.owner_ontoclass : ''}</span>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <div className="box box-noborder">
                                <div className="box-body">
                                    <p className="text-label text-center"><Trans id="Content.Definition"></Trans></p>
                                    <h3 className="nome_main text-center" id="lemma">{definition ? definition.definition : ''}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="comandi">
                        <div>
                            {
                                list_transition && list_transition.indexOf('update') > -1 ? <button id="update" type="button" className="btn btn-default" onClick={() => {
                                    this.props.modalActions.showModal('EDIT_DEFINITION_MODAL');
                                }}><Trans id="Button.Modification"></Trans>
                                </button> : <></>
                            }
                            {
                                list_transition && list_transition.indexOf('deletion') > -1 ?
                                    <button id="delete" className="btn" onClick={() => {
                                        this.props.modalActions.showModal('DELETE_DEFINITION_MODAL');
                                    }}><b><Trans id="Button.Delete"></Trans></b></button> : <></>
                            }
                            <button id="back" className="btn" onClick={() => { this.props.history.goBack();this.props.commonActions.deletePathname() }}><b><Trans id="Button.Back"></Trans></b></button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <table id="list_table" className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <span data-tip data-for="header1"><Trans id="Table.Lemma" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header1" className="dynTooltip">
                                                <div><Trans id="Table.Lemma" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header2"><Trans id="Table.Definition" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
                                                <div><Trans id="Table.Definition" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header3"><Trans id="Table.Sources" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header3" className="dynTooltip">
                                                <div><Trans id="Table.Sources" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header7"><Trans id="Table.LastUpdate" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header7" className="dynTooltip">
                                                <div><Trans id="Table.LastUpdate" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header8"><Trans id="Table.User" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header8" className="dynTooltip">
                                                <div><Trans id="Table.User" /></div>
                                            </ReactTooltip>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="list_table_body">

                                </tbody>

                            </table>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({
        definitions: state.definitions,
        events: state.events
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname, addPathname, deletePathname }, dispatch),
        definitionActions: bindActionCreators({ getDefinitionDetail }, dispatch),
        eventActions: bindActionCreators({ getEvents }, dispatch),
        modalActions: bindActionCreators({ showModal }, dispatch),
    })
)(DefinitionDetail);
