import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postUserToApi } from '../../../../services/users/usersAction';
import { hideModal } from '../../../modalConductorActions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import Trans from '../../../../services/utils/Trans';

class UserAddModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            lastname: "",
            email: "",
            username: "",
            password: "",
            role: null
        }
        this.change = this.change.bind(this);
        this.selectFile = this.selectFile.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    change(e) {
        console.log("iput changed")
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    selectFile(e) {
        // e.preventDefault();
        const file = e.target.files[0];
        this.setState({
            [e.target.name]: file
        })
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }

    render() {
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader><Trans id="Modal.NewUser"></Trans></ModalHeader>
                <Formik
                    initialValues={{
                        name: "",
                        lastname: "",
                        email: "",
                        username: "",
                        password: "",
                        role: null
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required('Name is required'),
                        lastname: Yup.string()
                            .required('Lastname is required'),
                        email: Yup.string().email('Invalid email')
                            .required('Email is required'),
                        username: Yup.string().min(8, 'Should be more than 8 characters')
                            .required('Username is required'),
                        password: Yup.string()
                            .required('Password is required'),
                        role: Yup.number().nullable().min(1, 'Role is required')
                            .required('Role is required'),
                    })}
                    onSubmit={fields => {
                        const name = fields.name;
                        const lastname = fields.lastname;
                        const username = fields.username;
                        const email = fields.email;
                        const password = fields.password;
                        const profile_id = fields.role;
                        this.props.usersActions.postUserToApi(name, lastname, username, password, email, profile_id);
                        this.props.modalActions.hideModal();
                    }}
                    render={({ errors, status, touched, setFieldValue }) => (
                        <Form className="skin-utenti">
                            <ModalBody>
                                <label className="text-muted text-center"><Trans id="Modal.Name"></Trans></label>
                                <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} placeholder="Nome" />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </ModalBody>
                            <ModalBody>
                                <label className="text-muted text-center"><Trans id="Modal.LastName"></Trans></label>
                                <Field name="lastname" type="text" className={'form-control' + (errors.lastname && touched.lastname ? ' is-invalid' : '')} placeholder="Cognome" />
                                <ErrorMessage name="lastname" component="div" className="invalid-feedback" />
                            </ModalBody>
                            <ModalBody>
                                <label className="text-muted text-center"><Trans id="Modal.Email"></Trans></label>
                                <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                <ErrorMessage name="email" component="div" className="invalid-feedback" />
                            </ModalBody>
                            <ModalBody>
                                <label className="text-muted text-center"><Trans id="Modal.Username"></Trans></label>
                                <Field name="username" type="text" className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')} />
                                <ErrorMessage name="username" component="div" className="invalid-feedback" />
                            </ModalBody>
                            <ModalBody>
                                <label className="text-muted text-center"><Trans id="Modal.Password"></Trans></label>
                                <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                            </ModalBody>
                            <ModalBody>
                                <label className="text-muted text-center"><Trans id="Modal.Role"></Trans></label>
                                <select name="role" id="profilo_id" className={'form-control' + (errors.role && touched.role ? ' is-invalid' : '')} onChange={(event) => {
                                    setFieldValue("role", event.currentTarget.value);
                                    console.log("selected value", event.currentTarget.value)
                                }}>
                                    <option value="0" lang="it">Seleziona</option>
                                    <option value="1" lang="it">Administrator</option>
                                    <option value="2" lang="it">Publisher</option>
                                    <option value="3" lang="it">Guest</option>
                                </select>
                                <ErrorMessage name="role" component="div" className="invalid-feedback" />
                            </ModalBody>
                            <ModalFooter>
                                <button type="submit" className="btn btn-default" >
                                    <Trans id="Button.Confirm"></Trans>
                                </button>
                                <button className="btn" onClick={this.onCancel}>
                                    <Trans id="Button.Cancel"></Trans>
                                </button>
                            </ModalFooter>
                        </Form>
                    )}
                />
            </Modal>
        );
    }
}

export default connect(
    null,
    dispatch => ({
        usersActions: bindActionCreators({ postUserToApi }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(UserAddModal);
