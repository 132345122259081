import { handleActions } from 'redux-actions';

import {
    getRequests,
    getRequestsFailed,
    getRequestsSucceed,
    postRequest,
    postRequestFailed,
    postRequestSucceed,
    getRequestDetail,
    getRequestDetailFailed,
    getRequestDetailSucceed,
    updateRequest,
    updateRequestFailed,
    updateRequestSucceed,
    deleteRequest,
    deleteRequestFailed,
    deleteRequestSucceed,
    approveRequest,
    approveRequestFailed,
    approveRequestSucceed,
    discardRequest,
    discardRequestFailed,
    discardRequestSucceed
} from './requestActions';

const defaultState = {
    requests: null,
    error: null,
    loading: false,
    success: false,
    message: null,
    sel_request: null
};

const reducer = handleActions(
    {
        [getRequests](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null,
                requests: null
            };
        },
        [getRequestsFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error,
                requests: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [getRequestsSucceed](
            state,
            {
                payload: { requests }
            }
        ) {
            return {
                ...state,
                requests: requests,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
        [postRequest](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null
            };
        },
        [postRequestFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in postFailed', error)
            return {
                ...state,
                error,
                loading: false,
                success: false,
                message: error.data.message,
            };
        },
        [postRequestSucceed](
            state,
            {
                payload: { request }
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                message: "Add Request Succeed!",
                sel_request: request
            };
        },
        [getRequestDetail](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getRequestDetailFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in reqeust get detail', error)
            return {
                ...state,
                error,
                sel_request: null,
                loading: false,
                success: false,
                message: error.data.error
            };
        },
        [getRequestDetailSucceed](
            state,
            {
                payload: { requests }
            }
        ) {
            return {
                ...state,
                sel_request: requests,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
        [updateRequest](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null,
                sel_request: null
            };
        },
        [updateRequestFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error,
                sel_request: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [updateRequestSucceed](
            state,
            {
                payload: { request }
            }
        ) {
            return {
                ...state,
                sel_request: request,
                error: null,
                loading: false,
                success: true,
                message: "Update Request Succeed"
            };
        },
        [deleteRequest](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null
            };
        },
        [deleteRequestFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [deleteRequestSucceed](state) {
            return {
                ...state,
                sel_request: null,
                error: null,
                loading: false,
                success: true,
                message: "Delete Request Succeed"
            };
        },
        [approveRequest](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null
            };
        },
        [approveRequestFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in approveRequest', error)
            return {
                ...state,
                error,
                loading: false,
                success: false,
                message: error.data.message ? error.data.message : error.data.error,
            };
        },
        [approveRequestSucceed](
            state,
            {
                payload: { request }
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                message: "Approve Request Succeed!",
            };
        },
        [discardRequest](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null
            };
        },
        [discardRequestFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in discardRequest', error)
            return {
                ...state,
                error,
                loading: false,
                success: false,
                message: error.data.message ? error.data.message : error.data.error,
            };
        },
        [discardRequestSucceed](
            state,
            {
                payload: { request }
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                message: "Discard Request Succeed!",
            };
        },
    },
    defaultState
);

export default reducer;
