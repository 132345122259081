import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux';
import Trans from '../../../services/utils/Trans'
import ReactTooltip from 'react-tooltip';
import TruncTip from '../../components/t.js';

class ContentHeader extends React.Component {
   
    render() {
        var path = this.props.common.path;
        var color = "";
        if (path && path.length >= 2) {
            color = path[1].color;
        }
        return (
            <section className="content-header">
                {
                    path && (path[path.length-1].pathName === "Dashboard") ? <h1 style={{ color: color, border: "none" }}>
                        {path ?  path[path.length - 1].pathName  : ""}
                    </h1> : <h1 style={{ color: color, borderColor: color }}>
                            {path ? path[path.length - 1].subHeader : ""}
                        </h1>
                }
                <ol className="breadcrumb">
                    <FontAwesomeIcon icon={["fas", "tachometer-alt"]} />
                    {
                        path ? path.map((paths) => {
                            if (paths.id !== path.length) {
                                return <li key={"bread_" + paths.id}>
                                    <NavLink to={"/"+paths.Url}>
                                        <TruncTip params={{str: paths.pathName, limit: 50, uuid: 'breadcrumbs'+paths.id}}/>
                                    </NavLink>
                                </li>
                            } else {
                                return <li className="active" key={"bread_" + paths.id}>
                                    <TruncTip params={{ str: paths.pathName, limit: 50, uuid: 'breadcrumbs' + paths.id }} />
                                </li>
                            }
                        }) : <></>
                    }
                </ol>
            </section>
        )
    }
}

export default connect(
    state => ({
        common: state.common
    }),
    null
)(ContentHeader);
