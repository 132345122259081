import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { discardRequest } from '../../../../services/request/requestActions';
import { hideModal } from '../../../modalConductorActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Trans from '../../../../services/utils/Trans';

class ConfirmModal extends React.Component {
    constructor() {
        super();
        this.onCancel = this.onCancel.bind(this);
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }

    render() {
        console.log('Props in Confirm Approve Modal', this.props.modal.params)
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <div className="skin-richieste">
                    <ModalHeader><Trans id="Modal.ConfirmDiscardRequest"></Trans></ModalHeader>
                    <ModalBody>
                        <h2 className="request-color">
                            <Trans id="Message.DiscardAnswer"></Trans>
                        </h2>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-default" onClick={() => {
                            if (this.props.modal.params) {
                                this.props.requestActions.discardRequest(this.props.modal.params.request_id, this.props.modal.params.answer);
                                this.onCancel();
                            }
                        }} >
                            <Trans id="Button.Confirm"></Trans>
                        </button>
                        <button className="btn" onClick={this.onCancel}>
                            <Trans id="Button.Cancel"></Trans>
                        </button>
                    </ModalFooter>
                </div>

            </Modal>
        );
    }
}

export default connect(
    state => ({
        modal: state.modal
    }),
    dispatch => ({
        requestActions: bindActionCreators({ discardRequest }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(ConfirmModal);
