import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateProjectOntoclass } from '../../../../services/project/projectActions';
import { getConcepts } from '../../../../services/concept/conceptActions';
import { getOntoclasses } from '../../../../services/ontoclass/ontoclassActions';
import { hideModal } from '../../../modalConductorActions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Trans from '../../../../services/utils/Trans';
import $ from 'jquery';
import './index.css';
class ProjectOntoclassModal extends React.Component {
	constructor() {
		super();
		this.state = {
			selectedItems: [],
			selectedConcept: [],
			owner_ontoclass_id: 0,
			concepts: [],
			ontoclasses: [],
			visible_Sel_Concepts: [],
			is_associated: false,
			// associa: 1
		}
		this.onCancel = this.onCancel.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	componentWillMount() {

		this.props.conceptActions.getConcepts();
	}
	onCancel() {
		this.props.modalActions.hideModal();
	}
	componentDidUpdate(prevProps) {
		if (this.props.ontoclasses && prevProps.ontoclasses && this.props.ontoclasses.ontoclasses !== prevProps.ontoclasses.ontoclasses) {
			if (this.props.ontoclasses && this.props.ontoclasses.ontoclasses) {
				var ontoclasses = this.props.ontoclasses.ontoclasses;
				if (ontoclasses.length > 0) {
					ontoclasses.sort(function (a, b) {
						var textA = a.ontoclass.toUpperCase();
						var textB = b.ontoclass.toUpperCase();
						return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
					});
				}
				this.setState({ ontoclasses })
			}
		}
		// if (this.props.projects && this.props.projects.sel_project) {
		// 	for (let item of this.props.projects.sel_project.ontoclasses) {
		// 		if (this.state.selectedItems.indexOf((item.ontoclass_id).toString()) < 0) {
		// 			this.state.selectedItems.push((item.ontoclass_id).toString())
		// 		}
		// 	}
		// }
		// $("[name='oc_id']:not(:checked)").each(function () {
		// 	var oc_id = $(this).attr('value');
		// 	$("[father_id=" + oc_id + "]").each(function () {
		// 		$(this).attr('disabled', true);
		// 	});
		// });
	}
	componentDidMount() {
		if (this.props.ontoclasses && !this.props.ontoclasses.ontoclasses) {
			this.props.ontoclassActions.getOntoclasses();
		}
		if (this.props.ontoclasses && this.props.ontoclasses.ontoclasses) {
			var ontoclasses = this.props.ontoclasses.ontoclasses;
			if (ontoclasses.length > 0) {
				ontoclasses.sort(function (a, b) {
					var textA = a.ontoclass.toUpperCase();
					var textB = b.ontoclass.toUpperCase();
					return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
				});
			}
			this.setState({ ontoclasses })
		}
	}
	handleInputChange(event) {
		const target = event.target;
		var value = parseInt(target.value);
		var _selectedConcept = this.state.selectedConcept;
		var _visible_Sel_Concepts = this.state.visible_Sel_Concepts;
		if (target.checked) {
			if (_selectedConcept.indexOf(value) < 0) {
				_selectedConcept.push(value)
			}
			if (_visible_Sel_Concepts.indexOf(value) < 0) {
				_visible_Sel_Concepts.push(value);
			}
		} else {
			const index = _selectedConcept.indexOf(value);
			if (index > -1) {
				_selectedConcept.splice(index, 1);
			}
			const index_visible = _visible_Sel_Concepts.indexOf(value);
			if (index_visible > -1) {
				_visible_Sel_Concepts.splice(index_visible, 1);
			}
		}
		this.setState({ selectedConcept: [..._selectedConcept], visible_Sel_Concepts: [..._visible_Sel_Concepts] }, () => { console.log('selected concept', this.state.selectedConcept, _selectedConcept) });
	}

	onFilterChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});

		let sel_ontoclass_id = e.target.value;
		let _isAssociatedProject = false;
		let project_ontoclasses = this.props.projects.sel_project ? this.props.projects.sel_project.ontoclasses : [];
		var selConceptArray = [];
		let visible_Sel_Concepts = [];
		if (project_ontoclasses && project_ontoclasses.length > 0) {
			for (let project_ontoclass of project_ontoclasses) {
				if (project_ontoclass.ontoclass_id.toString() === sel_ontoclass_id.toString()) {
					_isAssociatedProject = true;
					let sel_concepts = this.props.projects.sel_project.concepts;
					if (sel_concepts) {
						for (let concept of sel_concepts) {
							selConceptArray.push(concept.concept_id);
							visible_Sel_Concepts.push(concept.concept_id);
						}
					}
				}
			}
			this.setState({ selectedConcept: selConceptArray });
		}
		let concepts = [];
		if (this.props.ontoclasses && this.props.ontoclasses.ontoclasses) {
			for (let ontoclass of this.props.ontoclasses.ontoclasses) {
				if (ontoclass.ontoclass_id.toString() === sel_ontoclass_id.toString()) {
					concepts = ontoclass.concepts;
					if (concepts && concepts.length > 0) {
						if (!_isAssociatedProject) {
							selConceptArray = [];
							for (let concept of concepts) {
								selConceptArray.push(concept.concept_id);
								visible_Sel_Concepts.push(concept.concept_id);
							}
						}
					}
					let sel_concepts = this.props.projects.sel_project.concepts;
					for (let sel_concept of sel_concepts) {
						if (!_isAssociatedProject && selConceptArray.indexOf(sel_concept.concept_id) < 0) selConceptArray.push(sel_concept.concept_id)
					}

				}
			}
		}
		concepts.sort(function (a, b) {
			var textA = a.lemma.toUpperCase() + a.definition.toUpperCase();
			var textB = b.lemma.toUpperCase() + b.definition.toUpperCase();
			return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
		});
		this.setState({ concepts: concepts, selectedConcept: selConceptArray, visible_Sel_Concepts, is_associated: _isAssociatedProject }, () => { console.log('-------------filter change', this.state.concepts) });

	}

	handleSubmit() {
		var pro_onto_del_flag = false;
		if (this.state.is_associated && this.state.visible_Sel_Concepts.length === 0) {
			pro_onto_del_flag = true;
		}
		if (this.state.owner_ontoclass_id) {
			this.props.projectActions.updateProjectOntoclass(this.props.projects.sel_project.project_id, this.state.owner_ontoclass_id, this.state.selectedConcept, pro_onto_del_flag)
		}
		this.onCancel();
	}
	render() {

		// var ontoclasses = (this.props && this.props.ontoclasses && this.props.ontoclasses.ontoclasses) ? this.props.ontoclasses.ontoclasses : [];
		return (
			<Modal
				isOpen={true}
				toggle={this.props.modalActions.hideModal}
			>
				<ModalHeader><Trans id="Modal.AssociateOntoclass"></Trans></ModalHeader>
				<div className="skin-progetti" id="associa">
					<ModalBody>
						<div className="form-group row underlined" style={{ border: 'none' }}>
							<div className="col-md-12">
								<label><Trans id="Modal.OntoclassRefer"></Trans>:</label>
								<select name='owner_ontoclass_id' className="form-control" value={this.state.owner_ontoclass_id} onChange={this.onFilterChange} >
									<option key={"onto_0"} value={0} disabled>Select</option>
									{
										this.state.ontoclasses && this.state.ontoclasses.length > 0 ? this.state.ontoclasses.map(ontoclass => {
											return <option key={"onto_" + ontoclass.ontoclass_id} value={ontoclass.ontoclass_id}>{ontoclass.ontoclass}</option>
										}) : <></>
									}
								</select>
							</div>
							{
								(this.state.concepts && this.state.concepts.length) > 0 ? this.state.concepts.map((concept) => {
									if (concept && this.state.selectedConcept.indexOf(concept.concept_id) > -1) {
										return <div className="form-group row" key={"concept_" + concept.concept_id}>
											<div className="col-md-10 indenta"><i style={{ paddingLeft: "20px" }}>{concept.lemma + '-' + concept.definition}</i></div>
											<div className="col-md-2 indenta"> <input type="checkbox" name='concept_id' defaultChecked value={concept.concept_id} onChange={this.handleInputChange} />
											</div>
										</div>
									} else {
										return <div className="form-group row" key={"concept_" + concept.concept_id}>
											<div className="col-md-10 indenta"><i style={{ paddingLeft: "20px" }}>{concept.lemma + '-' + concept.definition}</i></div>
											<div className="col-md-2 indenta"> <input type="checkbox" name='concept_id' value={concept.concept_id} onChange={this.handleInputChange} />
											</div>
										</div>
									}
								}) : <></>
							}
						</div>


						{/* {
								combinedArray ? combinedArray.map((item, index) => {
									if (item && item.type === 'ontoclass'){
										var ontoclass = item.content
										if (initialSelItems.indexOf(ontoclass.ontoclass_id) > -1) {
											return <div className="form-group row" key={"div_" + ontoclass.ontoclass_id} style={{color: 'red'}}>
												<div className="col-md-10"><i id="ontoclass_name">{ontoclass.ontoclass}</i></div>
												<div className="col-md-2">
													<input type="checkbox" key={"ocSel_" + ontoclass.ontoclass_id} name="oc_id" defaultChecked value={ontoclass.ontoclass_id} onChange={this.handleInputChange} />
												</div>
											</div>
										} else {
											return <div className="form-group row" key={"div_" + ontoclass.ontoclass_id} style={{ color: 'red' }}>
												<div className="col-md-10"><i id="ontoclass_name">{ontoclass.ontoclass}</i></div>
												<div className="col-md-2">
													<input type="checkbox" key={"ocSel_" + ontoclass.ontoclass_id} name="oc_id" value={ontoclass.ontoclass_id} onChange={this.handleInputChange} />
												</div>
											</div>
										}
									}
									if (item && item.type === 'concept') {
										var concept = item.content;
										if (concept && initialConceptItem.indexOf(concept.concept_id) > -1) {
											return <div className="form-group row" key={"concept_" + concept.concept_id}>
												<div className="col-md-10 indenta"><i style={{paddingLeft: "20px"}}>{concept.lemma + '-' + concept.definition}</i></div>
												<div className="col-md-2 indenta"> <input type="checkbox" father_id={concept.owner_ontoclass_id} name='concept_id' defaultChecked value={concept.concept_id}  />
												</div>
											</div>
										} else {
											return <div className="form-group row" key={"concept_" + concept.concept_id}>
												<div className="col-md-10 indenta"><i style={{ paddingLeft: "20px" }}>{concept.lemma + '-' + concept.definition}</i></div>
												<div className="col-md-2 indenta"> <input type="checkbox" father_id={concept.owner_ontoclass_id} name='concept_id' value={concept.concept_id} />
												</div>
											</div>
										}
									}
								}) : <></>
							} */}
					</ModalBody>
					<ModalFooter>
						<button type="button" className="btn btn-default" onClick={() => this.handleSubmit()} disabled={this.state.concepts.length < 1}>
							<Trans id="Button.Confirm"></Trans>
						</button>
						<button className="btn" onClick={this.onCancel}>
							<Trans id="Button.Cancel"></Trans>
						</button>
					</ModalFooter>
				</div>
			</Modal>
		);
	}
}

export default connect(
	state => ({
		projects: state.projects,
		ontoclasses: state.ontoclasses,
		concepts: state.concepts
	}), dispatch => ({
		projectActions: bindActionCreators({ updateProjectOntoclass }, dispatch),
		ontoclassActions: bindActionCreators({ getOntoclasses }, dispatch),
		conceptActions: bindActionCreators({ getConcepts }, dispatch),
		modalActions: bindActionCreators({ hideModal }, dispatch),
	})
)(ProjectOntoclassModal);
