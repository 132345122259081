import { wrapRequest, xapi } from '../utils_services';

const getUsersApi = wrapRequest(async () =>
    xapi().get("/users")
);
const getUserDetailApi = wrapRequest(async (user_id) =>
    xapi().get("/users/" + user_id)
);
const postUserApi = wrapRequest(async (name, lastname, username, password, email, profile_id) =>
    xapi().post("/users", { name, lastname, username, password, email, profile_id })
);
const updateUserApi = wrapRequest(async (user_id, name, lastname, email, username) =>
    xapi().put("/users/" + user_id, { name, lastname, email, username })
);
const changePwdApi = wrapRequest(async (user_id, password) =>
    xapi().post("/users/" + user_id + "/changepwd", { password })
);
const addUserOntoclassApi = wrapRequest(async (user_id, ontoclasses) =>
    xapi().post("/users/" + user_id + "/addontoclass", { ontoclasses })
);
const getOntoclassesApi = wrapRequest(async () =>
    xapi().get("/ontoclasses")
);
const addUserProjectApi = wrapRequest(async (user_id, projects) =>
    xapi().post("/users/" + user_id + "/addproject", { projects })
);
const getProjectsApi = wrapRequest(async () =>
    xapi().get("/projects")
);
const deleteUserApi = wrapRequest(async (user_id) =>
    xapi().delete("/users/"+ user_id)
);
export {
    getUsersApi,
    postUserApi,
    getUserDetailApi,
    updateUserApi,
    changePwdApi,
    getOntoclassesApi,
    addUserOntoclassApi,
    addUserProjectApi,
    getProjectsApi,
    deleteUserApi
};
