import { put, takeEvery, call, all } from 'redux-saga/effects';
/** Import actions */
import {
    getIndicatorTheorsFailed,
    getIndicatorTheorsSucceed,
    postIndicatorTheorFailed,
    postIndicatorTheorSucceed,
    getIndicatorTheorDetailFailed,
    getIndicatorTheorDetailSucceed,
    updateIndicatorTheorFailed,
    updateIndicatorTheorSucceed,
    deleteIndicatorTheorFailed,
    deleteIndicatorTheorSucceed,
    getIndicatorTheorAutoFailed,
    getIndicatorTheorAutoSucceed

} from './indicator_theorActions';

/** Import api */
import * as indicator_theorApi from './indicator_theorApi';

export function* indicator_theorSubscriber() {
    yield all([takeEvery('GET_INDICATOR_THEORS', getIndicatorTheorSaga)]);
    yield all([takeEvery('POST_INDICATOR_THEOR', postIndicatorTheorSaga)]);
    yield all([takeEvery('GET_INDICATOR_THEOR_DETAIL', getIndicatorTheorDetailSaga)]);
    yield all([takeEvery('UPDATE_INDICATOR_THEOR', updateIndicatorTheorSaga)]);
    yield all([takeEvery('DELETE_INDICATOR_THEOR', deleteIndicatorTheorSaga)]);
    yield all([takeEvery('GET_INDICATOR_THEOR_AUTO', getIndicatorTheorAutoSaga)]);

}
export function* getIndicatorTheorSaga({payload: {project_id}}) {
    try {
        const indicator_theors = yield call(indicator_theorApi.get, project_id);
        yield put(getIndicatorTheorsSucceed(indicator_theors));
    } catch (error) {
        console.log('indicator_theor saga error')
        yield put(getIndicatorTheorsFailed(error));
    }
}
export function* postIndicatorTheorSaga({ payload: { name, note, concept_id, algorithm, concepts, owner_ontoclass_id, biblios } }) {
    try {
        const indicator_theors = yield call(indicator_theorApi.postIndicatorTheor, name, note, concept_id, algorithm, concepts, owner_ontoclass_id, biblios);
        yield put(postIndicatorTheorSucceed(indicator_theors));
    } catch (error) {
        console.log("error--", error)
        yield put(postIndicatorTheorFailed(error));
    }
}
export function* getIndicatorTheorDetailSaga({ payload: { indicator_theor_id } }) {
    try {
        const indicator_theor = yield call(indicator_theorApi.getIndicatorTheorDetailApi, indicator_theor_id);
        yield put(getIndicatorTheorDetailSucceed(indicator_theor));
    } catch (error) {
        console.log('indicator_theorDetail saga error')
        yield put(getIndicatorTheorDetailFailed(error));
    }
}
export function* updateIndicatorTheorSaga({ payload: { indicator_theor_id, name, note, concept_id, algorithm, concepts, owner_ontoclass_id, biblios } }) {
    try {
        const indicator_theors = yield call(indicator_theorApi.updateIndicatorTheorApi, indicator_theor_id, name, note, concept_id, algorithm, concepts, owner_ontoclass_id, biblios);
        yield put(updateIndicatorTheorSucceed(indicator_theors));
    } catch (error) {
        console.log("error--", error)
        yield put(updateIndicatorTheorFailed(error));
    }
}
export function* deleteIndicatorTheorSaga({ payload: { indicator_theor_id } }) {
    try {
        yield call(indicator_theorApi.deleteIndicatorTheorApi, indicator_theor_id);
        yield put(deleteIndicatorTheorSucceed());
    } catch (error) {
        console.log("error--", error)
        yield put(deleteIndicatorTheorFailed(error));
    }
}

export function* getIndicatorTheorAutoSaga({ payload: { query_string } }) {
    try {
        const indicator_theors = yield call(indicator_theorApi.getIndicatorThoerAutoApi, query_string);
        yield put(getIndicatorTheorAutoSucceed(indicator_theors));
    } catch (error) {
        console.log('indicator_theor auto saga error')
        yield put(getIndicatorTheorAutoFailed(error));
    }
}