import React from 'react';
import { connect } from 'react-redux';
import { setPathname, addPathname, deletePathname } from '../../services/common/commonActions';
import { getProjectDetail, setSubProjectConcepts } from '../../services/project/projectActions';
import { showModal } from '../../modals/modalConductorActions';
import { getStoredUser } from '../../services/auth/services';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import Swal from 'sweetalert2';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';


/*
import 'datatables.net'
import 'datatables.net-bs'


import 'datatables.net/Buttons-1.5.6/js/dataTables.buttons.js';
import 'datatables.net/Buttons-1.5.6/js/buttons.bootstrap.js';
import 'datatables.net/JSZip-2.5.0/jszip.js';
import 'datatables.net/pdfmake-0.1.36/pdfmake.js';
import 'datatables.net/pdfmake-0.1.36/vfs_fonts.js';
import 'datatables.net/Buttons-1.5.6/js/buttons.html5.min.js';
import 'datatables.net/Buttons-1.5.6/js/buttons.print.min.js';
import 'datatables.net/pdfmake-0.1.36/vfs_fonts.js';
*/

// import 'jszip';
import 'pdfmake';
import 'datatables.net-dt';
// import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';

const jzip = require('jzip');
window.JSZip = jzip;

var table = null;
var table_concepts = null;
var table_indicators = null;

class ProjectDetail extends React.Component {
	constructor() {
		super();
		this.state = {
			list_concepts: []
		}
		$.fn.dataTableExt.oSort["customdate-desc"] = function (_x, _y) {

			var xx = _x.split('/');
			var x = new Date(xx[2], xx[1], xx[0]);
			x = x.getTime();

			var yy = _y.split('/');
			var y = new Date(yy[2], yy[1], yy[0]);
			y = y.getTime();
			if (x > y) {
				return -1;
			}

			return 1;

		};

		$.fn.dataTableExt.oSort["customdate-asc"] = function (_x, _y) {

			var xx = _x.split('/');
			var x = new Date(xx[2], xx[1], xx[0]);
			x = x.getTime();

			var yy = _y.split('/');
			var y = new Date(yy[2], yy[1], yy[0]);
			y = y.getTime();

			if (x > y) {
				return 1;
			}
			return -1;
		}
	}
	componentWillMount() {
		table = null;
		table_concepts = null;
		table_indicators = null;
	}
	componentDidUpdate(prevProps) {
		if (this.props.projects && this.props.projects.sel_project && this.props.projects.sel_project !== prevProps.projects.sel_project) {
			if (this.props.projects.error === null && !this.props.projects.loading && prevProps.projects && prevProps.projects.loading && this.props.projects.message) {
				if (table_indicators) {
					table_indicators.destroy();
					table_indicators = null;
				}
				if (table_concepts) {
					table_concepts.destroy();
					table_concepts = null;
				}
				toastr.success('Success', this.props.projects.message);
				if (this.props.projects.sel_project.father_id) {
					this.props.history.push('/subproject/' + this.props.projects.sel_project.project_id)
				} else {
					this.props.history.push('/projects')
				}
			}
			var subprojects = this.props.projects.sel_project.subprojects;
			var list_indicators = [];
			var list_indicator_ids = [];
			var list_concepts = [];
			var list_concept_ids = [];
			if (subprojects && subprojects.length > 0) {
				for (let subproject of subprojects) {
					if (subproject.concepts && subproject.concepts.length > 0) {
						for (let concept of subproject.concepts) {
							if (list_concept_ids.indexOf(concept.concept_id) < 0) {
								list_concepts.push(concept);
								list_concept_ids.push(concept.concept_id);
							}
						}
					}
					if (subproject.indicators_t && subproject.indicators_t.length > 0) {
						for (let indicator_t of subproject.indicators_t) {
							if (list_indicator_ids.indexOf(indicator_t.indicator_theor_id) < 0) {
								list_indicators.push(indicator_t);
								list_indicator_ids.push(indicator_t.indicator_theor_id);
							}
						}
					}
				}
			}
			var label_export = "Export project indicators (" + list_indicators.length + ")";
			var project_name = this.props.projects.sel_project ? this.props.projects.sel_project.name : '';
			var subProjectName = '';
			for (var i = 0; i < subprojects.length; i++) {
				subProjectName += subprojects[i].name
				if (i < subprojects.length - 1) {
					subProjectName += ' ;'
				}
			}
			if (!table_indicators) {
				table_indicators = $('#list_indicators').DataTable({
					'dom': 'Bfrtip',
					'paging': false,
					'lengthChange': false,
					'searching': false,
					'ordering': false,
					'info': false,
					'scrollX': false,
					'data': list_indicators,
					'autoWidth': false,
					'buttons': [
						{
							extend: 'excel',
							text: '<i class="fa fa-file-excel-o"></i> ' + label_export,
							className: 'btn'
						},
					],
					'columnDefs': [
						{
							'targets': 0,
							'data': function (row, type, val, meta) {
								return row.name
							}
						},
						{
							'targets': 1,
							'data': function (row, type, val, meta) {
								return row.note
							}
						},
						{
							'targets': 2,
							'data': function (row, type, val, meta) {
								return project_name
							}
						},
						{
							'targets': 3,
							'data': function (row, type, val, meta) {
								return subProjectName;
							}
						},
						{
							'targets': 4,
							'data': function (row, type, val, meta) {
								return row.owner_ontoclass
							}
						},
						{
							'targets': 5,
							'data': function (row, type, val, meta) {
								var result = "";
								var algorithmArray = [];
								if (row.algorithm) {
									algorithmArray = JSON.parse(row.algorithm)
								}
								var temp_string = '';
								if (algorithmArray && algorithmArray.length > 0) {
									for (var i = 0; i < algorithmArray.length; i++) {
										if (algorithmArray[i].type === 'string') {
											temp_string += `"${algorithmArray[i].content}"`
										} else {
											temp_string += algorithmArray[i].content;
										}
									}
								}
								return temp_string;
								// return result;
							}
						},
					],
					initComplete: function () {
						$("#list_indicators").hide();
					},

				});
			}
			// if (list_indicators && list_indicators.length > 0) {
			table_indicators.clear();
			table_indicators.rows.add(list_indicators);
			table_indicators.draw();
			// }

			table.clear();
			table.rows.add(subprojects);
			table.draw();

			var label_export = "Export project concepts (" + list_concepts.length + ")";
			if (!table_concepts) {
				table_concepts = $('#list_concepts').DataTable({
					'dom': 'Bfrtip',
					'paging': false,
					'lengthChange': false,
					'searching': false,
					'ordering': false,
					'info': false,
					'scrollX': false,
					'data': list_concepts,
					'autoWidth': false,
					'buttons': [
						{
							extend: 'excel',
							text: '<i class="fa fa-file-excel-o"></i> ' + label_export,
							className: 'btn'
						},
					],
					'columnDefs': [
						{
							'targets': 0,
							'data': function (row, type, val, meta) {
								return row.lemma
							}
						},
						{
							'targets': 1,
							'data': function (row, type, val, meta) {
								return row.definition
							}
						},
						{
							'targets': 2,
							'data': function (row, type, val, meta) {
								var result = "";
								if (row.sources.length) {
									for (var i = 0; i < row.sources.length; i++) {
										result += row.sources[i].source;
										if (i < row.sources.length - 1) {
											result += '; ';
										}
									}
								}
								return result;
							}
						},
						{
							'targets': 3,
							'data': function (row, type, val, meta) {
								var result = "";
								if (row.ontoclasses.length) {
									for (let i = 0; i < row.ontoclasses.length; i++) {
										result += row.ontoclasses[i].ontoclass;
										if (i < row.ontoclasses.length - 1) result += '; ';
									}
								}
								return result
							}
						},
					],
					initComplete: function () {
						$("#list_concepts").hide();
					}
				});
			}
			table_concepts.clear();
			table_concepts.rows.add(list_concepts);
			table_concepts.draw();
			var project_name = this.props.projects.sel_project.name;
			this.props.commonActions.addPathname(
				{ "pathName": project_name, "Url": "project/" + this.props.match.params.id, "color": "#6d81ab", "subHeader": "Project Detail" }
			)
		}
		if (this.props.projects.error !== prevProps.projects.error && this.props.projects.error !== null && this.props.projects.message) {
			toastr.error('Error Occured', this.props.projects.message);
		}

	}
	componentDidMount() {
		this.props.projectActions.getProjectDetail(this.props.match.params.id);
		var _that = this;
		var currentUser = getStoredUser();
		if (currentUser.profile === 'Guest' || currentUser.profile === 'Publisher') {
			$("[edit=1]").hide();
		}
		var subprojects = this.props.projects.sel_project ? this.props.projects.sel_project.subprojects : [];
		table = $('#list_table').DataTable({
			'paging': true,
			'lengthChange': false,
			'searching': false,
			'ordering': true,
			'info': true,
			'scrollX': true,
			'data': subprojects,
			'autoWidth': false,
			'createdRow': function (row, data, dataIndex) {
				// if (currentUser.profile != 'Guest') {
					$('td:lt(2)', row).on('click', function () {
						// if (data && data.ontoclass_id) {
						// 	_that.goOntoclass(data.ontoclass_id);
						// }
						_that.goSubproject(data.project_id, data.concepts);
					});
				// }
			},
			'columnDefs': [
				{
					'targets': 0,
					'data': function (row, type, val, meta) {
						return row.name
					}
				},
				{
					'targets': 1,
					'data': function (row, type, val, meta) {
						return row.concepts.length
					}
				},
				{
					'targets': 2,
					'data': function (row, type, val, meta) {
						var result = "";
						for (var i = 0; i < row.ontoclasses.length; i++) {
							result += row.ontoclasses[i].ontoclass;
							if (i < row.ontoclasses.length - 1) {
								result += '; ';
							}
						}
						return result;
					}
				},
				// {
				// 	'targets': 3,
				// 	"className": 'details-control',
				// 	"orderable": false,
				// 	'data': 'test',
				// 	"defaultContent": ''
				// }
			]
		});
		$("#list_table_body").on('click', 'td.details-control', function () {
			var tr = $(this).closest('tr');
			var row = table.row(tr);

			if (row.child.isShown()) {
				row.child.hide();
				$(tr).removeClass('shown');
			} else {
				if (row.data()) {
					row.child(_that.subTableCreator(row.data().project_id, row.data().concepts)).show();
					$(tr).addClass('shown');
					_that.drawSubTab(row.data().project_id, row.data().concepts);
				}
			}
		});
		// Elenco completo concetti
	}

	subTableCreator = (ontoclass_id, rowData) => {
		var $div = $('<div></div>');
		var $table = $('<table class="list-table table table-bordered table-striped tabAutoWidth" jmSubTab=' + ontoclass_id + '></table>');
		$table.append("<thead>" +
			"<tr> " +
			"<th>Lemma</th> " +
			"<th>Definition</th> " +
			"<th>Theoretical indicators where it is contained</th> " +
			"</tr> " +
			"</thead>");
		$div.append($table);
		return $div;
	}

	drawSubTab = (ontoclass_id, rowData) => {
		var _that = this;
		var list = rowData || [];
		$("[jmSubTab=" + ontoclass_id + "]").dataTable().fnDestroy();
		$("[jmSubTab=" + ontoclass_id + "]").DataTable({
			'paging': false,
			'lengthChange': false,
			'searching': false,
			'ordering': true,
			'info': false,
			'scrollX': true,
			'data': list,
			'autoWidth': false,
			'createdRow': function (row, data, dataIndex) {
				$('td:lt(2)', row).on('click', function () {
					if (row && row.concept_id) {
						_that.goConcept(row.concept_id);
					}
				});

			},
			'columnDefs': [
				{
					'targets': 0,
					'data': function (row, type, val, meta) {
						return row.lemma
					}
				},
				{
					'targets': 1,
					'data': function (row, type, val, meta) {
						return row.definition
					}
				},
				{
					'targets': 2,
					'data': function (row, type, val, meta) {
						return row.indicators_t.length
					}
				}
			],
		});
		$("[jmSubTab=" + ontoclass_id + "] tbody").on('click', 'td.details-control2', function () {
			var tr = $(this).closest('tr');
			var row = table.row(tr);
			if (row.child.isShown()) {
				row.child.hide();
				$(tr).removeClass('shown');
			} else {
				if (row.data()) {
					row.child(_that.subTableCreator(row.data().ontoclass_id, row.data().concepts)).show();
					_that.drawSubTab(row.data().ontoclass_id, row.data().concepts);
				}
				$(tr).addClass('shown');
			}
		});
	}
	goOntoclass = (ontoclass_id) => {
		this.props.history.push(`/ontoclass/${ontoclass_id}`);
	}
	goSubproject = (project_id, concepts) => {
		this.props.projectActions.setSubProjectConcepts(concepts);
		this.props.history.push(`/subproject/${project_id}`);
	}
	goConcept = (concept_id) => {
		this.props.history.push(`/concept/${concept_id}`);
	}
	render() {
		var project = {};
		var link_graphic;
		if (this.props.projects) {
			if (this.props.projects.loading) {
				Swal.fire({
					title: 'Loading...',
					text: "Plese wait",
					onOpen: () => {
						Swal.showLoading();
					},
					allowOutsideClick: false,
					allowEscapeKey: false,
					allowEnterKey: false
				});
			} else {
				Swal.close();
			}
			project = this.props.projects.sel_project;
			if(project) link_graphic = 'https://smartview.mosis-lab.org/app/map/' + project.project_id
		}
		var currentUser = getStoredUser();
		return (
			<div className="content skin-progetti">
				{ currentUser.profile != 'Guest' && 
					<div className="row">
						<div className="col-md-12">
							<div className="sommario">
								<label><Trans id="Content.CreationDate"></Trans>:</label><span id="createdAt">{project ? project.createdAt : ''}</span>
								<label><Trans id="Content.LastUpdate"></Trans>:</label><span id="updatedAt">{project ? project.updatedAt : ''}</span>
								<label><Trans id="Content.User"></Trans>:</label><span id="auth">{project ? project.auth : ''}</span>
							</div>
						</div>
					</div>
				}
				<div className="form-group row">
					{project && project.logo_base64 != '' ?
						<div className="col-md-12" align="center">
							{project.link ? (
								<a href={project.link}><img src={`data:image/jpeg;base64,${project.logo_base64}`} width="400px" /></a>
							) : (
								<img src={`data:image/jpeg;base64,${project.logo_base64}`} width="400px" />
							)}
						</div>
						: <></>}
					<div className="col-md-12">
						<h4><b>{project ? project.name : ''}</b></h4>

					</div>
					<div className="col-md-12">
						<div>{project ? project.description : ''}</div>
					</div>
					<div className="col-md-12">
						<label className="text-muted text-center"><Trans id="Content.Link"></Trans></label>
						<div>{project ? <a style={{textDecoration: 'underline'}} href={project.link} target="blank">{project.link}</a> : ''}</div>
					</div>
				</div>
				<div className="comandi">
					<div>
						<button type="button" edit="1" className="btn btn-default" onClick={() => this.props.modalActions.showModal('EDIT_PROJECT_MODAL')}>
							<Trans id="Button.Modification"></Trans>
						</button>
						{1 === 0 &&
							<>
								<button type="button" edit="1" className="btn btn-default" onClick={() => this.props.modalActions.showModal('UPDATE_PROJECT_ONTOCLASS_MODAL')}>
									<Trans id="Button.AssociateOntoclass"></Trans>
								</button>
								<button type="button" edit="1" onClick={() => {
									this.props.modalActions.showModal('ADD_ONTOCLASS_MODAL')
								}} id="add" className="btn btn-default">
									<Trans id="Button.InsertNewOntoclass" />
								</button>
							</>
						}
						<button type="button" edit="1" onClick={() => {
							this.props.modalActions.showModal('ADD_SUBPROJECT_MODAL')
						}} id="add" className="btn btn-default">
							<Trans id="Button.InsertNewSubproject" />
						</button>
						<button id="delete" edit="1" className="btn" onClick={() => {
							this.props.modalActions.showModal('DELETE_PROJECT_MODAL');
						}}><b><Trans id="Button.Delete"></Trans></b></button>
						<button className="btn" id="back" onClick={() => { this.props.history.goBack(); this.props.commonActions.deletePathname() }}><b><Trans id="Button.Back"></Trans></b></button>
						<button className="btn btn-graphic"  onClick={(e) => { e.preventDefault(); window.open(link_graphic, '_blank'); }}><b><Trans id="Button.Graphic"></Trans></b></button>
					</div>
				</div>
				< div className="row" >
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-3">
								<table id="list_indicators" className="table table-hover hidden">
									<thead>
										<tr>
											<th>
												<div><Trans id="Table.Name" /></div>
											</th>
											<th>
												<div><Trans id="Content.Note" /></div>
											</th>
											<th>
												<div><Trans id="Table.Project" /></div>
											</th>
											<th>
												<div><Trans id="Table.Subproject" /></div>
											</th>
											<th>
												<div><Trans id="Table.Ontoclass" /></div>
											</th>
											<th>
												<div><Trans id="Content.Algorithm" /></div>
											</th>
										</tr>
									</thead>
									<tbody id="list_indicators_body">
									</tbody>
								</table>
							</div>
							<div className="col-md-3">
								<table id="list_concepts" className="table table-hover hidden">
									<thead>
										<tr>
											<th>
												<div><Trans id="Table.Lemma" /></div>
											</th>
											<th>
												<div><Trans id="Table.Definition" /></div>
											</th>
											<th>
												<div><Trans id="Table.Source" /></div>
											</th>
											<th>
												<div><Trans id="Table.Ontoclass" /></div>
											</th>
										</tr>
									</thead>
									<tbody id="list_concepts_body">
									</tbody>
								</table>
							</div>
						</div>
						{1 === 0 &&
							<>
								<h3> <Trans id="Table.OntoclassesAssociated"></Trans></h3>
								<table id="list_table_old" className="table table-hover">
									<thead>
										<tr>
											<th>
												<span data-tip data-for="header9"><Trans id="Table.Ontoclass" /></span>
												<ReactTooltip place="top" effect="solid" id="header9" className="dynTooltip">
													<div><Trans id="Table.Ontoclass" /></div>
												</ReactTooltip>
											</th>
											<th>
												<span data-tip data-for="header10"><Trans id="Table.OntoclassConceptsAssociated" /></span>
												<ReactTooltip place="top" effect="solid" id="header10" className="dynTooltip">
													<div><Trans id="Table.OntoclassConceptsAssociated" /></div>
												</ReactTooltip>
											</th>
											<th>
												<span data-tip data-for="header11"><Trans id="Table.OntoclassConcepts" /></span>
												<ReactTooltip place="top" effect="solid" id="header11" className="dynTooltip">
													<div><Trans id="Table.OntoclassConcepts" /></div>
												</ReactTooltip>
											</th>
											<th>
												<span data-tip data-for="header12"><Trans id="Button.Expand" /></span>
												<ReactTooltip place="top" effect="solid" id="header12" className="dynTooltip">
													<div><Trans id="Button.Expand" /></div>
												</ReactTooltip>
											</th>
										</tr>
									</thead>
									<tbody id="list_table_body_old">
									</tbody>
								</table>
							</>
						}
						<h3> <Trans id="Table.Subprojects"></Trans></h3>
						<table id="list_table" className="table table-hover">
							<thead>
								<tr>
									<th>
										<span data-tip data-for="header5"><Trans id="Table.Subproject" /></span>
										<ReactTooltip place="top" effect="solid" id="header5" className="dynTooltip">
											<div><Trans id="Table.Subproject" /></div>
										</ReactTooltip>
									</th>
									<th>
										<span data-tip data-for="header6"><Trans id="Table.ConceptsAssociated" /></span>
										<ReactTooltip place="top" effect="solid" id="header6" className="dynTooltip">
											<div><Trans id="Table.ConceptsAssociated" /></div>
										</ReactTooltip>
									</th>
									<th>
										<span data-tip data-for="header7"><Trans id="Table.OntoclassesAssociated" /></span>
										<ReactTooltip place="top" effect="solid" id="header7" className="dynTooltip">
											<div><Trans id="Table.OntoclassesAssociated" /></div>
										</ReactTooltip>
									</th>
									{ 1== 0 && 
										<th>
											<span data-tip data-for="header8"><Trans id="Button.Expand" /></span>
											<ReactTooltip place="top" effect="solid" id="header8" className="dynTooltip">
												<div><Trans id="Button.Expand" /></div>
											</ReactTooltip>
										</th>
									}
								</tr>
							</thead>
							<tbody id="list_table_body">
							</tbody>
						</table>
					</div >
				</div >
			</div>
		)
	}
}

export default connect(
	state => ({
		projects: state.projects,
		common: state.common
	}),
	dispatch => ({
		commonActions: bindActionCreators({ setPathname, addPathname, deletePathname }, dispatch),
		projectActions: bindActionCreators({ getProjectDetail, setSubProjectConcepts }, dispatch),
		modalActions: bindActionCreators({ showModal }, dispatch),
	})
)(ProjectDetail);
