import React from 'react';
import { connect } from 'react-redux';
import { setPathname } from '../../services/common/commonActions';
import { getOntoclasses, postOntoclass } from '../../services/ontoclass/ontoclassActions';
import { showModal } from '../../modals/modalConductorActions';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import 'datatables.net'
import 'datatables.net-bs'
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';

class OnToClass extends React.Component {
    constructor() {
        super();
        this.ontoclassTable = null;
    }
    componentWillMount() {
        this.props.commonActions.setPathname(
            [
                { "pathName": "Home", "Url": "", "id": 1, "color": "" },
                { "pathName": "Ontoclasses", "Url": "ontoclasses", "id": 2, "color": "#da645b", "subHeader": "Ontoclasses" }
            ]
        )
        this.props.ontoclassActions.getOntoclasses();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.ontoclasses && this.props.ontoclasses.ontoclasses !== prevProps.ontoclasses.ontoclasses && this.ontoclassTable !== null) {
            this.ontoclassTable.clear();
            if (this.props.ontoclasses.ontoclasses) {
                this.ontoclassTable.rows.add(this.props.ontoclasses.ontoclasses);
            }
            this.ontoclassTable.draw();
        }
        if (this.props.ontoclasses.error !== prevProps.ontoclasses.error && this.props.ontoclasses.error !== null) {
            toastr.error('Error Occured', this.props.ontoclasses.message);
        }

        if (this.props.ontoclasses.error === null && !this.props.ontoclasses.loading && prevProps.ontoclasses && prevProps.ontoclasses.loading && this.props.ontoclasses.message) {
            toastr.success('Success', this.props.ontoclasses.message);
            if (this.props.ontoclasses.sel_ontoclass && this.props.ontoclasses.sel_ontoclass !== prevProps.ontoclasses.sel_ontoclass) {
                this.props.history.push(`/ontoclass/${this.props.ontoclasses.sel_ontoclass.ontoclass_id}`)
            }
        }
    }
    componentDidMount() {
        var _that = this;
        this.ontoclassTable = $('#list_table').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'scrollX': true,
            'createdRow': function (row, data, dataIndex) {
                $(row).on('click', function () {
                    if (data && data.ontoclass_id) {
                        _that.goOntoclass(data.ontoclass_id);
                    }
                })
            },
            'columns': [
                { 'data': 'ontoclass' },
                { 
                    'data': 'projects',
                    'render': function (data, type, full, meta) {
                        var list = '';
                        for (var i in data) {
                            list += data[i].name + " "
                        }
                        return list;
                    }
                },
                { 
                    'data': 'concepts',
                    'render': function (data, type, full, meta) {
                        return data.length;
                    }
                },
                { 'data': 'indicators'}
            ]
        });
        this.ontoclassTable.clear();
        if (this.props.ontoclasses.ontoclasses) {
            this.ontoclassTable.rows.add(this.props.ontoclasses.ontoclasses);
        }
        this.ontoclassTable.draw();
    }
    goOntoclass = (ontoclassId) => {
        this.props.history.push(`ontoclass/${ontoclassId}`);
    }
    render() {
        if (this.props.ontoclasses.loading) {
            Swal.fire({
                title: 'Loading...',
                text: "Plese wait",
                onOpen: () => {
                    Swal.showLoading();
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
        } else {
            Swal.close();
        }
        return (
            <React.Fragment>
                <section className="content skin-ontoclassi">
                    <div className="top_comandi">
                        <button type="button" onClick={() => {
                            this.props.modalActions.showModal('ADD_ONTOCLASS_MODAL')
                        }} id="add" className="btn btn-default">
                            <span className="glyphicon glyphicon-plus-sign"></span><Trans id="Button.InsertNewOntoclass" />
                        </button>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <table id="list_table" className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <span data-tip data-for="header1"><Trans id="Table.Ontoclass" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header1" className="dynTooltip">
                                                <div><Trans id="Table.Ontoclass" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header2"><Trans id="Table.ProjectsAssociated" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
                                                <div><Trans id="Table.ProjectsAssociated" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header3"><Trans id="Table.ConceptsInserted" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header3" className="dynTooltip">
                                                <div><Trans id="Table.ConceptsInserted" /></div>
                                            </ReactTooltip>
                                        </th>
                                        <th>
                                            <span data-tip data-for="header4"><Trans id="Table.IndicatorsInserted" /></span>
                                            <ReactTooltip place="top" effect="solid" id="header4" className="dynTooltip">
                                                <div><Trans id="Table.IndicatorsInserted" /></div>
                                            </ReactTooltip>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="list_table_body">
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({
        ontoclasses: state.ontoclasses
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname }, dispatch),
        ontoclassActions: bindActionCreators({ getOntoclasses, postOntoclass }, dispatch),
        modalActions: bindActionCreators({ showModal }, dispatch),
    })
)(OnToClass);
