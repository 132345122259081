import React from 'react';
import { connect } from 'react-redux';
import { setPathname, addPathname, deletePathname } from '../../services/common/commonActions';
import { getConcepts, getConceptDetail, postConcept, deleteConcept } from '../../services/concept/conceptActions';
import { getUtilities } from '../../services/utilities/utilityActions';
import { getEvents } from '../../services/event_entity/eventActions';
import { getStoredUser } from '../../services/auth/services';
import { showModal } from '../../modals/modalConductorActions';
import { capitalize } from '../../services/utils_services';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import 'datatables.net'
import 'datatables.net-bs'
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
class ConceptDetail extends React.Component {
    constructor() {
        super();
        this.indicatorTable = null;
        this.otherTable = null;
        $.fn.dataTableExt.oSort["customdate-desc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();
            if (x > y) {
                return -1;
            }

            return 1;

        };

        $.fn.dataTableExt.oSort["customdate-asc"] = function (_x, _y) {

            var xx = _x.split('/');
            var x = new Date(xx[2], xx[1], xx[0]);
            x = x.getTime();

            var yy = _y.split('/');
            var y = new Date(yy[2], yy[1], yy[0]);
            y = y.getTime();

            if (x > y) {
                return 1;
            }
            return -1;
        }
    }
    componentWillMount() {
        this.props.conceptActions.getConceptDetail(this.props.match.params.id);
        this.props.eventActions.getEvents(this.props.match.params.id, 'concept')
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.props.conceptActions.getConceptDetail(this.props.match.params.id);
            // this.props.conceptActions.getConcepts(undefined, undefined, this.props.concepts.sel_concept.lemma_id)
        }
        if (this.props.concepts && this.props.concepts.sel_concept && this.props.concepts.sel_concept !== prevProps.concepts.sel_concept) {
            this.indicatorTable.clear();
            if (this.props.concepts.sel_concept.indicators_t) {
                this.indicatorTable.rows.add(this.props.concepts.sel_concept.indicators_t);
            }
            this.indicatorTable.draw();

            this.indicatorATable.clear();
            if (this.props.concepts.sel_concept.indicators_a) {
                this.indicatorATable.rows.add(this.props.concepts.sel_concept.indicators_a);
            }
            this.indicatorATable.draw();
        }
        // if (this.props.concepts && this.props.concepts.concepts !== prevProps.concepts.concepts) {
        if (this.props.concepts && this.props.concepts.sel_concept && this.props.concepts.sel_concept !== prevProps.concepts.sel_concept) {
            var conceptDetail = this.props.concepts.sel_concept.lemma + '-' + this.props.concepts.sel_concept.definition
            this.props.commonActions.addPathname({
                "pathName": conceptDetail, "Url": "concept/" + this.props.match.params.id, "color": "#6d81ab", "subHeader": "Concept Detail"
            })
            // var concepts = this.props.concepts.concepts || [];
            var concepts = this.props.concepts.sel_concept.further_concepts || [];
            for (var i = 0; i < concepts.length; i++) {
                if (concepts[i].concept_id.toString() === this.props.match.params.id.toString()) {
                    concepts.splice(i, 1);
                }
            }
            this.otherTable.clear();
            if (concepts) {
                this.otherTable.rows.add(concepts);
            }
            this.otherTable.draw();
        }
        if (this.props.concepts.error !== prevProps.concepts.error && this.props.concepts.error !== null) {
            toastr.error('Error Occured', this.props.concepts.message);
        }

        if (this.props.concepts.error === null && !this.props.concepts.loading && prevProps.concepts && prevProps.concepts.loading && this.props.concepts.message) {
            toastr.success('Success', this.props.concepts.message);
            this.props.history.push(`/concepts/`)
        }
    }
    componentDidMount() {
        var _that = this;
        var currentUser = getStoredUser();
        var userVisibility = true;
        if (currentUser.profile == 'Guest') {
            $("[edit=1]").hide();
            userVisibility = false;
        }
        this.indicatorTable = $('#list_table_indicator').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'scrollX': false,
            'createdRow': function (row, data, dataIndex) {
                $(row).on('click', function () {
                    if (data && data.indicator_theor_id) {
                        _that.goIndicator(data.indicator_theor_id);
                    }
                })
            },
            'columns': [
                {
                    'data': 'name',
                    'render': function (data, type, full, meta) {
                        var result = "";
                        if (data) {
                           result = data;
                        }
                        return result;
                    }
                },
                {
                    'data': 'note',
                    'render': function (data, type, full, meta) {
                        var result = "";
                        if (data) {
                            result = data;
                        }
                        return result;
                    }
                },
                {
                    'data': 'owner_ontoclass',
                    'render': function (data, type, full, meta) {
                        var result = "";
                        if (data) {
                            result = data;
                        }
                        return result;
                    }
                },
                {
                    'data': 'updatedAt',
                },
                {
                    'data': 'auth',
                }
            ],
            columnDefs: [
                {
                    'targets': 3, //index of column
                    'type': 'customdate',
                    "visible": userVisibility,
                },
                {
                    'targets': 4, //index of column
                    "visible": userVisibility,
                },
            ],
        });
        this.indicatorTable.clear();
        if (this.props.concepts && this.props.concepts.sel_concept && this.props.concepts.sel_concept.indicators_t) {
            var list_indicators = this.props.concepts.sel_concept.indicators_t || [];
            // this.indicatorTable.rows.add(this.props.concepts.sel_concept.indicators_t);
            this.indicatorTable.rows.add(list_indicators);
        }
        this.indicatorTable.draw();
        if (this.props.concepts && this.props.concepts.sel_concept && this.props.concepts.sel_concept.lemma_id) {
            /// this.props.conceptActions.getConcepts(undefined, undefined, this.props.concepts.sel_concept.lemma_id)
        }

        this.indicatorATable = $('#list_table_indicator_a').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'scrollX': false,
            'createdRow': function (row, data, dataIndex) {
                $(row).on('click', function () {
                    if (data && data.indicator_applied_id) {
                        _that.goIndicatorA(data.indicator_applied_id);
                    }
                })
            },
            'columns': [
                {
                    'data': 'name',
                    'render': function (data, type, full, meta) {
                        var result = "";
                        if (data) {
                           result = data;
                        }
                        return result;
                    }
                },
                {
                    'data': 'note',
                    'render': function (data, type, full, meta) {
                        var result = "";
                        if (data) {
                            result = data;
                        }
                        return result;
                    }
                },
                {
                    'data': 'owner_ontoclass',
                    'render': function (data, type, full, meta) {
                        var result = "";
                        if (data) {
                            result = data;
                        }
                        return result;
                    }
                },
                {
                    'data': 'updatedAt',
                },
                {
                    'data': 'auth',
                }
            ],
            columnDefs: [
                {
                    'targets': 3, //index of column
                    'type': 'customdate',
                    "visible": userVisibility,
                },
                {
                    'targets': 4, //index of column
                    "visible": userVisibility,
                },
            ],
        });
        this.indicatorATable.clear();
        if (this.props.concepts && this.props.concepts.sel_concept && this.props.concepts.sel_concept.indicators_a) {
            var list_indicators = this.props.concepts.sel_concept.indicators_a || [];
            // this.indicatorTable.rows.add(this.props.concepts.sel_concept.indicators_t);
            this.indicatorATable.rows.add(list_indicators);
        }
        this.indicatorATable.draw();

        this.otherTable = $('#list_table_other').DataTable({
            'paging': true,
            'lengthChange': false,
            'searching': false,
            'ordering': true,
            'info': true,
            'autoWidth': false,
            'scrollX': false,
            'createdRow': function (row, data, dataIndex) {
                $(row).on('click', function () {
                    if (data && data.concept_id) {
                        _that.goConcept(data.concept_id);
                    }
                })
            },
            'columns': [
                {
                    'data': 'lemma'
                },
                {
                    'data': 'definition',
                },
                {
                    'data': 'sources',
                    'render': function (data, type, full, meta) {
                        var result = "";
                        if (data) {
                            for (var i = 0; i < data.length; i++) {
                                result += data[i].source;
                                if (i < data.length - 1) {
                                    result += '; ';
                                }
                            }
                        }
                        return result;
                    }
                },
                {
                    'data': 'indicators_t',
                    'render': function (data, type, full, meta) {
                        return data ? data.length : ''
                    }
                },
                {
                    'data': 'indicators_a',
                    'render': function (data, type, full, meta) {
                        return data ? data.length : ''
                    }
                },
                {
                    'data': 'ontoclasses',
                    'render': function (data, type, full, meta) {
                        let result = '';
                        if (data) {
                            for (let i = 0; i < data.length; i++) {
                                result += data[i].ontoclass;
                                if (i < data.length - 1) {
                                    result += '; '
                                }
                            }
                        }
                        return result
                    }
                },
                {
                    'data': 'updatedAt',
                },
                {
                    'data': 'auth',
                }
            ],
            columnDefs: [
                {
                    'targets': 6, //index of column
                    'type': 'customdate',
                    "visible": userVisibility,
                },
                {
                    'targets': 7, //index of column
                    "visible": userVisibility,
                },
            ],
        });
        this.otherTable.clear();
        if (this.props.concepts && this.props.concepts.sel_concept) {
            var concepts = this.props.concepts.sel_concept.further_concepts || [];
            for (var i = 0; i < concepts.length; i++) {
                if (concepts[i].concept_id.toString() === this.props.match.params.id.toString()) {
                    concepts.splice(i, 1);
                }
            }
            this.otherTable.rows.add(concepts);
        }
        this.otherTable.draw();
    }

    goConcept = (concept_id) => {
        this.props.history.push(`/concept/${concept_id}`);
        window.$('[href="#tab_concept"]').tab('show');
    }
    goIndicator = (indicator_id) => {
        this.props.history.push(`/theordetail/${indicator_id}`);
    }
    goIndicatorA = (indicator_id) => {
        this.props.history.push(`/applieddetail/${indicator_id}`);
    }
    render() {
        var list_transition = [];
        var sel_concept = {};
        if (this.props.concepts) {
            if (this.props.concepts.loading) {
                Swal.fire({
                    title: 'Loading...',
                    text: "Plese wait",
                    onOpen: () => {
                        Swal.showLoading();
                    },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            } else {
                Swal.close();
            }
            if (this.props.concepts.sel_concept) {
                sel_concept = this.props.concepts.sel_concept
            }
            if (this.props.concepts.list_transition) {
                list_transition = this.props.concepts.list_transition;
            }
        }
        if (this.props && this.props.auth && this.props.auth.currentUser) {
            var profile = this.props.auth.currentUser.profile;
        }
		var currentUser = getStoredUser();
        return (
            <React.Fragment>
                <section className="content skin-concetti">
				{ currentUser.profile != 'Guest' && 
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sommario">
                                <label><Trans id="Content.CreationDate"></Trans>:</label><span id="createdAt">{sel_concept ? sel_concept.createdAt : ''}</span>
                                <label><Trans id="Content.LastUpdate"></Trans>:</label>
                                <span id="updatedAt">{sel_concept ? sel_concept.updatedAt : ''}</span>
                                <label><Trans id="Content.User"></Trans>:</label><span id="auth">{sel_concept ? sel_concept.auth : ''}</span>
                                <label><Trans id="Content.State"></Trans>:</label> <span className="label label-success" id="state">{sel_concept ? capitalize(sel_concept.state) : ''}</span><br />
                                <label><Trans id="Modal.OntoclassRefer"></Trans>:</label><span id="owner_ontoclass">{sel_concept ? sel_concept.owner_ontoclass : ''}</span>
                            </div>
                        </div>
                    </div>
                }
                    <div className="row">
                        <div className="col-md-12">
                            <div className="nav-tabs-custom">
                                <ul className="nav nav-tabs">
                                    <li className="active"><a href="#tab_concept" data-toggle="tab"><Trans id="Content.Concept"></Trans></a></li>
                                    <li><a href="#tab_indicator" data-toggle="tab"><Trans id="Content.IndicatorsBuiltConcept" /></a></li>
                                    <li><a href="#tab_indicator_a" data-toggle="tab"><Trans id="Table.IndicatorsAppliedAssociated" /></a></li>
                                    <li><a href="#tab_others" data-toggle="tab"><Trans id="Content.FurtherConceptsLemma" /></a></li>
                                </ul>
                                <div className="tab-content no-padding">
                                    <div className="tab-pane active" id="tab_concept" style={{ clear: "both" }}>
                                        <div className="form-group row underlined">
                                            <div className="col-md-6">
                                                <label><Trans id="Table.Lemma"></Trans>:</label>
                                                <div id="lemma">{sel_concept ? sel_concept.lemma : ''}</div>
                                            </div>
                                            <div className="col-md-6">
                                                <label><Trans id="Content.Specification"></Trans>:</label>
                                                <div id="is_applied">{sel_concept && sel_concept.is_applied ? 'Yes' : 'No'}</div>
                                            </div>
                                        </div>
                                        <div className="form-group row underlined">
                                            <div className="col-md-12">
                                                <label><Trans id="Table.Definition"></Trans>:</label>
                                                <div id="definition">{sel_concept ? sel_concept.definition : ''}</div>
                                            </div>
                                        </div>
                                        <div className="form-group row underlined">
                                            <div className="col-md-12">
                                                <label><Trans id="Table.Source"></Trans>:</label>
                                                {
                                                    sel_concept && sel_concept.sources ? sel_concept.sources.map(source => {
                                                        return <div className="lista_item" key={"source_" + source.source_id}><a href={source.source} target="_blank">{source.source}</a> </div>
                                                    }) : <></>
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group row underlined">
                                            <div className="col-md-12">
                                                <label><Trans id="Table.Ontoclass"></Trans>:</label>
                                                {
                                                    sel_concept && sel_concept.ontoclasses ? sel_concept.ontoclasses.map(ontoclass => {
                                                        return <div className="lista_item" key={"ontoclass_" + ontoclass.ontoclass_id}><a href={ontoclass.ontoclass} target="_blank">{ontoclass.ontoclass}</a> </div>
                                                    }) : <></>
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group row underlined">
                                            <div className="col-md-12">
                                                <label><Trans id="Table.Biblio"></Trans>:</label>
                                                {
                                                    sel_concept && sel_concept.biblios ? sel_concept.biblios.map(biblio => {
                                                        return <div className="lista_item" key={"biblio_" + biblio.biblio_id}><a href={biblio.biblio} target="_blank">{biblio.biblio}</a> </div>
                                                    }) : <></>
                                                }
                                            </div>
                                        </div>
                                        <h3><Trans id="Content.Relations"></Trans></h3>
                                        <div id="box_concetto_rel" className="box_fonte">
                                            {
                                                sel_concept && sel_concept.rels ? sel_concept.rels.map((rel, index) => {
                                                    return <div id="rel_line" className="linea_sx" key={"rel_" + index}>
                                                        <div><span><Trans id="Content.TypeRelation"></Trans>: </span><strong id="type_relation">{rel.type_relation}</strong>
                                                        </div>
                                                        <div><span><Trans id="Table.Lemma"></Trans>: </span><strong id="rel_lemma">{rel.rel_lemma}</strong></div>
                                                        <div><span><Trans id="Table.Definition"></Trans>: </span><strong id="rel_definition">{rel.rel_definition}</strong></div>
                                                        <div className="rx_comandi">
                                                            <button type="button" className="btn btn-default btn-xs" id="rel_concept" onClick={() => this.props.history.push(`/concept/${rel.concept_rel_id}`)}><span
                                                                className="glyphicon glyphicon-link" aria-hidden="true" ></span></button>
                                                        </div>
                                                    </div>
                                                }) : <></>
                                            }
                                        </div>
                                        <div className="comandi">
                                            <div>
                                                {
                                                    list_transition && (list_transition.indexOf('update') > -1) ? <button className="btn btn-default view" id="update"
                                                        onClick={() => { this.props.history.push(`/newconcept/${sel_concept.concept_id}`) }}><b><Trans id="Button.Modification"></Trans></b></button> : <></>
                                                }
                                                {
                                                    profile && (profile === 'Administrator' || profile === 'Publisher') ? <button className="btn btn-default" id="add_indicator" onClick={() => { this.props.history.push(`/newtheor/null/${sel_concept.concept_id}`) }}><b><Trans id="Table.AddIndicatorTheor"></Trans></b></button> : <></>
                                                }
                                                {
                                                    list_transition && (list_transition.indexOf('deletion') > -1) ? <button className="btn" id="delete" onClick={() => { this.props.modalActions.showModal('DELETE_CONCEPT_MODAL') }}><b><Trans id="Button.Delete"></Trans></b></button> : <></>
                                                }
                                                <button className="btn" id="back" onClick={() => { this.props.history.goBack(); this.props.commonActions.deletePathname(); }}><b><Trans id="Button.Back"></Trans></b></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="tab_indicator">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table id="list_table_indicator" className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <span data-tip data-for="header11"><Trans id="Content.Name" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header11" className="dynTooltip">
                                                                    <div><Trans id="Content.Name" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header12"><Trans id="Content.Note" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header12" className="dynTooltip">
                                                                    <div><Trans id="Content.Note" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header13"><Trans id="Modal.OntoclassRefer" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header13" className="dynTooltip">
                                                                    <div><Trans id="Modal.OntoclassRefer" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header9"><Trans id="Table.LastUpdate" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header9" className="dynTooltip">
                                                                    <div><Trans id="Table.LastUpdate" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header10"><Trans id="Content.User" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header10" className="dynTooltip">
                                                                    <div><Trans id="Content.User" /></div>
                                                                </ReactTooltip>:
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="tab_indicator_a">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table id="list_table_indicator_a" className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <span data-tip data-for="header11"><Trans id="Content.Name" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header11" className="dynTooltip">
                                                                    <div><Trans id="Content.Name" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header12"><Trans id="Content.Note" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header12" className="dynTooltip">
                                                                    <div><Trans id="Content.Note" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header13"><Trans id="Modal.OntoclassRefer" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header13" className="dynTooltip">
                                                                    <div><Trans id="Modal.OntoclassRefer" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header9"><Trans id="Table.LastUpdate" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header9" className="dynTooltip">
                                                                    <div><Trans id="Table.LastUpdate" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header10"><Trans id="Content.User" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header10" className="dynTooltip">
                                                                    <div><Trans id="Content.User" /></div>
                                                                </ReactTooltip>:
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="tab_others">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table id="list_table_other" className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <span data-tip data-for="header1"><Trans id="Table.Lemma" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header1" className="dynTooltip">
                                                                    <div><Trans id="Table.Lemma" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header2"><Trans id="Table.Definition" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
                                                                    <div><Trans id="Table.Definition" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header3"><Trans id="Table.Source" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header3" className="dynTooltip">
                                                                    <div><Trans id="Table.Source" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header4"><Trans id="Table.IndicatorsTheor" /><br></br><Trans id="Table.Contained" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header4" className="dynTooltip">
                                                                    <div><Trans id="Table.IndicatorsTheor" /><br></br><Trans id="Table.Contained" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th><span data-tip data-for="header5"><Trans id="Table.IndicatorsApplied" /><br></br><Trans id="Table.Contained" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header5" className="dynTooltip">
                                                                    <div><Trans id="Table.IndicatorsApplied" /><br></br><Trans id="Table.Contained" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header6"><Trans id="Modal.OntoclassRefer" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header6" className="dynTooltip">
                                                                    <div><Trans id="Modal.OntoclassRefer" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header7"><Trans id="Table.LastUpdate" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header7" className="dynTooltip">
                                                                    <div><Trans id="Table.LastUpdate" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                            <th>
                                                                <span data-tip data-for="header8"><Trans id="Content.User" /></span>
                                                                <ReactTooltip place="top" effect="solid" id="header8" className="dynTooltip">
                                                                    <div><Trans id="Content.User" /></div>
                                                                </ReactTooltip>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({
        concepts: state.concepts,
        utilities: state.utilities,
        auth: state.auth
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname, addPathname, deletePathname }, dispatch),
        conceptActions: bindActionCreators({ getConcepts, getConceptDetail, postConcept, deleteConcept }, dispatch),
        utilityActions: bindActionCreators({ getUtilities }, dispatch),
        eventActions: bindActionCreators({ getEvents }, dispatch),
        modalActions: bindActionCreators({ showModal }, dispatch),

    })
)(ConceptDetail);
