import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from 'react-router-dom';
import Trans from '../../../services/utils/Trans';
import { connect } from 'react-redux';

class Aside extends React.Component {
    render() {
        var profile = null;
        var userId = null;
        var project_id;
        if (this.props.auth.currentUser) {
            profile = this.props.auth.currentUser.profile;
            userId = this.props.auth.currentUser.user_id;
            project_id = this.props.auth.currentUser.projects.length ? 'app/map/' + this.props.auth.currentUser.projects[0].project_id : '';
        }
        return (
            <aside className="main-sidebar">
                {/* sidebar: style can be found in sidebar.less */}
                <section className="sidebar">
                    <ul className="sidebar-menu" data-widget="tree">
                        <li >
                            <NavLink to="/projects" className="treeview" >
                                <FontAwesomeIcon className="project-color sidebar-icon" icon={["fas", "circle"]} />
                                <span><Trans id="Sidebar.Projects" /></span>
                            </NavLink>
                        </li>
                        {
                            profile === "Administrator" ? <li >
                                <NavLink to="/ontoclasses" className="treeview">
                                    <FontAwesomeIcon className="ontoclass-color sidebar-icon" icon={["fas", "circle"]} />
                                    <span><Trans id="Sidebar.Ontoclasses" /></span>
                                </NavLink>
                            </li> : <></>
                        }
                        {
                            profile === "Administrator" || profile === "Publisher" ? <li >
                                <NavLink to="/lemmas" className="treeview">
                                    <FontAwesomeIcon className="lemma-color sidebar-icon" icon={["fas", "circle"]} />
                                    <span><Trans id="Sidebar.Lemmas" /></span>
                                </NavLink>
                            </li> : <></>
                        }
                        {
                            profile === "Administrator" || profile === "Publisher" ? <li >
                                <NavLink to="/definitions" className="treeview">
                                    <FontAwesomeIcon className="definition-color sidebar-icon" icon={["fas", "circle"]} />
                                    <span><Trans id="Sidebar.Definitions" /></span>
                                </NavLink>
                            </li> : <></>
                        }
                        {
                            profile === "Administrator" || profile === "Publisher" ? <li >
                                <NavLink to="/sources" className="treeview">
                                    <FontAwesomeIcon className="source-color sidebar-icon" icon={["fas", "circle"]} />
                                    <span><Trans id="Sidebar.Sources" /></span>
                                </NavLink>
                            </li> : <></>
                        }
                        {
                            profile === "Administrator" || profile === "Publisher" ? <li >
                                <NavLink to="/biblios" className="treeview">
                                    <FontAwesomeIcon className="biblio-color sidebar-icon" icon={["fas", "circle"]} />
                                    <span><Trans id="Sidebar.Biblio" /></span>
                                </NavLink>
                            </li> : <></>
                        }
                        <li >
                            <NavLink to="/concepts" className="treeview" >
                                <FontAwesomeIcon className="concept-color sidebar-icon" icon={["fas", "circle"]} />
                                <span><Trans id="Sidebar.Concepts" /></span>
                            </NavLink>
                        </li>
                        <li >
                            <NavLink to="/indicators" className="treeview" >
                                <FontAwesomeIcon className="indicator-color sidebar-icon" icon={["fas", "circle"]} />
                                <span><Trans id="Sidebar.Indicators" /></span>
                            </NavLink>
                        </li>
                        {
                            profile === "Administrator" ? <li >
                                <NavLink to="/utilities" className="treeview">
                                    <FontAwesomeIcon className="utility-color sidebar-icon" icon={["fas", "circle"]} />
                                    <span><Trans id="Sidebar.Utilities" /></span>
                                </NavLink>
                            </li> : <></>
                        }
                        {
                            profile === "Administrator" || profile === "Publisher" ? <li >
                                <NavLink to="/requests" className="treeview">
                                    <FontAwesomeIcon className="request-color sidebar-icon" icon={["fas", "circle"]} />
                                    <span><Trans id="Sidebar.Requests" /></span>
                                </NavLink>
                            </li> : <></>
                        }
                        {
                            profile === "Administrator" ? <li >
                                <NavLink to="/users" className="treeview">
                                    <FontAwesomeIcon className="users-color sidebar-icon" icon={["fas", "circle"]} />
                                    <span><Trans id="Sidebar.Users" /></span>
                                </NavLink>
                            </li> : <></>
                        }
                        {
                            (profile === "Publisher" || profile === "Guest") && userId ? <li >
                                <NavLink to={`/user/${userId}`} className="treeview">
                                    <FontAwesomeIcon className="users-color sidebar-icon" icon={["fas", "circle"]} />
                                    <span><Trans id="Sidebar.User" /></span>
                                </NavLink>
                            </li> : <></>
                        }
                        <li >
                            <NavLink className="treeview" to={{pathname:"https://smartview.mosis-lab.org/" + project_id}} target="_blank">
                                <FontAwesomeIcon className="map-color sidebar-icon" icon={["fas", "circle"]} />
                                <span><Trans id="Sidebar.Map" /></span>
                            </NavLink>
                        </li>
                    </ul>
                </section>
			<section>
			<div style={{"paddingTop":"200px", "align": "center", "color":"#fff"}}>
				Contacts: <span className="pointer" style={{"color":"#fff", "align":"center"}}  onClick={() => window.location = 'mailto:mosis@pin.unifi.it'}> mosis@pin.unifi.it</span>
			</div>
			</section>
            </aside>
        )
    }
}

export default connect(
    state => ({
        auth: state.auth
    }),
    null
)(Aside);
