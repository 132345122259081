import { createActions } from 'redux-actions';

const {
    getSources,
    getSourcesFailed,
    getSourcesSucceed,
    postSource,
    postSourceFailed,
    postSourceSucceed,
    getSourceDetail,
    getSourceDetailFailed,
    getSourceDetailSucceed,
    updateSource,
    updateSourceFailed,
    updateSourceSucceed,
    deleteSource,
    deleteSourceFailed,
    deleteSourceSucceed,
    getSourceAuto,
    getSourceAutoFailed,
    getSourceAutoSucceed

} = createActions({
    GET_SOURCES: () => ({}),
    GET_SOURCES_FAILED: error => ({ error }),
    GET_SOURCES_SUCCEED: sources => ({ sources }),
    POST_SOURCE: (source, owner_ontoclass_id) => ({ source, owner_ontoclass_id }),
    POST_SOURCE_FAILED: error => ({ error }),
    POST_SOURCE_SUCCEED: sources => ({ sources }),
    GET_SOURCE_DETAIL: source_id => ({ source_id }),
    GET_SOURCE_DETAIL_FAILED: error => ({ error }),
    GET_SOURCE_DETAIL_SUCCEED: source => ({ source }),
    UPDATE_SOURCE: (source_id, source, owner_ontoclass_id) => ({ source_id, source, owner_ontoclass_id }),
    UPDATE_SOURCE_FAILED: error => ({ error }),
    UPDATE_SOURCE_SUCCEED: sources => ({ sources }),
    DELETE_SOURCE: source_id => ({ source_id }),
    DELETE_SOURCE_FAILED: error => ({ error }),
    DELETE_SOURCE_SUCCEED: () => ({}),
    GET_SOURCE_AUTO: query_string => ({ query_string }),
    GET_SOURCE_AUTO_FAILED: error => ({ error }),
    GET_SOURCE_AUTO_SUCCEED: sources => ({ sources })
});

export {
    getSources,
    getSourcesFailed,
    getSourcesSucceed,
    postSource,
    postSourceFailed,
    postSourceSucceed,
    getSourceDetail,
    getSourceDetailFailed,
    getSourceDetailSucceed,
    updateSource,
    updateSourceFailed,
    updateSourceSucceed,
    deleteSource,
    deleteSourceFailed,
    deleteSourceSucceed,
    getSourceAuto,
    getSourceAutoFailed,
    getSourceAutoSucceed
};
