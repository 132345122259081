import { handleActions } from 'redux-actions';

import {
    getIndicatorTheors,
    getIndicatorTheorsFailed,
    getIndicatorTheorsSucceed,
    postIndicatorTheor,
    postIndicatorTheorFailed,
    postIndicatorTheorSucceed,
    getIndicatorTheorDetail,
    getIndicatorTheorDetailFailed,
    getIndicatorTheorDetailSucceed,
    updateIndicatorTheor,
    updateIndicatorTheorFailed,
    updateIndicatorTheorSucceed,
    deleteIndicatorTheor,
    deleteIndicatorTheorFailed,
    deleteIndicatorTheorSucceed,
    getIndicatorTheorAuto,
    getIndicatorTheorAutoFailed,
    getIndicatorTheorAutoSucceed

} from './indicator_theorActions';

const defaultState = {
    indicator_theors: null,
    error: null,
    loading: false,
    success: false,
    message: null,
    sel_indicator_theor: null,
};

const reducer = handleActions(
    {
        [getIndicatorTheors](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null
            };
        },
        [getIndicatorTheorsFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error.data,
                indicator_theors: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [getIndicatorTheorsSucceed](
            state,
            {
                payload: { indicator_theors }
            }
        ) {
            return {
                ...state,
                indicator_theors: indicator_theors,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
        [postIndicatorTheor](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null,
                success: false,
            };
        },
        [postIndicatorTheorFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in postFailed', error)
            return {
                ...state,
                error,
                indicator_theors: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [postIndicatorTheorSucceed](
            state,
            {
                payload: { indicator_theors }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                message: "Add IndicatorTheor Succeed!",
                sel_indicator_theor: indicator_theors,
                success: true
            };
        },
        [getIndicatorTheorDetail](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getIndicatorTheorDetailFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in getIndicatorTheorDetail Failed', error)
            return {
                ...state,
                error: error,
                indicator_theors: null,
                loading: false,
                success: false,
                sel_indicator_theor: null,
                message: error.data.message
            };
        },
        [getIndicatorTheorDetailSucceed](
            state,
            {
                payload: { indicator_theor }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                success: true,
                message: null,
                sel_indicator_theor: indicator_theor
            };
        },
        [updateIndicatorTheor](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null
            };
        },
        [updateIndicatorTheorFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in updateIndicatorTheor', error)
            return {
                ...state,
                error,
                indicator_theors: null,
                loading: false,
                success: false,
                message: error && error.message ? error.message : "something went wrong"
            };
        },
        [updateIndicatorTheorSucceed](
            state,
            {
                payload: { indicator_theors }
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                sel_indicator_theor: indicator_theors,
                message: "Update IndicatorTheor Succeed!"
            };
        },
        [deleteIndicatorTheor](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null
            };
        },
        [deleteIndicatorTheorFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in deleteIndicatorTheor', error)
            return {
                ...state,
                error,
                indicator_theors: null,
                loading: false,
                success: false,
                message: error && error.message ? error.message : error
            };
        },
        [deleteIndicatorTheorSucceed](
            state,
            {
                payload: { indicator_theors }
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                sel_indicator_theor: null,
                success: true,
                message: "Delete IndicatorTheor Succeed!"
            };
        },
        [getIndicatorTheorAuto](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getIndicatorTheorAutoFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error.data,
                indicator_theors: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [getIndicatorTheorAutoSucceed](
            state,
            {
                payload: { indicator_theors }
            }
        ) {
            return {
                ...state,
                indicator_theors: indicator_theors,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
    },
    defaultState
);

export default reducer;
