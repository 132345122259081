import { createActions } from 'redux-actions';

const {
    getIndicatorTheors,
    getIndicatorTheorsFailed,
    getIndicatorTheorsSucceed,
    postIndicatorTheor,
    postIndicatorTheorFailed,
    postIndicatorTheorSucceed,
    getIndicatorTheorDetail,
    getIndicatorTheorDetailFailed,
    getIndicatorTheorDetailSucceed,
    updateIndicatorTheor,
    updateIndicatorTheorFailed,
    updateIndicatorTheorSucceed,
    deleteIndicatorTheor,
    deleteIndicatorTheorFailed,
    deleteIndicatorTheorSucceed,
    getIndicatorTheorAuto,
    getIndicatorTheorAutoFailed,
    getIndicatorTheorAutoSucceed

} = createActions({
    GET_INDICATOR_THEORS: (project_id) => ({ project_id }),
    GET_INDICATOR_THEORS_FAILED: error => ({ error }),
    GET_INDICATOR_THEORS_SUCCEED: indicator_theors => ({ indicator_theors }),
    POST_INDICATOR_THEOR: (name, note, concept_id, algorithm, concepts, owner_ontoclass_id, biblios) => ({ name, note, concept_id, algorithm, concepts, owner_ontoclass_id, biblios }),
    POST_INDICATOR_THEOR_FAILED: error => ({ error }),
    POST_INDICATOR_THEOR_SUCCEED: indicator_theors => ({ indicator_theors }),
    GET_INDICATOR_THEOR_DETAIL: indicator_theor_id => ({ indicator_theor_id }),
    GET_INDICATOR_THEOR_DETAIL_FAILED: error => ({ error }),
    GET_INDICATOR_THEOR_DETAIL_SUCCEED: indicator_theor => ({ indicator_theor }),
    UPDATE_INDICATOR_THEOR: (indicator_theor_id, name, note, concept_id, algorithm, concepts, owner_ontoclass_id, biblios) => ({ indicator_theor_id, name, note, concept_id, algorithm, concepts, owner_ontoclass_id, biblios }),
    UPDATE_INDICATOR_THEOR_FAILED: error => ({ error }),
    UPDATE_INDICATOR_THEOR_SUCCEED: indicator_theors => ({ indicator_theors }),
    DELETE_INDICATOR_THEOR: indicator_theor_id => ({ indicator_theor_id }),
    DELETE_INDICATOR_THEOR_FAILED: error => ({ error }),
    DELETE_INDICATOR_THEOR_SUCCEED: () => ({}),
    GET_INDICATOR_THEOR_AUTO: query_string => ({ query_string }),
    GET_INDICATOR_THEOR_AUTO_FAILED: error => ({ error }),
    GET_INDICATOR_THEOR_AUTO_SUCCEED: indicator_theors => ({ indicator_theors })
});

export {
    getIndicatorTheors,
    getIndicatorTheorsFailed,
    getIndicatorTheorsSucceed,
    postIndicatorTheor,
    postIndicatorTheorFailed,
    postIndicatorTheorSucceed,
    getIndicatorTheorDetail,
    getIndicatorTheorDetailFailed,
    getIndicatorTheorDetailSucceed,
    updateIndicatorTheor,
    updateIndicatorTheorFailed,
    updateIndicatorTheorSucceed,
    deleteIndicatorTheor,
    deleteIndicatorTheorFailed,
    deleteIndicatorTheorSucceed,
    getIndicatorTheorAuto,
    getIndicatorTheorAutoFailed,
    getIndicatorTheorAutoSucceed

};
