/**
 *
 * @link https://github.com/happy-tinker
 * @author Eric Lee
 * @since 9/23/218
 */

import { createActions } from 'redux-actions';

const {
    setPathname,
    addPathname,
    deletePathname
} = createActions({
    SET_PATHNAME: (path) => ({ path }),
    ADD_PATHNAME: (newpath) => ({ newpath }),
    DELETE_PATHNAME: () => ({ }),
});

export {
    setPathname,
    addPathname,
    deletePathname
};
