import { handleActions } from 'redux-actions';

import {
    getDefinitions,
    getDefinitionsFailed,
    getDefinitionsSucceed,
    postDefinition,
    postDefinitionFailed,
    postDefinitionSucceed,
    getDefinitionDetail,
    getDefinitionDetailFailed,
    getDefinitionDetailSucceed,
    updateDefinition,
    updateDefinitionFailed,
    updateDefinitionSucceed,
    deleteDefinition,
    deleteDefinitionFailed,
    deleteDefinitionSucceed,
    getDefinitionAuto,
    getDefinitionAutoFailed,
    getDefinitionAutoSucceed
} from './definitionActions';

const defaultState = {
    definitions: null,
    error: null,
    loading: false,
    success: false,
    message: null,
    sel_definition: null,
    ontoclasses: null
};

const reducer = handleActions(
    {
        [getDefinitions](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getDefinitionsFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error.data,
                definitions: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [getDefinitionsSucceed](
            state,
            {
                payload: { definitions }
            }
        ) {
            return {
                ...state,
                definitions: definitions,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
        [postDefinition](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null,
                success: false,
            };
        },
        [postDefinitionFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in getDefinitionDetail Failed', error)
            return {
                ...state,
                error: error,
                definitions: null,
                loading: false,
                success: false,
                sel_definition: null,
                message: error.data.message
            };
        },
        [getDefinitionDetailSucceed](
            state,
            {
                payload: { definition }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                success: true,
                message: null,
                sel_definition: definition
            };
        },
        [updateDefinition](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null
            };
        },
        [updateDefinitionFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in updateDefinition', error)
            return {
                ...state,
                error,
                definitions: null,
                loading: false,
                success: false,
                message: error && error.message ? error.message : error
            };
        },
        [updateDefinitionSucceed](
            state,
            {
                payload: { definitions }
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                sel_definition: definitions,
                message: "Update Definition Succeed!"
            };
        },
        [deleteDefinition](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null
            };
        },
        [deleteDefinitionFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in deleteDefinition', error)
            return {
                ...state,
                error,
                definitions: null,
                loading: false,
                success: false,
                message: error && error.message ? error.message : error
            };
        },
        [deleteDefinitionSucceed](
            state,
            {
                payload: { definitions }
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                sel_definition: null,
                success: true,
                message: "Delete Definition Succeed!"
            };
        },
        [getDefinitionAuto](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getDefinitionAutoFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error ? error : 'Error',
                loading: false,
                success: false,
                message: error ? error.message : 'API connection error'
            };
        },
        [getDefinitionAutoSucceed](
            state,
            {
                payload: { definitions }
            }
        ) {
            return {
                ...state,
                definitions: definitions,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
    },
    defaultState
);

export default reducer;
