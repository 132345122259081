import { createActions } from 'redux-actions';

const {
  login,
  loginFailed,
  loginSucceed,
  logout,
  logoutSucceed
} = createActions({
  LOGIN: (usr, pwd) => ({ usr, pwd }),
  LOGIN_FAILED: error => ({ error }),
  LOGIN_SUCCEED: userInfo => ({ userInfo }),
  LOGOUT: () => ({}),
  LOGOUT_SUCCEED: () => ({})
});

export {
  login,
  loginFailed,
  loginSucceed,
  logout,
  logoutSucceed
};
