import { put, takeEvery, call, all } from 'redux-saga/effects';
/** Import actions */
import {
    getIndicatorAppliedFailed,
    getIndicatorAppliedSucceed,
    postIndicatorAppliedFailed,
    postIndicatorAppliedSucceed,
    getIndicatorAppliedDetail,
    getIndicatorAppliedDetailFailed,
    getIndicatorAppliedDetailSucceed,
    updateIndicatorAppliedFailed,
    updateIndicatorAppliedSucceed,
    deleteIndicatorAppliedFailed,
    deleteIndicatorAppliedSucceed,
    updateIndicatorAppliedTimeFailed,
    updateIndicatorAppliedTimeSucceed,
    deleteIndicatorAppliedTimeFailed,
    deleteIndicatorAppliedTimeSucceed

} from './indicator_appliedActions';

/** Import api */
import * as indicator_appliedApi from './indicator_appliedApi';

export function* indicator_appliedSubscriber() {
    yield all([takeEvery('GET_INDICATOR_APPLIED', getIndicatorAppliedSaga)]);
    yield all([takeEvery('POST_INDICATOR_APPLIED', postIndicatorAppliedSaga)]);
    yield all([takeEvery('GET_INDICATOR_APPLIED_DETAIL', getIndicatorAppliedDetailSaga)]);
    yield all([takeEvery('UPDATE_INDICATOR_APPLIED', updateIndicatorAppliedSaga)]);
    yield all([takeEvery('DELETE_INDICATOR_APPLIED', deleteIndicatorAppliedSaga)]);
    yield all([takeEvery('POST_INDICATOR_APPLIED_TIME', postIndicatorAppliedTimeSaga)]);
    yield all([takeEvery('UPDATE_INDICATOR_APPLIED_TIME', updateIndicatorAppliedTimeSaga)]);
    yield all([takeEvery('DELETE_INDICATOR_APPLIED_TIME', deleteIndicatorAppliedTimeSaga)]);

}
export function* getIndicatorAppliedSaga({ payload: { project_id } }) {
    try {
        const indicators_applied = yield call(indicator_appliedApi.get, project_id);
        yield put(getIndicatorAppliedSucceed(indicators_applied));
    } catch (error) {
        console.log('indicator_applied saga error')
        yield put(getIndicatorAppliedFailed(error));
    }
}
export function* postIndicatorAppliedSaga({ payload: { name, note, indicator_theor_id, concepts, owner_ontoclass_id, sources, leg_refs} }) {
    try {
        const indicators_applied = yield call(indicator_appliedApi.postIndicatorApplied, name, note, indicator_theor_id, concepts, owner_ontoclass_id, sources, leg_refs);
        yield put(postIndicatorAppliedSucceed(indicators_applied));
    } catch (error) {
        console.log("error--", error)
        yield put(postIndicatorAppliedFailed(error));
    }
}
export function* getIndicatorAppliedDetailSaga({ payload: { indicator_applied_id } }) {
    try {
        const indicator_applied = yield call(indicator_appliedApi.getIndicatorAppliedDetailApi, indicator_applied_id);
        yield put(getIndicatorAppliedDetailSucceed(indicator_applied));
    } catch (error) {
        console.log('indicator_appliedDetail saga error')
        yield put(getIndicatorAppliedDetailFailed(error));
    }
}
export function* updateIndicatorAppliedSaga({ payload: { indicator_applied_id, name, note, indicator_theor_id, concepts, owner_ontoclass_id, sources, leg_refs } }) {
    try {
        const indicators_applied = yield call(indicator_appliedApi.updateIndicatorAppliedApi, indicator_applied_id, name, note, indicator_theor_id, concepts, owner_ontoclass_id, sources, leg_refs);
        yield put(updateIndicatorAppliedSucceed(indicators_applied));
    } catch (error) {
        console.log("error--", error)
        yield put(updateIndicatorAppliedFailed(error));
    }
}
export function* deleteIndicatorAppliedSaga({ payload: { indicator_applied_id } }) {
    try {
        yield call(indicator_appliedApi.deleteIndicatorAppliedApi, indicator_applied_id);
        yield put(deleteIndicatorAppliedSucceed());
    } catch (error) {
        console.log("error--", error)
        yield put(deleteIndicatorAppliedFailed(error));
    }
}
export function* postIndicatorAppliedTimeSaga({ payload: { indicator_applied_id, time_id, value, measure_id } }) {
    try {
        const indicators_applied = yield call(indicator_appliedApi.postIndicatorAppliedTime, indicator_applied_id, time_id, value, measure_id);
        yield put(postIndicatorAppliedSucceed(indicators_applied));
    } catch (error) {
        console.log("error--", error)
        yield put(postIndicatorAppliedFailed(error));
    }
}
export function* updateIndicatorAppliedTimeSaga({ payload: { indicator_applied_id, indicator_a_time_id, time_id, value, measure_id } }) {
    try {
        const indicators_applied = yield call(indicator_appliedApi.updateIndicatorAppliedTimeApi, indicator_applied_id, indicator_a_time_id, time_id, value, measure_id);
        yield put(updateIndicatorAppliedTimeSucceed(indicators_applied));
    } catch (error) {
        console.log("error--", error)
        yield put(updateIndicatorAppliedTimeFailed(error));
    }
}
export function* deleteIndicatorAppliedTimeSaga({ payload: { indicator_applied_id, indicator_a_time_id } }) {
    try {
        yield call(indicator_appliedApi.deleteIndicatorAppliedTimeApi, indicator_applied_id, indicator_a_time_id);
        yield put(deleteIndicatorAppliedTimeSucceed());
        yield put(getIndicatorAppliedDetail(indicator_applied_id))
    } catch (error) {
        console.log("error--", error)
        yield put(deleteIndicatorAppliedTimeFailed(error));
    }
}