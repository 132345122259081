import { handleActions } from 'redux-actions';

import {
    getSources,
    getSourcesFailed,
    getSourcesSucceed,
    postSource,
    postSourceFailed,
    postSourceSucceed,
    getSourceDetail,
    getSourceDetailFailed,
    getSourceDetailSucceed,
    updateSource,
    updateSourceFailed,
    updateSourceSucceed,
    deleteSource,
    deleteSourceFailed,
    deleteSourceSucceed,
    getSourceAuto,
    getSourceAutoFailed,
    getSourceAutoSucceed
} from './sourceActions';

const defaultState = {
    sources: null,
    error: null,
    loading: false,
    success: false,
    message: null,
    sel_source: null,
};

const reducer = handleActions(
    {
        [getSources](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getSourcesFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error.data,
                sources: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [getSourcesSucceed](
            state,
            {
                payload: { sources }
            }
        ) {
            return {
                ...state,
                sources: sources,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
        [postSource](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null,
                success: false,
            };
        },
        [postSourceFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in postFailed', error)
            return {
                ...state,
                error,
                sources: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [postSourceSucceed](
            state,
            {
                payload: { sources }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                message: "Add Source Succeed!",
                sel_source: sources,
                success: true
            };
        },
        [getSourceDetail](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getSourceDetailFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in getSourceDetail Failed', error)
            return {
                ...state,
                error: error,
                sources: null,
                loading: false,
                success: false,
                sel_source: null,
                message: error.data.message
            };
        },
        [getSourceDetailSucceed](
            state,
            {
                payload: { source }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                success: true,
                message: null,
                sel_source: source
            };
        },
        [updateSource](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null
            };
        },
        [updateSourceFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in updateSource', error)
            return {
                ...state,
                error,
                sources: null,
                loading: false,
                success: false,
                message: error && error.message ? error.message : error
            };
        },
        [updateSourceSucceed](
            state,
            {
                payload: { sources }
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                sel_source: sources,
                message: "Update Source Succeed!"
            };
        },
        [deleteSource](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null
            };
        },
        [deleteSourceFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in deleteSource', error)
            return {
                ...state,
                error,
                sources: null,
                loading: false,
                success: false,
                message: error && error.message ? error.message : error
            };
        },
        [deleteSourceSucceed](
            state,
            {
                payload: { sources }
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                sel_source: null,
                success: true,
                message: "Delete Source Succeed!"
            };
        },
        [getSourceAuto](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getSourceAutoFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error ? error : 'Error',
                loading: false,
                success: false,
                message: error ? error.message : 'API connection error'
            };
        },
        [getSourceAutoSucceed](
            state,
            {
                payload: { sources }
            }
        ) {
            return {
                ...state,
                sources: sources,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
    },
    defaultState
);

export default reducer;
