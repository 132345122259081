import React from 'react';
import { connect } from 'react-redux';
import { setPathname, addPathname, deletePathname } from '../../services/common/commonActions';
import { getConceptDetail, postConcept, getConceptAuto, updateConcept } from '../../services/concept/conceptActions';
import { postIndicatorTheor, getIndicatorTheorDetail, updateIndicatorTheor, getIndicatorTheorAuto } from '../../services/indicatorTheor/indicator_theorActions';
import { postIndicatorApplied, updateIndicatorApplied, getIndicatorAppliedDetail } from '../../services/indicatorApplied/indicator_appliedActions';
import { getSourceAuto } from '../../services/source/sourceActions';
import { getUtilities, postUtility } from '../../services/utilities/utilityActions';
import { showModal } from '../../modals/modalConductorActions';
import { getOntoclasses } from '../../services/ontoclass/ontoclassActions';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import MathJax from 'react-mathjax2'

class NewApplied extends React.Component {
    constructor() {
        super();
        this.state = {
            owner_ontoclass_id: 1,
            name: '',
            note: '',
            theorItem: {},
            search_theorItem: '',
            search_concept_array: [],
            conceptArray: [],
            conceptItem: {},
            conceptOptions: [],
            theorOptions: [],
            algorithmItem: {},
            search_concept_id: '',
            algorithmArray: [],
            algorithmString: '',
            indicator_t_id: null,
            appliedId: null,
            sourceItem: {},
            sourceOptions: [],
            search_source: [],
            sourceArray: [],
            legisl_id: 0,
            legisl_label: '',
            legislArray: [],
            search_legisl: [],
        }
        this.theor_typeahead_ref = null;
        this.source_typeahead_ref = null;
        this.concept_typeahead_ref = null;

    }
    componentWillMount() {
        // this.props.commonActions.setPathname(
        //     [
        //         { "pathName": "Home", "Url": "", "id": 1, "color": "" },
        //         { "pathName": "Indicators", "Url": "indicators", "id": 2, "color": "#927e9b" },
        //         { "pathName": "NewApplied", "Url": "/", "id": 3, "color": "#927e9b" },
        //     ]
        // )
        this.props.ontoclassActions.getOntoclasses();
        var _indicator_t_id = this.props.match.params.indicator_t_id;
        var _appliedId = this.props.match.params.appliedId
        if (_indicator_t_id !== 'null' && _indicator_t_id !== undefined) {
            this.props.indicator_theorActions.getIndicatorTheorDetail(_indicator_t_id);
        }
        if (_appliedId !== 'null' && _appliedId !== undefined) {
            this.props.indicator_appliedActions.getIndicatorAppliedDetail(_appliedId);
        }
        this.setState({
            indicator_t_id: _indicator_t_id,
            appliedId: _appliedId,
        })
        this.props.utilityActions.getUtilities('legisl');
        var ontoclasses = null;
        if (this.props && this.props.auth && this.props.auth.currentUser) {
            var profile = this.props.auth.currentUser.profile;
            if (profile === "Administrator") {
                ontoclasses = this.props.ontoclasses ? this.props.ontoclasses.ontoclasses : []
            } else if (profile === "Publisher") {
                ontoclasses = this.props.auth.currentUser.ontoclasses
            }
            if (ontoclasses !== null) {
                this.setState({
                    owner_ontoclass_id: ontoclasses[0].ontoclass_id
                })
            }
        }
    }
    componentDidMount() {
        if (this.props.indicator_theors && this.props.indicator_theors.sel_indicator_theor && this.props.indicator_theors.sel_indicator_theor.indicator) {
            var indicator_name = this.props.indicator_theors.sel_indicator_theor.indicator.name;
            this.props.commonActions.addPathname(
                { "pathName": indicator_name, "Url": "newapplied/null/" + this.props.match.params.indicator_t_id, "color": "#6d81ab", "subHeader": "New Applied in Theor" }
            )
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.indicator_theors && this.props.indicator_theors.indicator_theors !== prevProps.indicator_theors.indicator_theors && this.props.indicator_theors.indicator_theors && this.props.indicator_theors.indicator_theors.suggestions) {
            this.setState({
                theorOptions: this.props.indicator_theors.indicator_theors.suggestions
            });
        }
        if (this.props.sources && this.props.sources.sources !== prevProps.sources.sources) {
            this.setState({
                sourceOptions: this.props.sources.sources.suggestions
            })
        }
        var _algorithm = '';
        if (this.props.indicator_theors && this.props.indicator_theors.sel_indicator_theor && prevProps.indicator_theors && prevProps.indicator_theors.sel_indicator_theor && this.props.indicator_theors.sel_indicator_theor !== prevProps.indicator_theors.sel_indicator_theor) {
            var sel_indicator_theor = this.props.indicator_theors.sel_indicator_theor.indicator;
            if (sel_indicator_theor) {
                this.props.commonActions.addPathname(
                    { "pathName": "New Applied in Theor", "Url": "newapplied/null/" + this.props.match.params.indicator_t_id, "color": "#6d81ab", "subHeader": "New Applied in Theor" }
                )
                var temp_theor = {
                    t_name: sel_indicator_theor.name,
                    indicator_t_id: sel_indicator_theor.indicator_t_id
                };
                try {
                    _algorithm = JSON.parse(sel_indicator_theor.algorithm.replace(/'/g, '"'));
                } catch (err) {
                    _algorithm = '';
                }
                this.setState({
                    theorItem: temp_theor,
                    search_theorItem: sel_indicator_theor.indicator_t_id,
                    algorithmArray: _algorithm
                })
            }
        }
        var sources;
        if (this.props.sources.error === null && !this.props.sources.loading && prevProps.sources && this.props.sources.message === null && prevProps.sources.sources !== this.props.sources.sources && !this.props.sources.sources.suggestions) {
            sources = this.props.sources.sources;
            var _arraySource = this.state.sourceArray;
            var _searchSource = this.state.search_source;
            var item_sources = sources[sources.length - 1];
            _searchSource.push(item_sources.source_id.toString());
            var tempsourceArry = { value: item_sources.source_id, label: item_sources.source };
            _arraySource.push(tempsourceArry);
            this.setState({ sourceArray: _arraySource, search_source: _searchSource })
        }
        if (this.props.concepts && this.props.concepts.concepts && this.props.concepts.concepts !== prevProps.concepts.concepts && this.props.concepts.concepts.suggestions) {
            this.setState({
                conceptOptions: this.props.concepts.concepts.suggestions
            });
        }
        if (this.props.indicators_applied && this.props.indicators_applied.sel_indicator_applied && this.props.indicators_applied.sel_indicator_applied !== prevProps.indicators_applied.sel_indicator_applied) {
            var sel_indicator_applied = this.props.indicators_applied.sel_indicator_applied.indicator;
            if (sel_indicator_applied.indicator_theor) {
                var temp_array = {
                    t_name: sel_indicator_applied.indicator_theor.name,
                    indicator_t_id: sel_indicator_applied.indicator_theor.indicator_theor_id
                };
                console.log('algorithem test', sel_indicator_applied.indicator_theor.algorithm)
                try {
                    _algorithm = JSON.parse(sel_indicator_applied.indicator_theor.algorithm.replace(/'/g, '"'));
                } catch (err) {
                    _algorithm = ''
                }
                this.setState({
                    theorItem: temp_array ? temp_array : {},
                    search_theorItem: sel_indicator_applied.indicator_theor.indicator_theor_id,
                    algorithmArray: _algorithm
                })
            };
            if (sel_indicator_applied) {
                this.setState({
                    name: sel_indicator_applied.name,
                    note: sel_indicator_applied.note ? sel_indicator_applied.note : '',

                });
                sources = sel_indicator_applied.sources;
                if (sources && sources.length > 0) {
                    var _arraySource = this.state.sourceArray;
                    var _arraySearch = this.state.search_source;

                    for (let source of sources) {
                        var temp_source = {
                            value: source.source_id,
                            label: source.source
                        };
                        _arraySource.push(temp_source);
                        _arraySearch.push(source.source_id.toString());
                        this.setState({
                            sourceArray: _arraySource,
                            search_source: _arraySearch,
                        });
                        /*
                        this.setState({
                            sourceArray: [
                                ...this.state.sourceArray,
                                temp_source
                            ],
                            search_source: [
                                ...this.state.search_source,
                                source.source_id.toString()
                            ]
                        })
                        */
                    }
                }
                var cohorts = sel_indicator_applied.cohort;
                if (cohorts && cohorts.length > 0) {
                    var _arrayCohort = this.state.conceptArray;
                    var _arraySearch = this.state.search_concept_array;
                    for (let cohort of cohorts) {
                        var temp_concept = {
                            lemma: cohort.lemma,
                            definition: cohort.definition,
                            id: cohort.concept_id
                        }
                        _arrayCohort.push(temp_concept);
                        _arraySearch.push(cohort.concept_id.toString());
                        this.setState({
                            legislArray: _arrayLegisl,
                            // search_legisl: _arrayCohort,
                        });
                        /*
                        this.setState({
                            conceptArray: [
                                ...this.state.conceptArray,
                                temp_concept
                            ],
                            search_concept_array: [
                                ...this.state.search_concept_array,
                                cohort.concept_id.toString()
                            ]
                        });
                        */
                    }
                }
                var legisls = sel_indicator_applied.legisls;
                if (legisls && legisls.length > 0) {
                    var _arrayLegisl = this.state.legislArray;
                    var _arraySearch = this.state.search_legisl;
                    for (let legisl of legisls) {
                        var temp_legisl = {
                            id: legisl.legisl_id,
                            label: legisl.legisl
                        }
                        _arrayLegisl.push(temp_legisl);
                        _arraySearch.push(legisl.legisl_id.toString());
                        this.setState({
                            legislArray: _arrayLegisl,
                            search_legisl: _arraySearch,
                        });
                        /*
                        this.setState({
                            legislArray: [
                                ...this.state.legislArray,
                                temp_legisl
                            ],
                            search_legisl: [
                                ...this.state.search_legisl,
                                legisl.legisl_id.toString()
                            ]
                        })
                        */
                    }
                }
                if (sel_indicator_applied.owner_ontoclass_id) {
                    this.setState({
                        owner_ontoclass_id: sel_indicator_applied.owner_ontoclass_id
                    })
                }
            }
        }
        if (this.state.algorithmArray && prevState.algorithmArray && this.state.algorithmArray !== prevState.algorithmArray) {
            var temp_string = '';
            if (this.state.algorithmArray.length > 0) {
                for (var i = 0; i < this.state.algorithmArray.length; i++) {
                    if (this.state.algorithmArray[i].type === 'string') {
                        temp_string += `"${this.state.algorithmArray[i].content}"`
                    } else {
                        temp_string += this.state.algorithmArray[i].content;
                    }
                }
            }
            this.setState({
                algorithmString: temp_string
            })
        }
        if (this.props.indicators_applied.error !== prevProps.indicators_applied.error && this.props.indicators_applied.error !== null) {
            toastr.error('Error Occured', this.props.indicators_applied.message);
        }

        if (this.props.indicators_applied.error === null && !this.props.indicators_applied.loading && prevProps.indicators_applied && prevProps.indicators_applied.loading && this.props.indicators_applied.message) {
            toastr.success('Success', this.props.indicators_applied.message);
            if (this.props.indicators_applied.sel_indicator_applied.indicator.indicator_applied_id) {
                this.props.history.push(`/applieddetail/${this.props.indicators_applied.sel_indicator_applied.indicator.indicator_applied_id}`)
            }
        }
    }

    onFilterChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        console.log('ontoclass selected', e.target.value)
    }
    onLegislChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        var legisls = this.props.utilities && this.props.utilities.legisls ? this.props.utilities.legisls : '';
        if (legisls && legisls.length > 0) {
            for (var i = 0; i < legisls.length; i++) {
                if (legisls[i].id.toString() === e.target.value.toString()) {
                    this.setState({ legisl_label: legisls[i].label });
                }
            }
        }
    }

    handleAddTheor = (item) => {
        var _theorItem = this.state.theorItem;
        if (!_theorItem || (_theorItem && _theorItem.concept_id !== item[0].value)) {
            var t_name = item[0].label;
            var t_algorithm = item[0].algorithm;
            var _algorithm = '';
            try {
                _algorithm = JSON.parse(t_algorithm.replace(/'/g, '"'));
            } catch (err) {
                _algorithm = ''
            }
            var temp_array = {
                t_name,
                indicator_t_id: item[0].value
            }
            this.setState({
                theorItem: temp_array,
                search_theorItem: item[0].value,
                algorithmArray: _algorithm
            })
        }
        const instance = this.theor_typeahead_ref.getInstance()
        instance.clear()
        instance.focus()
    }
    handleAddSource = (item) => {
        var _arraySource = this.state.sourceArray;
        var _searchSource = this.state.search_source;
        console.log("========================= handle add source:", this.state.search_source)
        var _is = false;
        if (_arraySource && _arraySource.length > 0) {
            for (var i = 0; i < _arraySource.length; i++) {
                if (_arraySource[i].value === item[0].value) {
                    _is = true;
                    const instance = this.source_typeahead_ref.getInstance()
                    instance.clear()
                    instance.focus()
                    break;
                }
            }
        }
        if (!_is) {
            _arraySource.push(item[0]);
            this.setState({
                sourceArray: _arraySource
            });
            _searchSource.push(item[0].value.toString());
            this.setState({
                search_source: _searchSource
            });
            const instance = this.source_typeahead_ref.getInstance()
            instance.clear()
            instance.focus()
        }
        console.log("========================= fine ", this.state.search_source)
    }
    handleAddLegisl = () => {
        var legisl = this.state.legisl_id;
        if (legisl !== 0) {
            var _legislArray = this.state.legislArray;
            var _search_legisl = this.state.search_legisl;
            var _is = false;
            if (_legislArray && _legislArray.length > 0) {
                for (var i = 0; i < _legislArray.length; i++) {
                    if (_legislArray[i].id.toString() === this.state.legisl_id.toString()) {
                        _is = true;
                    }
                }
            }
            if (!_is) {
                var _temp = {
                    id: this.state.legisl_id,
                    label: this.state.legisl_label
                };
                _legislArray.push(_temp);
                _search_legisl.push(this.state.legisl_id.toString());
            }
            this.setState({
                legisl_id: 0,
                legisl_label: ''
            })
        }
    }
    handleAddConceptArray = (item) => {
        var _conceptArray = this.state.conceptArray;
        var _searchConcept = this.state.search_concept_array;
        var _is = false;
        if (_conceptArray && _conceptArray.length > 0) {
            for (var i = 0; i < _conceptArray.length; i++) {
                if (_conceptArray[i].id === item[0].value) {
                    _is = true;
                }
            }
        }
        if (!_is) {
            var lemma = item[0].label.split("-")[0];
            var definition = item[0].label.split("-")[1];
            var temp_item = {
                lemma,
                definition,
                id: item[0].value
            };
            _conceptArray.push(temp_item);
            _searchConcept.push(item[0].value.toString());
            this.setState({
                conceptArray: _conceptArray,
                search_concept_array: _searchConcept
            });
        }
        const instance = this.concept_typeahead_ref.getInstance()
        instance.clear()
        instance.focus()
    }
    handleDeleteTheor = () => {
        this.setState({
            search_theorItem: '',
            algorithmArray: [],
            theorItem: {}
        });
    }
    handleDeleteSource = (value) => {
        var _searchSource = this.state.search_source;
        var _arraySource = this.state.sourceArray;
        if (_arraySource && _arraySource.length > 0) {
            for (var i = 0; i < _arraySource.length; i++) {
                if (_arraySource[i].value.toString() === value.toString()) {
                    _arraySource.splice(i, 1);
                    this.setState({
                        sourceArray: _arraySource
                    });
                    break;
                }
            }
        }
        var index = _searchSource.indexOf(value.toString());
        if (index > -1) {
            _searchSource.splice(index, 1);
        }
    }
    handleDeleteLegisl = (index) => {
        var _legislArray = this.state.legislArray;
        _legislArray.splice(index, 1);
        var _search_legisl = this.state.search_legisl;
        _search_legisl.splice(index, 1);
        console.log('comet to delete legisl', index, _legislArray, _search_legisl)
        this.setState({
            legislArray: _legislArray,
            search_legisl: _search_legisl
        })
    }
    handleDeleteConcept = (index) => {
        var _conceptArray = this.state.conceptArray;
        var _searchConcept = this.state.search_concept_array;
        _conceptArray.splice(index, 1);
        _searchConcept.splice(index, 1);
        this.setState({
            conceptArray: _conceptArray,
            search_concept_array: _searchConcept
        })

    }
    handleTheorSearch = (query) => {
        this.props.indicator_theorActions.getIndicatorTheorAuto(query);
    };
    handleSourceSearch = (query) => {
        this.props.sourceActions.getSourceAuto(query);
    }
    handleConceptSearch = (query) => {
        this.props.conceptActions.getConceptAuto(query);
    }
    handlePostIndicatorApplied = () => {
        var owner_ontoclass_id = this.state.owner_ontoclass_id;
        var name = this.state.name;
        var note = this.state.note;
        var indicator_theor_id = this.state.search_theorItem;
        var concepts = this.state.search_concept_array;
        var sources = this.state.search_source;
        var leg_refs = this.state.search_legisl;
        if (!name || !owner_ontoclass_id || !indicator_theor_id) {
            Swal.fire({
                icon: 'error',
                title: 'You need to select Name, Ontoclass, Indicator Theor',
            });
            return;
        }
        var indicator_applied_id = this.state.appliedId;
        if (indicator_applied_id !== 'null' && indicator_applied_id !== undefined) {
            console.log('come to post button', indicator_applied_id, name, note, indicator_theor_id, concepts, owner_ontoclass_id, sources, leg_refs)
            this.props.indicator_appliedActions.updateIndicatorApplied(indicator_applied_id, name, note, indicator_theor_id, concepts, owner_ontoclass_id, sources, leg_refs)
        } else {
            this.props.indicator_appliedActions.postIndicatorApplied(name, note, indicator_theor_id, concepts, owner_ontoclass_id, sources, leg_refs)
        }
    }
    render() {
        if (this.props.indicator_theors.loading) {
            Swal.fire({
                title: 'Loading...',
                text: "Plese wait",
                onOpen: () => {
                    Swal.showLoading();
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
        } else {
            Swal.close();
        }
        var ontoclasses = null;
        if (this.props && this.props.auth && this.props.auth.currentUser) {
            var profile = this.props.auth.currentUser.profile;
            if (profile === "Administrator") {
                ontoclasses = this.props.ontoclasses ? this.props.ontoclasses.ontoclasses : []
            } else if (profile === "Publisher") {
                ontoclasses = this.props.auth.currentUser.ontoclasses
            }
        }
        var legisls = null;
        if (this.props.utilities && this.props.utilities.legisls) {
            legisls = this.props.utilities.legisls
        }
        return (
            <React.Fragment>
                <section className="content skin-indicatori">
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label><Trans id="Modal.OntoclassRefer"></Trans>:</label>
                            <select name='owner_ontoclass_id' className="form-control" value={this.state.owner_ontoclass_id} onChange={this.onFilterChange} >
                                {
                                    ontoclasses ? ontoclasses.map(ontoclass => {
                                        return <option key={"onto_" + ontoclass.ontoclass_id} value={ontoclass.ontoclass_id}>{ontoclass.ontoclass}</option>
                                    }) : <></>
                                }
                            </select>
                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-6">
                            <label><Trans id="Content.Name"></Trans>:</label>
                            <div className="input-group">
                                <input type="text" className="form-control" name="name" size="100" value={this.state.name} onChange={this.onFilterChange} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label><Trans id="Content.Note"></Trans></label>
                            <div className="input-group">
                                <textarea className="form-control" name="note" cols="120" rows="2" value={this.state.note} onChange={this.onFilterChange}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-6">
                            <label>Indicatore teorico:</label>
                            <div className="input-group" style={{ width: "100%" }}>
                                <AsyncTypeahead
                                    minLength={2}
                                    id="search_indicator"
                                    isLoading={false}
                                    onChange={(e) => { this.handleAddTheor(e) }}
                                    onSearch={this.handleTheorSearch}
                                    options={this.state.theorOptions}
                                    placeholder="Cerca indicatore..."
                                    ref={(ref) => this.theor_typeahead_ref = ref}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            {
                                this.state.theorItem && this.state.theorItem.indicator_t_id ? <div className="lista_item" id="indicator_line" key={"indicator_t_" + this.state.theorItem.indicator_t_id}>
                                    <div><span>Indicatore teorico: </span><strong id="t_name">{this.state.theorItem.t_name}</strong></div>
                                    <div><span><Trans id="Content.Algorithm"></Trans>: </span><strong id="t_algorithm">{this.state.algorithmString ? <MathJax.Context input='ascii'>
                                        <div>
                                            <MathJax.Node inline>{this.state.algorithmString}</MathJax.Node>
                                        </div>
                                    </MathJax.Context> : <></>}</strong></div>
                                    <button type="button" className="btn btn-danger btn-xs" id="del_indicator" onClick={() => { this.handleDeleteTheor() }}><span
                                        className="glyphicon glyphicon-trash" aria-hidden="true"></span></button>
                                    <input type="hidden" name="indiciator_theor_id" />
                                </div> : <></>
                            }

                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label><Trans id="Table.Source" />:</label>
                            <div className="input-group">
                                <AsyncTypeahead
                                    minLength={2}
                                    id="search_source"
                                    isLoading={false}
                                    onChange={(e) => { this.handleAddSource(e) }}
                                    onSearch={this.handleSourceSearch}
                                    options={this.state.sourceOptions}
                                    placeholder="Cerca fonte..."
                                    ref={(ref) => this.source_typeahead_ref = ref}
                                />
                                <span className="input-group-btn">
                                    <button className="btn btn-default" data-type="button"
                                        id="new_source" onClick={() => { this.props.modalActions.showModal('ADD_SOURCE_MODAL') }}>+</button>
                                </span>
                            </div>
                            {
                                this.state.sourceArray && this.state.sourceArray.length > 0 ? this.state.sourceArray.map(item => {
                                    return (
                                        <div className="lista_item" id="source_line" key={`item-${item.value}`}>
                                            <i id="lemma"><a href={item.label} target="_blanck">{item.label}</a></i>
                                            <button type="button" className="btn btn-danger btn-xs" id="del_source" onClick={() => { this.handleDeleteSource(item.value) }}>
                                                <span className="glyphicon glyphicon-trash" aria-hidden="true" ></span>
                                            </button>
                                            <input type="hidden" name="source_id" />
                                        </div>
                                    )
                                }) : <></>
                            }
                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label>Riferimento normativo:</label>
                            <div className="input-group">
                                <select name="legisl_id" className="form-control" value={this.state.legisl_id} onChange={this.onLegislChange}>
                                    <option value={0} disabled>Select</option>
                                    {
                                        legisls ? legisls.map((legisl, index) => {
                                            return <option key={"legisl_" + index} value={legisl.id}>{legisl.label}</option>
                                        }) : <></>
                                    }
                                </select>
                                <button type="button" className="btn btn-danger btn-xs" id="add_legisl" onClick={() => { this.handleAddLegisl() }}>
                                    <span className="glyphicon glyphicon-plus"
                                        aria-hidden="true"></span></button>
                            </div>
                            {
                                this.state.legislArray && this.state.legislArray.length > 0 ? this.state.legislArray.map((legisl, index) => {
                                    return <div className="lista_item" id="legisl_line" key={"legisl_array_" + index}>
                                        <i id="legisl">{legisl.label} </i> <button type="button" className="btn btn-danger btn-xs" id="del_legisl" onClick={() => { this.handleDeleteLegisl(index) }}>
                                            <span
                                                className="glyphicon glyphicon-trash" aria-hidden="true"></span></button>
                                        <input type="hidden" name="legisl_id" />
                                    </div>
                                }) : <></>
                            }
                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label>Unità di osservazione:</label>
                            <div className="input-group">
                                <AsyncTypeahead
                                    minLength={2}
                                    id="search_concept"
                                    isLoading={false}
                                    onChange={(e) => { this.handleAddConceptArray(e) }}
                                    onSearch={this.handleConceptSearch}
                                    options={this.state.conceptOptions}
                                    placeholder="Cerca concetto..."
                                    ref={(ref) => this.concept_typeahead_ref = ref}
                                />
                            </div>
                            {
                                this.state.conceptArray && this.state.conceptArray.length > 0 ? this.state.conceptArray.map((concept, index) => {
                                    return <div className="lista_item" id="cohort_line" key={"concept_" + index}>
                                        <i><Trans id="Table.Lemma"></Trans>: </i><span id="cohort_lemma">{concept.lemma} </span> <br />
                                        <i><Trans id="Table.Definition"></Trans>: </i><span id="cohort_definition">{concept.definition} </span> <br />
                                        <button type="button" className="btn btn-danger btn-xs" id="del_cohort" onClick={() => { this.handleDeleteConcept(index) }}>
                                            <span className="glyphicon glyphicon-trash" aria-hidden="true"></span></button>
                                        <input type="hidden" name="concept_id" />
                                    </div>
                                }) : <></>
                            }
                        </div>
                    </div>
                    <div className="comandi">
                        <button className="btn btn-default" id="confirm" onClick={() => { this.handlePostIndicatorApplied() }}><b><Trans id="Button.Confirm"></Trans></b></button>
                        <button className="btn" id="back" onClick={() => { this.props.history.goBack(); this.props.commonActions.deletePathname() }}><b><Trans id="Button.Cancel"></Trans></b></button>
                    </div></section>
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({
        concepts: state.concepts,
        auth: state.auth,
        ontoclasses: state.ontoclasses,
        indicator_theors: state.indicator_theors,
        sources: state.sources,
        utilities: state.utilities,
        indicators_applied: state.indicators_applied,
        common: state.common
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname, addPathname, deletePathname }, dispatch),
        conceptActions: bindActionCreators({ getConceptDetail, postConcept, getConceptAuto, updateConcept }, dispatch),
        modalActions: bindActionCreators({ showModal }, dispatch),
        ontoclassActions: bindActionCreators({ getOntoclasses }, dispatch),
        indicator_theorActions: bindActionCreators({ getIndicatorTheorDetail, postIndicatorTheor, updateIndicatorTheor, getIndicatorTheorAuto }, dispatch),
        indicator_appliedActions: bindActionCreators({ postIndicatorApplied, updateIndicatorApplied, getIndicatorAppliedDetail }, dispatch),
        sourceActions: bindActionCreators({ getSourceAuto }, dispatch),
        utilityActions: bindActionCreators({ getUtilities, postUtility }, dispatch),
    })
)(NewApplied);
