import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteUser } from '../../../../services/users/usersAction';
import { hideModal } from '../../../modalConductorActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Trans from '../../../../services/utils/Trans';

class DeleteUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.onCancel = this.onCancel.bind(this);
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }
    handleSubmit() {
        console.log('selecteItems', this.props.users.users.user_id,)
        this.props.usersActions.deleteUser(this.props.users.users.user_id);
        this.onCancel();
    }
    render() {
        console.log('props in Associate project modal', this.props)
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader><Trans id="Modal.DeleteProject"></Trans></ModalHeader>
                <div className="skin-utenti">
                    <ModalBody>
                        <h2 className="ontoclass-color">
                            <Trans id="Message.DeleteConfirm"></Trans>
                        </h2>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-default" onClick={() => this.handleSubmit()} >
                            <Trans id="Button.Confirm"></Trans>
                        </button>
                        <button className="btn" onClick={this.onCancel}>
                            <Trans id="Button.Cancel"></Trans>
                        </button>
                    </ModalFooter>
                </div>

            </Modal>
        );
    }
}

export default connect(
    state => ({
        users: state.users
    }),
    dispatch => ({
        usersActions: bindActionCreators({ deleteUser }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(DeleteUserModal);
