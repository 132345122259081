import { handleActions } from 'redux-actions';

import {
    setPathname,
    addPathname,
    deletePathname
} from './commonActions';


const defaultState = {
    path: null
};

const reducer = handleActions(
    {
        [setPathname](
            state,
            {
                payload: { path }
            }
        ) {
            return {
                ...state,
                path: path
            };
        },
        [addPathname](
            state,
            {
                payload: { newpath }
            }
        ) {
            // console.log('state.path in reducer', state.path)
            if (state.path && state.path[state.path.length-1].pathName !== newpath.pathName) {
                return {
                    ...state,
                    path: [...state.path, {"pathName": newpath.pathName, "Url": newpath.Url, "id": state.path.length+1, "color": newpath.color, "subHeader": newpath.subHeader}]
                };
            } else {
                return {...state}
            }
        },
        [deletePathname](
            state,
        ) {
            state.path.pop();
            return {
                ...state,
                path: [...state.path]
            };
        },
    },
    defaultState
);

export default reducer;
