import React from 'react';
import { connect } from 'react-redux';
import { setPathname, addPathname, deletePathname } from '../../services/common/commonActions';
import { getRequests, getRequestDetail, deleteRequest } from '../../services/request/requestActions';
import { getOntoclasses } from '../../services/ontoclass/ontoclassActions';
import { getConceptAuto } from '../../services/concept/conceptActions';
import { getLemmaAuto } from '../../services/lemma/lemmaActions';
import { getSourceAuto } from '../../services/source/sourceActions';
import { getDefinitionAuto } from '../../services/definition/definitionActions';
import { getIndicatorTheorAuto } from '../../services/indicatorTheor/indicator_theorActions';
import { showModal } from '../../modals/modalConductorActions';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import { capitalize } from '../../services/utils_services';

class RequestDetail extends React.Component {
    constructor(props) {
        super();
        this.state = {
            request_id: '',
            tbl: '',
            ref_id: ''
        }
    }
    componentWillMount() {
        // this.props.commonActions.setPathname(
        //     [
        //         { "pathName": "Home", "Url": "", "id": 1, "color": "" },
        //         { "pathName": "Requests", "Url": "requests", "id": 2, "color": "#bfa422" },
        //         { "pathName": "Request", "Url": "/", "id": 3, "color": "#bfa422" },
        //     ]
        // )
        var id = this.props.match.params.id;
        if (id) {
            this.props.requestActions.getRequestDetail(id);
            this.setState({ request_id: id });
            this.props.conceptActions.addPathname(
                { "pathName": "Request Detail", "Url": "request/" + this.props.match.params.id, "color": "#6d81ab", "subHeader": "Request Detail"}
            )
        }
    }
    componentDidUpdate(prevProps, prevState) {
        var props = this.props;
        if (props.requests && props.requests.sel_request && props.requests.sel_request !== prevProps.requests.sel_request) {
            var request = props.requests.sel_request.request ? props.requests.sel_request.request : null;
            if (request) {
                this.setState({
                    tbl: request.tbl ? request.tbl : '',
                    ref_id: request.ref_id ? request.ref_id : ''
                })
            }
        }
        if (props.requests.error !== prevProps.requests.error && props.requests.error !== null) {
            toastr.error('Error Occured', props.requests.message);
        }

        if (props.requests.error === null && !props.requests.loading && prevProps.requests && prevProps.requests.loading && props.requests.message) {
            toastr.success('Success', props.requests.message);
            if (props.requests.sel_request) {
                if ( props.requests.sel_request !== prevProps.requests.sel_request && props.requests.sel_request.request) {
                    props.history.push(`/request/${props.requests.sel_request.request_id}`)
                }
            } else {
                props.history.push(`/requests/`);
            }
        }
    }
    componentDidMount() {

    }
    handleClickRel = () => {
        var tbl = this.state.tbl;
        var ref_id = this.state.ref_id;
        if (tbl && ref_id) {
            switch (tbl) {
                case 'concept':
                    this.props.history.push(`/concept/${ref_id}`);
                    break;
                case 'lemma':
                    this.props.history.push(`/lemma/${ref_id}`);
                    break;
                case 'definition':
                    this.props.history.push(`/definition/${ref_id}`);
                    break;
                case 'indicator_theor':
                    this.props.history.push(`/theordetail/${ref_id}`);
                    break;
                case 'source':
                    this.props.history.push(`/source/${ref_id}`);
            }
        }
    }

    render() {
        var list_transition = [];
        var sel_request = {};
        if(this.props.requests) {
            if (this.props.requests.loading) {
                Swal.fire({
                    title: 'Loading...',
                    text: "Plese wait",
                    onOpen: () => {
                        Swal.showLoading();
                    },
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            } else {
                Swal.close();
            }
            if (this.props.requests.sel_request) {
                sel_request = this.props.requests.sel_request.request;
                list_transition = this.props.requests.sel_request.list_transition;
            }
        }
        return (
            <React.Fragment>
                <section className="content skin-richieste">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sommario">
                                <label><Trans id="Content.CreationDate"></Trans>:</label><span id="createdAt">{sel_request ? sel_request.createdAt : ''}</span>
                                <label><Trans id="Content.LastUpdate"></Trans>:</label><span id="updatedAt">{sel_request ? sel_request.updatedAt : ''}</span>
                                <label><Trans id="Content.User"></Trans>:</label><span id="auth">{sel_request ? sel_request.auth : ''}</span>
                                <label><Trans id="Content.State"></Trans>:</label> <span className="label label-success" id="state" >{sel_request ? capitalize(sel_request.type_state_request) : ''}</span><br />
                                <label><Trans id="Content.RequestingOntoclass"></Trans></label><span id="owner_ontoclass">{sel_request ? sel_request.from_ontoclass : ''}</span>
                            </div>
                        </div>
                    </div>

                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label><Trans id="Table.Object"></Trans>:</label>
                            <div id="object">{sel_request ? sel_request.object : ''}</div>
                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label><Trans id="Content.Request"></Trans>:</label>
                            <div id="request">{sel_request ? sel_request.request : ''}</div>
                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label><Trans id="Content.Entity"></Trans>:</label>
                            <div>{sel_request ? sel_request.description_entity: ''}</div>
                        </div>
                        <div className="rx_comandi">
                            <button type="button" className="btn btn-default btn-xs" id="rel_entity" onClick={() => this.handleClickRel()}>
                                <span className="glyphicon glyphicon-link" aria-hidden="true"></span>
                            </button>
                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label><Trans id="Content.OntoclassAssigned"></Trans>:</label>
                            <div id="to_ontoclass">{sel_request ? sel_request.to_ontoclass : ''} </div>
                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label><Trans id="Content.Reply"></Trans>:</label>
                            <div id="answer">{sel_request && sel_request.answer ? sel_request.answer : ''} </div>
                        </div>
                    </div>
                    <div className="comandi">
                        <div>
                            {
                                list_transition && list_transition.indexOf('modify') > -1 ? <button className="btn btn-default view" id="modifica" onClick={() => { if (this.state.request_id) { this.props.history.push(`/newrequest/${this.state.request_id}`) } }}><b><Trans id="Button.Modification"></Trans></b></button> : <></>
                            }
                            {
                                list_transition && list_transition.indexOf('requestdiscard') > -1 && list_transition.indexOf('requestapprove') > -1 ? <button className="btn btn-default" id="add_answert" onClick={() => { this.props.modalActions.showModal('ADD_ANSWER_MODAL') }}>
                                    <span className="glyphicon glyphicon-edit-sign"></span><Trans id="Button.Answer"></Trans>
                            </button> : <></>
                            }
                            {
                                list_transition && list_transition.indexOf('deletion') > -1 ? <button className="btn" id="cancella" onClick={() => { if (this.state.request_id) { this.props.requestActions.deleteRequest(this.state.request_id) } }}><b><Trans id="Button.Delete"></Trans></b></button> : <></>
                            }
                            <button className="btn" id="indietro" onClick={() => { this.props.history.goBack() }}><b><Trans id="Button.Back"></Trans></b></button>
                        </div>
                    </div>
                </section>
            </React.Fragment >
        )
    }
}

export default connect(
    state => ({
        requests: state.requests,
        ontoclasses: state.ontoclasses,
        auth: state.auth,
        definitions: state.definitions,
        lemmas: state.lemmas,
        sources: state.sources,
        concepts: state.concepts,
        indicator_theors: state.indicator_theors
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname,addPathname, deletePathname }, dispatch),
        requestActions: bindActionCreators({ getRequests, getRequestDetail, deleteRequest }, dispatch),
        ontoclassActions: bindActionCreators({ getOntoclasses }, dispatch),
        conceptActions: bindActionCreators({ getConceptAuto }, dispatch),
        lemmaActions: bindActionCreators({ getLemmaAuto }, dispatch),
        definitionActions: bindActionCreators({ getDefinitionAuto }, dispatch),
        sourceActions: bindActionCreators({ getSourceAuto }, dispatch),
        indicator_theorActions: bindActionCreators({ getIndicatorTheorAuto }, dispatch),
        modalActions: bindActionCreators({ showModal }, dispatch),
    })
)(RequestDetail);
