import React from 'react';
import { connect } from 'react-redux';
import { setPathname, addPathname, deletePathname } from '../../services/common/commonActions';
import { getConceptDetail, postConcept, getConceptAuto, updateConcept } from '../../services/concept/conceptActions';
import { postIndicatorTheor, getIndicatorTheorDetail, updateIndicatorTheor } from '../../services/indicatorTheor/indicator_theorActions';
import { getBiblioAuto } from '../../services/biblio/biblioActions';

import { showModal } from '../../modals/modalConductorActions';
import { getOntoclasses } from '../../services/ontoclass/ontoclassActions';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import MathJax from 'react-mathjax2'

class NewTheor extends React.Component {
    constructor() {
        super();
        this.state = {
            owner_ontoclass_id: 1,
            name: '',
            note: '',
            conceptItem: {},
            search_conceptItem: '',
            search_concept_array: [],
            conceptArray: [],
            conceptOptions: [],
            algorithmItem: {},
            search_concept_id: '',
            algorithmArray: [],
            algorithmString: '',
            algorithmConcepts: [],
            conceptId: null,
            theorId: null,
            biblioItem: {},
            biblioOptions: [],
            search_biblio: [],
            biblioArray: [],
        }
        this.concept_typeahead_ref = null;
        this.algorithm_typeahead_ref = null;
        this.biblio_typeahead_ref = null;
    }
    componentWillMount() {
        // this.props.commonActions.setPathname(
        //     [
        //         { "pathName": "Home", "Url": "", "id": 1, "color": "" },
        //         { "pathName": "Indicators", "Url": "indicators", "id": 2, "color": "#927e9b" },
        //         { "pathName": "NewTheor", "Url": "/", "id": 3, "color": "#927e9b" },
        //     ]
        // )
        this.props.ontoclassActions.getOntoclasses();
        var _conceptId = this.props.match.params.conceptId;
        var _theorId = this.props.match.params.theorId
        if (_conceptId !== 'null' && _conceptId !== undefined) {
            this.props.conceptActions.getConceptDetail(_conceptId);
        }
        if (_theorId !== 'null' && _theorId !== undefined) {
            this.props.indicator_theorActions.getIndicatorTheorDetail(_theorId);
        }
        this.setState({
            conceptId: _conceptId,
            theorId: _theorId,
        })
        var ontoclasses = null;
        if (this.props && this.props.auth && this.props.auth.currentUser) {
            var profile = this.props.auth.currentUser.profile;
            if (profile === "Administrator") {
                ontoclasses = this.props.ontoclasses ? this.props.ontoclasses.ontoclasses : []
            } else if (profile === "Publisher") {
                ontoclasses = this.props.auth.currentUser.ontoclasses
            }
            if (ontoclasses !== null) {
                this.setState({
                    owner_ontoclass_id: ontoclasses[0].ontoclass_id
                })
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.concepts && this.props.concepts.concepts !== prevProps.concepts.concepts) {
            this.setState({
                conceptOptions: this.props.concepts.concepts.suggestions
            });
        }
        if (this.props.biblios && this.props.biblios.biblios !== prevProps.biblios.biblios) {
            this.setState({
                biblioOptions: this.props.biblios.biblios.suggestions
            })
        }
        if (this.props.concepts && this.props.concepts.sel_concept && this.props.concepts.sel_concept !== prevProps.concepts.sel_concept) {
            var sel_concept = this.props.concepts.sel_concept;
            if (sel_concept && this.props.match.params.conceptId) {
                this.props.commonActions.addPathname(
                    { "pathName": "New Theor", "Url": "newtheor/null/" + this.props.match.params.conceptId, "color": "#6d81ab", "subHeader": "New Theoretical in Concept" }
                )
            }
            var _conceptItem = {
                concept_id: this.state.conceptId,
                rel_lemma: sel_concept.lemma,
                rel_definition: sel_concept.definition
            }
            this.setState({
                conceptItem: _conceptItem,
                search_conceptItem: this.state.conceptId
            });
        }
        var biblios;
        if (this.props.biblios && this.props.biblios.error === null && !this.props.biblios.loading && prevProps.biblios && this.props.biblios.message === null && prevProps.biblios.biblios !== this.props.biblios.biblios && !this.props.biblios.biblios.suggestions) {
            biblios = this.props.biblios.biblios;
            var _arrayBiblio = this.state.biblioArray;
            var _searchBiblio = this.state.search_biblio;
            var item_biblios = biblios[biblios.length - 1];
            _searchBiblio.push(item_biblios.biblio_id.toString());
            var tempbiblioArry = { value: item_biblios.biblio_id, label: item_biblios.biblio };
            _arrayBiblio.push(tempbiblioArry);
            this.setState({ biblioArray: _arrayBiblio, search_biblio: _searchBiblio })
        }
        if (this.props.indicator_theors && this.props.indicator_theors.sel_indicator_theor && this.props.indicator_theors.sel_indicator_theor !== prevProps.indicator_theors.sel_indicator_theor) {
            var sel_indicator_theor = this.props.indicator_theors.sel_indicator_theor.indicator;
            if (sel_indicator_theor.rel_concept) {
                var _conceptItem = {
                    concept_id: sel_indicator_theor.rel_concept.concept_id,
                    rel_lemma: sel_indicator_theor.rel_concept.lemma,
                    rel_definition: sel_indicator_theor.rel_concept.definition
                }
            }
            if (sel_indicator_theor.concepts && sel_indicator_theor.concepts.length > 0) {
                var _search_conceptArray = this.state.search_concept_array;
                for (let concept of sel_indicator_theor.concepts) {
                    _search_conceptArray.push(concept.concept_id.toString());
                }
            }
            if (sel_indicator_theor.algorithm) {
                var _conceptArray = this.state.conceptArray;
                try {
                    var _algorithm = JSON.parse(sel_indicator_theor.algorithm);
                    if (_algorithm && _algorithm.length > 0) {
                        for (let list of _algorithm) {
                            if (list.type === 'string') {
                                var _tempConcept = {
                                    rel_lemma: list.content,
                                    rel_definition: list.definition,
                                    concept_id: list.concept_id
                                };
                                _conceptArray.push(_tempConcept);
                            }
                        }
                    }
                } catch (err) {
                    var _algorithm = ''
                }
                this.setState({ conceptArray: _conceptArray })
            }
            biblios = sel_indicator_theor.biblios;
            if (biblios && biblios.length > 0) {
                var _arrayBiblio = this.state.biblioArray;
                var _arraySearch = this.state.search_biblio;

                for (let biblio of biblios) {
                    var temp_biblio = {
                        value: biblio.biblio_id,
                        label: biblio.biblio
                    };
                    _arrayBiblio.push(temp_biblio);
                    _arraySearch.push(biblio.biblio_id.toString());
                    this.setState({
                        biblioArray: _arrayBiblio,
                        search_biblio: _arraySearch,
                    });
                }
            }

            this.setState({
                owner_ontoclass_id: sel_indicator_theor.owner_ontoclass_id,
                name: sel_indicator_theor.name,
                note: sel_indicator_theor.note ? sel_indicator_theor.note : '',
                conceptItem: _conceptItem ? _conceptItem : {},
                search_conceptItem: _conceptItem ? _conceptItem.concept_id : '',
                conceptArray: _conceptArray ? _conceptArray : [],
                search_concept_array: _search_conceptArray ? _search_conceptArray : [],
                algorithmArray: _algorithm ? _algorithm : []
            })
        }
        if (this.state.algorithmArray && prevState.algorithmArray && this.state.algorithmArray !== prevState.algorithmArray) {
            var temp_string = '';
            if (this.state.algorithmArray.length > 0) {
                for (var i = 0; i < this.state.algorithmArray.length; i++) {
                    if (this.state.algorithmArray[i].type === 'string') {
                        temp_string += `"${this.state.algorithmArray[i].content}"`
                    } else {
                        temp_string += this.state.algorithmArray[i].content;
                    }
                }
            }
            this.setState({
                algorithmString: temp_string
            })
        }
        if (this.props.indicator_theors.error !== prevProps.indicator_theors.error && this.props.indicator_theors.error !== null) {
            toastr.error('Error Occured', this.props.indicator_theors.message);
        }

        if (this.props.indicator_theors.error === null && !this.props.indicator_theors.loading && prevProps.indicator_theors && prevProps.indicator_theors.loading && this.props.indicator_theors.message) {
            toastr.success('Success', this.props.indicator_theors.message);
            if (this.props.indicator_theors.sel_indicator_theor.indicator.indicator_t_id) {
                this.props.history.push(`/theordetail/${this.props.indicator_theors.sel_indicator_theor.indicator.indicator_t_id}`)
            }
        }
    }

    onFilterChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        console.log('ontoclass selected', e.target.value)
    }

    handleAddAlgorithm = (item) => {
        var _conceptItem = this.state.algorithmItem;
        if (!_conceptItem || (_conceptItem && _conceptItem.concept_id !== item[0].value)) {
            var rel_lemma = item[0].label.split("-")[0];
            var rel_definition = item[0].label.split("-")[1];
            var temp_array = {
                rel_lemma,
                rel_definition,
                concept_id: item[0].value
            }
            this.setState({
                algorithmItem: temp_array,
                search_concept_id: item[0].value
            })
        }
        const instance = this.algorithm_typeahead_ref.getInstance()
        instance.clear()
        instance.focus()
    }
    handleAddConcept = (item) => {
        var _conceptItem = this.state.conceptItem;
        if (!_conceptItem || (_conceptItem && _conceptItem.concept_id !== item[0].value)) {
            var rel_lemma = item[0].label.split("-")[0];
            var rel_definition = item[0].label.split("-")[1];
            var temp_array = {
                rel_lemma,
                rel_definition,
                concept_id: item[0].value
            }
            this.setState({
                conceptItem: temp_array,
                search_conceptItem: item[0].value
            })
        }
        const instance = this.concept_typeahead_ref.getInstance()
        instance.clear()
        instance.focus()
    }
    handleAddConceptArray = (e) => {
        var _conceptArray = this.state.conceptArray;
        var _searchConcept = this.state.search_concept_array;
        if (this.state.algorithmItem && this.state.algorithmItem.concept_id) {
            _conceptArray.push(this.state.algorithmItem);
            if (_searchConcept.indexOf(this.state.algorithmItem.concept_id.toString()) < 0) {
                _searchConcept.push(this.state.algorithmItem.concept_id.toString());
            }
            var temp_algorithm = {
                content: this.state.algorithmItem.rel_lemma,
                definition: this.state.algorithmItem.rel_definition,
                concept_id: this.state.algorithmItem.concept_id,
                type: 'string'
            }
            this.setState({
                conceptArray: _conceptArray,
                search_concept_array: _searchConcept,
                algorithmItem: {},
                algorithmArray: [
                    ...this.state.algorithmArray,
                    temp_algorithm
                ]
            });
        }
    }
    handleBiblioSearch = (query) => {
        this.props.biblioActions.getBiblioAuto(query);
    }
    handleAddBiblio = (item) => {
        var _arrayBiblio = this.state.biblioArray;
        var _searchBiblio = this.state.search_biblio;
        var _is = false;
        if (_arrayBiblio && _arrayBiblio.length > 0) {
            for (var i = 0; i < _arrayBiblio.length; i++) {
                if (_arrayBiblio[i].value === item[0].value) {
                    _is = true;
                    const instance = this.biblio_typeahead_ref.getInstance()
                    instance.clear()
                    instance.focus()
                    break;
                }
            }
        }
        if (!_is) {
            _arrayBiblio.push(item[0]);
            this.setState({
                biblioArray: _arrayBiblio
            });
            _searchBiblio.push(item[0].value.toString());
            this.setState({
                search_biblio: _searchBiblio
            });
            const instance = this.biblio_typeahead_ref.getInstance()
            instance.clear()
            instance.focus()
        }
    }
    handleDeleteBiblio = (value) => {
        var _searchBiblio = this.state.search_biblio;
        var _arrayBiblio = this.state.biblioArray;
        if (_arrayBiblio && _arrayBiblio.length > 0) {
            for (var i = 0; i < _arrayBiblio.length; i++) {
                if (_arrayBiblio[i].value.toString() === value.toString()) {
                    _arrayBiblio.splice(i, 1);
                    this.setState({
                        biblioArray: _arrayBiblio
                    });
                    break;
                }
            }
        }
        var index = _searchBiblio.indexOf(value.toString());
        if (index > -1) {
            _searchBiblio.splice(index, 1);
        }
    }
    handleDeleteConcept = () => {
        this.setState({
            search_conceptItem: '',
            conceptItem: {}
        });
    }

    handleConceptSearch = (query) => {
        this.props.conceptActions.getConceptAuto(query);
    };
    handleDeleteAlgorithmConcept = (e) => {
        var _conceptArray = this.state.conceptArray;
        var _algorithmArray = [...this.state.algorithmArray];
        var _searchConcept = this.state.search_concept_array;
        if (_algorithmArray.length > 0) {
            var temp = _algorithmArray.pop();
            if (temp.type === 'string') {
                _conceptArray.pop();
                _searchConcept.pop();
            }
            this.setState({
                conceptArray: _conceptArray,
                algorithmArray: _algorithmArray
            })
        }
    }
    handleClickOperator = (query) => {
        console.log('button clickete', query);
        var temp = {
            content: query,
            type: 'operator'
        }
        this.setState({
            algorithmArray: [
                ...this.state.algorithmArray,
                temp
            ]
        })
    }
    handlePostIndicatorTheor = () => {
        var owner_ontoclass_id = this.state.owner_ontoclass_id;
        var name = this.state.name;
        var note = this.state.note;
        var concept_id = this.state.search_conceptItem;
        var biblios = this.state.search_biblio;
        try {
            var algorithm = JSON.stringify(this.state.algorithmArray);
        } catch (err) {
            var algorithm = ''
        }
        var concepts = this.state.search_concept_array;
        if (!name || !owner_ontoclass_id) {
            Swal.fire({
                icon: 'error',
                title: 'You need to select Name, Ontoclass',
            });
            return;
        }
        var indicator_theor_id = this.state.theorId;
        if (indicator_theor_id !== 'null' && indicator_theor_id !== undefined) {
            this.props.indicator_theorActions.updateIndicatorTheor(indicator_theor_id, name, note, concept_id, algorithm, concepts, owner_ontoclass_id, biblios)
        } else {
            this.props.indicator_theorActions.postIndicatorTheor(name, note, concept_id, algorithm, concepts, owner_ontoclass_id, biblios)
        }
    }
    render() {
        if (this.props.indicator_theors.loading) {
            Swal.fire({
                title: 'Loading...',
                text: "Plese wait",
                onOpen: () => {
                    Swal.showLoading();
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
        } else {
            Swal.close();
        }
        var ontoclasses = null;
        if (this.props && this.props.auth && this.props.auth.currentUser) {
            var profile = this.props.auth.currentUser.profile;
            if (profile === "Administrator") {
                ontoclasses = this.props.ontoclasses ? this.props.ontoclasses.ontoclasses : []
            } else if (profile === "Publisher") {
                ontoclasses = this.props.auth.currentUser.ontoclasses
            }
        }
        return (
            <React.Fragment>
                <section className="content skin-indicatori">
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label><Trans id="Modal.OntoclassRefer"></Trans>:</label>
                            <select name='owner_ontoclass_id' className="form-control" value={this.state.owner_ontoclass_id} onChange={this.onFilterChange} >
                                {
                                    ontoclasses ? ontoclasses.map(ontoclass => {
                                        return <option key={"onto_" + ontoclass.ontoclass_id} value={ontoclass.ontoclass_id}>{ontoclass.ontoclass}</option>
                                    }) : <></>
                                }
                            </select>
                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-6">
                            <label><Trans id="Content.Name"></Trans>:</label>
                            <div className="input-group">
                                <input type="text" className="form-control" name="name" size="100" value={this.state.name} onChange={this.onFilterChange} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label><Trans id="Content.Note"></Trans></label>
                            <div className="input-group">
                                <textarea className="form-control" name="note" cols="120" rows="2" value={this.state.note} onChange={this.onFilterChange}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-6">
                            <label><Trans id="Content.ConceptAssociated"></Trans>:</label>
                            <div className="input-group" style={{ width: "100%" }}>
                                <AsyncTypeahead
                                    minLength={2}
                                    id="search_concept"
                                    isLoading={false}
                                    onChange={(e) => { this.handleAddConcept(e) }}
                                    onSearch={this.handleConceptSearch}
                                    options={this.state.conceptOptions}
                                    placeholder="Cerca concetto..."
                                    ref={(ref) => this.concept_typeahead_ref = ref}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            {
                                this.state.conceptItem && this.state.conceptItem.concept_id ? <div className="lista_item" id="concept_line">
                                    <div><span><Trans id="Table.Lemma"></Trans>: </span><strong id="concept_lemma">{this.state.conceptItem.rel_lemma}</strong></div>
                                    <div><span><Trans id="Table.Definition"></Trans>: </span><strong id="concept_definizione" >{this.state.conceptItem.rel_definition}</strong></div>
                                    <button type="button" className="btn btn-danger btn-xs" id="del_concept" onClick={() => { this.handleDeleteConcept() }}><span
                                        className="glyphicon glyphicon-trash" aria-hidden="true"></span></button>
                                    <input type="hidden" name="concept_id" />
                                </div> : <></>
                            }
                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label><Trans id="Table.Biblio"/>:</label>
                            <div className="input-group">
                                <AsyncTypeahead
                                    minLength={2}
                                    id="search_biblio"
                                    isLoading={false}
                                    onChange={(e) => { this.handleAddBiblio(e) }}
                                    onSearch={this.handleBiblioSearch}
                                    options={this.state.biblioOptions}
                                    placeholder="Cerca bibliografia..."
                                    ref={(ref) => this.biblio_typeahead_ref = ref}
                                />
                                <span className="input-group-btn">
                                    <button className="btn btn-default" data-type="button"
                                        id="new_biblio" onClick={() => { this.props.modalActions.showModal('ADD_BIBLIO_MODAL') }}>+</button>
                                </span>
                            </div>
                            {
                                this.state.biblioArray && this.state.biblioArray.length > 0 ? this.state.biblioArray.map(item => {
                                    return (
                                        <div className="lista_item" id="biblio_line" key={`item-${item.value}`}>
                                            <i id="lemma"><a href={item.label} target="_blanck">{item.label}</a></i>
                                            <button type="button" className="btn btn-danger btn-xs" id="del_biblio" onClick={() => { this.handleDeleteBiblio(item.value) }}>
                                                <span className="glyphicon glyphicon-trash" aria-hidden="true" ></span>
                                            </button>
                                            <input type="hidden" name="biblio_id" />
                                        </div>
                                    )
                                }) : <></>
                            }
                        </div>
                    </div>
                    <div className="form-group row _underlined">
                        <div className="col-md-6">
                            <label><Trans id="Content.Algorithm"></Trans>:</label>
                            <div className="input-group" style={{ width: "100%" }}>
                                <AsyncTypeahead
                                    minLength={2}
                                    id="search_concept_algorithm"
                                    isLoading={false}
                                    onChange={(e) => { this.handleAddAlgorithm(e) }}
                                    onSearch={this.handleConceptSearch}
                                    options={this.state.conceptOptions}
                                    placeholder="Cerca concetto da inserire nell'algoritmo..."
                                    ref={(ref) => this.algorithm_typeahead_ref = ref}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="lista_item" id="concept_algorithm_line">
                                {
                                    this.state.algorithmItem && this.state.algorithmItem.concept_id ? <div>
                                        <div><span><Trans id="Table.Lemma"></Trans>: </span><strong id="search_concept_lemma">{this.state.algorithmItem.rel_lemma}</strong></div>
                                        <div><span><Trans id="Table.Definition"></Trans>: </span><strong id="search_concept_definition">{this.state.algorithmItem.rel_definition}</strong></div>
                                        <input type="hidden" name="search_concept_id" />
                                        <button type="button" id="add_concept_to_algorithm" className="btn btn-default" onClick={() => { this.handleAddConceptArray() }}><span
                                            className="glyphicon glyphicon-plus-sign" ></span><Trans id="Button.AddConceptToAlgorithm"></Trans></button> </div> : <></>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12">
                                <div className="form-group" style={{ textAlign: "center" }}>
                                    <button type='button' val="N:" onClick={() => { this.handleClickOperator('N:') }}> N </button>
                                    <button type='button' val="sum" onClick={() => { this.handleClickOperator('sum') }}> ∑ </button>
                                    <button type='button' val="sqrt" onClick={() => { this.handleClickOperator('sqrt') }}> √ </button>
                                    <button type='button' val="(" onClick={() => { this.handleClickOperator('(') }}> ( </button>
                                    <button type='button' val=")" onClick={() => { this.handleClickOperator(')') }}> ) </button>
                                    <button type='button' val="+" onClick={() => { this.handleClickOperator('+') }}> + </button>
                                    <button type='button' val="-" onClick={() => { this.handleClickOperator('-') }}> - </button>
                                    <button type='button' val="/" onClick={() => { this.handleClickOperator('/') }}> : </button>
                                    <button type='button' val="*" onClick={() => { this.handleClickOperator('*') }}> x </button>
                                    <br />
                                    <button type='button' val="1" onClick={() => { this.handleClickOperator('1') }}> 1 </button>
                                    <button type='button' val="2" onClick={() => { this.handleClickOperator('2') }}> 2 </button>
                                    <button type='button' val="3" onClick={() => { this.handleClickOperator('3') }}> 3 </button>
                                    <button type='button' val="4" onClick={() => { this.handleClickOperator('4') }}> 4 </button>
                                    <button type='button' val="5" onClick={() => { this.handleClickOperator('5') }}> 5 </button>
                                    <button type='button' val="6" onClick={() => { this.handleClickOperator('6') }}> 6 </button>
                                    <button type='button' val="7" onClick={() => { this.handleClickOperator('7') }}> 7 </button>
                                    <button type='button' val="8" onClick={() => { this.handleClickOperator('8') }}> 8 </button>
                                    <button type='button' val="9" onClick={() => { this.handleClickOperator('9') }}> 9 </button>
                                    <button type='button' val="0" onClick={() => { this.handleClickOperator('0') }}> 0 </button>
                                    <button type='button' val="DEL" onClick={() => this.handleDeleteAlgorithmConcept()}> DEL </button>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div id="algoritmo" className="col-md-12" style={{ minHeight: "30px", textAlign: "center" }}>
                                <MathJax.Context input='ascii'>
                                    <div>
                                        <MathJax.Node inline>{this.state.algorithmString}</MathJax.Node>
                                    </div>
                                </MathJax.Context>
                            </div>
                        </div>
                        <h3><Trans id="Content.ConceptsUsedInAlgorithm"></Trans></h3>
                        <div id="box_concetto_rel" className="box_fonte">
                            {
                                this.state.conceptArray && this.state.conceptArray.length > 0 ? this.state.conceptArray.map((concept, index) => {
                                    return (
                                        <div id="concept_algorithm_line" className="linea_sx" key={"linea_sx_" + index}>
                                            <div><span><Trans id="Table.Lemma"></Trans>: </span><strong id="ca_lemma">{concept.rel_lemma}</strong></div>
                                            <div><span><Trans id="Table.Definition"></Trans>: </span><strong id="ca_definition">{concept.rel_definition}</strong></div>
                                        </div>
                                    )
                                }) : <></>
                            }
                        </div>
                        <div className="comandi">
                            <button className="btn btn-default" id="confirm" onClick={() => { this.handlePostIndicatorTheor() }}><b><Trans id="Button.Confirm"></Trans></b></button>
                            <button className="btn" id="back" onClick={() => { this.props.history.goBack() }}><b><Trans id="Button.Cancel"></Trans></b></button>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({
        concepts: state.concepts,
        auth: state.auth,
        biblios: state.biblios,
        ontoclasses: state.ontoclasses,
        indicator_theors: state.indicator_theors
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname, addPathname, deletePathname }, dispatch),
        conceptActions: bindActionCreators({ getConceptDetail, postConcept, getConceptAuto, updateConcept }, dispatch),
        modalActions: bindActionCreators({ showModal }, dispatch),
        ontoclassActions: bindActionCreators({ getOntoclasses }, dispatch),
        indicator_theorActions: bindActionCreators({ getIndicatorTheorDetail, postIndicatorTheor, updateIndicatorTheor }, dispatch),
        biblioActions: bindActionCreators({ getBiblioAuto }, dispatch),
    })
)(NewTheor);
