import React from 'react';
import { connect } from 'react-redux';
import { setPathname } from '../../services/common/commonActions';
import { getUserDetail } from '../../services/users/usersAction';
import { showModal } from '../../modals/modalConductorActions';
import { getStoredUser } from '../../services/auth/services';
import { toastr } from 'react-redux-toastr';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';

class UserDetail extends React.Component {
	componentWillMount() {
		const user_id = this.props.match.params.id;
        var currentUser = getStoredUser();
		if(currentUser.profile == 'Guest') {
			this.props.commonActions.setPathname(
				[
					{ "pathName": "Home", "Url": "", "id": 1, "color": "" },
					{ "pathName": "User", "Url": "/", "id": 2, "color": "#de95c0" }
				]
			)
		} else {
			this.props.commonActions.setPathname(
				[
					{ "pathName": "Home", "Url": "", "id": 1, "color": "" },
					{ "pathName": "Users", "Url": "users", "id": 2, "color": "#de95c0" },
					{ "pathName": "User", "Url": "/", "id": 3, "color": "" }
				]
			)

		}
		this.props.usersActions.getUserDetail(user_id);
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.props.users.users === null && this.props.users.users !== prevProps.users.users && this.props.users.success) {
			this.props.history.push("/users");
		}
		if (this.props.users.error !== prevProps.users.error && this.props.users.error !== null) {
			toastr.error('Error Occured', this.props.users.message);
		}

		if (this.props.users.error === null && this.props.users.success && !this.props.users.loading && prevProps.users && prevProps.users.loading && this.props.users.message) {
			toastr.success('Success', this.props.users.message);
		}
	}

	render() {
		if (this.props.users.loading) {
			Swal.fire({
				title: 'Loading...',
				text: "Plese wait",
				onOpen: () => {
					Swal.showLoading();
				},
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false
			});
		} else {
			Swal.close();
		}
		var userinfo = this.props.users.users;
		var currentUser = getStoredUser();
		return (
			<section className="content skin-utenti">
				<div className="form-group row">
					<div className="col-md-4">
						<label className="text-muted text-center"><Trans id="Table.Name" /></label>
						<div id="name">{userinfo ? userinfo.name : ""}</div>
					</div>
					<div className="col-md-4">
						<label className="text-muted text-center"><Trans id="Table.LastName" /></label>
						<div id="lastname">{userinfo ? userinfo.lastname : ""}</div>
					</div>
					<div className="col-md-4">
						<label className="text-muted text-center"><Trans id="Table.Profile" /></label>
						<div id="profile">{userinfo ? userinfo.profile : ""}</div>
					</div>
				</div>
				<div className="form-group row">
					<div className="col-md-4">
						<label className="text-muted text-center"><Trans id="Table.Username" /></label>
						<div id="username">{userinfo ? userinfo.username : ""}</div>
					</div>
					<div className="col-md-4">
						<label className="text-muted text-center"><Trans id="Table.Email" /></label>
						<div id="email">{userinfo ? userinfo.email : ""}</div>
					</div>
					<div className="col-md-4">
					</div>
				</div>
				<div className="comandi">
					{
						currentUser && currentUser.profile === "Administrator" ? <button id="update" type="button" className="btn btn-default"
							onClick={() => this.props.modalActions.showModal('EDIT_USER_MODAL')}><Trans id="Button.Modification"></Trans>
						</button> : <></>
					}
					{
						currentUser && userinfo && userinfo.user_id === currentUser.user_id ? <button id="updated_pwd" type="button" className="btn btn-default"
							onClick={() => this.props.modalActions.showModal('CHANGE_USER_PASSWORD')}><Trans id="Button.ChangePwd"></Trans>
						</button> : <></>
					}
					{
						currentUser && currentUser.profile === "Administrator" && userinfo && userinfo.profile === "Publisher" ? <button id="add_ontoclass" type="button" className="btn btn-default"
							onClick={() => this.props.modalActions.showModal('ADD_USER_ONTOCLASSES')}><Trans id="Button.AssociateOntoclass"></Trans>
						</button> : <></>
					}
					{
						currentUser && currentUser.profile === "Administrator" && userinfo && userinfo.profile === "Guest" ? <button id="add_project" type="button" className="btn btn-default"
							onClick={() => this.props.modalActions.showModal('ADD_USER_PROJECTS')}><Trans id="Button.AssociateProject"></Trans>
						</button> : <></>
					}
					{
						currentUser && currentUser.profile === "Administrator" && userinfo && userinfo.in_use == 0? <button id="delete" className="btn"
							onClick={() => this.props.modalActions.showModal('DELETE_USER')}><b><Trans id="Button.Delete"></Trans></b>
						</button> : <></>
					}
					{userinfo && userinfo.profile != 'Guest' && 
						<button id="back" className="btn" onClick={() => { this.props.history.goBack() }}><b><Trans id="Button.Back"></Trans></b></button>
					}
				</div>
				{
					userinfo && userinfo.profile == 'Publisher' ? 
					<div className="form-group row underlined">
						<div className="col-md-12" id="list_ontoclasses">
							<h3><Trans id="Table.OntoclassesAssociated"></Trans>:</h3>
							{
								userinfo && userinfo.ontoclasses && userinfo.ontoclasses.length > 0 ? userinfo.ontoclasses.map(oc => {
									return <div className="lista_item pointer" key={"ocAso_" + oc.ontoclass_id} onClick={() => {this.props.history.push(`/ontoclass/${oc.ontoclass_id}`)}}><i id="ontoclass">{oc.ontoclass}</i></div>
								}) : <></>
							}
						</div>
					</div> : <></>
				}
				{
					userinfo && userinfo.profile == 'Guest' ? 
					<div className="form-group row underlined">
						<div className="col-md-12" id="list_projects">
							<h3><Trans id="Table.ProjectsAssociated"></Trans>:</h3>
							{
								userinfo && userinfo.projects && userinfo.projects.length > 0 ? userinfo.projects.map(project => {
									return <div className="lista_item pointer" key={"proAso_" + project.project_id} onClick={() => { this.props.history.push(`/project/${project.project_id}`) }} ><i id="project_name">{project.name}</i></div>
								}) : <></>
							}
						</div>
					</div > : <></>
				}
			</section >
		)
	}
}

export default connect(
	state => ({
		users: state.users
	}),
	dispatch => ({
		commonActions: bindActionCreators({ setPathname }, dispatch),
		usersActions: bindActionCreators({ getUserDetail }, dispatch),
		modalActions: bindActionCreators({ showModal }, dispatch),
	})
)(UserDetail);
