import { createActions } from 'redux-actions';

const {
    getConcepts,
    getConceptsFailed,
    getConceptsSucceed,
    postConcept,
    postConceptFailed,
    postConceptSucceed,
    getConceptDetail,
    getConceptDetailFailed,
    getConceptDetailSucceed,
    updateConcept,
    updateConceptFailed,
    updateConceptSucceed,
    deleteConcept,
    deleteConceptFailed,
    deleteConceptSucceed,
    getConceptIndicatorTheors,
    getConceptIndicatorTheorsFailed,
    getConceptIndicatorTheorsSucceed,
    getConceptAuto,
    getConceptAutoFailed,
    getConceptAutoSucceed
} = createActions({
    GET_CONCEPTS: (project_id, by_user, lemma_id) => ({ project_id, by_user, lemma_id }),
    GET_CONCEPTS_FAILED: error => ({ error }),
    GET_CONCEPTS_SUCCEED: concepts => ({ concepts }),
    POST_CONCEPT: (lemma_id, definition_id, sources, biblios, is_applied, rels, owner_ontoclass_id, ontoclasses) => ({ lemma_id, definition_id, sources, biblios, is_applied, rels, owner_ontoclass_id, ontoclasses }),
    POST_CONCEPT_FAILED: error => ({ error }),
    POST_CONCEPT_SUCCEED: concept => ({ concept }),
    GET_CONCEPT_DETAIL: concept_id => ({ concept_id }),
    GET_CONCEPT_DETAIL_FAILED: error => ({ error }),
    GET_CONCEPT_DETAIL_SUCCEED: concepts => ({ concepts }),
    UPDATE_CONCEPT: (concept_id, lemma_id, definition_id, sources, biblios, is_applied, rels, owner_ontoclass_id, ontoclasses) => ({ concept_id, lemma_id, definition_id, sources, biblios, is_applied, rels, owner_ontoclass_id, ontoclasses }),
    UPDATE_CONCEPT_FAILED: error => ({ error }),
    UPDATE_CONCEPT_SUCCEED: concept => ({ concept }),
    DELETE_CONCEPT: concept_id => ({ concept_id }),
    DELETE_CONCEPT_FAILED: error => ({ error }),
    DELETE_CONCEPT_SUCCEED: () => ({}),
    GET_CONCEPT_INDICATORTHEORS: (project_id) => ({ project_id }),
    GET_CONCEPT_INDICATORTHEORS_FAILED: error => ({ error }),
    GET_CONCEPT_INDICATORTHEORS_SUCCEED: indicator_theors => ({ indicator_theors }),
    GET_CONCEPT_AUTO: query_string => ({ query_string }),
    GET_CONCEPT_AUTO_FAILED: error => ({ error }),
    GET_CONCEPT_AUTO_SUCCEED: concepts => ({ concepts })
});

export {
    getConcepts,
    getConceptsFailed,
    getConceptsSucceed,
    postConcept,
    postConceptFailed,
    postConceptSucceed,
    getConceptDetail,
    getConceptDetailFailed,
    getConceptDetailSucceed,
    updateConcept,
    updateConceptFailed,
    updateConceptSucceed,
    deleteConcept,
    deleteConceptFailed,
    deleteConceptSucceed,
    getConceptIndicatorTheors,
    getConceptIndicatorTheorsFailed,
    getConceptIndicatorTheorsSucceed,
    getConceptAuto,
    getConceptAutoFailed,
    getConceptAutoSucceed
};
