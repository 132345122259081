import { put, takeEvery, call, all } from 'redux-saga/effects';
/** Import actions */
import {
    getSources,
    getSourcesFailed,
    getSourcesSucceed,
    postSourceFailed,
    postSourceSucceed,
    getSourceDetailFailed,
    getSourceDetailSucceed,
    updateSourceFailed,
    updateSourceSucceed,
    deleteSourceFailed,
    deleteSourceSucceed,
    getSourceAutoFailed,
    getSourceAutoSucceed
} from './sourceActions';

/** Import api */
import * as sourceApi from './sourceApi';

export function* sourceSubscriber() {
    yield all([takeEvery('GET_SOURCES', getSourceSaga)]);
    yield all([takeEvery('POST_SOURCE', postSourceSaga)]);
    yield all([takeEvery('GET_SOURCE_DETAIL', getSourceDetailSaga)]);
    yield all([takeEvery('UPDATE_SOURCE', updateSourceSaga)]);
    yield all([takeEvery('DELETE_SOURCE', deleteSourceSaga)]);
    yield all([takeEvery('GET_SOURCE_AUTO', getSourceAutoSaga)]);

}
export function* getSourceSaga() {
    try {
        const sources = yield call(sourceApi.get);
        yield put(getSourcesSucceed(sources));
    } catch (error) {
        console.log('source saga error')
        yield put(getSourcesFailed(error));
    }
}
export function* getSourceAutoSaga({ payload: { query_string } }) {
    try {
        const sources = yield call(sourceApi.getSourceAutoApi, query_string);
        yield put(getSourceAutoSucceed(sources));
    } catch (error) {
        yield put(getSourceAutoFailed(error));
    }
}
export function* postSourceSaga({ payload: { source, owner_ontoclass_id } }) {
    try {
        const sources = yield call(sourceApi.postSource, source, owner_ontoclass_id);
        yield put(postSourceSucceed(sources));
        yield put(getSources());
    } catch (error) {
        console.log("error--", error)
        yield put(postSourceFailed(error));
    }
}
export function* getSourceDetailSaga({ payload: { source_id } }) {
    try {
        const source = yield call(sourceApi.getSourceDetailApi, source_id);
        yield put(getSourceDetailSucceed(source));
    } catch (error) {
        console.log('sourceDetail saga error')
        yield put(getSourceDetailFailed(error));
    }
}
export function* updateSourceSaga({ payload: { source_id, source, owner_ontoclass_id } }) {
    try {
        const sources = yield call(sourceApi.updateSourceApi, source_id, source, owner_ontoclass_id);
        yield put(updateSourceSucceed(sources));
    } catch (error) {
        console.log("error--", error)
        yield put(updateSourceFailed(error));
    }
}
export function* deleteSourceSaga({ payload: { source_id } }) {
    try {
        yield call(sourceApi.deleteSourceApi, source_id);
        yield put(deleteSourceSucceed());
    } catch (error) {
        console.log("error--", error)
        yield put(deleteSourceFailed(error));
    }
}
