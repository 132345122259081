import { put, takeEvery, call, all } from 'redux-saga/effects';
/** Import actions */
import {
    getDashboardFailed,
    getDashboardSucceed,
} from './dashboardActions';

/** Import api */
import * as dashboardApi from './dashboardApi';

export function* dashboardSubscriber() {
    yield all([takeEvery('GET_DASHBOARD', getDashboardSaga)]);
}
export function* getDashboardSaga() {
    try {
        const dashboard = yield call(dashboardApi.getDashboardApi);
        yield put(getDashboardSucceed(dashboard));
    } catch (error) {
        yield put(getDashboardFailed(error));
    }
}


