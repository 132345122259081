import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteSource } from '../../../../services/source/sourceActions';
import { hideModal } from '../../../modalConductorActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Trans from '../../../../services/utils/Trans';

class SourceDeleteModal extends React.Component {
    constructor() {
        super();
        this.onCancel = this.onCancel.bind(this);
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }

    render() {
        console.log('Props in Source Delete Modal', this.props)
        var sel_source = null;
        if (this.props.sources && this.props.sources.sel_source) {
            sel_source = this.props.sources.sel_source
        }
        console.log('sel_source in Delete modal', sel_source)
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader><Trans id="Modal.DeleteSource"></Trans></ModalHeader>
                <div className="skin-fonti">
                    <ModalBody>
                        <h2 className="fonti-color">
                            <Trans id="Message.DeleteConfirm"></Trans>
                        </h2>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-default" onClick={() => {
                            this.props.sourceActions.deleteSource(sel_source.source.source_id);
                            this.props.modalActions.hideModal()
                        }} >
                            <Trans id="Button.Confirm"></Trans>
                        </button>
                        <button className="btn" onClick={this.onCancel}>
                            <Trans id="Button.Cancel"></Trans>
                        </button>
                    </ModalFooter>
                </div>

            </Modal>
        );
    }
}

export default connect(
    state => ({
        sources: state.sources
    }),
    dispatch => ({
        sourceActions: bindActionCreators({ deleteSource }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(SourceDeleteModal);
