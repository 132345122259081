import { put, takeEvery, call, all } from 'redux-saga/effects';
/** Import actions */
import {
    getLemmas,
    getLemmasFailed,
    getLemmasSucceed,
    postLemmaFailed,
    postLemmaSucceed,
    getLemmaDetailFailed,
    getLemmaDetailSucceed,
    updateLemmaFailed,
    updateLemmaSucceed,
    deleteLemmaFailed,
    deleteLemmaSucceed,
    getLemmaAutoFailed,
    getLemmaAutoSucceed
} from './lemmaActions';

/** Import api */
import * as lemmaApi from './lemmaApi';

export function* lemmaSubscriber() {
    yield all([takeEvery('GET_LEMMAS', getLemmasSaga)]);
    yield all([takeEvery('POST_LEMMA', postLemmaSaga)]);
    yield all([takeEvery('GET_LEMMA_DETAIL', getLemmaDetailSaga)]);
    yield all([takeEvery('UPDATE_LEMMA', updateLemmaSaga)]);
    yield all([takeEvery('DELETE_LEMMA', deleteLemmaSaga)]);
    yield all([takeEvery('GET_LEMMA_AUTO', getLemmaAutoSaga)]);
}
export function* getLemmasSaga() {
    try {
        const lemmas = yield call(lemmaApi.getLemmasApi);
        yield put(getLemmasSucceed(lemmas));
    } catch (error) {
        yield put(getLemmasFailed(error));
    }
}
export function* getLemmaAutoSaga({ payload: { query_string } }) {
    try {
        const lemmas = yield call(lemmaApi.getLemmaAutoApi, query_string);
        yield put(getLemmaAutoSucceed(lemmas));
    } catch (error) {
        yield put(getLemmaAutoFailed(error));
    }
}
export function* postLemmaSaga({ payload: { lemma, owner_ontoclass_id } }) {
    try {
        const lemmas = yield call(lemmaApi.postLemmaApi, lemma, owner_ontoclass_id);
        yield put(postLemmaSucceed(lemmas));
        yield put(getLemmas());
    } catch (error) {
        yield put(postLemmaFailed(error));
    }
}
export function* getLemmaDetailSaga({ payload: { lemma_id } }) {
    try {
        const lemma = yield call(lemmaApi.getLemmaDetailApi, lemma_id);
        yield put(getLemmaDetailSucceed(lemma));
    } catch (error) {
        console.log("error-- detail", error)
        yield put(getLemmaDetailFailed(error));
    }
}
export function* updateLemmaSaga({ payload: { lemma_id, lemma, owner_ontoclass_id } }) {
    try {
        const lemmas = yield call(lemmaApi.updateLemmaApi, lemma_id, lemma, owner_ontoclass_id);
        yield put(updateLemmaSucceed(lemmas));
    } catch (error) {
        console.log("error-- in update", error)
        yield put(updateLemmaFailed(error));
    }
}
export function* deleteLemmaSaga({ payload: { lemma_id } }) {
    try {
        yield call(lemmaApi.deleteLemmaApi, lemma_id);
        yield put(deleteLemmaSucceed());
    } catch (error) {
        console.log("error-- in delete", error)
        yield put(deleteLemmaFailed(error));
    }
}

