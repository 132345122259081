import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeUserPwd } from '../../../../services/users/usersAction';
import { hideModal } from '../../../modalConductorActions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import Trans from '../../../../services/utils/Trans';

class UserChangePwdModal extends React.Component {
    constructor() {
        super();
        this.onCancel = this.onCancel.bind(this);
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }

    render() {
        var userinfo = this.props.users.users
        console.log('props in changePwd modal', userinfo)
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader><Trans id="Modal.ChangePassword"></Trans></ModalHeader>
                <Formik
                    initialValues={{
                        password: '',
                        confirmpwd: ''
                    }}
                    validationSchema={Yup.object().shape({
                        password: Yup.string().min(6, '6 letter at least')
                            .required('Password is required'),
                        confirmpwd: Yup.string().oneOf([Yup.ref('password'), null], 'Confirm Password does not match')
                            .required('ConfirmPassword is required'),
                    })}
                    onSubmit={fields => {
                        const password = fields.password;
                        this.props.usersActions.changeUserPwd(userinfo.user_id, password);
                        this.props.modalActions.hideModal();
                    }}
                    render={({ errors, status, touched, setFieldValue }) => (
                        <Form className="skin-utenti">
                            <ModalBody>
                                <label className="text-muted text-center"><Trans id="Modal.EnterNewPassword"></Trans></label>
                                <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                            </ModalBody>
                            <ModalBody>
                                <label className="text-muted text-center"><Trans id="Modal.ConfirmPassword"></Trans></label>
                                <Field name="confirmpwd" type="password" className={'form-control' + (errors.confirmpwd && touched.confirmpwd ? ' is-invalid' : '')}/>
                                <ErrorMessage name="confirmpwd" component="div" className="invalid-feedback" />
                            </ModalBody>
                            <ModalFooter>
                                <button type="submit" className="btn btn-default" >
                                    <Trans id="Button.Confirm"></Trans>
                                </button>
                                <button className="btn" onClick={this.onCancel}>
                                    <Trans id="Button.Cancel"></Trans>
                                </button>
                            </ModalFooter>
                        </Form>
                    )}
                />
            </Modal>
        );
    }
}

export default connect(
    state => ({
        users: state.users
    }),
    dispatch => ({
        usersActions: bindActionCreators({ changeUserPwd }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(UserChangePwdModal);
