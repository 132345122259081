import { put, takeEvery, call, all } from 'redux-saga/effects';
/** Import actions */
import {
    getConcepts,
    getConceptsFailed,
    getConceptsSucceed,
    postConceptFailed,
    postConceptSucceed,
    getConceptDetailFailed,
    getConceptDetailSucceed,
    updateConceptFailed,
    updateConceptSucceed,
    deleteConceptFailed,
    deleteConceptSucceed,
    getConceptAutoFailed,
    getConceptAutoSucceed
} from './conceptActions';

/** Import api */
import * as conceptApi from './conceptApi';

export function* conceptSubscriber() {
    yield all([takeEvery('GET_CONCEPTS', getConceptsSaga)]);
    yield all([takeEvery('POST_CONCEPT', postConceptSaga)]);
    yield all([takeEvery('GET_CONCEPT_DETAIL', getConceptDetailSaga)]);
    yield all([takeEvery('UPDATE_CONCEPT', updateConceptSaga)]);
    yield all([takeEvery('DELETE_CONCEPT', deleteConceptSaga)]);
    yield all([takeEvery('GET_CONCEPT_AUTO', getConceptAutoSaga)]);

}
export function* getConceptsSaga({ payload: { project_id, by_user, lemma_id } }) {
    try {
        const concepts = yield call(conceptApi.getConceptsApi, project_id, by_user, lemma_id);
        yield put(getConceptsSucceed(concepts));
    } catch (error) {
        yield put(getConceptsFailed(error));
    }
}
export function* getConceptAutoSaga({ payload: { query_string } }) {
    try {
        const concepts = yield call(conceptApi.getConceptAutoApi, query_string);
        yield put(getConceptAutoSucceed(concepts));
    } catch (error) {
        yield put(getConceptAutoFailed(error));
    }
}
export function* postConceptSaga({ payload: { lemma_id, definition_id, sources, biblios, is_applied, rels, owner_ontoclass_id, ontoclasses } }) {
    try {
        const concepts = yield call(conceptApi.postConceptApi, lemma_id, definition_id, sources, biblios, is_applied, rels, owner_ontoclass_id, ontoclasses);
        yield put(postConceptSucceed(concepts));
        yield put(getConcepts());
    } catch (error) {
        yield put(postConceptFailed(error));
    }
}
export function* getConceptDetailSaga({ payload: { concept_id } }) {
    try {
        const concepts = yield call(conceptApi.getConceptDetailApi, concept_id);
        yield put(getConceptDetailSucceed(concepts));
    } catch (error) {
        yield put(getConceptDetailFailed(error));
    }
}
export function* updateConceptSaga({ payload: { concept_id, lemma_id, definition_id, sources, biblios, is_applied, rels, owner_ontoclass_id, ontoclasses } }) {
    try {
        const concepts = yield call(conceptApi.updateConceptApi, concept_id, lemma_id, definition_id, sources, biblios, is_applied, rels, owner_ontoclass_id, ontoclasses);
        yield put(updateConceptSucceed(concepts));
    } catch (error) {
        yield put(updateConceptFailed(error));
    }
}
export function* deleteConceptSaga({ payload: { concept_id } }) {
    try {
        yield call(conceptApi.deleteConceptApi, concept_id);
        yield put(deleteConceptSucceed());
    } catch (error) {
        yield put(deleteConceptFailed(error));
    }
}