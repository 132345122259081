import AuthReducer from '../auth/authReducer'
import CommonReducer from '../common/commonReducer'
import {reducer as i18nReducer} from '../i18n/i18n';
import { reducer as toastrReducer } from 'react-redux-toastr'
import ProjectReducer from '../project/projectReducer';
import OntoclassReducer from '../ontoclass/ontoclassReducer';
import UsersReducer from '../users/usersReducer';
import modalReducer from '../../modals/modalConductorReducer';
import LemmaReducer from '../lemma/lemmaReducer';
import DefinitionReducer from '../definition/definitionReducer';
import UtilityReducer from '../utilities/utilityReducer';
import ConceptReducer from '../concept/conceptReducer';
import SourceReducer from '../source/sourceReducer';
import BiblioReducer from '../biblio/biblioReducer';
import IndicatorTheorReducer from '../indicatorTheor/indicator_theorReducer';
import IndicatorAppliedReducer from '../indicatorApplied/indicator_appliedReducer';
import RequestReducer from '../request/requestReducer';
import EventReducer from '../event_entity/eventReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
export default {
    auth: AuthReducer,
    i18n: i18nReducer,
    common: CommonReducer,
    projects: ProjectReducer,
    ontoclasses: OntoclassReducer,
    modal: modalReducer,
    users: UsersReducer,
    toastr: toastrReducer,
    lemmas: LemmaReducer,
    definitions: DefinitionReducer,
    utilities: UtilityReducer,
    concepts: ConceptReducer,
    sources: SourceReducer,
    biblios: BiblioReducer,
    indicator_theors: IndicatorTheorReducer,
    indicators_applied: IndicatorAppliedReducer,
    requests: RequestReducer,
    events: EventReducer,
    dashboard: DashboardReducer,
}