import { put, takeEvery, call, all } from 'redux-saga/effects';
import {
    getUtilities,
    getUtilitiesFailed,
    getUtilitiesSucceed,
    postUtilityFailed,
    postUtilitySucceed,
    updateUtilityFailed,
    updateUtilitySucceed,
    deleteUtilityFailed,
    deleteUtilitySucceed
} from './utilityActions';
import * as utilityApi from './utilityApi';

export function* utilitySubscriber() {
    yield all([takeEvery('GET_UTILITIES', getUtilitiesSaga)]);
    yield all([takeEvery('POST_UTILITY', postUtilitySaga)]);
    yield all([takeEvery('UPDATE_UTILITY', updateUtilitySaga)]);
    yield all([takeEvery('DELETE_UTILITY', deleteUtilitySaga)]);
}
export function* getUtilitiesSaga({ payload: { ref_table } }) {
    try {
        const utilities = yield call(utilityApi.getUtilitiesApi, ref_table);
        yield put(getUtilitiesSucceed(utilities, ref_table));
    } catch (error) {
        console.log('utility saga error')
        yield put(getUtilitiesFailed(error));
    }
}
export function* postUtilitySaga({ payload: { ref_table, label, owner_ontoclass_id } }) {
    try {
        const utility = yield call(utilityApi.postUtilitiesApi, ref_table, label, owner_ontoclass_id);
        yield put(postUtilitySucceed(utility));
        yield put(getUtilities(ref_table));
    } catch (error) {
        console.log("error--", error)
        yield put(postUtilityFailed(error));
    }
}
export function* updateUtilitySaga({ payload: {utility_id, tbl, label } }) {
    try {
        const utility = yield call(utilityApi.updateUtilitiesApi, utility_id, tbl, label);
        yield put(updateUtilitySucceed(utility));
        yield put(getUtilities(tbl));
    } catch (error) {
        console.log("error-- update", error)
        yield put(updateUtilityFailed(error));
    }
}
export function* deleteUtilitySaga({ payload: { utility_id, ref_table } }) {
    try {
        yield call(utilityApi.deleteUtilitiesApi, utility_id, ref_table);
        yield put(deleteUtilitySucceed());
        yield put(getUtilities(ref_table));
    } catch (error) {
        console.log("error-- delete", error)
        yield put(deleteUtilityFailed(error));
    }
}