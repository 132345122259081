import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserProjects, addUserProjects } from '../../../../services/users/usersAction';
import { hideModal } from '../../../modalConductorActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Trans from '../../../../services/utils/Trans';

class UserProjectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItems: []
        }
        this.onCancel = this.onCancel.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }
    componentWillMount() {
        this.props.usersActions.getUserProjects();
    }
    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        if (target.checked) {
            this.state.selectedItems.push(value)
        } else {
            const index = this.state.selectedItems.indexOf(value);
            if (index > -1) {
                this.state.selectedItems.splice(index, 1);
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        console.log('didupdate called', this.state.selectedItems)
        for (let item of this.props.users.users.projects) {
            if (this.state.selectedItems.indexOf((item.project_id).toString()) < 0) {
                this.state.selectedItems.push((item.project_id).toString())
            }
        }
    }
    handleSubmit() {
        console.log('selecteItems', this.state.selectedItems, this.props.users.users.user_id,)
        this.props.usersActions.addUserProjects(this.props.users.users.user_id, this.state.selectedItems);
        this.onCancel();
    }
    render() {
        var projects = this.props.users.projects;
        var initialSelItems = [];
        for (let item of this.props.users.users.projects) {
            initialSelItems.push(item.project_id);
        }
        console.log('props in Associate project modal', this.props)
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader><Trans id="Modal.AssociateProject"></Trans></ModalHeader>
                <div className="skin-utenti">
                    <ModalBody>
                        {
                            projects ? projects.map(project => {
                                if (initialSelItems.indexOf(project.project_id) > -1) {
                                    return <div className="form-group row" key={"div_" + project.project_id}>
                                        <div className="col-md-6"><i id="project_name">{project.name}</i></div>
                                        <div className="col-md-6">
                                            <input type="checkbox" key={"proSel_" + project.project_id} name={"pro_" + project.project_id} defaultChecked value={project.project_id} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                } else {
                                    return <div className="form-group row" key={"div_" + project.project_id}>
                                        <div className="col-md-6"><i id="project_name">{project.name}</i></div>
                                        <div className="col-md-6">
                                            <input type="checkbox" key={"proSel_" + project.project_id} name={"pro_" + project.project_id} value={project.project_id} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                }
                            }): <></>
                        }

                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-default" onClick={() => this.handleSubmit()} >
                            <Trans id="Button.Confirm"></Trans>
                        </button>
                        <button className="btn" onClick={this.onCancel}>
                            <Trans id="Button.Cancel"></Trans>
                        </button>
                    </ModalFooter>
                </div>

            </Modal>
        );
    }
}

export default connect(
    state => ({
        users: state.users
    }),
    dispatch => ({
        usersActions: bindActionCreators({ addUserProjects, getUserProjects }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(UserProjectModal);
