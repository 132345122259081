import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postProject } from '../../../../services/project/projectActions';
import { hideModal } from '../../../modalConductorActions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import Trans from '../../../../services/utils/Trans';

class SubProjectAddModal extends React.Component {
    constructor() {
        super();
        this.onCancel = this.onCancel.bind(this);
        this.state = {
            project_id: null
        }
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }
    // componentDidWillMount() {
    //     if (this.props.projects && this.props.projects.sel_project) {
    //         console.log('add new sub project modal-------------', this.props.projects.sel_project.project_id)
    //         var project_id = this.props.projects.sel_project.project_id;
    //         this.setState({project_id});
    //     }
    // }
    render() {
        console.log('add new sub project modal-------------', this.props.projects.sel_project.project_id)

        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader><Trans id="Modal.NewSubProject"></Trans></ModalHeader>
                <Formik
                    initialValues={{
                        name: '',
                        description: '',
                        father_id: this.props.projects.sel_project.project_id,
                        link: this.props.projects.sel_project.link,
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required('Name is required'),
                        description: Yup.string(),
                        logo: Yup.mixed()
                    })}
                    onSubmit={fields => {
                        const formData = new FormData();
                        formData.append('name', fields.name);
                        formData.append('description', fields.description);
                        formData.append('father_id', this.props.projects.sel_project.project_id);
                        formData.append('link', fields.link);
                        this.props.projectActions.postProject(this.props.projects.sel_project.project_id, formData);
                        this.props.modalActions.hideModal();
                    }}
                >
                    {({ errors, status, touched, setFieldValue }) => (
                        <Form className="skin-progetti">
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Modal.Name"></Trans></label>
                                    <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} placeholder="Nome del sottoprogetto" />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Modal.Description"></Trans></label>
                                    <Field as="textarea" name="description" type="text" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} placeholder="Descrizione del sottoprogetto" />
                                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Modal.Link"></Trans></label>
                                    <Field as="textarea" name="link" type="text" className={'form-control' + (errors.link && touched.link ? ' is-invalid' : '')} placeholder="Link del progetto" />
                                    <ErrorMessage name="link" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button type="submit" className="btn btn-default" >
                                    <Trans id="Button.Confirm"></Trans>
                                </button>
                                <button className="btn" onClick={this.onCancel}>
                                    <Trans id="Button.Cancel"></Trans>
                                </button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </Modal>
        );
    }
}

export default connect(
    state => ({
        projects: state.projects
    }),
    dispatch => ({
        projectActions: bindActionCreators({ postProject }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(SubProjectAddModal);
