import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteUtility } from '../../../../services/utilities/utilityActions';
import { hideModal } from '../../../modalConductorActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Trans from '../../../../services/utils/Trans';

class UtilityDeleteModal extends React.Component {
    constructor() {
        super();
        this.onCancel = this.onCancel.bind(this);
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }

    render() {
        if (this.props && this.props.modal && this.props.modal.params) {
            var header = this.props.modal.params.ref_table
        }
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader>
                    {
                        header && header === 'time' ? <Trans id="Modal.TimeRefer"></Trans> : <></>
                    }
                    {
                        header && header === 'legisl' ? <Trans id="Modal.LegislRefer"></Trans> : <></>
                    }
                    {
                        header && header === 'measure' ? <Trans id="Modal.MeasureRefer"></Trans> : <></>
                    }
                    {
                        header && header === 'type_relation' ? <Trans id="Modal.TypeRelationRefer"></Trans> : <></>
                    }
                </ModalHeader>
                <div className="skin-utility">
                    <ModalBody>
                        <h2 className="ontoclass-color">
                            <Trans id="Message.DeleteConfirm"></Trans>
                        </h2>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-default" onClick={() => {
                            this.props.utilityActions.deleteUtility(this.props.modal.params.id, this.props.modal.params.ref_table);
                            this.props.modalActions.hideModal()
                        }} >
                            <Trans id="Button.Confirm"></Trans>
                        </button>
                        <button className="btn" onClick={this.onCancel}>
                            <Trans id="Button.Cancel"></Trans>
                        </button>
                    </ModalFooter>
                </div>

            </Modal>
        );
    }
}

export default connect(
    state => ({
        modal: state.modal
    }),
    dispatch => ({
        utilityActions: bindActionCreators({ deleteUtility }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(UtilityDeleteModal);
