import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postDefinition } from '../../../../services/definition/definitionActions';
import { getOntoclasses } from '../../../../services/ontoclass/ontoclassActions';
import { hideModal } from '../../../modalConductorActions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import Trans from '../../../../services/utils/Trans';

class DefinitionAddModal extends React.Component {
    constructor() {
        super();
        this.onCancel = this.onCancel.bind(this);
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }
    componentWillUnmount() {
        this.props.ontoclassActions.getOntoclasses();
    }
    componentDidMount() {
        console.log('Props in Definition add modal', this.props, this.props.ontoclasses)
    }
    componentDidUpdate(prevProps) {
        console.log('success definition', this.props.definitions)
    }
    render() {
        var ontoclasses = null;
        if (this.props && this.props.auth && this.props.auth.currentUser) {
            var profile = this.props.auth.currentUser.profile;
            if (profile === "Administrator") {
                ontoclasses = this.props.ontoclasses ? this.props.ontoclasses.ontoclasses : []
            } else if (profile === "Publisher") {
                ontoclasses = this.props.auth.currentUser.ontoclasses
            }
        }
        
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader><Trans id="Modal.NewDefinition"></Trans></ModalHeader>
                <Formik
                    initialValues={{
                        ontoclass: ontoclasses && ontoclasses.length > 0 ? ontoclasses[0].ontoclass_id : 1,
                        definition: ''
                    }}
                   
                    validationSchema={Yup.object().shape({
                        ontoclass: Yup.string()
                            .required('Ontoclass is required'),
                        definition: Yup.string()
                            .required('Definition is required')
                    })}
                    onSubmit={fields => {
                        this.props.definitionActions.postDefinition(fields.definition, fields.ontoclass);
                        this.props.modalActions.hideModal();
                    }}>
                    {({ errors, status, touched, setFieldValue }) => (
                        <Form className="skin-definizioni">
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Modal.OntoclassRefer"></Trans></label>
                                    <Field as="select" name="ontoclass" type="text" className={'form-control' + (errors.ontoclass && touched.ontoclass ? ' is-invalid' : '')}>
                                        {
                                            ontoclasses ? ontoclasses.map(ontoclass => {
                                                if (ontoclass) {
                                                    return <option key={"onto_" + ontoclass.ontoclass_id} value={ontoclass.ontoclass_id}>{ontoclass.ontoclass}</option>
                                                }
                                            }) : <></>
                                        }
                                    </Field>
                                    <ErrorMessage name="ontoclass" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Content.Definition"></Trans></label>
                                    <Field name="definition" type="text" className={'form-control' + (errors.definition && touched.definition ? ' is-invalid' : '')} autoComplete="off"/>
                                    <ErrorMessage name="definition" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button type="submit" className="btn btn-default" >
                                    <Trans id="Button.Confirm"></Trans>
                                </button>
                                <button className="btn" onClick={this.onCancel}>
                                    <Trans id="Button.Cancel"></Trans>
                                </button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>

            </Modal>
        );
    }
}

export default connect(
    state => ({
        auth: state.auth,
        ontoclasses: state.ontoclasses,
        definitions: state.definitions
    }),
    dispatch => ({
        definitionActions: bindActionCreators({ postDefinition }, dispatch),
        ontoclassActions: bindActionCreators({ getOntoclasses }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(DefinitionAddModal);
