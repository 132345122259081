import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from 'react-router-dom';
import LangSelector from './langeSelector';
import { connect } from 'react-redux';
import { logout } from '../../../services/auth/authActions';
import { bindActionCreators } from 'redux';
import { capitalize } from '../../../services/utils_services';
class Header extends React.Component {
    constructor() {
        super();
        this.state = {
            events: []
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.events && this.props.events.events && this.props.events.events !== prevProps.events.events) {
            var events = this.props.events.events;
            var temp = [];
            if (events && events.length > 15) {
                var length = events.length;
                for (var i = 15; i > 0; i--) {
                    temp.push(events[length - i])
                }
                this.setState({
                    events: temp
                })
            } else {
                this.setState({
                    events: events
                });
            }
        }
    }
    render() {
        var path = this.props.common.path;
        var color = "";
        var history_flag = false;
        if (path && path.length >= 2) {
            color = path[1].color;
            var path_length = path.length;
            var url = path[path_length - 1].pathName;
            if (url === 'AppliedDetail' || url === 'TheorDetail' || url === 'ConceptDetail' || url === 'Source' || url === 'Definition' || url === 'Lemma') {
                history_flag = true;
            }
        }
        return (
            <header className="main-header">
                {/* Logo */}
                <NavLink to="" className="logo">
                    {/* mini logo for sidebar mini 50x50 pixels */}
                    <span className="logo-mini">
                        <img src="/images/sidoc_mini.png" alt="logo-mini" />
                    </span>
                    {/* logo for regular state and mobile devices */}
                    <span className="logo-lg">
                        <img src="/images/sidoc.png" alt="logo-lg" />
                    </span>
                </NavLink>
                {/* Header Navbar: style can be found in header.less */}
                <nav className="navbar navbar-static-top" style={{ backgroundColor: color }}>
                    {/* Sidebar toggle button*/}
                    <NavLink
                        to=""
                        className="sidebar-toggle"
                        data-toggle="push-menu"
                        role="button"
                    >
                        <FontAwesomeIcon icon={["fa", "bars"]} />
                    </NavLink>
                    <div className="navbar-custom-menu">
                        <ul className="nav navbar-nav">
                            <li className="dropdown user user-menu">
                                <NavLink to="/" className="dropdown-toggle" data-toggle="dropdown">
                                    <img src="/images/user2-160x160.png" className="user-image" alt="User_Image" />
                                    <span className="hidden-xs"><strong id="user">{this.props.auth.currentUser ? capitalize(this.props.auth.currentUser.name) + ' ' + capitalize(this.props.auth.currentUser.lastname) : ''}</strong> (<i id="profile">{this.props.auth.currentUser ? this.props.auth.currentUser.profile: ''}</i>)</span>
                                </NavLink>
                                <ul className="dropdown-menu">
                                    <li className="user-header">
                                        <p>
                                            <strong id="user">{this.props.auth.currentUser ? capitalize(this.props.auth.currentUser.name) + ' ' + capitalize(this.props.auth.currentUser.lastname): ''}</strong> (<i id="profile">{this.props.auth.currentUser ?this.props.auth.currentUser.profile: ''}</i>)</p>
                                        <p>
                                            <button id="logout" className="btn btn-default btn-flat" style={{ backgroundColor: color, color: "white" }} onClick={() => {
                                                this.props.authActions.logout()
                                            }}>Sign out</button>
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            {
                                history_flag ? <li>
                                    <NavLink to="/" data-toggle="control-sidebar" id="nav-notification">
                                        <FontAwesomeIcon icon={["far", "bell"]} />
                                    </NavLink>
                                </li> : <></>
                            }

                            <LangSelector />
                        </ul>
                    </div>
                    <aside className="control-sidebar control-sidebar-light" id="history">
                        <h3 className="control-sidebar-heading" style={{ color: color }}>History</h3>
                        <ul className="control-sidebar-menu">
                            {
                                this.state.events && this.state.events.length > 0 ? this.state.events.map((event, index) => {
                                    return <li key={"li_" + index}>
                                        <div className="menu-info">
                                            <h4 className="control-sidebar-subheading"><span id="history_type_state">{event.type_state ? event.type_state : ''}</span> <span id="history_date" className="date">{event.date ? event.date : ''}</span></h4>
                                            <p><span id="history_auth">{event.auth ? event.auth : ''} </span></p>
                                        </div>
                                    </li>
                                }) : <></>
                            }
                        </ul>
                    </aside>
                    <div className="control-sidebar-bg"></div>
                </nav>
            </header>);
    }
}
export default connect(
    state => ({
        auth: state.auth,
        common: state.common,
        events: state.events
    }),
    dispatch => ({
        authActions: bindActionCreators({ logout }, dispatch)
    })
)(Header);

