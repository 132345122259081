import { wrapRequest, xapi } from '../utils_services';

const get = wrapRequest(async (project_id) =>
    xapi().get('/indicators/theor?project_id=' + project_id)
);

const postIndicatorTheor = wrapRequest(async (name, note, concept_id, algorithm, concepts, owner_ontoclass_id, biblios) => {
    return xapi().post('/indicators/theor', { name, note, concept_id, algorithm, concepts, owner_ontoclass_id, biblios })
});
const getIndicatorTheorDetailApi = wrapRequest(async (indicator_theor_id) =>
    xapi().get('/indicators/theor/' + indicator_theor_id)
);
const updateIndicatorTheorApi = wrapRequest(async (indicator_theor_id, name, note, concept_id, algorithm, concepts, owner_ontoclass_id, biblios) =>
    xapi().put('/indicators/theor/' + indicator_theor_id, { name, note, concept_id, algorithm, concepts, owner_ontoclass_id, biblios })
);
const updateIndicatorTheorOntoclassApi = wrapRequest(async (indicator_theor_id, ontoclasses) =>
    xapi().post('/indicators/theor/' + indicator_theor_id + '/addontoclass', { ontoclasses })
);
const deleteIndicatorTheorApi = wrapRequest(async (indicator_theor_id) =>
    xapi().delete('/indicators/theor/' + indicator_theor_id)
);
const getIndicatorThoerAutoApi = wrapRequest(async (query_string) =>
    xapi().get('/indicators/theor/auto?query_string=' + query_string)
);
export { get, postIndicatorTheor, getIndicatorTheorDetailApi, updateIndicatorTheorApi, updateIndicatorTheorOntoclassApi, deleteIndicatorTheorApi, getIndicatorThoerAutoApi };