import { put, takeEvery, call, all } from 'redux-saga/effects';
/** Import actions */
import {
    getEventsFailed,
    getEventsSucceed,
} from './eventActions';

/** Import api */
import * as eventApi from './eventApi';

export function* eventSubscriber() {
    yield all([takeEvery('GET_EVENTS', getEventsSaga)]);
}
export function* getEventsSaga({ payload: { id, table } }) {
    try {
        const events = yield call(eventApi.getEvents, id, table);
        yield put(getEventsSucceed(events));
    } catch (error) {
        console.log('event saga error')
        yield put(getEventsFailed(error));
    }
}
