import { createActions } from 'redux-actions';

const {
    getRequests,
    getRequestsFailed,
    getRequestsSucceed,
    postRequest,
    postRequestFailed,
    postRequestSucceed,
    getRequestDetail,
    getRequestDetailFailed,
    getRequestDetailSucceed,
    updateRequest,
    updateRequestFailed,
    updateRequestSucceed,
    deleteRequest,
    deleteRequestFailed,
    deleteRequestSucceed,
    approveRequest,
    approveRequestFailed,
    approveRequestSucceed,
    discardRequest,
    discardRequestFailed,
    discardRequestSucceed
} = createActions({
    GET_REQUESTS: () => ({}),
    GET_REQUESTS_FAILED: error => ({ error }),
    GET_REQUESTS_SUCCEED: requests => ({ requests }),
    POST_REQUEST: (object, request, ref_id, tbl, from_ontoclass_id, to_ontoclass_id) => ({ object, request, ref_id, tbl, from_ontoclass_id, to_ontoclass_id }),
    POST_REQUEST_FAILED: error => ({ error }),
    POST_REQUEST_SUCCEED: request => ({ request }),
    GET_REQUEST_DETAIL: request_id => ({ request_id }),
    GET_REQUEST_DETAIL_FAILED: error => ({ error }),
    GET_REQUEST_DETAIL_SUCCEED: requests => ({ requests }),
    UPDATE_REQUEST: (request_id, object, request, ref_id, tbl, from_ontoclass_id, to_ontoclass_id) => ({ request_id, object, request, ref_id, tbl, from_ontoclass_id, to_ontoclass_id }),
    UPDATE_REQUEST_FAILED: error => ({ error }),
    UPDATE_REQUEST_SUCCEED: request => ({ request }),
    DELETE_REQUEST: request_id => ({ request_id }),
    DELETE_REQUEST_FAILED: error => ({ error }),
    DELETE_REQUEST_SUCCEED: () => ({}),
    APPROVE_REQUEST: (request_id, answer) => ({ request_id, answer }),
    APPROVE_REQUEST_FAILED: error => ({ error }),
    APPROVE_REQUEST_SUCCEED: request => ({ request }),
    DISCARD_REQUEST: (request_id, answer) => ({ request_id, answer }),
    DISCARD_REQUEST_FAILED: error => ({ error }),
    DISCARD_REQUEST_SUCCEED: request => ({ request })
});

export {
    getRequests,
    getRequestsFailed,
    getRequestsSucceed,
    postRequest,
    postRequestFailed,
    postRequestSucceed,
    getRequestDetail,
    getRequestDetailFailed,
    getRequestDetailSucceed,
    updateRequest,
    updateRequestFailed,
    updateRequestSucceed,
    deleteRequest,
    deleteRequestFailed,
    deleteRequestSucceed,
    approveRequest,
    approveRequestFailed,
    approveRequestSucceed,
    discardRequest,
    discardRequestFailed,
    discardRequestSucceed
};
