import { put, takeEvery, call, all } from 'redux-saga/effects';
/** Import actions */
import {
    getBiblios,
    getBibliosFailed,
    getBibliosSucceed,
    postBiblioFailed,
    postBiblioSucceed,
    getBiblioDetailFailed,
    getBiblioDetailSucceed,
    updateBiblioFailed,
    updateBiblioSucceed,
    deleteBiblioFailed,
    deleteBiblioSucceed,
    getBiblioAutoFailed,
    getBiblioAutoSucceed
} from './biblioActions';

/** Import api */
import * as biblioApi from './biblioApi';

export function* bibliosSubscriber() {
    yield all([takeEvery('GET_BIBLIOS', getBiblioSaga)]);
    yield all([takeEvery('POST_BIBLIO', postBiblioSaga)]);
    yield all([takeEvery('GET_BIBLIO_DETAIL', getBiblioDetailSaga)]);
    yield all([takeEvery('UPDATE_BIBLIO', updateBiblioSaga)]);
    yield all([takeEvery('DELETE_BIBLIO', deleteBiblioSaga)]);
    yield all([takeEvery('GET_BIBLIO_AUTO', getBiblioAutoSaga)]);

}
export function* getBiblioSaga() {
    try {
        const biblios = yield call(biblioApi.get);
        yield put(getBibliosSucceed(biblios));
    } catch (error) {
        console.log('biblio saga error')
        yield put(getBibliosFailed(error));
    }
}
export function* getBiblioAutoSaga({ payload: { query_string } }) {
    try {
        const biblios = yield call(biblioApi.getBiblioAutoApi, query_string);
        yield put(getBiblioAutoSucceed(biblios));
    } catch (error) {
        yield put(getBiblioAutoFailed(error));
    }
}
export function* postBiblioSaga({ payload: { biblio, owner_ontoclass_id } }) {
    try {
        const biblios = yield call(biblioApi.postBiblio, biblio, owner_ontoclass_id);
        yield put(postBiblioSucceed(biblios));
        yield put(getBiblios());
    } catch (error) {
        console.log("error--", error)
        yield put(postBiblioFailed(error));
    }
}
export function* getBiblioDetailSaga({ payload: { biblio_id } }) {
    try {
        const biblio = yield call(biblioApi.getBiblioDetailApi, biblio_id);
        yield put(getBiblioDetailSucceed(biblio));
    } catch (error) {
        console.log('biblioDetail saga error')
        yield put(getBiblioDetailFailed(error));
    }
}
export function* updateBiblioSaga({ payload: { biblio_id, biblio, owner_ontoclass_id } }) {
    try {
        const biblios = yield call(biblioApi.updateBiblioApi, biblio_id, biblio, owner_ontoclass_id);
        yield put(updateBiblioSucceed(biblios));
    } catch (error) {
        console.log("error--", error)
        yield put(updateBiblioFailed(error));
    }
}
export function* deleteBiblioSaga({ payload: { biblio_id } }) {
    try {
        yield call(biblioApi.deleteBiblioApi, biblio_id);
        yield put(deleteBiblioSucceed());
    } catch (error) {
        console.log("error--", error)
        yield put(deleteBiblioFailed(error));
    }
}
