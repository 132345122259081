import React from 'react';
import { connect } from 'react-redux';
// Import Modals
import AddProject from './components/projects/add';
import AddUser from './components/users/add';
import EditUser from './components/users/edit';
import ChangePwd from './components/users/changePwd';
import AddUserOntoclass from './components/users/user_ontoclass';
import AddUserProject from './components/users/user_project';
import DeleteUser from './components/users/delete';
import AddOntoclass from './components/ontoclass/add';
import AddSubproject from './components/projects/addsub';
import EditOntoclass from './components/ontoclass/edit';
import DeleteOntoclass from './components/ontoclass/delete';
import EditProject from './components/projects/edit';
import DeleteProjectModal from './components/projects/delete';
import UpdateProjectOntoclass from './components/projects/project_ontoclass';
import ImportConcepts from './components/projects/import_concepts';
import AddLemma from './components/lemma/add';
import EditLemma from './components/lemma/edit';
import DeleteLemmaModal from './components/lemma/delete';
import AddDefinitionModal from './components/definition/add';
import EditDefinitionModal from './components/definition/edit';
import DeleteDefinitionModal from './components/definition/delete';
import AddUtilityModal from './components/utility/add';
import EditUtilityModal from './components/utility/edit';
import DeleteUtilityModal from './components/utility/delete';
import AddSourceModal from './components/source/add';
import DeleteSourceModal from './components/source/delete';
import EditSourceModal from './components/source/edit';
import AddBiblioModal from './components/biblio/add';
import DeleteBiblioModal from './components/biblio/delete';
import EditBiblioModal from './components/biblio/edit';
import AppliedModal from './components/indicator_applied';
import DeleteConceptModal from './components/concept/delete';
import DeleteTheorModal from './components/indicator_theor/delete';
import DeleteAppliedModal from './components/indicator_applied/delete';
import AnswerModal from './components/request/addanswer';
import ConfirmApproveModal from './components/request/confirmApprove';
import ConfirmDiscardModal from './components/request/confirmDiscard';

import './modal.css';

const ModalConductor = props => {
  switch (props.modal.modalType) {
    case 'ADD_PROJECT_MODAL':
      return <AddProject {...props} />;
    case 'ADD_USER_MODAL':
      return <AddUser {...props} />;
    case 'EDIT_USER_MODAL':
      return <EditUser {...props} />;
    case 'CHANGE_USER_PASSWORD':
      return <ChangePwd {...props} />;
    case 'ADD_USER_ONTOCLASSES':
      return <AddUserOntoclass {...props} />;
    case 'ADD_USER_PROJECTS':
      return <AddUserProject {...props} />;
    case 'DELETE_USER':
      return <DeleteUser {...props} />;
    case 'ADD_ONTOCLASS_MODAL':
      return <AddOntoclass {...props} />;
    case 'ADD_SUBPROJECT_MODAL':
      return <AddSubproject {...props} />;
    case 'EDIT_ONTOCLASS_MODAL':
      return <EditOntoclass {...props} />;
    case 'DELETE_ONTOCLASS_MODAL':
      return <DeleteOntoclass {...props} />;
    case 'EDIT_PROJECT_MODAL':
      return <EditProject {...props} />;
    case 'DELETE_PROJECT_MODAL':
      return <DeleteProjectModal {...props} />;
    case 'UPDATE_PROJECT_ONTOCLASS_MODAL':
      return <UpdateProjectOntoclass {...props} />;
    case 'IMPORT_CONCEPTS_MODAL':
      return <ImportConcepts {...props} />;
    case 'ADD_LEMMA_MODAL':
      return <AddLemma {...props} />;
    case 'EDIT_LEMMA_MODAL':
      return <EditLemma {...props} />;
    case 'DELETE_LEMMA_MODAL':
      return <DeleteLemmaModal {...props} />;
    case 'ADD_DEFINITION_MODAL':
      return <AddDefinitionModal {...props} />;
    case 'EDIT_DEFINITION_MODAL':
      return <EditDefinitionModal {...props} />;
    case 'DELETE_DEFINITION_MODAL':
      return <DeleteDefinitionModal {...props} />;
    case 'ADD_UTILITY_MODAL':
      return <AddUtilityModal {...props} />;
    case 'EDIT_UTILITY_MODAL':
      return <EditUtilityModal {...props} />;
    case 'DELETE_UTILITY_MODAL':
      return <DeleteUtilityModal {...props} />;
    case 'ADD_SOURCE_MODAL':
      return <AddSourceModal {...props} />;
    case 'ADD_BIBLIO_MODAL':
      return <AddBiblioModal {...props} />;
    case 'EDIT_SOURCE_MODAL':
      return <EditSourceModal {...props} />;
    case 'EDIT_BIBLIO_MODAL':
      return <EditBiblioModal {...props} />;
    case 'DELETE_SOURCE_MODAL':
      return <DeleteSourceModal {...props} />;
    case 'DELETE_BIBLIO_MODAL':
      return <DeleteBiblioModal {...props} />;
    case 'SHOW_APPLIED_MODAL':
      return <AppliedModal {...props} />;
    case 'DELETE_CONCEPT_MODAL':
      return <DeleteConceptModal {...props} />;
    case 'DELETE_THEOR_MODAL':
      return <DeleteTheorModal {...props} />;
    case 'DELETE_APPLIED_MODAL':
      return <DeleteAppliedModal {...props} />;
    case 'ADD_ANSWER_MODAL':
      return <AnswerModal {...props} />;
    case 'CONFIRM_APPROVE_MODAL':
      return <ConfirmApproveModal {...props} />;
    case 'CONFIRM_DISCARD_MODAL':
      return <ConfirmDiscardModal {...props} />;
    default:
      return null;
  }
};

export default connect(
  state => ({
    modal: state.modal
  }),
  null
)(ModalConductor);
