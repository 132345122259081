import React from 'react';
import { connect } from 'react-redux';
import { setPathname, addPathname } from '../../services/common/commonActions';
import { getConceptDetail, postConcept, getConceptAuto, updateConcept } from '../../services/concept/conceptActions';
import { getLemmaAuto } from '../../services/lemma/lemmaActions';
import { getDefinitionAuto } from '../../services/definition/definitionActions';
import { getSourceAuto } from '../../services/source/sourceActions';
import { getBiblioAuto } from '../../services/biblio/biblioActions';
import { getUtilities } from '../../services/utilities/utilityActions';
import { showModal } from '../../modals/modalConductorActions';
import { getOntoclasses, getOntoclassAuto } from '../../services/ontoclass/ontoclassActions';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import 'react-bootstrap-typeahead/css/Typeahead.css';
class NewConcept extends React.Component {
    constructor() {
        super();
        this.state = {
            owner_ontoclass_id: 1,
            search_lemma: '',
            lemmaArray: '',
            lemmaOptions: [],
            is_applied: false,
            search_definition: '',
            definitionArray: '',
            definitionOptions: [],
            search_source: [],
            sourceArray: [],
            sourceOptions: [],
            ontoclassArray: [],
            search_ontoclass: [],
            ontoclassOptions: [],
            biblioArray: [],
            search_biblio: [],
            biblioOptions: [],
            type_relation_id: 0,
            type_relation: '',
            search_concept_rel: [],
            concept_relOptions: [],
            concept_relArray: [],
            concept_rel_id: [],
        }
        this.lemma_typeahead_ref = null;
        this.definition_typeahead_ref = null;
        this.source_typeahead_ref = null;
        this.biblio_typeahead_ref = null;
        this.rel_concept_typeahead_ref = null;
    }
    componentWillMount() {
        // this.props.commonActions.setPathname(
        //     [
        //         { "pathName": "Home", "Url": "", "id": 1, "color": "" },
        //         { "pathName": "Concepts", "Url": "concepts", "id": 2, "color": "#81b05f" },
        //         { "pathName": "NewConcept", "Url": "/", "id": 3, "color": "#81b05f" },
        //     ]
        // )
        this.props.utilityActions.getUtilities('type_relation');
        this.props.ontoclassActions.getOntoclasses();
        if (this.props.match.params.id) {
            this.props.conceptActions.getConceptDetail(this.props.match.params.id);
        }
        var ontoclasses = null;
        if (this.props && this.props.auth && this.props.auth.currentUser) {
            var profile = this.props.auth.currentUser.profile;
            if (profile === "Administrator") {
                ontoclasses = this.props.ontoclasses ? this.props.ontoclasses.ontoclasses : []
            } else if (profile === "Publisher") {
                ontoclasses = this.props.auth.currentUser.ontoclasses
            }
            if (ontoclasses !== null) {
                this.setState({ owner_ontoclass_id: ontoclasses[0].ontoclass_id })
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.lemmas && this.props.lemmas.lemmas !== prevProps.lemmas.lemmas) {
            this.setState({
                lemmaOptions: this.props.lemmas.lemmas.suggestions
            })
        }
        if (this.props.definitions && this.props.definitions.definitions !== prevProps.definitions.definitions) {
            this.setState({
                definitionOptions: this.props.definitions.definitions.suggestions
            })
        }
        if (this.props.sources && this.props.sources.sources !== prevProps.sources.sources) {
            this.setState({
                sourceOptions: this.props.sources.sources.suggestions
            })
        }
        if (this.props.ontoclasses && this.props.ontoclasses.auto_ontoclasses !== prevProps.ontoclasses.auto_ontoclasses ) {
            this.setState({
                ontoclassOptions: this.props.ontoclasses.auto_ontoclasses
            })
        }
        if (this.props.biblios && this.props.biblios.biblios !== prevProps.biblios.biblios) {
            this.setState({
                biblioOptions: this.props.biblios.biblios.suggestions
            })
        }
        if (this.props.concepts && this.props.concepts.concepts !== prevProps.concepts.concepts) {
            this.setState({
                concept_relOptions: this.props.concepts.concepts.suggestions
            })
        }
        if (this.props.concepts.error !== prevProps.concepts.error && this.props.concepts.error !== null) {
            toastr.error('Error Occured', this.props.concepts.message);
        }

        if (this.props.concepts.error === null && !this.props.concepts.loading && prevProps.concepts && prevProps.concepts.loading && this.props.concepts.message) {
            toastr.success('Success', this.props.concepts.message);
            if (this.props.concepts.sel_concept && this.props.concepts.sel_concept !== prevProps.concepts.sel_concept) {
                this.props.history.push(`/concept/${this.props.concepts.sel_concept.concept_id}`)
            }
        }
        if (this.props.lemmas.error === null && !this.props.lemmas.loading && prevProps.lemmas && this.props.lemmas.message === null && prevProps.lemmas.lemmas !== this.props.lemmas.lemmas && !this.props.lemmas.lemmas.suggestions) {
            var lemmas = this.props.lemmas.lemmas;
            var item_lemma = lemmas[lemmas.length - 1];
            var templemmaArry = { value: item_lemma.lemma_id, label: item_lemma.lemma };
            this.setState({
                lemmaArray: templemmaArry,
                search_lemma: item_lemma.lemma_id
            })
        }
        if (this.props.definitions.error === null && !this.props.definitions.loading && prevProps.definitions && this.props.definitions.message === null && prevProps.definitions.definitions !== this.props.definitions.definitions && !this.props.definitions.definitions.suggestions) {
            var definitions = this.props.definitions.definitions;
            var item_definition = definitions[definitions.length - 1];
            var tempdefinitionArry = { value: item_definition.definition_id, label: item_definition.definition };
            this.setState({
                definitionArray: tempdefinitionArry,
                search_definition: item_definition.definition_id
            })
        }
        if (this.props.sources.error === null && !this.props.sources.loading && prevProps.sources && this.props.sources.message === null && prevProps.sources.sources !== this.props.sources.sources && !this.props.sources.sources.suggestions) {
            var sources = this.props.sources.sources;
            var _arraySource = this.state.sourceArray;
            var _searchSource = this.state.search_source;
            var item_sources = sources[sources.length - 1];
            _searchSource.push(item_sources.source_id.toString());
            var tempsourceArry = { value: item_sources.source_id, label: item_sources.source };
            _arraySource.push(tempsourceArry);
            this.setState({ sourceArray: _arraySource, search_source: _searchSource })
        }
        if (this.props.biblios.error === null && !this.props.biblios.loading && prevProps.biblios && this.props.biblios.message === null && prevProps.biblios.biblios !== this.props.biblios.biblios && !this.props.biblios.biblios.suggestions) {
            var biblios = this.props.biblios.biblios;
            var _arrayBiblio = this.state.biblioArray;
            var _searchBiblio = this.state.search_biblio;
            var item_biblios = biblios[biblios.length - 1];
            _searchBiblio.push(item_biblios.biblio_id.toString());
            var tempbiblioArry = { value: item_biblios.biblio_id, label: item_biblios.biblio };
            _arrayBiblio.push(tempbiblioArry);
            this.setState({ biblioArray: _arrayBiblio, search_biblio: _searchBiblio })
        }
    }
    componentDidMount() {
        var id = this.props.match.params.id;
        if (!id) {
            this.props.commonActions.addPathname({
                "pathName": "NewConcept", "Url": "/newconcept", "color": "#6d81ab", "subHeader": "New Concept"
            })
        }
        if (id) {
            let sel_concept = this.props.concepts && this.props.concepts.sel_concept ? this.props.concepts.sel_concept : null;
            if (sel_concept) {
                let _lemmaArray = { value: sel_concept.lemma_id, label: sel_concept.lemma };
                let _definitionArray = { value: sel_concept.definition_id, label: sel_concept.definition }
                let _sourceArray = [];
                let _biblioArray = [];
                let _searchSource = [];
                let _searchBiblio = [];
                let _ontoclassArray = [];
                let _searchOntoclass = [];
                if (sel_concept.sources.length > 0) {
                    for (let source of sel_concept.sources) {
                        let temp_source = { value: source.source_id, label: source.source };
                        _sourceArray.push(temp_source);
                        _searchSource.push(source.source_id.toString());
                    }
                }
                if (sel_concept.ontoclasses.length > 0) {
                    for (let ontoclass of sel_concept.ontoclasses){
                        let temp_ontoclass = { value: ontoclass.ontoclass_id, label: ontoclass.ontoclass};
                        _ontoclassArray.push(temp_ontoclass);
                        _searchOntoclass.push(ontoclass.ontoclass_id.toString())
                    }
                }
                if (sel_concept.biblios.length > 0) {
                    for (let biblio of sel_concept.biblios) {
                        var temp_biblio = { value: biblio.biblio_id, label: biblio.biblio };
                        _biblioArray.push(temp_biblio);
                        _searchBiblio.push(biblio.biblio_id.toString());
                    }
                }
                let _relArray = [];
                let _searchRel = [];
                if (sel_concept.rels.length > 0) {
                    for (let rel of sel_concept.rels) {
                        var temp_rel = {
                            type_relation: rel.type_relation,
                            rel_lemma: rel.rel_lemma,
                            rel_definition: rel.rel_definition
                        };
                        _relArray.push(temp_rel);
                        var temp_searchRel = {
                            type_relation_id: rel.type_relation_id,
                            concept_rel_id: rel.concept_rel_id
                        }
                        _searchRel.push(temp_searchRel);
                    }
                }
                this.setState({
                    owner_ontoclass_id: sel_concept.owner_ontoclass_id,
                    lemmaArray: _lemmaArray,
                    search_lemma: sel_concept.lemma_id,
                    is_applied: sel_concept.is_applied,
                    definitionArray: _definitionArray,
                    search_definition: sel_concept.definition_id,
                    sourceArray: _sourceArray,
                    biblioArray: _biblioArray,
                    search_source: _searchSource,
                    search_biblio: _searchBiblio,
                    concept_relArray: _relArray,
                    search_concept_rel: _searchRel,
                    ontoclassArray: _ontoclassArray,
                    search_ontoclass: _searchOntoclass
                })
            }
        }
    }
    onFilterChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    onCheckChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked
        });
    }
    onType_RelChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        var type_relations = this.props.utilities.type_relations;
        for (var i = 0; i < type_relations.length; i++) {
            if (type_relations[i].id.toString() === e.target.value.toString()) {
                this.setState({
                    type_relation: type_relations[i].label
                });
            }
        }
    }
    handleAddLemmaArray = (item) => {
        var _arrayLemma = this.state.lemmaArray;
        if (!_arrayLemma || (_arrayLemma && _arrayLemma.value !== item[0].value)) {
            this.setState({
                lemmaArray: item[0],
                search_lemma: item[0].value
            })
        }
        const instance = this.lemma_typeahead_ref.getInstance()
        instance.clear()
        instance.focus()
    }
    handleAddDefinitionArray = (item) => {
        var _arrayDefinition = this.state.definitionArray;
        if (!_arrayDefinition || (_arrayDefinition && _arrayDefinition.value !== item[0].value)) {
            this.setState({
                definitionArray: item[0],
                search_definition: item[0].value
            })
        }
        const instance = this.definition_typeahead_ref.getInstance()
        instance.clear()
        instance.focus()
    }
    handleAddSourceArray = (item) => {
        var _arraySource = this.state.sourceArray;
        var _searchSource = this.state.search_source;
        var _is = false;
        if (_arraySource && _arraySource.length > 0) {
            for (var i = 0; i < _arraySource.length; i++) {
                if (_arraySource[i].value === item[0].value) {
                    _is = true;
                    const instance = this.source_typeahead_ref.getInstance()
                    instance.clear()
                    instance.focus()
                    break;
                }
            }
        }
        if (!_is) {
            _arraySource.push(item[0]);
            this.setState({
                sourceArray: _arraySource
            });
            _searchSource.push(item[0].value.toString());
            this.setState({
                search_source: _searchSource
            });
            const instance = this.source_typeahead_ref.getInstance()
            instance.clear()
            instance.focus()
        }
    }
    handleAddOntoclassArray = (item) => {
        let _arrayOntoclasses = this.state.ontoclassArray;
        let _searchOntoclass = this.state.search_ontoclass;
        let _is = false;
        if (_arrayOntoclasses && _arrayOntoclasses.length > 0) {
            for (let i = 0; i < _arrayOntoclasses.length; i++) {
                if (_arrayOntoclasses[i].value === item[0].value) {
                    _is = true;
                    const instance = this.ontoclass_typeahead_ref.getInstance()
                    instance.clear()
                    instance.focus()
                    break;
                }
            }
        }
        if (!_is) {
            _arrayOntoclasses.push(item[0]);
            this.setState({
                ontoclassArray: _arrayOntoclasses
            });
            _searchOntoclass.push(item[0].value.toString());
            this.setState({
                search_ontoclass: _searchOntoclass
            }, () => {console.log('----------add ontoclass event', this.state.search_ontoclass)});
            const instance = this.ontoclass_typeahead_ref.getInstance()
            instance.clear()
            instance.focus()
        }
    }
    handleAddBiblioArray = (item) => {
        let _arrayBiblio = this.state.biblioArray;
        let _searchBiblio = this.state.search_biblio;
        let _is = false;
        if (_arrayBiblio && _arrayBiblio.length > 0) {
            for (let i = 0; i < _arrayBiblio.length; i++) {
                if (_arrayBiblio[i].value === item[0].value) {
                    _is = true;
                    const instance = this.biblio_typeahead_ref.getInstance()
                    instance.clear()
                    instance.focus()
                    break;
                }
            }
        }
        if (!_is) {
            _arrayBiblio.push(item[0]);
            this.setState({
                biblioArray: _arrayBiblio
            });
            _searchBiblio.push(item[0].value.toString());
            this.setState({
                search_biblio: _searchBiblio
            });
            const instance = this.biblio_typeahead_ref.getInstance()
            instance.clear()
            instance.focus()
        }
    }
    handleAddRel_ConceptArray = (item) => {
        let _arryConcept_rel = this.state.concept_relArray;
        let _searchConcept_rel = this.state.search_concept_rel;
        let _type_rel_id = this.state.type_relation_id;
        let _is = false;
        if (_arryConcept_rel && _arryConcept_rel.length > 0) {
            for (let i = 0; i < _arryConcept_rel.length; i++) {

                if (_searchConcept_rel[i].concept_rel_id === item[0].value && _searchConcept_rel[i].type_relation_id === _type_rel_id) {
                    _is = true;
                    const instance = this.rel_concept_typeahead_ref.getInstance()
                    instance.clear();
                    instance.focus();
                    this.setState({
                        type_relation_id: 0,
                        type_relation: ''
                    })
                    break;
                }
            }
        }
        if (!_is) {
            let rel_lemma = item[0].label.split("-")[0];
            let rel_definition = item[0].label.split("-")[1];
            let temp_array = {
                type_relation: this.state.type_relation,
                rel_lemma,
                rel_definition
            }
            _arryConcept_rel.push(temp_array);
            this.setState({
                concept_relArray: _arryConcept_rel
            });
            var temp_search = {
                type_relation_id: _type_rel_id,
                concept_rel_id: item[0].value
            }
            _searchConcept_rel.push(temp_search);
            this.setState({
                search_concept_rel: _searchConcept_rel
            });
            const instance = this.rel_concept_typeahead_ref.getInstance()
            instance.clear()
            instance.focus()
            this.setState({
                type_relation_id: 0,
                type_relation: ''
            })
        }
    }
    handleDeleteLemma = () => {
        this.setState({
            search_lemma: '',
            lemmaArray: ''
        });
    }

    handleDeleteDefinition = () => {
        this.setState({
            search_definition: '',
            definitionArray: ''
        })
    }
    handleDeleteSource = (value) => {
        var _searchSource = this.state.search_source;
        var _arraySource = this.state.sourceArray;
        if (_arraySource && _arraySource.length > 0) {
            for (var i = 0; i < _arraySource.length; i++) {
                if (_arraySource[i].value.toString() === value.toString()) {
                    _arraySource.splice(i, 1);
                    this.setState({
                        sourceArray: _arraySource
                    });
                    break;
                }
            }
        }
        var index = _searchSource.indexOf(value.toString());
        if (index > -1) {
            _searchSource.splice(index, 1);
        }

    }
    handleDeleteOntoclass = (value) => {
        let _searchOntoclass = this.state.search_ontoclass;
        let _arrayOntoclasses = this.state.ontoclassArray;
        if (_arrayOntoclasses && _arrayOntoclasses.length > 0) {
            for (var i = 0; i < _arrayOntoclasses.length; i++) {
                if (_arrayOntoclasses[i].value.toString() === value.toString()) {
                    _arrayOntoclasses.splice(i, 1);
                    this.setState({
                        ontoclassArray: _arrayOntoclasses
                    });
                    break;
                }
            }
        }
        var index = _searchOntoclass.indexOf(value.toString());
        if (index > -1) {
            _searchOntoclass.splice(index, 1);
        }

    }
    handleDeleteBiblio = (value) => {
        var _searchBiblio = this.state.search_biblio;
        var _arrayBiblio = this.state.biblioArray;
        if (_arrayBiblio && _arrayBiblio.length > 0) {
            for (var i = 0; i < _arrayBiblio.length; i++) {
                if (_arrayBiblio[i].value.toString() === value.toString()) {
                    _arrayBiblio.splice(i, 1);
                    this.setState({
                        biblioArray: _arrayBiblio
                    });
                    break;
                }
            }
        }
        var index = _searchBiblio.indexOf(value.toString());
        if (index > -1) {
            _searchBiblio.splice(index, 1);
        }

    }
    handleDeleteRel_Concept = (index) => {
        var _arryConcept_rel = this.state.concept_relArray;
        var _searchConcept_rel = this.state.search_concept_rel;
        if (_arryConcept_rel && _arryConcept_rel.length > 0) {
            _arryConcept_rel.splice(index, 1);
            this.setState({
                concept_relArray: _arryConcept_rel
            })
        }
        if (_searchConcept_rel && _searchConcept_rel.length > 0) {
            _searchConcept_rel.splice(index, 1);
            this.setState({
                search_concept_rel: _searchConcept_rel
            })
        }
    }
    handleLemmaSearch = (query) => {
        this.props.lemmaActions.getLemmaAuto(query);
    };
    handleDefinitionSearch = (query) => {
        this.props.definitionActions.getDefinitionAuto(query);
    }
    handleSourceSearch = (query) => {
        this.props.sourceActions.getSourceAuto(query);
    }
    handleOntoclassSearch = (query) => {
        this.props.ontoclassActions.getOntoclassAuto(query);
    }
    handleBiblioSearch = (query) => {
        this.props.biblioActions.getBiblioAuto(query);
    }
    handleRel_ConceptSearch = (query) => {
        this.props.conceptActions.getConceptAuto(query);
    }
    handlePostConcept = () => {
        var lemma_id = this.state.search_lemma;
        var definition_id = this.state.search_definition;
        var sources = this.state.search_source;
        var biblios = this.state.search_biblio;
        var is_applied = this.state.is_applied;
        var rels = this.state.search_concept_rel;
        var owner_ontoclass_id = this.state.owner_ontoclass_id;
        let ontoclasses = this.state.search_ontoclass
        if (owner_ontoclass_id === 0 || !lemma_id || !definition_id || !sources || sources.length < 1 || !ontoclasses || ontoclasses.length < 1) {
            Swal.fire({
                icon: 'error',
                title: 'You need to select Ontoclass, Lemma, Definition and Sources',
            });
            return;
        }
        if (lemma_id && definition_id && sources && ontoclasses) {
            if (this.props.match.params.id) {
                this.props.conceptActions.updateConcept(this.props.match.params.id, lemma_id, definition_id, sources, biblios, is_applied, rels, owner_ontoclass_id, ontoclasses);
            } else {
                this.props.conceptActions.postConcept(lemma_id, definition_id, sources, biblios, is_applied, rels, owner_ontoclass_id, ontoclasses);
            }
        }
    }
    render() {
        if (this.props.concepts.loading) {
            Swal.fire({
                title: 'Loading...',
                text: "Plese wait",
                onOpen: () => {
                    Swal.showLoading();
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
        } else {
            Swal.close();
        }
        let ontoclasses = null;
        if (this.props && this.props.auth && this.props.auth.currentUser) {
            var profile = this.props.auth.currentUser.profile;
            if (profile === "Administrator") {
                ontoclasses = this.props.ontoclasses ? this.props.ontoclasses.ontoclasses : []
            } else if (profile === "Publisher") {
                ontoclasses = this.props.auth.currentUser.ontoclasses
            }
        }
        var type_relations = null;
        if (this.props.utilities && this.props.utilities.type_relations) {
            type_relations = this.props.utilities.type_relations
        }
        return (
            <React.Fragment>
                <section className="content skin-concetti">
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label><Trans id="Modal.OntoclassRefer"></Trans>:</label>
                            <select name='owner_ontoclass_id' className="form-control" value={this.state.owner_ontoclass_id} onChange={this.onFilterChange} >
                                {
                                    ontoclasses && ontoclasses.length > 0 ? ontoclasses.map(ontoclass => {
                                        return <option key={"onto_" + ontoclass.ontoclass_id} value={ontoclass.ontoclass_id}>{ontoclass.ontoclass}</option>
                                    }) : <></>
                                }
                            </select>
                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-6">
                            <label><Trans id="Table.Lemma"></Trans>:</label>
                            <div className="input-group">
                                <AsyncTypeahead
                                    minLength={2}
                                    id="search_lemma"
                                    isLoading={false}
                                    onChange={(e) => { this.handleAddLemmaArray(e) }}
                                    onSearch={this.handleLemmaSearch}
                                    options={this.state.lemmaOptions}
                                    placeholder="Cerca lemma..."
                                    autoComplete="off"
                                    ref={(ref) => this.lemma_typeahead_ref = ref}
                                />
                                <span className="input-group-btn">
                                    <button className="btn btn-default" data-type="button"
                                        id="new_lemma" onClick={() => { this.props.modalActions.showModal('ADD_LEMMA_MODAL') }}>+</button>
                                </span>
                            </div>
                            <div className="lista_item">
                                {
                                    this.state.lemmaArray && this.state.lemmaArray.value ? <div key={`item-${this.state.lemmaArray.value}`}>
                                        <i id="lemma">{this.state.lemmaArray.label} </i> <button type="button" className="btn btn-danger btn-xs" id="del_lemma" onClick={() => { this.handleDeleteLemma() }}><span
                                            className="glyphicon glyphicon-trash" aria-hidden="true" ></span></button>
                                        <input type="hidden" name="lemma_id" />
                                    </div> : <></>
                                }

                            </div>
                        </div>
                        <div className="col-md-6">
                            <label><Trans id="Content.Specification"></Trans>:</label>
                            <div className="checkbox">
                                <label>
                                    <input type="checkbox" checked={this.state.is_applied} name="is_applied" onChange={this.onCheckChange} />
                                    <span className=""></span>
                                </label>
                            </div>

                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label><Trans id="Table.Definition"></Trans>:</label>
                            <div className="input-group">
                                <AsyncTypeahead
                                    minLength={2}
                                    id="search_definition"
                                    isLoading={false}
                                    onChange={(e) => { this.handleAddDefinitionArray(e) }}
                                    onSearch={this.handleDefinitionSearch}
                                    options={this.state.definitionOptions}
                                    placeholder="Cerca definizione..."
                                    ref={(ref) => this.definition_typeahead_ref = ref}
                                    autoComplete="off"
                                />
                                <span className="input-group-btn">
                                    <button className="btn btn-default" data-type="button" id="new_definition"
                                        onClick={() => { this.props.modalActions.showModal('ADD_DEFINITION_MODAL') }}>+</button>
                                </span>
                            </div>
                            <div className="lista_item">
                                {
                                    this.state.definitionArray && this.state.definitionArray.value ? <div key={`item-${this.state.definitionArray.value}`}>
                                        <i id="definition">{this.state.definitionArray.label} </i>
                                        <button type="button" className="btn btn-danger btn-xs" id="del_definition" onClick={() => { this.handleDeleteDefinition() }}>
                                            <span className="glyphicon glyphicon-trash" aria-hidden="true" ></span>
                                        </button>
                                        <input type="hidden" name="definition_id" />
                                    </div> : <></>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label><Trans id="Table.Source" />:</label>
                            <div className="input-group">
                                <AsyncTypeahead
                                    minLength={2}
                                    id="search_source"
                                    isLoading={false}
                                    onChange={(e) => { this.handleAddSourceArray(e) }}
                                    onSearch={this.handleSourceSearch}
                                    options={this.state.sourceOptions}
                                    placeholder="Cerca fonte..."
                                    ref={(ref) => this.source_typeahead_ref = ref}
                                    autoComplete="off"
                                />
                                <span className="input-group-btn">
                                    <button className="btn btn-default" data-type="button" id="new_source"
                                        onClick={() => { this.props.modalActions.showModal('ADD_SOURCE_MODAL') }}>+</button>
                                </span>
                            </div>
                            <div className="lista_item" id="source_line">
                                {
                                    this.state.sourceArray && this.state.sourceArray.length > 0 ? this.state.sourceArray.map(item => {
                                        return (
                                            <div key={`item-${item.value}`}>
                                                <i id="lemma"><a href={item.label} target="_blank">{item.label}</a></i>
                                                <button type="button" className="btn btn-danger btn-xs" id="del_source" onClick={() => { this.handleDeleteSource(item.value) }}>
                                                    <span className="glyphicon glyphicon-trash" aria-hidden="true" ></span>
                                                </button>
                                                <input type="hidden" name="source_id" />
                                            </div>
                                        )
                                    }) : <></>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label><Trans id="Table.Ontoclass" />:</label>
                            <div className="input-group">
                                <AsyncTypeahead
                                    minLength={2}
                                    id="search_ontoclass"
                                    isLoading={false}
                                    onChange={(e) => { this.handleAddOntoclassArray(e) }}
                                    onSearch={this.handleOntoclassSearch}
                                    options={this.state.ontoclassOptions}
                                    placeholder="Cerca ontoclass..."
                                    ref={(ref) => this.ontoclass_typeahead_ref = ref}
                                    autoComplete="off"
                                />
                                <span className="input-group-btn">
                                    <button className="btn btn-default" data-type="button" id="new_ontoclass"
                                        onClick={() => { this.props.modalActions.showModal('ADD_ONTOCLASS_MODAL') }}>+</button>
                                </span>
                            </div>
                            <div className="lista_item" id="ontoclass_line">
                                {
                                    this.state.ontoclassArray && this.state.ontoclassArray.length > 0 ? this.state.ontoclassArray.map(item => {
                                        return (
                                            <div key={`item-${item.value}`}>
                                                <i id="lemma"><a href={item.label} target="_blank">{item.label}</a></i>
                                                <button type="button" className="btn btn-danger btn-xs" id="del_ontoclass" onClick={() => { this.handleDeleteOntoclass(item.value) }}>
                                                    <span className="glyphicon glyphicon-trash" aria-hidden="true" ></span>
                                                </button>
                                                <input type="hidden" name="ontoclass_id" />
                                            </div>
                                        )
                                    }) : <></>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label><Trans id="Table.Biblio" />:</label>
                            <div className="input-group">
                                <AsyncTypeahead
                                    minLength={2}
                                    id="search_biblio"
                                    isLoading={false}
                                    onChange={(e) => { this.handleAddBiblioArray(e) }}
                                    onSearch={this.handleBiblioSearch}
                                    options={this.state.biblioOptions}
                                    placeholder="Cerca bibliografia..."
                                    ref={(ref) => this.biblio_typeahead_ref = ref}
                                    autoComplete="off"
                                />
                                <span className="input-group-btn">
                                    <button className="btn btn-default" data-type="button" id="new_biblio"
                                        onClick={() => { this.props.modalActions.showModal('ADD_BIBLIO_MODAL') }}>+</button>
                                </span>
                            </div>
                            <div className="lista_item" id="biblio_line">
                                {
                                    this.state.biblioArray && this.state.biblioArray.length > 0 ? this.state.biblioArray.map(item => {
                                        return (
                                            <div key={`item-${item.value}`}>
                                                <i id="lemma"><a href={item.label} target="_blank">{item.label}</a></i>
                                                <button type="button" className="btn btn-danger btn-xs" id="del_biblio" onClick={() => { this.handleDeleteBiblio(item.value) }}>
                                                    <span className="glyphicon glyphicon-trash" aria-hidden="true" ></span>
                                                </button>
                                                <input type="hidden" name="biblio_id" />
                                            </div>
                                        )
                                    }) : <></>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-12">
                            <h3><Trans id="Content.Relations"></Trans></h3>
                            <select name="type_relation_id" id="type_relation_id" className="form-control" value={this.state.type_relation_id} onChange={this.onType_RelChange}>
                                <option key="type_0" value={0} disabled>Select</option>
                                {
                                    type_relations ? type_relations.map(type_relation => {
                                        return <option key={"type_" + type_relation.id} value={type_relation.id}>{type_relation.label}</option>
                                    }) : <></>
                                }
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-12">
                            <AsyncTypeahead
                                minLength={2}
                                id="search_ref_concept"
                                isLoading={false}
                                onChange={(e) => { this.handleAddRel_ConceptArray(e) }}
                                onSearch={this.handleRel_ConceptSearch}
                                options={this.state.concept_relOptions}
                                placeholder={this.state.type_relation_id ? "" : "Select a type of relation"}
                                ref={(ref) => this.rel_concept_typeahead_ref = ref}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div id="box_concetto_rel" className="box_fonte" id="rel_line">
                        {
                            this.state.concept_relArray && this.state.concept_relArray.length > 0 ? this.state.concept_relArray.map((item, index) => {
                                return <div className="linea_sx" key={"linea_sx" + index}>
                                    <div><span lang="it"><Trans id="Content.TypeRelation"></Trans>: </span><strong id="type_relation">{item.type_relation}</strong>
                                    </div>
                                    <div><span lang="it"><Trans id="Table.Lemma"></Trans>: </span><strong id="rel_lemma">{item.rel_lemma}</strong></div>
                                    <div><span lang="it"><Trans id="Table.Definition"></Trans>: </span><strong id="rel_definition">{item.rel_definition}</strong></div>
                                    <input type="hidden" name="concept_rel_id" />
                                    <input type="hidden" name="type_relation_id" />
                                    <div className="rx_comandi">
                                        <button type="button" className="btn btn-default btn-xs" onClick={() => this.handleDeleteRel_Concept(index)}><span className="glyphicon glyphicon-trash"
                                            aria-hidden="true"></span></button>
                                    </div>
                                </div>
                            }) : <></>
                        }

                        <div className="comandi">
                            <button className="btn btn-default" id="confirm" onClick={() => this.handlePostConcept()}><b><Trans id="Button.Confirm"></Trans></b></button>
                            <button className="btn" id="back" onClick={() => { this.props.history.goBack() }}><b><Trans id="Button.Back"></Trans></b></button>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({
        concepts: state.concepts,
        utilities: state.utilities,
        auth: state.auth,
        ontoclasses: state.ontoclasses,
        lemmas: state.lemmas,
        definitions: state.definitions,
        sources: state.sources,
        biblios: state.biblios
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname, addPathname }, dispatch),
        conceptActions: bindActionCreators({ getConceptDetail, postConcept, getConceptAuto, updateConcept }, dispatch),
        utilityActions: bindActionCreators({ getUtilities }, dispatch),
        modalActions: bindActionCreators({ showModal }, dispatch),
        ontoclassActions: bindActionCreators({ getOntoclasses, getOntoclassAuto }, dispatch),
        lemmaActions: bindActionCreators({ getLemmaAuto }, dispatch),
        definitionActions: bindActionCreators({ getDefinitionAuto }, dispatch),
        sourceActions: bindActionCreators({ getSourceAuto }, dispatch),
        biblioActions: bindActionCreators({ getBiblioAuto }, dispatch)
    })
)(NewConcept);
