import React from "react";
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import configureStore from './store'
import AppRoutes from './AppRoutes';
import  I18nProvider  from "./services/i18n/I18nProvider";
import 'react-bootstrap-typeahead/css/Typeahead.css';
const { persistor, store } = configureStore()

const onBeforeLift = () => {
  // take some action before the gate lifts
}

const App = (props) => {
  return (
    <main>
      <Provider store={store}>
        <PersistGate
          //loading={<Loading/>>}
          onBeforeLift={onBeforeLift}
          persistor={persistor}>
            <I18nProvider>
               <AppRoutes/>
            </I18nProvider>
        </PersistGate>
      </Provider>
    </main>
  )
};

export default App;
