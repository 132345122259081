import React from 'react';
import { connect } from 'react-redux';
import { setPathname } from '../../services/common/commonActions';
import { getProjects } from '../../services/project/projectActions';
import { getIndicatorTheors } from '../../services/indicatorTheor/indicator_theorActions';
import { getIndicatorApplied } from '../../services/indicatorApplied/indicator_appliedActions';
import { getOntoclasses } from '../../services/ontoclass/ontoclassActions';
import { getStoredUser } from '../../services/auth/services';
import { showModal } from '../../modals/modalConductorActions';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
// import 'datatables.net'
// import 'datatables.net-bs'
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from 'react-tooltip';
class Indicator extends React.Component {
	constructor() {
		super();
		this.indicatorTheorTable = null;
		this.indicatorAppliedTable = null;
		this.state = {
			t_filter_name: '',
			t_filter_count_concepts_from: '',
			t_filter_count_concepts_to: '',
			t_filter_count_indicators_a_from: '',
			t_filter_count_indicators_a_to: '',
			t_filter_updatedAt_from: '',
			t_filter_updatedAt_to: '',
			t_filter_project_id: 0,
			t_filter_owner_ontoclasse: '',
			t_filter_auth: '',
			t_filter_biblio: '',
			a_filter_name: '',
			a_filter_updatedAt_from: '',
			a_filter_updatedAt_to: '',
			a_filter_project_id: 0,
			a_filter_owner_ontoclass: '',
			a_filter_auth: '',
		}
		$.fn.dataTableExt.oSort["customdate-desc"] = function (_x, _y) {

			var xx = _x.split('/');
			var x = new Date(xx[2], xx[1], xx[0]);
			x = x.getTime();

			var yy = _y.split('/');
			var y = new Date(yy[2], yy[1], yy[0]);
			y = y.getTime();
			if (x > y) {
				return -1;
			}

			return 1;

		};

		$.fn.dataTableExt.oSort["customdate-asc"] = function (_x, _y) {

			var xx = _x.split('/');
			var x = new Date(xx[2], xx[1], xx[0]);
			x = x.getTime();

			var yy = _y.split('/');
			var y = new Date(yy[2], yy[1], yy[0]);
			y = y.getTime();

			if (x > y) {
				return 1;
			}
			return -1;
		}
	}

	componentWillMount() {
		this.props.commonActions.setPathname(
			[
				{ "pathName": "Home", "Url": "", "id": 1, "color": "", "subHeader": "" },
				{ "pathName": "Indicators", "Url": "indicators", "id": 2, "color": "#927e9b", "subHeader": "Indicators" }
			]
		);
		this.props.indicator_appliedActions.getIndicatorApplied();
		this.props.indicator_theorActions.getIndicatorTheors();
        this.props.ontoclassActions.getOntoclasses();
        this.props.projectActions.getProjects();
		const goToPreviousPath = () => {
			this.props.history.goBack()
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.props.indicator_theors && this.props.indicator_theors !== prevProps.indicator_theors && this.indicatorTheorTable !== null) {
			this.indicatorTheorTable.clear();
			if (this.props.indicator_theors.indicator_theors) {
				this.indicatorTheorTable.rows.add(this.props.indicator_theors.indicator_theors);
			}
			this.indicatorTheorTable.draw();
		}
		if (this.props.indicators_applied && this.props.indicators_applied.indicators_applied !== prevProps.indicators_applied.indicators_applied && this.indicatorAppliedTable !== null) {
			this.indicatorAppliedTable.clear();
			if (this.props.indicators_applied.indicators_applied) {
				this.indicatorAppliedTable.rows.add(this.props.indicators_applied.indicators_applied);
			}
			this.indicatorAppliedTable.draw();
		}
		if (this.props.indicator_theors.error !== prevProps.indicator_theors.error && this.props.indicator_theors.error !== null) {
			toastr.error('Error Occured', this.props.indicator_theors.message);
		}

		if (this.props.indicator_theors.error === null && !this.props.indicator_theors.loading && prevProps.indicator_theors && prevProps.indicator_theors.loading && this.props.indicator_theors.message) {
			toastr.success('Success', this.props.indicator_theors.message);
		}
		if (this.props.indicators_applied.error !== prevProps.indicators_applied.error && this.props.indicators_applied.error !== null) {
			toastr.error('Error Occured', this.props.indicators_applied.message);
		}

		if (this.props.indicators_applied.error === null && !this.props.indicators_applied.loading && prevProps.indicators_applied && prevProps.indicators_applied.loading && this.props.indicators_applied.message) {
			toastr.success('Success', this.props.indicators_applied.message);
		}
	}
	componentDidMount() {
		var _that = this;
		var currentUser = getStoredUser();
        var userVisibility = true;
		if (currentUser.profile == 'Guest') {
			$("[edit=1]").hide();
            userVisibility = false;
		}
		this.indicatorTheorTable = $('#list_table_theor').DataTable({
			'paging': true,
			'lengthChange': false,
			'searching': false,
			'ordering': true,
			'info': true,
			'autoWidth': false,
			'scrollX': false,
			'createdRow': function (row, data, dataIndex) {
				$(row).on('click', function () {
					if (data && data.indicator_t_id) {
						_that.goTheorDetail(data.indicator_t_id);
					}
				})
			},
			'columns': [
				{
					'data': 'name'
				},
				{
					'data': 'note',
				},
				{
					'data': 'concepts',
					'render': function (data, type, full, meta) {
						return data ? data.length : ''
					}
				},
				{
					'data': 'biblios',
					'render': function (data, type, full, meta) {
						var result = "";
						for (var i = 0; i < data.length; i++) {
							result += data[i].biblio;
							if (i < data.length - 1) {
								result += '; ';
							}
						}
						return result;
					}
				},
				{
					'data': 'indicators_applied',
					'render': function (data, type, full, meta) {
						return data ? data.length : ''
					}
				},
				{
					'data': 'owner_ontoclass'
				},
				{
					'data': 'updatedAt',
				},
				{
					'data': 'auth',
				},
			],
			columnDefs: [
				{
					'targets': 6, //index of column
					'type': 'customdate',
					'visible': userVisibility
				},
				{
					'targets': 7, //index of column
					'visible': userVisibility
				}
			],
		});
		this.indicatorAppliedTable = $('#list_table_applied').DataTable({
			'paging': true,
			'lengthChange': false,
			'searching': false,
			'ordering': true,
			'info': true,
			'autoWidth': false,
			'scrollX': false,
			'createdRow': function (row, data, dataIndex) {
				$(row).on('click', function () {
					if (data && data.indicator_applied_id) {
						_that.goAppliedDetail(data.indicator_applied_id);
					}
				})
			},
			'columns': [
				{
					'data': 'name'
				},
				{
					'data': 'indicator_theor',
					// 'render': function (data, type, full, meta) {
					// 	return data ? data.name : ''
					// }
				},
				{
					'data': 'owner_ontoclass'
				},
				{
					'data': 'updatedAt',
				},
				{
					'data': 'auth',
				},
			],
			columnDefs: [
				{
					'targets': 3, //index of column
					'type': 'customdate',
					'visible': userVisibility
				},
				{
					'targets': 4, //index of column
					'visible': userVisibility
				}
			],
		});
		this.indicatorTheorTable.clear();
		if (this.props.indicator_theors && this.props.indicator_theors.indicator_theors) {
			this.indicatorTheorTable.rows.add(this.props.indicator_theors.indicator_theors);
		}
		this.indicatorTheorTable.draw();
		this.indicatorAppliedTable.clear();
		if (this.props.indicators_applied && this.props.indicators_applied.indicators_applied) {
			this.indicatorAppliedTable.rows.add(this.props.indicators_applied.indicators_applied);
		}
		this.indicatorAppliedTable.draw();
	}
	onFilterChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	goTheorDetail = (theorId) => {
		this.props.history.push(`/theordetail/${theorId}`);
	}
	goAppliedDetail = (appliedId) => {
		this.props.history.push(`/applieddetail/${appliedId}`);
	}
	handleTheorSearch = (e) => {
		var filterData = [];
		if (this.props.indicator_theors && this.props.indicator_theors.indicator_theors) {
			var indicator_theors = this.props.indicator_theors.indicator_theors;
			for (var i = 0; i < indicator_theors.length; i++) {
				var fileterRes = true;
				// var temp = indicator_theors[i].updatedAt.split(',')[0].split('/');
				var temp = indicator_theors[i].updatedAt.split('/');
				var year = temp[2];
				var month = temp[1];
				var day = temp[0];
				var concept_date = new Date(`${year}/${month}/${day}`);
				if (this.state.t_filter_updatedAt_from !== '') {
					// var t_filter_updatedAt_from = new Date(this.state.t_filter_updatedAt_from.replace(/-/g, "/"))
					var t_filter_updatedAt_from = new Date(this.state.t_filter_updatedAt_from);
					if (t_filter_updatedAt_from.getTime() > concept_date.getTime()) {
						fileterRes = false;
					}
				}
				if (this.state.t_filter_updatedAt_to !== '') {
					// var t_filter_updatedAt_to = new Date(this.state.t_filter_updatedAt_to.replace(/-/g, "/"))
					var t_filter_updatedAt_to = new Date(this.state.t_filter_updatedAt_to);
					if (t_filter_updatedAt_to.getTime() < concept_date.getTime()) fileterRes = false;
				}
				if (this.state.t_filter_name !== '') {
					if (indicator_theors[i].name.toLowerCase().indexOf(this.state.t_filter_name.toLowerCase()) === -1) fileterRes = false;
				}
				if (this.state.t_filter_biblio !== '') {
					var temp_biblio = '';
					if (indicator_theors[i].biblios && indicator_theors[i].biblios.length > 0) {
						for (let biblio of indicator_theors[i].biblios) {
							temp_biblio += biblio.biblio
						}
					}
					if (temp_biblio.toLowerCase().indexOf(this.state.t_filter_biblio.toLowerCase()) === -1) fileterRes = false;
				}
				if (this.state.t_filter_count_concepts_from) {
					if (indicator_theors[i].concepts.length < this.state.t_filter_count_concepts_from) fileterRes = false;
				}
				if (this.state.t_filter_count_concepts_to) {
					if (indicator_theors[i].concepts.length > this.state.t_filter_count_concepts_to) fileterRes = false;
				}
				if (this.state.t_filter_count_indicators_a_from) {
					if (indicator_theors[i].indicators_applied.length < this.state.t_filter_count_indicators_a_from) fileterRes = false;
				}
				if (this.state.t_filter_count_indicators_a_to) {
					if (indicator_theors[i].indicators_applied.length > this.state.t_filter_count_indicators_a_to) fileterRes = false;
				}
				if (this.state.t_filter_owner_ontoclass) {
					if (indicator_theors[i].owner_ontoclass_id != this.state.t_filter_owner_ontoclass) fileterRes = false;
				}
				if (this.state.t_filter_auth) {
					if (indicator_theors[i].auth.toLowerCase().indexOf(this.state.t_filter_auth.toLowerCase()) === -1) fileterRes = false;
				}
				if (this.state.t_filter_biblio !== '') {
					var temp_biblio = '';
					if (indicator_theors[i].biblios && indicator_theors[i].biblios.length > 0) {
						for (let biblio of indicator_theors[i].biblios) {
							temp_biblio += biblio.biblio
						}
					}
					if (temp_biblio.toLowerCase().indexOf(this.state.t_filter_biblio.toLowerCase()) === -1) fileterRes = false;
				}
				if (this.state.t_filter_project_id > 0) {
					if (! indicator_theors[i].projects.find(element => element == this.state.t_filter_project_id)) fileterRes = false;
				}
				if (fileterRes) {
					filterData.push(indicator_theors[i]);
				}
			}
			this.indicatorTheorTable.clear();
			this.indicatorTheorTable.rows.add(filterData);
			this.indicatorTheorTable.draw();
		}
	}
	handleAppliedSearch = (e) => {
		var filterData = [];
		if (this.props.indicators_applied && this.props.indicators_applied.indicators_applied) {
			var indicators_applied = this.props.indicators_applied.indicators_applied;
			for (var i = 0; i < indicators_applied.length; i++) {
				var fileterRes = true;
				var temp = indicators_applied[i].updatedAt.split('/');
				var year = temp[2];
				var month = temp[1];
				var day = temp[0];
				var concept_date = new Date(`${year}/${month}/${day}`);
				if (this.state.a_filter_updatedAt_from !== '') {
					var a_filter_updatedAt_from = new Date(this.state.a_filter_updatedAt_from)

					if (a_filter_updatedAt_from.getTime() > concept_date.getTime()) {
						fileterRes = false;
					}
				}
				if (this.state.a_filter_updatedAt_to !== '') {
					var a_filter_updatedAt_to = new Date(this.state.a_filter_updatedAt_to)
					if (a_filter_updatedAt_to.getTime() < concept_date.getTime()) fileterRes = false;
				}
				if (this.state.a_filter_name !== '') {
					if (indicators_applied[i].name.toLowerCase().indexOf(this.state.a_filter_name.toLowerCase()) === -1) fileterRes = false;
				}
				if (this.state.a_filter_owner_ontoclass) {
					if (indicators_applied[i].owner_ontoclass_id != this.state.a_filter_owner_ontoclass) fileterRes = false;
				}
				if (this.state.a_filter_auth) {
					if (indicators_applied[i].auth.toLowerCase().indexOf(this.state.a_filter_auth.toLowerCase()) === -1) fileterRes = false;
				}
				if (this.state.a_filter_project_id > 0) {
					if (! indicators_applied[i].projects.find(element => element == this.state.a_filter_project_id)) fileterRes = false;
				}
				if (fileterRes) {
					filterData.push(indicators_applied[i]);
				}
			}
			this.indicatorAppliedTable.clear();
			this.indicatorAppliedTable.rows.add(filterData);
			this.indicatorAppliedTable.draw();
		}
	}
	handleTheorReset = (e) => {
		this.props.indicator_theorActions.getIndicatorTheors();
		this.setState({
			t_filter_name: '',
			t_filter_count_concepts_from: '',
			t_filter_count_concepts_to: '',
			t_filter_count_indicators_a_from: '',
			t_filter_count_indicators_a_to: '',
			t_filter_updatedAt_from: '',
			t_filter_updatedAt_to: '',
			t_filter_project_id: 0,
			t_filter_owner_ontoclasse: '',
			t_filter_auth: '',
			t_filter_biblio: '',
		});
		this.indicatorTheorTable.clear();
		if (this.props.indicator_theors.indicator_theors) {
			this.indicatorTheorTable.rows.add(this.props.indicator_theors.indicator_theors);
		}
		this.indicatorTheorTable.draw();
	}
	handleAppliedReset = (e) => {
		this.props.indicator_appliedActions.getIndicatorApplied();
		this.setState({
			a_filter_name: '',
			a_filter_updatedAt_from: '',
			a_filter_updatedAt_to: '',
			a_filter_project_id: 0,
			a_filter_owner_ontoclasse: '',
			a_filter_auth: '',
		})
	}
	handleDateChange = (date, type) => {
		switch (type) {
			case 't_filter_updatedAt_from':
				this.setState({ t_filter_updatedAt_from: date });
				break;
			case 't_filter_updatedAt_to':
				this.setState({ t_filter_updatedAt_to: date });
				break;
			case 'a_filter_updatedAt_from':
				this.setState({ a_filter_updatedAt_from: date });
				break;
			case 'a_filter_updatedAt_to':
				this.setState({ a_filter_updatedAt_to: date });
				break;
			default:
		}
	}
	render() {
		if ((this.props.indicator_theors && this.props.indicator_theors.loading)
			|| (this.props.indicators_applied && this.props.indicators_applied.loading)) {
			Swal.fire({
				title: 'Loading...',
				text: "Plese wait",
				onOpen: () => {
					Swal.showLoading();
				},
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false
			});
		} else {
			Swal.close();
		}
		var projects = this.props.projects && this.props.projects.projects ? this.props.projects.projects : null;
        var ontoclasses = this.props.ontoclasses && this.props.ontoclasses.ontoclasses ? this.props.ontoclasses.ontoclasses : [];
		return (
			<React.Fragment>
				<section className="content skin-indicatori">
					<div className="row">
						<div className="col-md-12">
							<div className="nav-tabs-custom">
								<ul className="nav nav-tabs">
									<li className="active"><a href="#tab_teorici" data-toggle="tab"><Trans id="Table.IndicatorsTheor"></Trans></a></li>
									<li><a href="#tab_applicati" data-toggle="tab"><Trans id="Table.IndicatorsApplied"></Trans></a></li>
								</ul>
								<div className="tab-content no-padding">
									<div className="tab-pane active" id="tab_teorici">
										<div className="top_comandi">
											<button type="button" edit="1" id="add" className="btn btn-default" onClick={() => { this.props.history.push('/newtheor') }}>
												<span className="glyphicon glyphicon-plus-sign"></span><Trans id="Button.InsertNewTheor"></Trans>
											</button>
										</div>
										<div className="ricerca">
											<div className="_form-inline">
												<div className="form-group row">
													<div className="col-md-4">
														<label><Trans id="Table.Filterby"></Trans>:</label>
														<div className="input-group">
															<span className="input-group-addon"><Trans id="Content.Name"></Trans></span>
															<input type="text" name="t_filter_name" className="form-control" value={this.state.t_filter_name} onChange={this.onFilterChange} />
														</div>
													</div>
													<div className="col-md-4">
														<label><Trans id="Table.Filterby"></Trans>:</label>
														<div className="input-group">
															<span className="input-group-addon"><Trans id="Table.Biblio"></Trans></span>
															<input type="text" name="t_filter_biblio" className="form-control" value={this.state.t_filter_biblio} onChange={this.onFilterChange} />
														</div>
													</div>
													<div className="col-md-4">
														<label><Trans id="Table.Filterby"></Trans>:</label>
														<div className="input-group">
															<span className="input-group-addon"><Trans id="Modal.Ontoclass"></Trans></span>
															<select name="t_filter_owner_ontoclass" className="form-control" value={this.state.t_filter_owner_ontoclass} onChange={this.onFilterChange}>
																<option key="onto_0" value={'0'} >Select</option>
																{
																	ontoclasses ? ontoclasses.map((ontoclass, index) => {
																		return <option key={"onto_" + index} value={ontoclass.ontoclass_id}>{ontoclass.ontoclass}</option>
																	}) : <></>
																}
															</select>
														</div>
													</div>
												</div>
												<div className="form-group row">
													<div className="col-md-6">
														<label><Trans id="Content.ConceptsUsed"></Trans>:</label>
														<div className="row">
															<div className="col-md-6">
																<div className="input-group">
																	<span className="input-group-addon"><Trans id="Table.From"></Trans></span>
																	<input type="number" size="2" name="t_filter_count_concepts_from" className="form-control" value={this.state.t_filter_count_concepts_from} onChange={this.onFilterChange} />
																</div>
															</div>
															<div className="col-md-6">
																<div className="input-group">
																	<span className="input-group-addon"><Trans id="Table.To"></Trans></span>
																	<input type="number" size="2" name="t_filter_count_concepts_to" className="form-control" value={this.state.t_filter_count_concepts_to} onChange={this.onFilterChange} />
																</div>
															</div>
														</div>
													</div>
													<div className="col-md-6">
														<label><Trans id="Table.IndicatorsApplied"></Trans> in <Trans id="Table.Contained"></Trans>:</label>
														<div className="row">
															<div className="col-md-6">
																<div className="input-group">
																	<span className="input-group-addon"><Trans id="Table.From"></Trans></span>
																	<input type="number" size="2" name="t_filter_count_indicators_a_from" className="form-control" value={this.state.t_filter_count_indicators_a_from} onChange={this.onFilterChange} />
																</div>
															</div>
															<div className="col-md-6">
																<div className="input-group">
																	<span className="input-group-addon"><Trans id="Table.To"></Trans></span>
																	<input type="number" size="2" name="t_filter_count_indicators_a_to" className="form-control" value={this.state.t_filter_count_indicators_a_to} onChange={this.onFilterChange} />
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="form-group row">
													<div className="col-md-6">
														<label><Trans id="Content.LastUpdate"></Trans>:</label>
														<div className="row">
															<div className="col-md-6">
																<div className="input-group">
																	<span className="input-group-addon"><Trans id="Table.From"></Trans></span>
																	<DatePicker onChange={(date) => {
																		this.handleDateChange(date, "t_filter_updatedAt_from");
																	}}
																		showMonthDropdown={true}
																		relativeSize={true}
																		selected={this.state.t_filter_updatedAt_from}
																		showYearDropdown={true}
																		scrollableYearDropdown={false}
																		dateFormat={'dd/MM/yyyy'}
																		className="form-control"
																		placeholderText="dd/mm/yyyy"
																	/>
																	<FontAwesomeIcon className="calendar-icon" icon={["far", "calendar"]} />
																	{/* <input type="date" size="2" name="t_filter_updatedAt_from" className="form-control" value={this.state.t_filter_updatedAt_from} onChange={this.onFilterChange} /> */}
																</div>
															</div>
															<div className="col-md-6">
																<div className="input-group">
																	<span className="input-group-addon"><Trans id="Table.To"></Trans></span>
																	<DatePicker onChange={(date) => {
																		this.handleDateChange(date, "t_filter_updatedAt_to");
																	}}
																		showMonthDropdown={true}
																		relativeSize={true}
																		selected={this.state.t_filter_updatedAt_to}
																		showYearDropdown={true}
																		scrollableYearDropdown={false}
																		dateFormat={'dd/MM/yyyy'}
																		className="form-control"
																		placeholderText="dd/mm/yyyy"
																	/>
																	<FontAwesomeIcon className="calendar-icon" icon={["far", "calendar"]} />
																	{/* <input type="date" size="2" name="t_filter_updatedAt_to" className="form-control" value={this.state.t_filter_updatedAt_to} onChange={this.onFilterChange} /> */}
																</div>
															</div>
														</div>
													</div>
													<div className="col-md-6">
														<label><Trans id="Table.Filterby"></Trans>:</label>
														<div className="input-group">
															<span className="input-group-addon"><Trans id="Table.Project"></Trans></span>
															<select name="t_filter_project_id" className="form-control" value={this.state.t_filter_project_id} onChange={this.onFilterChange}>
																<option key="type_0" value={0} disabled>Select</option>
																{
																	projects ? projects.map(project => {
																		return <option key={"project_" + project.project_id} value={project.project_id}>{project.name}</option>
																	}) : <></>
																}
															</select>
														</div>
													</div>
												</div>
												<div className="form-group row">
													<div className="col-md-8">
														<label><Trans id="Content.User"></Trans>:</label>
														<input type="text" name="t_filter_auth" className="form-control" value={this.state.t_filter_auth} onChange={this.onFilterChange} />
													</div>
													<div className="col-md-2">
														<label>&nbsp;</label>
														<button className="btn btn-default form-control" id="search_theor" onClick={() => { this.handleTheorSearch() }}><Trans id="Button.SearchFor"></Trans></button>
													</div>
													<div className="col-md-2">
														<label>&nbsp;</label>
														<button className="btn form-control" id="reset_theor" onClick={() => { this.handleTheorReset() }}><Trans id="Button.Reset"></Trans></button>
													</div>
												</div>
											</div>
										</div>
										<table id="list_table_theor" className="table table-hover">
											<thead>
												<tr>
													<th>
														<span data-tip data-for="header9"><Trans id="Content.Name" /></span>
														<ReactTooltip place="top" effect="solid" id="header9" className="dynTooltip">
															<div><Trans id="Content.Name" /></div>
														</ReactTooltip>
													</th>
													<th>
														<span data-tip data-for="header10"><Trans id="Content.Note" /></span>
														<ReactTooltip place="top" effect="solid" id="header10" className="dynTooltip">
															<div><Trans id="Content.Note" /></div>
														</ReactTooltip>
													</th>
													<th>
														<span data-tip data-for="header11"><Trans id="Content.ConceptsUsed" /></span>
														<ReactTooltip place="top" effect="solid" id="header11" className="dynTooltip">
															<div><Trans id="Content.ConceptsUsed" /></div>
														</ReactTooltip>
													</th>
													<th>
														<span data-tip data-for="header1"><Trans id="Content.Biblio" /></span>
														<ReactTooltip place="top" effect="solid" id="header1" className="dynTooltip">
															<div><Trans id="Content.Biblio" /></div>
														</ReactTooltip>
													</th>
													<th>
														<span data-tip data-for="header12"><Trans id="Table.IndicatorsAppliedAssociated" /></span>
														<ReactTooltip place="top" effect="solid" id="header12" className="dynTooltip">
															<div><Trans id="Table.IndicatorsAppliedAssociated" /></div>
														</ReactTooltip>
													</th>
													<th>
														<span data-tip data-for="header8"><Trans id="Modal.OntoclassRefer" /></span>
														<ReactTooltip place="top" effect="solid" id="header8" className="dynTooltip">
															<div><Trans id="Modal.OntoclassRefer" /></div>
														</ReactTooltip>:
													</th>
													<th>
														<span data-tip data-for="header13"><Trans id="Table.LastUpdate" /></span>
														<ReactTooltip place="top" effect="solid" id="header13" className="dynTooltip">
															<div><Trans id="Table.LastUpdate" /></div>
														</ReactTooltip>
													</th>
													<th>
														<span data-tip data-for="header14"><Trans id="Content.User" /></span>
														<ReactTooltip place="top" effect="solid" id="header14" className="dynTooltip">
															<div><Trans id="Content.User" /></div>
														</ReactTooltip>
													</th>
												</tr>
											</thead>
											<tbody>
											</tbody>
										</table>
									</div>
									<div className="tab-pane" id="tab_applicati">
										<div className="top_comandi"><button type="button" id="add" edit="1" className="btn btn-default" onClick={() => { this.props.history.push(`/newapplied`) }}>
											<span className="glyphicon glyphicon-plus-sign"></span> <Trans id="Button.InsertNewApplied"></Trans>
										</button>
										</div>
										<div className="ricerca">
											<div className="_form-inline">
												<div className="form-group row">
													<div className="col-md-6">
														<label><Trans id="Table.Filterby"></Trans>:</label>
														<div className="input-group">
															<span className="input-group-addon"><Trans id="Content.Name"></Trans></span>
															<input type="text" name="a_filter_name" className="form-control" value={this.state.a_filter_name} onChange={this.onFilterChange} />
														</div>
													</div>
													<div className="col-md-6">
														<label><Trans id="Table.Filterby"></Trans>:</label>
														<div className="input-group">
															<span className="input-group-addon"><Trans id="Modal.Ontoclass"></Trans></span>
															<select name="a_filter_owner_ontoclass" className="form-control" value={this.state.a_filter_owner_ontoclass} onChange={this.onFilterChange}>
																<option key="onto_0" value={'0'} >Select</option>
																{
																	ontoclasses ? ontoclasses.map((ontoclass, index) => {
																		return <option key={"onto_" + index} value={ontoclass.ontoclass_id}>{ontoclass.ontoclass}</option>
																	}) : <></>
																}
															</select>
														</div>
													</div>
												</div>
												<div className="form-group row">
													<div className="col-md-6">
														<label><Trans id="Content.LastUpdate"></Trans>:</label>
														<div className="row">
															<div className="col-md-6">
																<div className="input-group">
																	<span className="input-group-addon"><Trans id="Table.From"></Trans></span>
																	<DatePicker onChange={(date) => {
																		this.handleDateChange(date, "a_filter_updatedAt_from");
																	}}
																		showMonthDropdown={true}
																		relativeSize={true}
																		selected={this.state.a_filter_updatedAt_from}
																		showYearDropdown={true}
																		scrollableYearDropdown={false}
																		dateFormat={'dd/MM/yyyy'}
																		className="form-control"
																		placeholderText="dd/mm/yyyy"
																	/>
																	<FontAwesomeIcon className="calendar-icon" icon={["far", "calendar"]} />
																	{/* <input type="date" size="2" name="a_filter_updatedAt_from" className="form-control" value={this.state.a_filter_updatedAt_from} onChange={this.onFilterChange} /> */}
																</div>
															</div>
															<div className="col-md-6">
																<div className="input-group">
																	<span className="input-group-addon"><Trans id="Table.To"></Trans></span>
																	<DatePicker onChange={(date) => {
																		this.handleDateChange(date, "a_filter_updatedAt_to");
																	}}
																		showMonthDropdown={true}
																		relativeSize={true}
																		selected={this.state.a_filter_updatedAt_to}
																		showYearDropdown={true}
																		scrollableYearDropdown={false}
																		dateFormat={'dd/MM/yyyy'}
																		className="form-control"
																		placeholderText="dd/mm/yyyy"
																	/>
																	<FontAwesomeIcon className="calendar-icon" icon={["far", "calendar"]} />
																	{/* <input type="date" size="2" name="a_filter_updatedAt_to" className="form-control" value={this.state.a_filter_updatedAt_to} onChange={this.onFilterChange} /> */}
																</div>
															</div>
														</div>
													</div>
													<div className="col-md-6">
														<label><Trans id="Table.Filterby"></Trans>:</label>
														<div className="input-group">
															<span className="input-group-addon"><Trans id="Table.Project"></Trans></span>
															<select name="a_filter_project_id" className="form-control" value={this.state.a_filter_project_id} onChange={this.onFilterChange}>
																<option key="type_0" value={0} disabled>Select</option>
																{
																	projects ? projects.map(project => {
																		return <option key={"project_a_" + project.project_id} value={project.project_id}>{project.name}</option>
																	}) : <></>
																}
															</select>
														</div>
													</div>
												</div>
												<div className="form-group row">
													<div className="col-md-8">
														<label><Trans id="Content.User"></Trans>:</label>
														<input type="text" name="a_filter_auth" className="form-control" value={this.state.a_filter_auth} onChange={this.onFilterChange} />
													</div>
													<div className="col-md-2">
														<label>&nbsp;</label>
														<button className="btn btn-default form-control" id="search_applied" onClick={() => { this.handleAppliedSearch() }}><Trans id="Button.SearchFor"></Trans></button>
													</div>
													<div className="col-md-2">
														<label>&nbsp;</label>
														<button className="btn form-control" id="reset_applied" onClick={() => { this.handleAppliedReset() }}><Trans id="Button.Reset"></Trans></button>
													</div>
												</div>
											</div>
										</div>
										<table id="list_table_applied" className="table table-hover">
											<thead>
												<tr>
													<th>
														<span data-tip data-for="header2"><Trans id="Content.Name" /></span>
														<ReactTooltip place="top" effect="solid" id="header2" className="dynTooltip">
															<div><Trans id="Content.Name" /></div>
														</ReactTooltip>
													</th>
													<th>
														<span data-tip data-for="header3"><Trans id="Table.IndicatorTheor" /></span>
														<ReactTooltip place="top" effect="solid" id="header3" className="dynTooltip">
															<div><Trans id="Table.IndicatorTheor" /></div>
														</ReactTooltip>
													</th>
													<th>
														<span data-tip data-for="header8"><Trans id="Modal.OntoclassRefer" /></span>
														<ReactTooltip place="top" effect="solid" id="header8" className="dynTooltip">
															<div><Trans id="Modal.OntoclassRefer" /></div>
														</ReactTooltip>:
													</th>
													<th>
														<span data-tip data-for="header13"><Trans id="Table.LastUpdate" /></span>
														<ReactTooltip place="top" effect="solid" id="header13" className="dynTooltip">
															<div><Trans id="Table.LastUpdate" /></div>
														</ReactTooltip>
													</th>
													<th>
														<span data-tip data-for="header14"><Trans id="Content.User" /></span>
														<ReactTooltip place="top" effect="solid" id="header14" className="dynTooltip">
															<div><Trans id="Content.User" /></div>
														</ReactTooltip>
													</th>
												</tr>
											</thead>
											<tbody>
											</tbody>
										</table>
									</div>
								</div>
								<div className="comandi">
									<div>
										<button className="btn" id="back" onClick={() => { this.props.history.goBack() }}><b><Trans id="Button.Back"></Trans></b></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</React.Fragment>
		)
	}
}

export default connect(
	state => ({
		indicator_theors: state.indicator_theors,
		common: state.common,
		indicators_applied: state.indicators_applied,
		projects: state.projects,
        ontoclasses: state.ontoclasses
	}),
	dispatch => ({
		commonActions: bindActionCreators({ setPathname }, dispatch),
		projectActions: bindActionCreators({ getProjects }, dispatch),
		indicator_theorActions: bindActionCreators({ getIndicatorTheors }, dispatch),
		indicator_appliedActions: bindActionCreators({ getIndicatorApplied }, dispatch),
		modalActions: bindActionCreators({ showModal }, dispatch),
        ontoclassActions: bindActionCreators({ getOntoclasses }, dispatch),
	})
)(Indicator);
