import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <footer className="main-footer">
                <div className="pull-right hidden-xs">
                    <b>Version</b> 2.4.13
                </div>
                <strong>
                    Copyright © 2020 <a href="https://mathema.com">Mathema s.r.l.</a>
                </strong>{" "}
                All rights reserved.
			{/* <img src="images/loghi_partners.png" style={{"max-width": "100%","padding-top": "15px"}}/> */}
            </footer>
        )
    }
}

export default Footer;
