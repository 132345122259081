import { wrapRequest, xapi, uploadApi } from '../utils_services';

const get = wrapRequest(async () =>
    xapi().get('/projects')
);

const projectpost = wrapRequest(async (formData) => {

    console.log('form data in projectApi', formData);
    return uploadApi().post('/projects', formData)
});
const getProjectDetailApi = wrapRequest(async (project_id, main_flag) =>
    xapi().get('/projects/' + project_id + '/' + main_flag)
);
//const updateProjectApi = wrapRequest(async (project_id, name, description) =>
const updateProjectApi = wrapRequest(async (project_id, formData) => {
    console.log('form data in PUT projectApi', formData);
    // xapi().put('/projects/' + project_id, { name, description })
    return uploadApi().put('/projects/' + project_id, formData)
});
const updateProjectOntoclassApi = wrapRequest(async (project_id, ontoclasses, concepts, pro_onto_del_flag) =>
    xapi().post('/projects/' + project_id + '/addontoclass', { ontoclasses, concepts, pro_onto_del_flag })
);
const deleteProjectApi = wrapRequest(async (project_id) => xapi().delete('/projects/' + project_id)
)

export { get, projectpost, getProjectDetailApi, updateProjectApi, updateProjectOntoclassApi, deleteProjectApi };
