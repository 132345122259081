import { createStore, applyMiddleware, compose } from 'redux'
// import ReduxThunk from 'redux-thunk'
// import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // default: localStorage if web, AsyncStorage if react-native
import reducers from './services/reducers' // where reducers is an object of reducers
import history from './history';
import jwt_decode from 'jwt-decode';
import {
    authSubscriber,
    projectSubscriber,
    usersSubscriber,
    ontoclassSubscriber,
    lemmaSubscriber,
    definitionSubscriber,
    utilitySubscriber,
    conceptSubscriber,
    sourceSubscriber,
    bibliosSubscriber,
    indicator_theorSubscriber,
    indicator_appliedSubscriber,
    requestSubscriber,
    eventSubscriber,
    dashboardSubscriber
} from './services/saga';

const config = {
    key: 'root',
    storage,
}
const enhancers = [];
if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}
function authLink({ getState }) {
    return next => action => {
        let token = localStorage.getItem('token');
        let currentUser = localStorage.getItem('currentUser');
        // console.log('come to auth link', token, getState(), currentUser)
        if (token) {
            const { exp } = jwt_decode(token);
            const expirationTime = (exp * 1000) - 60000;
            if (Date.now() >= expirationTime) {
                // console.log('come to expiration', expirationTime)
                localStorage.clear();
                history.push('/login')
            }
        } else {
            history.push('/login')
        }
        const returnValue = next(action)
        return returnValue
    }
}
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware, authLink];
const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);
const reducer = persistCombineReducers(config, reducers)

function configureStore() {
    let store = createStore(reducer, {}, composedEnhancers)
    let persistor = persistStore(store)
    window.persistor = persistor
    sagaMiddleware.run(authSubscriber);
    sagaMiddleware.run(projectSubscriber);
    sagaMiddleware.run(usersSubscriber);
    sagaMiddleware.run(ontoclassSubscriber);
    sagaMiddleware.run(lemmaSubscriber);
    sagaMiddleware.run(definitionSubscriber);
    sagaMiddleware.run(utilitySubscriber);
    sagaMiddleware.run(conceptSubscriber);
    sagaMiddleware.run(sourceSubscriber);
    sagaMiddleware.run(bibliosSubscriber);
    sagaMiddleware.run(indicator_theorSubscriber);
    sagaMiddleware.run(indicator_appliedSubscriber);
    sagaMiddleware.run(requestSubscriber);
    sagaMiddleware.run(eventSubscriber);
    sagaMiddleware.run(dashboardSubscriber);
    // persistor.purge()
    return { persistor, store }
}

export default configureStore
