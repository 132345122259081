import { createActions } from 'redux-actions';

const {
    getOntoclasses,
    getOntoclassesFailed,
    getOntoclassesSucceed,
    postOntoclass,
    postOntoclassFailed,
    postOntoclassSucceed,
    getOntoclassDetail,
    getOntoclassDetailFailed,
    getOntoclassDetailSucceed,
    updateOntoclass,
    updateOntoclassFailed,
    updateOntoclassSucceed,
    deleteOntoclass,
    deleteOntoclassFailed,
    deleteOntoclassSucceed,
    getOntoclassAuto,
    getOntoclassAutoFailed,
    getOntoclassAutoSucceed
} = createActions({
    GET_ONTOCLASSES: () => ({}),
    GET_ONTOCLASSES_FAILED: error => ({ error }),
    GET_ONTOCLASSES_SUCCEED: ontoclasses => ({ ontoclasses }),
    POST_ONTOCLASS: ontoclass => ({ ontoclass }),
    POST_ONTOCLASS_FAILED: error => ({ error }),
    POST_ONTOCLASS_SUCCEED: ontoclass => ({ ontoclass }),
    GET_ONTOCLASS_DETAIL: ontoclass_id => ({ ontoclass_id }),
    GET_ONTOCLASS_DETAIL_FAILED: error => ({ error }),
    GET_ONTOCLASS_DETAIL_SUCCEED: ontoclasses => ({ ontoclasses }),
    UPDATE_ONTOCLASS: (ontoclass, ontoclass_id) => ({ ontoclass, ontoclass_id }),
    UPDATE_ONTOCLASS_FAILED: error => ({ error }),
    UPDATE_ONTOCLASS_SUCCEED: ontoclass => ({ ontoclass }),
    DELETE_ONTOCLASS: ontoclass_id => ({ontoclass_id}),
    DELETE_ONTOCLASS_FAILED: error => ({ error}),
    DELETE_ONTOCLASS_SUCCEED: () =>({}),
    GET_ONTOCLASS_AUTO: query_string => ({ query_string }),
    GET_ONTOCLASS_AUTO_FAILED: error => ({ error }),
    GET_ONTOCLASS_AUTO_SUCCEED: ontoclasses => ({ ontoclasses })
});

export {
    getOntoclasses,
    getOntoclassesFailed,
    getOntoclassesSucceed,
    postOntoclass,
    postOntoclassFailed,
    postOntoclassSucceed,
    getOntoclassDetail,
    getOntoclassDetailFailed,
    getOntoclassDetailSucceed,
    updateOntoclass,
    updateOntoclassFailed,
    updateOntoclassSucceed,
    deleteOntoclass,
    deleteOntoclassFailed,
    deleteOntoclassSucceed,
    getOntoclassAuto,
    getOntoclassAutoFailed,
    getOntoclassAutoSucceed
};
