import { handleActions } from 'redux-actions';
import { persistor} from 'redux-persist'
import {
  login,
  loginFailed,
  loginSucceed,
  logout,
  logoutSucceed
} from './authActions';

import {
  storeCurrentToken,
  storeCurrentUser,
  removeCurrentToken,
  removeCurrentUser
} from './services';

const defaultState = {
  currentUser: null,
  error: null,
  loading: false,
  token: null,
};

const reducer = handleActions(
  {
    [login](state) {
      return {
        ...state,
        error: null,
        loading: true
      };
    },
    [loginFailed](
      state,
      {
        payload: { error }
      }
    ) {
      console.log('error in failed login', error, state)
      return {
        ...state,
        error: error? error.data : {error: 'Error',message: 'Something went wrong'},
        currentUser: null,
        loading: false,
        token: null
      };
    },
    [loginSucceed](
      state,
      {
        payload: { userInfo }
      }
    ) {
      storeCurrentToken(userInfo.token);
      storeCurrentUser(userInfo.user)
      return {
        ...state,
        token: userInfo.token,
        currentUser: userInfo.user,
        error: null,
        loading: false
      };
    },
   
    [logout](state) {
      return {
        ...state,
      };
    },
    [logoutSucceed](state) {
      removeCurrentToken();
      removeCurrentUser();
      return {
        ...state,
        currentUser: null,
        token: null
      };
    }
  },
  defaultState
);

export default reducer;
