import { wrapRequest, xapi } from '../utils_services';

const getUtilitiesApi = wrapRequest(async ref_table => {
    return xapi().get('/utilities?ref_table=' + ref_table)
});

const postUtilitiesApi = wrapRequest(async (ref_table, label, owner_ontoclass_id) => {
    return xapi().post('/utilities', { ref_table, label, owner_ontoclass_id })
});
const updateUtilitiesApi = wrapRequest(async (utility_id, tbl, label) => {
    return xapi().put('/utilities/' + utility_id, { tbl, label })
});
const deleteUtilitiesApi = wrapRequest(async (utility_id, ref_table) => {
    console.log('-erere--', utility_id, ref_table)
    return xapi().delete('/utilities/' + utility_id+'/?ref_table='+ref_table, { ref_table })
});
export { getUtilitiesApi, postUtilitiesApi, updateUtilitiesApi, deleteUtilitiesApi }