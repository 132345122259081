import { createActions } from 'redux-actions';

const {
    getBiblios,
    getBibliosFailed,
    getBibliosSucceed,
    postBiblio,
    postBiblioFailed,
    postBiblioSucceed,
    getBiblioDetail,
    getBiblioDetailFailed,
    getBiblioDetailSucceed,
    updateBiblio,
    updateBiblioFailed,
    updateBiblioSucceed,
    deleteBiblio,
    deleteBiblioFailed,
    deleteBiblioSucceed,
    getBiblioAuto,
    getBiblioAutoFailed,
    getBiblioAutoSucceed

} = createActions({
    GET_BIBLIOS: () => ({}),
    GET_BIBLIOS_FAILED: error => ({ error }),
    GET_BIBLIOS_SUCCEED: biblios => ({ biblios }),
    POST_BIBLIO: (biblio, owner_ontoclass_id) => ({ biblio, owner_ontoclass_id }),
    POST_BIBLIO_FAILED: error => ({ error }),
    POST_BIBLIO_SUCCEED: biblios => ({ biblios }),
    GET_BIBLIO_DETAIL: biblio_id => ({ biblio_id }),
    GET_BIBLIO_DETAIL_FAILED: error => ({ error }),
    GET_BIBLIO_DETAIL_SUCCEED: biblio => ({ biblio }),
    UPDATE_BIBLIO: (biblio_id, biblio, owner_ontoclass_id) => ({ biblio_id, biblio, owner_ontoclass_id }),
    UPDATE_BIBLIO_FAILED: error => ({ error }),
    UPDATE_BIBLIO_SUCCEED: biblios => ({ biblios }),
    DELETE_BIBLIO: biblio_id => ({ biblio_id }),
    DELETE_BIBLIO_FAILED: error => ({ error }),
    DELETE_BIBLIO_SUCCEED: () => ({}),
    GET_BIBLIO_AUTO: query_string => ({ query_string }),
    GET_BIBLIO_AUTO_FAILED: error => ({ error }),
    GET_BIBLIO_AUTO_SUCCEED: biblios => ({ biblios })
});

export {
    getBiblios,
    getBibliosFailed,
    getBibliosSucceed,
    postBiblio,
    postBiblioFailed,
    postBiblioSucceed,
    getBiblioDetail,
    getBiblioDetailFailed,
    getBiblioDetailSucceed,
    updateBiblio,
    updateBiblioFailed,
    updateBiblioSucceed,
    deleteBiblio,
    deleteBiblioFailed,
    deleteBiblioSucceed,
    getBiblioAuto,
    getBiblioAutoFailed,
    getBiblioAutoSucceed
};
