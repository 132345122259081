import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Project from '../../project';
import OnToClass from '../../ontoclass';
import Dashboard from '../../dashboard';
import ContentHeader from './contentheader';
import ProjectDetail from '../../project/projectDetail';
import SubprojectDetail from '../../project/subprojectDetail';
import OntoclassDetail from '../../ontoclass/ontoclassDetail';
import Lemma from '../../lemma';
import LemmaDetail from '../../lemma/lemmaDetail'
import User from '../../user';
import UserDetail from '../../user/userDetail';
import Definition from '../../definition';
import DefinitionDetail from '../../definition/definitionDetail';
import Utilities from '../../utility';
import Concept from '../../concept';
import ConceptDetail from '../../concept/conceptDetail';
import NewConcept from '../../concept/newConcept';
import Biblio from '../../biblio';
import BiblioDetail from '../../biblio/biblioDetail';
import Source from '../../source';
import SourceDetail from '../../source/sourceDetail';
import Indicators from '../../indicator';
import NewTheor from '../../indicator/newTheor';
import TheorDetail from '../../indicator/theorDetail';
import AppliedDetail from '../../indicator/appliedDetail';
import NewApplied from '../../indicator/newApplied';
import Request from '../../request';
import NewRequest from '../../request/newRequest';
import RequestDetail from '../../request/requestDetail';
class Content extends React.Component {
    render() {
        var token = localStorage.getItem('token');
        if (!token) {
            return <Redirect to="/login" />
        } else {
            if (this.props.dashboard && this.props.dashboard.error && this.props.dashboard.error.data.error === "Unauthorized!") {
                localStorage.removeItem('token');
                return <Redirect to="/login" />
            }
        }
        return (
            <div className="content-wrapper">
                <ContentHeader />
                {/* Main content */}
                <Switch>
                    <Route path="/projects" render={props => <Project {...props} />} />
                    <Route exact path="/project/:id" render={props => <ProjectDetail {...props} />} />
                    <Route exact path="/subproject/:id" render={props => <SubprojectDetail {...props} />} />
                    <Route path="/ontoclasses" render={props => <OnToClass {...props} />} />
                    <Route exact path="/ontoclass/:id" render={props => <OntoclassDetail {...props} />} />
                    <Route path="/users" render={props => <User {...props} />} />
                    <Route path="/user/:id" render={props => <UserDetail {...props} />} />
                    <Route path="/lemmas" render={props => <Lemma {...props} />} />
                    <Route path="/lemma/:id" render={props => <LemmaDetail {...props} />} />
                    <Route path="/definitions" render={props => <Definition {...props} />} />
                    <Route path="/definition/:id" render={props => <DefinitionDetail {...props} />} />
                    <Route path="/sources" render={props => <Source {...props} />} />
                    <Route path="/source/:id" render={props => <SourceDetail {...props} />} />
                    <Route path="/biblios" render={props => <Biblio {...props} />} />
                    <Route path="/biblio/:id" render={props => <BiblioDetail {...props} />} />
                    <Route path="/utilities" render={props => <Utilities {...props} />} />
                    <Route path="/concepts/:source?" render={props => <Concept {...props} />} />
                    <Route exact path="/concept/:id" render={props => <ConceptDetail {...props} />} />
                    <Route path="/newconcept/:id" render={props => <NewConcept {...props} />} />
                    <Route path="/newconcept/" render={props => <NewConcept {...props} />} />
                    <Route path="/indicators" render={props => <Indicators {...props} />} />
                    <Route path="/newtheor/:theorId?/:conceptId?" render={props => <NewTheor {...props} />} />
                    <Route path="/theordetail/:theorId?" render={props => <TheorDetail {...props} />} />
                    <Route path="/applieddetail/:appliedId?" render={props => <AppliedDetail {...props} />} />
                    <Route path="/newapplied/:appliedId?/:indicator_t_id?" render={props => <NewApplied {...props} />} />
                    <Route path="/requests" render={props => <Request {...props} />} />
                    <Route path="/newrequest/:id?" render={props => <NewRequest {...props} />} />
                    <Route path="/request/:id?" render={props => <RequestDetail {...props} />} />
                    <Route path="/dashboard" render={props => <Dashboard {...props} />} />
                    <Redirect from='/' to='/login' />
                </Switch>
            </div>
        )
    }
}

export default Content;