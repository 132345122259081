import { handleActions } from 'redux-actions';

import {
    getBiblios,
    getBibliosFailed,
    getBibliosSucceed,
    postBiblio,
    postBiblioFailed,
    postBiblioSucceed,
    getBiblioDetail,
    getBiblioDetailFailed,
    getBiblioDetailSucceed,
    updateBiblio,
    updateBiblioFailed,
    updateBiblioSucceed,
    deleteBiblio,
    deleteBiblioFailed,
    deleteBiblioSucceed,
    getBiblioAuto,
    getBiblioAutoFailed,
    getBiblioAutoSucceed
} from './biblioActions';

const defaultState = {
    biblios: null,
    error: null,
    loading: false,
    success: false,
    message: null,
    sel_biblio: null,
};

const reducer = handleActions(
    {
        [getBiblios](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getBibliosFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error.data,
                biblios: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [getBibliosSucceed](
            state,
            {
                payload: { biblios }
            }
        ) {
            return {
                ...state,
                biblios: biblios,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
        [postBiblio](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null,
                success: false,
            };
        },
        [postBiblioFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in postFailed', error)
            return {
                ...state,
                error,
                biblios: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [postBiblioSucceed](
            state,
            {
                payload: { biblios }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                message: "Add Biblio Succeed!",
                sel_biblio: biblios,
                success: true
            };
        },
        [getBiblioDetail](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getBiblioDetailFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in getBiblioDetail Failed', error)
            return {
                ...state,
                error: error,
                biblios: null,
                loading: false,
                success: false,
                sel_biblio: null,
                message: error.data.message
            };
        },
        [getBiblioDetailSucceed](
            state,
            {
                payload: { biblio }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                success: true,
                message: null,
                sel_biblio: biblio
            };
        },
        [updateBiblio](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null
            };
        },
        [updateBiblioFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in updateBiblio', error)
            return {
                ...state,
                error,
                biblios: null,
                loading: false,
                success: false,
                message: error && error.message ? error.message : error
            };
        },
        [updateBiblioSucceed](
            state,
            {
                payload: { biblios }
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                sel_biblio: biblios,
                message: "Update Biblio Succeed!"
            };
        },
        [deleteBiblio](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null
            };
        },
        [deleteBiblioFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in deleteBiblio', error)
            return {
                ...state,
                error,
                biblios: null,
                loading: false,
                success: false,
                message: error && error.message ? error.message : error
            };
        },
        [deleteBiblioSucceed](
            state,
            {
                payload: { biblios }
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                sel_biblio: null,
                success: true,
                message: "Delete Biblio Succeed!"
            };
        },
        [getBiblioAuto](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: false,
                message: null
            };
        },
        [getBiblioAutoFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error ? error : 'Error',
                loading: false,
                success: false,
                message: error ? error.message : 'API connection error'
            };
        },
        [getBiblioAutoSucceed](
            state,
            {
                payload: { biblios }
            }
        ) {
            return {
                ...state,
                biblios: biblios,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
    },
    defaultState
);

export default reducer;
