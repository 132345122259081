import { handleActions } from 'redux-actions';

import {
    getUsersFromApi,
    getUsersFromApiFailed,
    getUsersFromApiSucceed,
    postUserToApi,
    postUserToApiFailed,
    postUserToApiSucceed,
    getUserDetail,
    getUserDetailFailed,
    getUserDetailSucceed,
    updateUser,
    updateUserFailed,
    updateUserSucceed,
    changeUserPwd,
    changeUserPwdFailed,
    changeUserPwdSucceed,
    getUserOntoclasses,
    getUserOntoclassesFailed,
    getUserOntoclassesSucceed,
    addUserOntoclasses,
    addUserOntoclassesFailed,
    addUserOntoclassesSucceed,
    getUserProjects,
    getUserProjectsFailed,
    getUserProjectsSucceed,
    addUserProjects,
    addUserProjectsFailed,
    addUserProjectsSucceed,
    deleteUser,
    deleteUserFailed,
    deleteUserSucceed
} from './usersAction';

const defaultState = {
    error: null,
    loading: false,
    users: null,
    success: false,
    message: null,
    ontoclasses: null,
    projects: null,
};

const reducer = handleActions(
    {
        [getUsersFromApi](state) {
            return {
                ...state,
                error: null,
                loading: false,
                message: null
            };
        },
        [getUsersFromApiFailed](
            state,
            {
                payload: { error }
            }
        ) {

            return {
                ...state,
                error: error.data,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [getUsersFromApiSucceed](
            state,
            {
                payload: { users }
            }
        ) {

            return {
                ...state,
                error: null,
                loading: false,
                users: users.user,
                success: true,
                message: null
            };
        },
        [postUserToApi](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null
            };
        },
        [postUserToApiFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error.data,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [postUserToApiSucceed](
            state,
            {
                payload: { user }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                users: user.user,
                success: true,
                message : "Insert New User Succeed!"
            };
        },
        [getUserDetail](state) {
            return {
                ...state,
                error: null,
                loading: false,
                message: null
            };
        },
        [getUserDetailFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error.data,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [getUserDetailSucceed](
            state,
            {
                payload: { user }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                users: user.user,
                success: true,
                message: null,
            };
        },
        [updateUser](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null
            };
        },
        [updateUserFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error.data,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [updateUserSucceed](
            state,
            {
                payload: { user }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                users: user.user,
                success: true,
                message: "User Update Succeed"
            };
        },
        [changeUserPwd](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null
            };
        },
        [changeUserPwdFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error.data,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [changeUserPwdSucceed](
            state,
            {
                payload: { user }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                users: user.user,
                success: true,
                message: "Change Password Succeed!"
            };
        },
        [getUserOntoclasses](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null
            };
        },
        [getUserOntoclassesFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error.data,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [getUserOntoclassesSucceed](
            state,
            {
                payload: { ontoclass }
            }
        ) {
console.log('getOntoclassSucceed', ontoclass)
            return {
                ...state,
                error: null,
                loading: false,
                ontoclasses: ontoclass,
                success: true,
                message: null
            };
        },
        [addUserOntoclasses](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null
            };
        },
        [addUserOntoclassesFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error.data,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [addUserOntoclassesSucceed](
            state,
            {
                payload: { user }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                users: user.user,
                success: true,
                message: "User-Ontoclass Association Updated Successfully!"
            };
        },
        [getUserProjects](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null
            };
        },
        [getUserProjectsFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error.data,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [getUserProjectsSucceed](
            state,
            {
                payload: { projects }
            }
        ) {

            return {
                ...state,
                error: null,
                loading: false,
                projects: projects.user,
                success: true,
                message: null
            };
        },
        [addUserProjects](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null
            };
        },
        [addUserProjectsFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error.data,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [addUserProjectsSucceed](
            state,
            {
                payload: { user }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                users: user.user,
                success: true,
                message: "User-Project Association Updated Successfully!"
            };
        },
        [deleteUser](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null
            };
        },
        [deleteUserFailed](
            state,
            {
                payload: { error }
            }
        ) {

            return {
                ...state,
                error: error.data,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [deleteUserSucceed](state) {
            return {
                ...state,
                error: null,
                loading: false,
                success: true,
                users: null,
                message: "User deleted successfully!"
            };
        },
    },
    defaultState
);

export default reducer;
