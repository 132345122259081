import { put, takeEvery, call, all } from 'redux-saga/effects';
/** Import actions */
import {
    getRequestsFailed,
    getRequestsSucceed,
    postRequestFailed,
    postRequestSucceed,
    getRequestDetail,
    getRequestDetailFailed,
    getRequestDetailSucceed,
    updateRequestFailed,
    updateRequestSucceed,
    deleteRequestFailed,
    deleteRequestSucceed,
    approveRequestFailed,
    approveRequestSucceed,
    discardRequestFailed,
    discardRequestSucceed
} from './requestActions';

/** Import api */
import * as requestApi from './requestApi';

export function* requestSubscriber() {
    yield all([takeEvery('GET_REQUESTS', getRequestSaga)]);
    yield all([takeEvery('POST_REQUEST', postRequestSaga)]);
    yield all([takeEvery('GET_REQUEST_DETAIL', getRequestDetailSaga)]);
    yield all([takeEvery('UPDATE_REQUEST', updateRequestSaga)]);
    yield all([takeEvery('DELETE_REQUEST', deleteRequestSaga)]);
    yield all([takeEvery('APPROVE_REQUEST', approveRequestSaga)]);
    yield all([takeEvery('DISCARD_REQUEST', discardRequestSaga)]);

}
export function* getRequestSaga() {
    try {
        const requests = yield call(requestApi.get);
        yield put(getRequestsSucceed(requests));
    } catch (error) {
        yield put(getRequestsFailed(error));
    }
}
export function* postRequestSaga({ payload: { object, request, ref_id, tbl, from_ontoclass_id, to_ontoclass_id } }) {
    try {
        const requests = yield call(requestApi.requestpost, object, request, ref_id, tbl, from_ontoclass_id, to_ontoclass_id);
        yield put(postRequestSucceed(requests));
    } catch (error) {
        yield put(postRequestFailed(error));
    }
}
export function* getRequestDetailSaga({ payload: { request_id } }) {
    try {
        const requests = yield call(requestApi.getRequestDetailApi, request_id);
        console.log('request in detail Saga', requests)
        yield put(getRequestDetailSucceed(requests));
    } catch (error) {
        console.log('request saga error')
        yield put(getRequestDetailFailed(error));
    }
}
export function* updateRequestSaga({ payload: { request_id, object, request, ref_id, tbl, from_ontoclass_id, to_ontoclass_id } }) {
    try {
        const requests = yield call(requestApi.updateRequestApi, request_id, object, request, ref_id, tbl, from_ontoclass_id, to_ontoclass_id);
        yield put(updateRequestSucceed(requests));
    } catch (error) {
        yield put(updateRequestFailed(error));
    }
}
export function* deleteRequestSaga({ payload: { request_id } }) {
    try {
        yield call(requestApi.deleteRequestApi, request_id);
        yield put(deleteRequestSucceed());
    } catch (error) {
        yield put(deleteRequestFailed(error));
    }
}
export function* approveRequestSaga({ payload: { request_id, answer } }) {
    try {
        const requests = yield call(requestApi.approveRequestApi, request_id, answer);
        yield put(approveRequestSucceed(requests));
        yield put(getRequestDetail(request_id));
    } catch (error) {
        yield put(approveRequestFailed(error));
    }
}
export function* discardRequestSaga({ payload: { request_id, answer } }) {
    try {
        const requests = yield call(requestApi.discardRequestApi, request_id, answer);
        yield put(discardRequestSucceed(requests));
        yield put(getRequestDetail(request_id));
    } catch (error) {
        yield put(discardRequestFailed(error));
    }
}