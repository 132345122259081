import React from 'react';
import { connect } from 'react-redux';
import { setPathname, addPathname, deletePathname } from '../../services/common/commonActions';
import { getRequests, postRequest, getRequestDetail, updateRequest } from '../../services/request/requestActions';
import { getOntoclasses } from '../../services/ontoclass/ontoclassActions';
import { getConceptAuto } from '../../services/concept/conceptActions';
import { getLemmaAuto } from '../../services/lemma/lemmaActions';
import { getSourceAuto } from '../../services/source/sourceActions';
import { getDefinitionAuto } from '../../services/definition/definitionActions';
import { getIndicatorTheorAuto } from '../../services/indicatorTheor/indicator_theorActions';
import { showModal } from '../../modals/modalConductorActions';
import Trans from '../../services/utils/Trans';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

class NewRequest extends React.Component {
    constructor(props) {
        super();
        this.state = {
            request_id: null,
            owner_ontoclass_id: 1,
            tbl: 'concept',
            requestOptions: [],
            object: '',
            request: '',
            to_ontoclass: '',
            to_ontoclass_id: '',
            entity: '',
            ref_id: 0
        }
        this.request_typeahead_ref = null;
    }
    componentWillMount() {
        // this.props.commonActions.setPathname(
        //     [
        //         { "pathName": "Home", "Url": "", "id": 1, "color": "" },
        //         { "pathName": "Requests", "Url": "requests", "id": 2, "color": "#bfa422" },
        //         { "pathName": "NewRequest", "Url": "/", "id": 3, "color": "#bfa422" },
        //     ]
        // )
        this.props.requestActions.getRequests();
        this.props.ontoclassActions.getOntoclasses();
        var ontoclasses = null;
        if (this.props && this.props.auth && this.props.auth.currentUser) {
            var profile = this.props.auth.currentUser.profile;
            if (profile === "Administrator") {
                ontoclasses = this.props.ontoclasses ? this.props.ontoclasses.ontoclasses : []
            } else if (profile === "Publisher") {
                ontoclasses = this.props.auth.currentUser.ontoclasses
            }
            if (ontoclasses !== null && ontoclasses.length !== 0) {
                this.setState({
                    owner_ontoclass_id: ontoclasses[0].ontoclass_id
                })
            }
        }
        if (this.props.match.params && this.props.match.params.id) {
            var id = this.props.match.params.id;
            this.props.requestActions.getRequestDetail(id);
            this.setState({ request_id: id });
        } else {
            this.props.commonActions.addPathname(
                { "pathName": "New Request", "Url": "newrequest", "color": "#6d81ab", "subHeader": "New Request"}
            )
        }
    }
    componentDidUpdate(prevProps, prevState) {
        var props = this.props;
        if (props.requests && props.requests.sel_request && props.requests.sel_request !== prevProps.requests.sel_request && this.state.request_id) {
            var sel_request = props.requests.sel_request.request;
            this.setState({
                owner_ontoclass_id: sel_request.from_ontoclass_id ? sel_request.from_ontoclass_id : this.state.owner_ontoclass_id,
                tbl: sel_request.tbl ? sel_request.tbl : this.state.tbl,
                ref_id: sel_request.ref_id ? sel_request.ref_id : 0,
                object: sel_request.object ? sel_request.object : '',
                request: sel_request.request ? sel_request.request : '',
                to_ontoclass_id: sel_request.to_ontoclass_id ? sel_request.to_ontoclass_id : this.state.to_ontoclass_id,
                entity: sel_request.description_entity ? sel_request.description_entity : '',
                to_ontoclass: sel_request.to_ontoclass ? sel_request.to_ontoclass: ''
            })
        }
        if (props.concepts && props.concepts.concepts && prevProps.concepts && prevProps.concepts.concepts && props.concepts.concepts !== prevProps.concepts.concepts) {
            this.setState({
                requestOptions: props.concepts.concepts.suggestions
            })
        }
        if (props.lemmas && props.lemmas.lemmas && prevProps.lemmas && prevProps.lemmas.lemmas && props.lemmas.lemmas !== prevProps.lemmas.lemmas) {
            this.setState({
                requestOptions: props.lemmas.lemmas.suggestions
            })
        }
        if (props.definitions && props.definitions.definitions && prevProps.definitions && prevProps.definitions.definitions && props.definitions.definitions !== prevProps.definitions.definitions) {
            this.setState({
                requestOptions: [...props.definitions.definitions.suggestions]
            })
        }
        if (props.sources && props.sources.sources && prevProps.sources && prevProps.sources.sources && props.sources.sources !== prevProps.sources.sources) {
            this.setState({
                requestOptions: props.sources.sources.suggestions
            })
        }
        if (props.indicator_theors && props.indicator_theors.indicator_theors && prevProps.indicator_theors && prevProps.indicator_theors.indicator_theors && props.indicator_theors.indicator_theors !== prevProps.indicator_theors.indicator_theors) {
            this.setState({
                requestOptions: props.indicator_theors.indicator_theors.suggestions
            })
        }
        if (props.requests.error !== prevProps.requests.error && props.requests.error !== null) {
            toastr.error('Error Occured', props.requests.message);
        }

        if (props.requests.error === null && !props.requests.loading && prevProps.requests && prevProps.requests.loading && props.requests.message) {
            toastr.success('Success', props.requests.message);
            if (props.requests.sel_request && props.requests.sel_request !== prevProps.requests.sel_request) {
                props.history.push(`/request/${props.requests.sel_request.request.request_id}`)
            }
        }
    }
    componentDidMount() {

    }
    handleAddRequest = (item) => {
        if (item[0] && item[0].value) {
            this.setState({ ref_id: item[0].value });
            if (item[0].ontoclass) {
                this.setState({ to_ontoclass: item[0].ontoclass });
            }
            if (item[0].ontoclass_id) {
                this.setState({ to_ontoclass_id: item[0].ontoclass_id });
            }
            if (item[0].label) {
                this.setState({ entity: item[0].label });
            }
            const instance = this.request_typeahead_ref.getInstance()
            instance.clear()
            instance.focus()
        }
    }
    handleRequestSearch = (query) => {
        var table = this.state.tbl;
        switch (table) {
            case 'concept':
                this.props.conceptActions.getConceptAuto(query);
                break;
            case 'lemma':
                this.props.lemmaActions.getLemmaAuto(query);
                break;
            case 'definition':
                this.props.definitionActions.getDefinitionAuto(query);
                break;
            case 'indicator_theor':
                this.props.indicator_theorActions.getIndicatorTheorAuto(query);
                break;
            case 'source':
                this.props.sourceActions.getSourceAuto(query);
                break;
            default:
        }
    }
    onFilterChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handlePostRequest = () => {
        var object = this.state.object;
        var request = this.state.request;
        var ref_id = this.state.ref_id;
        var tbl = this.state.tbl;
        var from_ontoclass_id = this.state.owner_ontoclass_id;
        var to_ontoclass_id = this.state.to_ontoclass_id
        if (!object && !request && !ref_id && !tbl && !from_ontoclass_id && !to_ontoclass_id) {
            Swal.fire({
                icon: 'error',
                title: 'You need to select all fields',
            });
            return;
        }
        if (this.state.request_id) {
            this.props.requestActions.updateRequest(this.state.request_id, object, request, ref_id, tbl, from_ontoclass_id, to_ontoclass_id)
        } else {
            this.props.requestActions.postRequest(object, request, ref_id, tbl, from_ontoclass_id, to_ontoclass_id);
        }
    }
    render() {
        if (this.props.requests.loading) {
            Swal.fire({
                title: 'Loading...',
                text: "Plese wait",
                onOpen: () => {
                    Swal.showLoading();
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
        } else {
            Swal.close();
        }
        var ontoclasses = null;
        if (this.props && this.props.auth && this.props.auth.currentUser) {
            var profile = this.props.auth.currentUser.profile;
            if (profile === "Administrator") {
                ontoclasses = this.props.ontoclasses ? this.props.ontoclasses.ontoclasses : null
            } else if (profile === "Publisher") {
                ontoclasses = this.props.auth.currentUser.ontoclasses ? this.props.auth.currentUser.ontoclasses : null
            }
        }
        return (
            <React.Fragment>
                <section className="content skin-richieste">
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label><Trans id="Content.RequestingOntoclass"></Trans>:</label>
                            <select name='owner_ontoclass_id' value={this.state.owner_ontoclass_id} className="form-control" onChange={this.onFilterChange}>
                                {
                                    ontoclasses && ontoclasses.length > 0 ? ontoclasses.map(ontoclass => {
                                        return <option key={"onto_" + ontoclass.ontoclass_id} value={ontoclass.ontoclass_id}>{ontoclass.ontoclass}</option>
                                    }) : <></>
                                }
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-6">
                            <label><Trans id="Content.SearchEntity"></Trans>:</label>
                            <select name="tbl" className="form-control" onChange={this.onFilterChange} value={this.state.tbl}>
                                <option value="concept">Concetto</option>
                                <option value="definition">Definizione</option>
                                <option value="source">Fonte</option>
                                <option value="indicator_theor">Indicatore teorico</option>
                                <option value="lemma">Lemma</option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label></label>
                            <AsyncTypeahead
                                minLength={2}
                                id="search"
                                isLoading={false}
                                onChange={(e) => { this.handleAddRequest(e) }}
                                onSearch={this.handleRequestSearch}
                                options={this.state.requestOptions}
                                placeholder="Ricerca..."
                                ref={(ref) => this.request_typeahead_ref = ref}
                            />
                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            {
                                this.state.ref_id === 0 ? <div id="nosearch"><label><Trans id="Content.NoEntity"></Trans></label></div> : <></>
                            }
                            <input type="hidden" name="ref_id" />
                            <input type="hidden" name="to_ontoclass_id" />
                            <div><label><Trans id="Table.Ontoclass"></Trans>:</label><span id="to_ontoclass">{this.state.to_ontoclass}</span></div>
                            <div><label><Trans id="Content.Entity"></Trans>:</label><span id="entity">{this.state.entity}</span></div>
                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label><Trans id="Table.Object"></Trans>:</label>
                            <input type="text" className="form-control" name="object" placeholder="Oggetto" value={this.state.object} onChange={this.onFilterChange} />
                        </div>
                    </div>
                    <div className="form-group row underlined">
                        <div className="col-md-12">
                            <label><Trans id="Content.Request"></Trans>:</label>
                            <textarea className="form-control required" name="request" value={this.state.request} onChange={this.onFilterChange}></textarea>
                        </div>
                    </div>
                    <div className="comandi">
                        <button className="btn btn-default" id="confirm" onClick={() => { this.handlePostRequest() }}><b><Trans id="Button.Confirm"></Trans></b></button>
                        <button className="btn" id="back" onClick={() => { this.props.history.goBack() }}><b><Trans id="Button.Back"></Trans></b></button>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({
        requests: state.requests,
        ontoclasses: state.ontoclasses,
        auth: state.auth,
        definitions: state.definitions,
        lemmas: state.lemmas,
        sources: state.sources,
        concepts: state.concepts,
        indicator_theors: state.indicator_theors
    }),
    dispatch => ({
        commonActions: bindActionCreators({ setPathname, addPathname, deletePathname }, dispatch),
        requestActions: bindActionCreators({ getRequests, postRequest, getRequestDetail, updateRequest }, dispatch),
        ontoclassActions: bindActionCreators({ getOntoclasses }, dispatch),
        conceptActions: bindActionCreators({ getConceptAuto }, dispatch),
        lemmaActions: bindActionCreators({ getLemmaAuto }, dispatch),
        definitionActions: bindActionCreators({ getDefinitionAuto }, dispatch),
        sourceActions: bindActionCreators({ getSourceAuto }, dispatch),
        indicator_theorActions: bindActionCreators({ getIndicatorTheorAuto }, dispatch),

        modalActions: bindActionCreators({ showModal }, dispatch),
    })
)(NewRequest);
