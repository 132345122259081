import { handleActions } from 'redux-actions';

import {
    getProjects,
    getProjectsFailed,
    getProjectsSucceed,
    postProject,
    postProjectFailed,
    postProjectSucceed,
    getProjectDetail,
    getProjectDetailFailed,
    getProjectDetailSucceed,
    setSubProjectConcepts,
    updateProject,
    updateProjectFailed,
    updateProjectSucceed,
    updateProjectOntoclass,
    updateProjectOntoclassFailed,
    updateProjectOntoclassSucceed,
    deleteProject,
    deleteProjectFailed,
    deleteProjectSucceed,
} from './projectActions';

const defaultState = {
    projects: null,
    error: null,
    loading: false,
    success: false,
    message: null,
    sel_project: null,
    subproject_concepts: null
};

const reducer = handleActions(
    {
        [getProjects](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null
            };
        },
        [getProjectsFailed](
            state,
            {
                payload: { error }
            }
        ) {
            return {
                ...state,
                error: error.data,
                projects: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [getProjectsSucceed](
            state,
            {
                payload: { projects }
            }
        ) {
            return {
                ...state,
                projects: projects.user,
                error: null,
                loading: false,
                success: true,
                message: null
            };
        },
        [postProject](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null
            };
        },
        [postProjectFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in postFailed', error)
            return {
                ...state,
                error,
                projects: null,
                loading: false,
                success: false,
                message: error.data.message
            };
        },
        [postProjectSucceed](state,
            {
                payload: { projects }
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                message: "Add Project Succeed!",
                sel_project: projects.user
            };
        },
        [getProjectDetail](state) {
            return {
                ...state,
                error: null,
                loading: true,
                success: false,
                message: null,
                sel_project: null
            };
        },
        [getProjectDetailFailed](
            state,
            {
                payload: { error }
            }
        ) {console.log('error in getProjectDetail Failed' ,error)
            return {
                ...state,
                error: error,
                projects: null,
                loading: false,
                success: false,
                sel_project: null,
                message: error ? error.data.message: null
            };
        },
        [getProjectDetailSucceed](
            state,
            {
                payload: { project }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                success: true,
                message: null,
                sel_project: project.user
            };
        },
        [setSubProjectConcepts](
            state,
            {
                payload: { concepts }
            }
        ) {
            return {
                ...state,
                error: null,
                loading: false,
                success: true,
                message: null,
                subproject_concepts: concepts
            };
        },
        [updateProject](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null
            };
        },
        [updateProjectFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in updateProject', error)
            return {
                ...state,
                error: error.data,
                projects: null,
                loading: false,
                success: false,
                message: error && error.data ? error.data.message : error.message
            };
        },
        [updateProjectSucceed](
            state,
            {
                payload: { project }
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                sel_project: project.user,
                message: "Edit Project Succeed!"
            };
        },
        [updateProjectOntoclass](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null
            };
        },
        [updateProjectOntoclassFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in updateProjectOntoclass', error)
            return {
                ...state,
                error: error.data,
                projects: null,
                loading: false,
                success: false,
                message: error && error.data ? error.data.message : error.message
            };
        },
        [updateProjectOntoclassSucceed](
            state,
            {
                payload: { project }
            }) {
            return {
                ...state,
                error: null,
                loading: false,
                sel_project: project.user,
                message: "Update Project_Ontoclass Succeed!"
            };
        },
        [deleteProject](state) {
            return {
                ...state,
                error: null,
                loading: true,
                message: null,
                success: false
            };
        },
        [deleteProjectFailed](
            state,
            {
                payload: { error }
            }
        ) {
            console.log('error in deleteProject', error)
            return {
                ...state,
                error,
                loading: false,
                success: false,
                message: error && error.message ? error.message : error
            };
        },
        [deleteProjectSucceed](state) {
            return {
                ...state,
                error: null,
                loading: false,
                message: "Delete Project Succeed!",
                sel_project: null,
                success: true
            };
        },

    },
    defaultState
);

export default reducer;
