import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateProject } from '../../../../services/project/projectActions';
import { getOntoclasses } from '../../../../services/ontoclass/ontoclassActions';
import { hideModal } from '../../../modalConductorActions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import Trans from '../../../../services/utils/Trans';

class ProjectEditModal extends React.Component {
    constructor() {
        super();
        this.onCancel = this.onCancel.bind(this);
    }
    componentWillMount(){
        this.props.ontoclassActions.getOntoclasses();
    }
    onCancel() {
        this.props.modalActions.hideModal();
    }
    componentDidUpdate(prevProps){
        console.log('PropsUPdaet in edit modal', this.props)

    }
    render() {
        console.log('Props in edit modal', this.props)
        return (
            <Modal
                isOpen={true}
                toggle={this.props.modalActions.hideModal}
            >
                <ModalHeader><Trans id="Modal.EditProject"></Trans></ModalHeader>
                <Formik
                    initialValues={{
                        name: this.props.projects.sel_project.name,
                        description: this.props.projects.sel_project.description,
                        link: this.props.projects.sel_project.link,
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required('Name is required'),
                        description: Yup.string(),
                    })}
                    onSubmit={fields => {
                        const formData = new FormData();
                        formData.append('project_id', this.props.projects.sel_project.project_id);
                        formData.append('myImage', fields.logo);
                        formData.append('name', fields.name);
                        formData.append('description', fields.description)
                        formData.append('link', fields.link)
                        // this.props.projectActions.updateProject(this.props.projects.sel_project.project_id, fields.name, fields.description);
                        this.props.projectActions.updateProject(this.props.projects.sel_project.project_id, formData);
                        this.props.modalActions.hideModal();
                    }}
                    render={({ errors, status, touched, setFieldValue }) => (
                        <Form className="skin-progetti">
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Modal.Name"></Trans></label>
                                    <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} placeholder="Nome del progetto" />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Modal.Description"></Trans></label>
                                    <Field as="textarea" name="description" type="text" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} placeholder="Descrizione del progetto" />
                                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Modal.Link"></Trans></label>
                                    <Field as="textarea" name="link" type="text" className={'form-control' + (errors.link && touched.link ? ' is-invalid' : '')} placeholder="Link del progetto" />
                                    <ErrorMessage name="link" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalBody>
                                <div className="form-group">
                                    <label className="text-muted text-center"><Trans id="Modal.Logo"></Trans></label>
                                    <input name="logo" type="file" className={'form-control' + (errors.logo && touched.logo ? ' is-invalid' : '')} onChange={(event) => {
                                        setFieldValue("logo", event.currentTarget.files[0]);
                                    }} />
                                    <ErrorMessage name="logo" component="div" className="invalid-feedback" />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button type="submit" className="btn btn-default" >
                                    <Trans id="Button.Confirm"></Trans>
                                </button>
                                <button className="btn" onClick={this.onCancel}>
                                    <Trans id="Button.Cancel"></Trans>
                                </button>
                            </ModalFooter>
                        </Form>
                    )}
                />
            </Modal>
        );
    }
}

export default connect(
    state => ({
        projects: state.projects,
        ontoclasses: state.ontoclasses
    }), dispatch => ({
        projectActions: bindActionCreators({ updateProject }, dispatch),
        ontoclassActions: bindActionCreators({ getOntoclasses }, dispatch),
        modalActions: bindActionCreators({ hideModal }, dispatch),
    })
)(ProjectEditModal);
