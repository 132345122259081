import { put, takeEvery, call, all } from 'redux-saga/effects';
/** Import actions */
import {
    getOntoclassesFailed,
    getOntoclassesSucceed,
    postOntoclassFailed,
    postOntoclassSucceed,
    getOntoclassDetailFailed,
    getOntoclassDetailSucceed,
    updateOntoclassFailed,
    updateOntoclassSucceed,
    deleteOntoclassFailed,
    deleteOntoclassSucceed,
    getOntoclassAutoFailed,
    getOntoclassAutoSucceed
} from './ontoclassActions';

/** Import api */
import * as ontoclassApi from './ontoclassApi';

export function* ontoclassSubscriber() {
    yield all([takeEvery('GET_ONTOCLASSES', getOntoclassSaga)]);
    yield all([takeEvery('POST_ONTOCLASS', postOntoclassSaga)]);
    yield all([takeEvery('GET_ONTOCLASS_DETAIL', getOntoclassDetailSaga)]);
    yield all([takeEvery('UPDATE_ONTOCLASS', updateOntoclassSaga)]);
    yield all([takeEvery('DELETE_ONTOCLASS', deleteOntoclassSaga)]);
    yield all([takeEvery('GET_ONTOCLASS_AUTO', getOntoclassAutoSaga)]);
}
export function* getOntoclassSaga() {
    try {
        const ontoclasses = yield call(ontoclassApi.get);
        yield put(getOntoclassesSucceed(ontoclasses));
    } catch (error) {
        yield put(getOntoclassesFailed(error));
    }
}
export function* getOntoclassAutoSaga({payload: {query_string}}) {
    try {
        const ontoclasses = yield call(ontoclassApi.getOntoclassAutoApi,query_string);
        yield put(getOntoclassAutoSucceed(ontoclasses));
    } catch (error) {
        yield put(getOntoclassAutoFailed(error));
    }
}
export function* postOntoclassSaga({ payload: { ontoclass } }) {
    try {
        const ontoclasses = yield call(ontoclassApi.ontoclasspost, ontoclass);
        yield put(postOntoclassSucceed(ontoclasses));
    } catch (error) {
        yield put(postOntoclassFailed(error));
    }
}
export function* getOntoclassDetailSaga({ payload: { ontoclass_id } }) {
    try {
        const ontoclasses = yield call(ontoclassApi.getOntoclassDetailApi, ontoclass_id);
        yield put(getOntoclassDetailSucceed(ontoclasses));
    } catch (error) {
        console.log('ontoclass saga error')
        yield put(getOntoclassDetailFailed(error));
    }
}
export function* updateOntoclassSaga({ payload: { ontoclass, ontoclass_id } }) {
    try {
        const ontoclasses = yield call(ontoclassApi.updateOntoclassApi, ontoclass, ontoclass_id);
        yield put(updateOntoclassSucceed(ontoclasses));
    } catch (error) {
        yield put(updateOntoclassFailed(error));
    }
}
export function* deleteOntoclassSaga({ payload: { ontoclass_id } }) {
    try {
        yield call(ontoclassApi.deleteOntoclassApi, ontoclass_id);
        yield put(deleteOntoclassSucceed());
    } catch (error) {
        yield put(deleteOntoclassFailed(error));
    }
}